import React, { useEffect, useState } from "react";
import Layout from "../../utils/layout";
import { Link } from "react-router-dom";
import UnitModal from "./UnitModal";
import RemoveCategoryModal from "./RemoveCategoryModal";
import CategoryRow from "./UnitRow";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";

const Unit = () => {
  const [showCategoryModal, setshowCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteCategory] = useState(false);
  const [mode, setMode] = useState("");
  const [updateUnit, setUpdateUnit] = useState(null);

  const {
    userStore: { userData },
    dashboardStore: { fetchUnit, unitList, searchUnit, filterUnit },
  } = useStore();

  const handleNewCategory = () => {
    setshowCategoryModal(!showCategoryModal);
    setMode("create");
  };

  const handleDeleteCategory = () => {
    setShowDeleteCategory(true);
  };

  const handleEditCategory = (unit) => {
    setshowCategoryModal(!showCategoryModal);
    setMode("edit");
    setUpdateUnit(unit);
  };

  const handleCloseNewCategoryMdoal = () => {
    setshowCategoryModal(false);
    setUpdateUnit(null);
  };

  const handleCloseDeleteCategoryMdoal = () => {
    setShowDeleteCategory(!showDeleteModal);
  };

  useEffect(() => {
    fetchUnit();
  }, [fetchUnit]);

  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Unit</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link
                        to={
                          userData.role === "owner"
                            ? "/admin/dashboard"
                            : "/company/dashboard"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">Unit</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            id="searchTableList"
                            placeholder="Search..."
                            value={searchUnit}
                            onChange={(e) => filterUnit(e.target.value)}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="text-sm-end">
                        <button
                          type="button"
                          onClick={handleNewCategory}
                          data-bs-toggle="modal"
                          data-bs-target="#newCustomerModal"
                          className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                        >
                          <i className="mdi mdi-plus me-1"></i> New Unit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive ">
                                <table className="table align-middle table-bordered table-nowrap mb-0">
                      <thead >
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name</th>
                          <th>Short Name</th>
                          <th>Position</th>
                          <th>Create Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(unitList) &&
                          unitList.map((category, id) => {
                            return (
                              <CategoryRow
                                handleDeleteCategory={handleDeleteCategory}
                                handleEditCategory={() =>
                                  handleEditCategory(category)
                                }
                                category={category}
                                key={id}
                                index={id + 1}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UnitModal
        open={showCategoryModal}
        onClose={handleCloseNewCategoryMdoal}
        mode={mode}
        initialState={updateUnit}
      />
      <RemoveCategoryModal
        open={showDeleteModal}
        onClose={handleCloseDeleteCategoryMdoal}
      />
    </Layout>
  );
};

export default observer(Unit);
