import {
  sumValues,
  calculateRevenue,
  percentage,
  sum,
  sumOfPer,
  getValueToShow
} from "../../src/utils/helpers";

const WITHOUT_ENVIORNMENT_BRSR_FORM_FIELDS = {

  SectionA: [
    {
      type: "accordion",
      label: "PRODUCT & SERVICES",
      tables: [
        {
          label:
            "Details of business activities (accounting for 90% of the turnover):",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                value: "a",
                type: "static",
                id: "0-0-0-0",
              },
              {
                value: "Description of Main Activity",
                type: "static",
                id: "0-0-0-0",
              },
              {
                id: "0-0-0-0",
                type: "text",
                key: "Description of Main Activity",
                valKey: "value",
              },
            ],
            [
              {
                value: "b",
                type: "static",
                id: "0-0-0-1",
              },
              {
                value: "Description of Business Activity",
                type: "static",
                id: "0-0-0-1",
              },
              {
                id: "0-0-0-1",
                type: "text",
                key: "Description of Business Activity",
                valKey: "value",
              },
            ],
            [
              {
                value: "c",
                type: "static",
                id: "0-0-0-2",
              },
              {
                value: "% of Turnover of the entity",
                type: "static",
                id: "0-0-0-2",
              },
              {
                id: "0-0-0-2",
                type: "text",
                key: "% of Turnover of the entity",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label:
            "Products/Services sold by the entity (accounting for 90% of the entity’s Turnover)",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                value: "a",
                type: "static",
                id: "0-0-1-0",
              },
              {
                value: "Product/Service",
                type: "static",
                id: "0-0-1-0",
              },
              {
                id: "0-0-1-0",
                type: "text",
                key: "Product/Service",
                valKey: "value",
              },
            ],
            [
              {
                value: "b",
                type: "static",
                id: "0-0-1-1",
              },
              {
                value: "NIC Code",
                type: "static",
                id: "0-0-1-1",
              },
              {
                id: "0-0-1-1",
                type: "text",
                key: "NIC Code",
                valKey: "value",
              },
            ],
            [
              {
                value: "c",
                type: "static",
                id: "0-0-1-2",
              },
              {
                value: "% of total Turnover contributed",
                type: "static",
                id: "0-0-1-2",
              },
              {
                id: "0-0-1-2",
                type: "text",
                key: "% of total Turnover contributed",
                valKey: "value",
              },
            ],
          ],
        },
      ],
    },
    {
      type: "accordion",
      label: "Operations",
      tables: [
        {
          label:
            "Number of locations where plants and/or operations/offices of the entity are situated",
          th: ["SR", "Location", "Number of plants", "Number of offices", "Total"],
          tr: [
          
            [
              {
                value: "a",
                type: "static",
                id: "0-1-0-1",
              },
              {
                value: "National",
                type: "static",
                id: "0-1-0-1",
              },
              {
                id: "0-1-0-1",
                type: "text",
                key: "National",
                valKey: "Number_of_plants",
              },
              {
                id: "0-1-0-1",
                type: "text",
                key: "National",
                valKey: "Number_of_offices",
              },
              {
                id: "0-1-0-1",
                type: "text",
                key: "National",
                valKey: "Total",
                // calculation: ({ data }) => {
                //   const a = Number(data.find(e => e.id === '0-1-0-1')?.value?.Number_of_plants || 0);
                //   const b = Number(data.find(e => e.id === '0-1-0-1')?.value?.Number_of_offices || 0);
                //   return a + b;
                // },
              },
            ],
            [
              {
                value: "b",
                type: "static",
                id: "0-1-0-2",
              },
              {
                value: "International",
                type: "static",
                id: "0-1-0-2",
              },
              {
                id: "0-1-0-2",
                type: "text",
                key: "International",
                valKey: "Number_of_plants",
              },
              {
                id: "0-1-0-2",
                type: "text",
                key: "International",
                valKey: "Number_of_offices",
              },
              {
                id: "0-1-0-2",
                type: "text",
                key: "International",
                valKey: "Total",
                // calculation: ({ data }) => {
                //   const a = Number(data.find(e => e.id === '0-1-0-2')?.value?.Number_of_plants || 0);
                //   const b = Number(data.find(e => e.id === '0-1-0-2')?.value?.Number_of_offices || 0);
                //   return a + b;
                // },
              },
            ],
          ],
        },
        {
          label: "Markets served by the entity",
          th: ["SR", "Number of locations", "Value"],
          tr: [
            [
              {
                value: "a",
                type: "static",
                id: "0-1-1-1",
              },
              {
                value: "National (No. of States)",
                type: "static",
                id: "0-1-1-1",
              },
              {
                id: "0-1-1-1",
                type: "text",
                key: "National (No. of States)",
                valKey: "value",
              },
            ],
            [
              {
                value: "b",
                type: "static",
                id: "0-1-1-2",
              },
              {
                value: "International (No. of Countries)",
                type: "static",
                id: "0-1-1-2",
              },
              {
                id: "0-1-1-2",
                type: "text",
                key: "International (No. of Countries)",
                valKey: "value",
              },
            ],
            [
              {
                value: "c",
                type: "static",
                id: "0-1-1-3",
              },
              {
                value:
                  "What is the contribution of exports as a percentage of the total turnover of the entity?",
                type: "static",
                id: "0-1-1-3",
              },
              {
                id: "0-1-1-3",
                type: "text",
                key: "Exports percentage of turnover",
                valKey: "value",
              },
            ],
            [
              {
                value: "d",
                type: "static",
                id: "0-1-1-4",
              },
              {
                value: "Brief type of customer",
                type: "static",
                id: "0-1-1-4",
              },
              {
                id: "0-1-1-4",
                type: "text",
                key: "Brief type of customer",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "Employees and workers",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "0-1-2-0",
              },
              {
                type: "static",
                value: "Employee",
                colSpan: 2,
                id: "0-1-2-0",
              },
            ],
            [
              {
                type: "static",
                value: "i",
                id: "0-1-2-1",
              },
              {
                type: "static",
                value: "Permanent Employee",
                colSpan: 2,
                id: "0-1-2-1",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-2",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-2",
              },
              {
                id: "0-1-2-2",
                type: "number",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-3",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-3",
              },
              {
                id: "0-1-2-3",
                type: "number",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-4",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-4",
              },
              {
                id: "0-1-2-4",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-2', '0-1-2-3'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "0-1-2-5",
              },
              {
                type: "static",
                value: "Contract Employee",
                colSpan: 2,
                id: "0-1-2-5",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-6",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-6",
              },
              {
                id: "0-1-2-6",
                type: "number",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-7",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-7",
              },
              {
                id: "0-1-2-7",
                type: "number",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-8",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-8",
              },
              {
                id: "0-1-2-8",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-6', '0-1-2-7'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "0-1-2-9",
              },
              {
                type: "static",
                value: "Workers",
                colSpan: 2,
                id: "0-1-2-9",
              },
            ],
            [
              {
                type: "static",
                value: "i",
                id: "0-1-2-10",
              },
              {
                type: "static",
                value: "Permanent Workers",
                colSpan: 2,
                id: "0-1-2-10",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-11",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-11",
              },
              {
                id: "0-1-2-11",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-12",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-12",
              },
              {
                id: "0-1-2-12",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-13",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-13",
              },
              {
                id: "0-1-2-13",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-11', '0-1-2-12'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "0-1-2-14",
              },
              {
                type: "static",
                value: "Contract Workers",
                colSpan: 2,
                id: "0-1-2-14",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-15",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-15",
              },
              {
                id: "0-1-2-15",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-16",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-16",
              },
              {
                id: "0-1-2-16",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-17",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-17",
              },
              {
                id: "0-1-2-17",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-15', '0-1-2-16'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "0-1-2-18",
              },
              {
                type: "static",
                value: "Differently Abled Employees And Workers",
                colSpan: 2,
                id: "0-1-2-18",
              },
            ],
            [
              {
                type: "static",
                value: "i",
                id: "0-1-2-19",
              },
              {
                type: "static",
                value: "Permanent Employee",
                colSpan: 2,
                id: "0-1-2-19",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-20",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-20",
              },
              {
                id: "0-1-2-20",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-21",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-21",
              },
              {
                id: "0-1-2-21",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-22",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-22",
              },
              {
                id: "0-1-2-22",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-20', '0-1-2-21'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "0-1-2-23",
              },
              {
                type: "static",
                value: "Contract Employee",
                colSpan: 2,
                id: "0-1-2-23",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-24",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-24",
              },
              {
                id: "0-1-2-24",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-25",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-25",
              },
              {
                id: "0-1-2-25",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-26",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-26",
              },
              {
                id: "0-1-2-26",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-24', '0-1-2-25'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "0-1-2-27",
              },
              {
                type: "static",
                value: "Permanent Workers",
                colSpan: 2,
                id: "0-1-2-27",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-28",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-28",
              },
              {
                id: "0-1-2-28",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-29",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-29",
              },
              {
                id: "0-1-2-29",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-30",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-30",
              },
              {
                id: "0-1-2-30",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-28', '0-1-2-29'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "0-1-2-31",
              },
              {
                type: "static",
                value: "Contract Workers",
                colSpan: 2,
                id: "0-1-2-31",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-32",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-32",
              },
              {
                id: "0-1-2-32",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-33",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-33",
              },
              {
                id: "0-1-2-33",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-34",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-34",
              },
              {
                id: "0-1-2-34",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-32', '0-1-2-33'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "0-1-2-35",
              },
              {
                type: "static",
                value: "Turnover rate of Permanent employees and workers",
                colSpan: 2,
                id: "0-1-2-35",
              },
            ],
            [
              {
                type: "static",
                value: "i",
                id: "0-1-2-36",
              },
              {
                type: "static",
                value: "Permanent Employee",
                colSpan: 2,
                id: "0-1-2-36",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-37",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-37",
              },
              {
                id: "0-1-2-37",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-38",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-38",
              },
              {
                id: "0-1-2-38",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-39",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-39",
              },
              {
                id: "0-1-2-39",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-37', '0-1-2-38'],"value");
                },
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "0-1-2-40",
              },
              {
                type: "static",
                value: "Contract Employee",
                colSpan: 2,
                id: "0-1-2-40",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-41",
              },
              {
                type: "static",
                value: "Male",
                id: "0-1-2-41",
              },
              {
                id: "0-1-2-41",
                type: "text",
                valKey: "value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-42",
              },
              {
                type: "static",
                value: "Female",
                id: "0-1-2-42",
              },
              {
                id: "0-1-2-42",
                type: "text",
                valKey: "value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "0-1-2-43",
              },
              {
                type: "static",
                value: "Total",
                id: "0-1-2-43",
              },
              {
                id: "0-1-2-43",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['0-1-2-41', '0-1-2-42'],"value");
                },
              },
            ],
          ],
        },
        {
          label:
            "Name of Holding / Subsidary / Associate companies / joint ventures",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                value: "a",
                type: "static",
                id: "0-1-3-0",
              },
              {
                value:
                  "Name of Holding / Subsidary / Associate companies / joint ventures",
                type: "static",
                id: "0-1-3-0",
              },
              {
                id: "0-1-3-0",
                type: "text",
                key: "Name of Holding / Subsidary / Associate companies / joint ventures",
                valKey: "value",
              },
            ],
            [
              {
                value: "b",
                type: "static",
                id: "0-1-3-1",
              },
              {
                value:
                  "Indicate whether holding / subsdiary / associate / Joint venture",
                type: "static",
                id: "0-1-3-1",
              },
              {
                id: "0-1-3-1",
                type: "text",
                key: "Indicate whether holding / subsdiary / associate / Joint venture",
                valKey: "value",
              },
            ],
            [
              {
                value: "c",
                type: "static",
                id: "0-1-3-2",
              },
              {
                value: "% of Share helf by listed entity",
                type: "static",
                id: "0-1-3-2",
              },
              {
                id: "0-1-3-2",
                type: "text",
                key: "% of Share helf by listed entity",
                valKey: "value",
              },
            ],
            [
              {
                value: "d",
                type: "static",
                id: "0-1-3-3",
              },
              {
                value:
                  "Does the entity indicated at Points A, particiapte in the buisness responsbility initiative of thelisted entity? Yes/No",
                type: "static",
                id: "0-1-3-3",
              },
              {
                id: "0-1-3-3",
                type: "text",
                key: "Does the entity indicated at Points A, particiapte in the buisness responsbility initiative of thelisted entity? Yes/No",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label:
            "Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                value: "a",
                type: "static",
                id: "0-1-5-0",
              },
              {
                value: "Stakeholder group from whom complaint is received",
                type: "static",
                colSpan: 2,
                id: "0-1-5-0",
              },
            ],
            [
              {
                value: "i",
                type: "static",
                id: "0-1-5-1",
              },
              {
                value: "Communities",
                type: "static",
                colSpan: 2,
                id: "0-1-5-1",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-2",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-2",
              },
              {
                id: "0-1-5-2",
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-3",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-3",
              },
              {
                id: "0-1-5-3",
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-4",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-4",
              },
              {
                id: "0-1-5-4",
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-5",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-5",
              },
              {
                id: "0-1-5-5",
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-6",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-6",
              },
              {
                id: "0-1-5-6",
                type: "text",
                key: "Remarks",
                valKey: "value",
              },
            ],
            [
              {
                value: "ii",
                type: "static",
                id: "0-1-5-7",
              },
              {
                value: "Investors (other than shareholders)",
                type: "static",
                colSpan: 2,
                id: "0-1-5-7",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-8",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-8",
              },
              {
                id: "0-1-5-8",
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-9",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-9",
              },
              {
                id: "0-1-5-9",
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-10",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-10",
              },
              {
                id: "0-1-5-10",
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-11",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-11",
              },
              {
                id: "0-1-5-11",
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-12",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-12",
              },
              {
                id: "0-1-5-12",
                type: "text",
                key: "Remarks",
                valKey: "value",
              },
            ],
            [
              {
                value: "iii",
                type: "static",
                id: "0-1-5-13",
              },
              {
                value: "Shareholders",
                type: "static",
                colSpan: 2,
                id: "0-1-5-13",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-14",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-14",
              },
              {
                id: "0-1-5-14",
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-15",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-15",
              },
              {
                id: "0-1-5-15",
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-16",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-16",
              },
              {
                id: "0-1-5-16",
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-17",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-17",
              },
              {
                id: "0-1-5-17",
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-18",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-18",
              },
              {
                id: "0-1-5-18",
                type: "text",
                key: "Remarks",
                valKey: "value",
              },
            ],
            [
              {
                value: "iv",
                type: "static",
                id: "0-1-5-19",
              },
              {
                value: "Employees and workers",
                type: "static",
                colSpan: 2,
                id: "0-1-5-19",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-20",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-20",
              },
              {
                id: "0-1-5-20",
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-21",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-21",
              },
              {
                id: "0-1-5-21",
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-22",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-22",
              },
              {
                id: "0-1-5-22",
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-23",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-23",
              },
              {
                id: "0-1-5-23",
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-24",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-24",
              },
              {
                id: "0-1-5-24",
                type: "text",
                key: "Remarks",
                valKey: "value",
              },
            ],
            [
              {
                value: "v",
                type: "static",
                id: "0-1-5-25",
              },
              {
                value: "Customers",
                type: "static",
                colSpan: 2,
                id: "0-1-5-25",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-26",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-26",
              },
              {
                id: "0-1-5-26",
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-27",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-27",
              },
              {
                id: "0-1-5-27",
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-28",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-28",
              },
              {
                id: "0-1-5-28",
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-29",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-29",
              },
              {
                id: "0-1-5-29",
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-30",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-30",
              },
              {
                id: "0-1-5-30",
                type: "text",
                key: "Remarks",
                valKey: "value",
              },
            ],
            [
              {
                value: "vi",
                type: "static",
                id: "0-1-5-31",
              },
              {
                value: "Value Chain Partners",
                type: "static",
                colSpan: 2,
                id: "0-1-5-31",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-32",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-32",
              },
              {
                id: "0-1-5-32",
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-33",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-33",
              },
              {
                id: "0-1-5-33",
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-34",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-34",
              },
              {
                id: "0-1-5-34",
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-35",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-35",
              },
              {
                id: "0-1-5-35",
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-36",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-36",
              },
              {
                type: "text",
                key: "Remarks",
                valKey: "value",
                id: "0-1-5-36",
              },
            ],
            [
              {
                value: "vii",
                type: "static",
                id: "0-1-5-37",
              },
              {
                value: "Other (please specify)",
                type: "static",
                colSpan: 2,
                id: "0-1-5-37",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-38",
              },
              {
                value: "Grievance Redressal Mechanism in Place (Yes/No)",
                type: "static",
                id: "0-1-5-38",
              },
              {
                type: "text",
                key: "Grievance Redressal Mechanism in Place (Yes/No)",
                valKey: "value",
                id: "0-1-5-38",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-39",
              },
              {
                value:
                  "(If Yes, then provide web-link for grievance redress policy)",
                type: "static",
                id: "0-1-5-39",
              },
              {
                type: "text",
                key: "(If Yes, then provide web-link for grievance redress policy)",
                valKey: "value",
                id: "0-1-5-39",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-40",
              },
              {
                value: "Number of complaints filed during the year",
                type: "static",
                id: "0-1-5-40",
              },
              {
                type: "text",
                key: "Number of complaints filed during the year",
                valKey: "value",
                id: "0-1-5-40",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-41",
              },
              {
                value:
                  "Number of complaints pending resolution at close of the year",
                type: "static",
                id: "0-1-5-41",
              },
              {
                type: "text",
                key: "Number of complaints pending resolution at close of the year",
                valKey: "value",
                id: "0-1-5-41",
              },
            ],
            [
              {
                value: "*",
                type: "static",
                id: "0-1-5-42",
              },
              {
                value: "Remarks",
                type: "static",
                id: "0-1-5-42",
              },
              {
                type: "text",
                key: "Remarks",
                valKey: "value",
                id: "0-1-5-42",
              },
            ],
          ],
        },
        {
          label:
            "Overview of the entity’s material responsible business conduct issues",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                value: "a",
                type: "static",
                id: "0-1-6-0",
              },
              {
                value: "Material issue identified",
                type: "static",
                id: "0-1-6-0",
              },
              {
                type: "text",
                key: "Material issue identified",
                valKey: "Value",
                id: "0-1-6-0",
              },
            ],
            [
              {
                value: "b",
                type: "static",
                id: "0-1-6-1",
              },
              {
                value: "Indicate whether risk or opportunity (R/O)",
                type: "static",
                id: "0-1-6-1",
              },
              {
                type: "text",
                key: "Indicate whether risk or opportunity (R/O)",
                valKey: "Value",
                id: "0-1-6-1",
              },
            ],
            [
              {
                value: "c",
                type: "static",
                id: "0-1-6-2",
              },
              {
                value: "Rationale for identifying the risk / opportunity",
                type: "static",
                id: "0-1-6-2",
              },
              {
                type: "text",
                key: "Rationale for identifying the risk / opportunity",
                valKey: "Value",
                id: "0-1-6-2",
              },
            ],
            [
              {
                value: "d",
                type: "static",
                id: "0-1-6-3",
              },
              {
                value: "In case of risk, approach to adapt or mitigate",
                type: "static",
                id: "0-1-6-3",
              },
              {
                type: "text",
                key: "In case of risk, approach to adapt or mitigate",
                valKey: "Value",
                id: "0-1-6-3",
              },
            ],
            [
              {
                value: "e",
                type: "static",
                id: "0-1-6-4",
              },
              {
                value:
                  "Financial implications of the risk or opportunity (Indicate positive or negative implications)",
                type: "static",
                id: "0-1-6-4",
              },
              {
                type: "text",
                key: "Financial implications of the risk or opportunity (Indicate positive or negative implications)",
                valKey: "Value",
                id: "0-1-6-4",
              },
            ],
          ],
        },
      ],
    },
    {
      type: "accordion",
      label: "GENERAL DISCLOSURES",
      tables: [
        {
          label: "Details of the listed entity",
          th: [],
          tr: [
            [
              {
                id: "0-2-0-0-0",
                type: "static",
                value: "Corporate Identity Number (CIN) of the Listed Entity",
              },
              {
                id: "0-2-0-0-0",
                type: "text",
                valKey: "value",
                key: "Corporate Identity Number (CIN) of the Listed Entity",
              },
            ],
            [
              {
                id: "0-2-0-0-1",
                type: "static",
                value: "Name of the Listed Entity",
              },
              {
                id: "0-2-0-0-1",
                type: "text",
                valKey: "value",
                key: "Name of the Listed Entity",
              },
            ],
            [
              {
                id: "0-2-0-0-2",
                type: "static",
                value: "Year of incorporation",
              },
              {
                id: "0-2-0-0-2",
                type: "text",
                valKey: "value",
                key: "Year of incorporation",
              },
            ],
            [
              {
                id: "0-2-0-0-3",
                type: "static",
                value: "Registered office address",
              },
              {
                id: "0-2-0-0-3",
                type: "text",
                valKey: "value",
                key: "Registered office address",
              },
            ],
            [
              { id: "0-2-0-0-4", type: "static", value: "Corporate address" },
              {
                id: "0-2-0-0-4",
                type: "text",
                valKey: "value",
                key: "Corporate address",
              },
            ],
            [
              { id: "0-2-0-0-5", type: "static", value: "Email" },
              { id: "0-2-0-0-5", type: "text", valKey: "value", key: "Email" },
            ],
            [
              { id: "0-2-0-0-6", type: "static", value: "Telephone" },
              {
                id: "0-2-0-0-6",
                type: "text",
                valKey: "value",
                key: "Telephone",
              },
            ],
            [
              { id: "0-2-0-0-7", type: "static", value: "Website" },
              {
                id: "0-2-0-0-7",
                type: "text",
                valKey: "value",
                key: "Website",
              },
            ],
            [
              {
                id: "0-2-0-0-8",
                type: "static",
                value: "Financial year for which reporting is being done",
              },
              {
                id: "0-2-0-0-8",
                type: "text",
                valKey: "value",
                key: "Financial year for which reporting is being done",
              },
            ],
            [
              {
                id: "0-2-0-0-9",
                type: "static",
                value: "Name of the Stock Exchange(s) where shares are listed",
              },
              {
                id: "0-2-0-0-9",
                type: "text",
                valKey: "value",
                key: "Name of the Stock Exchange(s) where shares are listed",
              },
            ],
            [
              { id: "0-2-0-0-10", type: "static", value: "Paid-up Capital" },
              {
                id: "0-2-0-0-10",
                type: "text",
                valKey: "value",
                key: "Paid-up Capital",
              },
            ],
          ],
        },
        {
          label:
            "Name and contact details (telephone, email address) of the person who may be contacted in case of any queries on the BRSR report",
          th: ["SRNO", "Particulars", "Details"],
          tr: [
            [
              { id: "0-2-0-1-0", type: "static", value: "1" },
              { id: "0-2-0-1-0", type: "static", value: "Name" },
              { id: "0-2-0-1-0", type: "text", valKey: "details", key: "Name" },
            ],
            [
              { id: "0-2-0-2-0", type: "static", value: "2" },
              { id: "0-2-0-2-0", type: "static", value: "Designation" },
              {
                id: "0-2-0-2-0",
                type: "text",
                valKey: "details",
                key: "Designation",
              },
            ],
            [
              { id: "0-2-0-3-0", type: "static", value: "3" },
              { id: "0-2-0-3-0", type: "static", value: "Contact Details" },
              {
                id: "0-2-0-3-0",
                type: "text",
                valKey: "details",
                key: "Contact Details",
              },
            ],
            [
              { id: "0-2-0-4-0", type: "static", value: "4" },
              { id: "0-2-0-4-0", type: "static", value: "MailId" },
              {
                id: "0-2-0-4-0",
                type: "text",
                valKey: "details",
                key: "MailId",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "0-2-1-0-0",
                type: "static",
                value:
                  "Reporting boundary - Are the disclosures under this report made on a standalone basis (i.e. only for the entity) or on a consolidated basis (i.e. for the entity and all the entities which form a part of its consolidated financial statements, taken together)",
              },
              {
                id: "0-2-1-0-0",
                type: "text",
                valKey: "value",
                key: "Reporting boundary",
              },
            ],
            [
              {
                id: "0-3-0-0-0",
                type: "static",
                value: "Name of assurance provider",
              },
              {
                id: "0-3-0-0-0",
                type: "text",
                valKey: "value",
                key: "Name of assurance provider",
              },
            ],
            [
              {
                id: "0-3-0-0-1",
                type: "static",
                value: "Type of assurance obtained",
              },
              {
                id: "0-3-0-0-1",
                type: "text",
                valKey: "value",
                key: "Type of assurance obtained",
              },
            ],
            // [
            //   { id: "0-3-0-0-2", type: "static", value: "Product/services" },
            //   {
            //     id: "0-3-0-0-2",
            //     type: "text",
            //     valKey: "value",
            //     key: "Product/services",
            //   },
            // ],
            // [
            //   { id: "0-3-0-0-3", type: "static", value: "Operations" },
            //   {
            //     id: "0-3-0-0-3",
            //     type: "text",
            //     valKey: "value",
            //     key: "Operations",
            //   },
            // ],
          ],
        },
        {
          label:
            "Number of locations where plants and/or operations/offices of the entity are situated",
          th: ["Location", "Number of Plants", "Number of offices", "Total"],
          tr: [
            [
              { id: "0-3-0-1-0", type: "static", value: "National" },
              {
                id: "0-3-0-1-0",
                type: "number",
                valKey: "Number_of_Plants",
                key: "National",
              },
              {
                id: "0-3-0-1-0",
                type: "number",
                valKey: "Number_of_Offices",
                key: "National",
              },
              {
                id: "0-3-0-1-0",
                type: "calculated",
                valKey: "Total",
                key: "National",
                calculation: ({ data }) => {
                  const a = Number(data.find(e => e.id === '0-3-0-1-0')?.value?.Number_of_Plants || 0);
                  const b = Number(data.find(e => e.id === '0-3-0-1-0')?.value?.Number_of_Offices || 0);
                  return a + b;
                },
              },
            ],
            [
              { id: "0-3-0-1-1", type: "static", value: "International" },
              {
                id: "0-3-0-1-1",
                type: "number",
                valKey: "Number_of_Plants",
                key: "International",
              },
              {
                id: "0-3-0-1-1",
                type: "number",
                valKey: "Number_of_Offices",
                key: "International",
              },
              {
                id: "0-3-0-1-1",
                type: "calculated",
                valKey: "Total",
                key: "International",
                calculation: ({ data }) => {
                  const a = Number(data.find(e => e.id === '0-3-0-1-1')?.value?.Number_of_Plants || 0);
                  const b = Number(data.find(e => e.id === '0-3-0-1-1')?.value?.Number_of_Offices || 0);
                  return a + b;
                },
              },
            ],
          ],
        },

        {
          label: "Differently abled Employees and workers",
          th: [
            "SRNO",
            "Particulars",
            "Total(A)",
            "Male(B)",
            "Male(%(B/A))",
            "Female(C)",
            "Female(%(C/A))",
          ],
          tr: [],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "0-3-1-0-0",
                type: "static",
                value: "DIFFERENTLY ABLED EMPLOYEES",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "0-3-1-0-1",
                type: "static",
                value: "1",
              },
              {
                id: "0-3-1-0-1",
                type: "static",
                value: "Permanent (D)",
              },
              {
                id: "0-3-1-0-1",
                type: "text",
                valKey: "Total(A)",
                key: "Permanent (D)",
              },
              {
                id: "0-3-1-0-1",
                type: "text",
                valKey: "Male(B)",
                key: "Permanent (D)",
              },
              {
                id: "0-3-1-0-1",
                valKey: "Male(%(B/A))",
                type: "calculated",
                key: "Permanent (D)",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-1'], "Male(B)", "Total(A)");
                },
              },
              {
                id: "0-3-1-0-1",
                type: "text",
                valKey: "Female(C)",
                key: "Permanent (D)",
              },
              {
                id: "0-3-1-0-1",
                type: "calculated",
                key: "Permanent (D)",
                valKey: "Female(%(C/A))",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-1'], "Female(C)", "Total(A)");
                },
              },
            ],
            [
              { id: "0-3-1-0-2", type: "static", value: 2 },
              {
                id: "0-3-1-0-2",
                type: "static",
                value: "Other than Permanent (E)",
              },
              {
                id: "0-3-1-0-2",
                type: "text",
                valKey: "Total(A)",
                key: "Other than Permanent (E)",
              },
              {
                id: "0-3-1-0-2",
                type: "text",
                valKey: "Male(B)",
                key: "Other than Permanent (E)",
              },
              {
                id: "0-3-1-0-2",
                valKey: "Male(%(B/A))",
                type: "calculated",
                key: "Other than Permanent (E)",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-2'], "Male(B)", "Total(A)");
                },
              },
              {
                id: "0-3-1-0-2",
                type: "text",
                valKey: "Female(C)",
                key: "Other than Permanent (E)",
              },
              {
                id: "0-3-1-0-2",
                type: "calculated",
                key: "Other than Permanent (E)",
                valKey: "Female(%(C/A))",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-2'], "Female(C)", "Total(A)");
                },
              },
            ],
            [
              { id: "0-3-1-0-3", type: "static", value: 3 },
              {
                id: "0-3-1-0-3",
                type: "static",
                value: "Total differently abled employees(D + E)",
              },
              {
                id: "0-3-1-0-3",
                type: "calculated",
                valKey: "Total(A)",
                key: "Total differently abled employees(D + E)",
                calculation: ({ data  }) => {
                  return sum(data, ['0-3-1-0-1', '0-3-1-0-2'],"Total(A)");
                },
              },
              {
                id: "0-3-1-0-3",
                type: "calculated",
                valKey: "Male(B)",
                key: "Total differently abled employees(D + E)",
                calculation: ({ data  }) => {
                  return sum(data, ['0-3-1-0-1', '0-3-1-0-2'], "Male(B)");
                },
              },
              {
                id: "0-3-1-0-3",
                valKey: "Male(%(B/A))",
                type: "calculated",
                key: "Total differently abled employees(D + E)",
                calculation: ({ data  }) => {
                  return percentage(data, ['0-3-1-0-1', '0-3-1-0-2'], "Male(B)", "Total(A)");
                },
              },
              {
                id: "0-3-1-0-3",
                type: "calculated",
                valKey: "Female(C)",
                key: "Total differently abled employees(D + E)",
                calculation: ({ data  }) => {
                  return sum(data, ['0-3-1-0-1', '0-3-1-0-2'],"Female(C)");
                },
              },
              {
                id: "0-3-1-0-3",
                type: "calculated",
                key: "Total differently abled employees(D + E)",
                valKey: "Female(%(C/A))",
                calculation: ({ data  }) => {
                  return percentage(data, ['0-3-1-0-1', '0-3-1-0-2'], "Female(C)", "Total(A)");
                },
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "0-3-1-0-0",
                type: "static",
                value: "DIFFERENTLY ABLED WORKERS",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              { id: "0-3-1-0-4", type: "static", value: 4 },
              { id: "0-3-1-0-4", type: "static", value: "Permanent (F)" },
              {
                id: "0-3-1-0-4",
                type: "text",
                valKey: "Total(A)",
                key: "Permanent (F)",
              },
              {
                id: "0-3-1-0-4",
                type: "text",
                valKey: "Male(B)",
                key: "Permanent (F)",
              },
              {
                id: "0-3-1-0-4",
                valKey: "Male(%(B/A))",
                type: "calculated",
                key: "Permanent (F)",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-4'], "Male(B)", "Total(A)");
                },
                
              },
              {
                id: "0-3-1-0-4",
                type: "text",
                valKey: "Female(C)",
                key: "Permanent (F)",
              },
              {
                id: "0-3-1-0-4",
                type: "calculated",
                key: "Permanent (F)",
                valKey: "Female(%(C/A))",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-4'], "Female(C)", "Total(A)");
                },
              },
            ],
            [
              { id: "0-3-1-0-5", type: "static", value: 5 },
              {
                id: "0-3-1-0-5",
                type: "static",
                value: "Other than permanent (G)",
              },
              {
                id: "0-3-1-0-5",
                type: "text",
                valKey: "Total(A)",
                key: "Other than permanent (G)",
              },
              {
                id: "0-3-1-0-5",
                type: "text",
                valKey: "Male(B)",
                key: "Other than permanent (G)",
              },
              {
                id: "0-3-1-0-5",
                valKey: "Male(%(B/A))",
                type: "calculated",
                key: "Other than permanent (G)",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-5'], "Male(B)", "Total(A)");
                },
              },
              {
                id: "0-3-1-0-5",
                type: "text",
                valKey: "Female(C)",
                key: "Other than permanent (G)",
              },
              {
                id: "0-3-1-0-5",
                type: "calculated",
                key: "Other than permanent (G)",
                valKey: "Female(%(C/A))",
                calculation: ({ data }) => {
                  return percentage(data, ['0-3-1-0-5'], "Female(C)", "Total(A)");
                },
              },
            ],
            [
              { id: "0-3-1-0-6", type: "static", value: 6 },
              {
                id: "0-3-1-0-6",
                type: "static",
                value: "Total differently abled workers(F + G)",
              },
              {
                id: "0-3-1-0-6",
                type: "calculated",
                valKey: "Total(A)",
                key: "Total differently abled workers(F + G)",
                calculation: ({ data  }) => {
                  return sum(data, ['0-3-1-0-4', '0-3-1-0-5'],"Total(A)");
                },
              },
              {
                id: "0-3-1-0-6",
                type: "calculated",
                valKey: "Male(B)",
                key: "Total differently abled workers(F + G)",
                calculation: ({ data  }) => {
                  return sum(data, ['0-3-1-0-4', '0-3-1-0-5'], "Male(B)");
                },
              },
              {
                id: "0-3-1-0-6",
                valKey: "Male(%(B/A))",
                type: "calculated",
                key: "Total differently abled workers(F + G)",
                calculation: ({ data  }) => {
                  return percentage(data, ['0-3-1-0-4', '0-3-1-0-5'], "Male(B)", "Total(A)");
                },
              },
              {
                id: "0-3-1-0-6",
                type: "calculated",
                valKey: "Female(C)",
                key: "Total differently abled workers(F + G)",
                calculation: ({ data  }) => {
                  return sum(data, ['0-3-1-0-4', '0-3-1-0-5'],"Female(C)");
                },
              },
              {
                id: "0-3-1-0-6",
                type: "calculated",
                key: "Total differently abled workers(F + G)",
                valKey: "Female(%(C/A))",
                calculation: ({ data  }) => {
                  return percentage(data, ['0-3-1-0-4', '0-3-1-0-5'], "Female(C)", "Total(A)");
                },
              },
            ],
          ],
        },
        {
          label: "Participation/Inclusion/Representation of women",
          th: [
            "",
            "Total(A)",
            "Number of Females(B)",
            "Percentage of Females(%(B/A)",
          ],
          tr: [
            [
              { id: "0-3-2-1-0", type: "static", value: "Board of Directors" },
              {
                id: "0-3-2-1-0",
                type: "number",
                valKey: "value",
                key: "Board of Directors",
              },
              {
                id: "0-3-2-1-0",
                type: "number",
                valKey: "Female",
                key: "Board of Directors",
              },
              {
                id: "0-3-2-1-0",
                type: "calculated",
                valKey: "Percentage_Female",
                key: "Board of Directors",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='0-3-2-1-0').value.value || 0
                  const b = data.find(e=>e.id==='0-3-2-1-0').value.Female || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
                
              },
            ],
            [
              {
                id: "0-3-2-1-1",
                type: "static",
                value: "Key	Management Personnel",
              },
              {
                id: "0-3-2-1-1",
                type: "number",
                valKey: "value",
                key: "Key	Management Personnel",
              },
              {
                id: "0-3-2-1-1",
                type: "number",
                valKey: "Female",
                key: "Key	Management Personnel",
              },
              {
                id: "0-3-2-1-1",
                type: "calculated",
                valKey: "Percentage_Female",
                key: "Key	Management Personnel",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='0-3-2-1-1').value.value || 0
                  const b = data.find(e=>e.id==='0-3-2-1-1').value.Female || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
          ],
        },
        {
          label: "Turnover rate for permanent employees and workers",
          th: ["", "Male", "Female", "Total"],
          tr: [
            [
              { id: "0-3-3-0-0", type: "static", value: "Permanent Employees" },
              {
                id: "0-3-3-0-0",
                type: "number",
                key: "Permanent Employees",
                valKey: "Male",
              },
              {
                id: "0-3-3-0-0",
                type: "number",
                key: "Permanent Employees",
                valKey: "Female",
              },
              {
                id: "0-3-3-0-0",
                type: "calculated",
                key: "Permanent Employees",
                valKey: "Total",
                calculation: ({ data }) => {
                  const a = Number(data.find(e => e.id === '0-3-3-0-0')?.value?.Male || 0);
                  const b = Number(data.find(e => e.id === '0-3-3-0-0')?.value?.Female || 0);
                  return a + b;
                },
              },
            ],
          ],
        },
        {
          label: "CSR Details",
          th: [],
          tr: [
            [
              { id: "0-3-4-0-0", type: "static", value: "Whether CSR is appliable as per person section 135 of companies Act, 2013: 2013: (Yes/No)",
              },
              {
                id: "0-3-4-0-0",
                type: "text",
                key: "Whether CSR is appliable as per person section 135 of companies Act, 2013: 2013: (Yes/No)",
                valKey: "value",
              },
            ],
            [
              { id: "0-3-4-0-1", type: "static", value: "Turnover" },
              {
                id: "0-3-4-0-1",
                type: "text",
                valKey: "value",
                key: "Turnover",
              },
            ],
            [
              { id: "0-3-4-0-2", type: "static", value: "Net Worth(in Rs)" },
              {
                id: "0-3-4-0-2",
                type: "text",
                valKey: "value",
                key: "Net Worth(in Rs)",
              },
            ],
            [
              {
                id: "0-3-4-0-3",
                type: "static",
                value: "CSR threshold Limit(INR Million)",
              },
              {
                id: "0-3-4-0-3",
                type: "text",
                valKey: "value",
                key: "CSR threshold Limit(INR Million)",
              },
            ],
            [
              {
                id: "0-3-4-0-4",
                type: "static",
                value: "CSR Spend(INR Million)",
              },
              {
                id: "0-3-4-0-4",
                type: "text",
                valKey: "value",
                key: "CSR Spend(INR Million)",
              },
            ],
            [
              {
                id: "0-3-4-0-5",
                type: "static",
                value:
                  "Overview of the entity's material responsible business conduct issues",
              },
              {
                id: "0-3-4-0-5",
                type: "text",
                valKey: "value",
                key: "Overview of the entity's material responsible business conduct issues",
              },
            ],
          ],
        },
      ],
    },
  ],
  SectionB: [
    {
      type: "accordion",
      label:
        "This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC Principles and Core Elements.",
      tables: [
        {
          label: "Policy and management processes",
          th: [
            "SR",
            "Disclosure Question",
            "P1",
            "P2",
            "P3",
            "P4",
            "P5",
            "P6",
            "P7",
            "P8",
            "P9",
          ],
          tr: [
            [
              {
                type: "static",
                value: "1.a",
                id: "1-0-0-0",
              },
              {
                type: "static",
                value:
                  "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                id: "1-0-0-0",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P1",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P2",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P3",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P4",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P5",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P6",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P7",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P8",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
              {
                id: "1-0-0-0",
                type: "checkbox",
                valKey: "P9",
                key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value: "1.b",
                id: "1-0-0-1",
              },
              {
                type: "static",
                value: "Has the policy been approved by the Board? (Yes/No)",
                id: "1-0-0-1",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P1",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P2",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P3",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P4",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P5",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P6",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P7",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P8",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
              {
                id: "1-0-0-1",
                type: "checkbox",
                valKey: "P9",
                key: "Has the policy been approved by the Board? (Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value: "1.c",
                id: "1-0-0-2",
              },
              {
                type: "static",
                value: "Web Link of the Policies, if available",
                id: "1-0-0-2",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P1",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P2",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P3",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P4",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P5",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P6",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P7",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P8",
                key: "Web Link of the Policies, if available",
              },
              {
                id: "1-0-0-2",
                type: "checkbox",
                valKey: "P9",
                key: "Web Link of the Policies, if available",
              },
            ],
            [
              {
                type: "static",
                value: "2",
                id: "1-0-0-3",
              },
              {
                type: "static",
                value:
                  "Whether the entity has translated the policy into procedures. (Yes / No)",
                id: "1-0-0-3",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P1",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P2",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P3",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P4",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P5",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P6",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P7",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P8",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
              {
                id: "1-0-0-3",
                type: "checkbox",
                valKey: "P9",
                key: "Whether the entity has translated the policy into procedures. (Yes / No)",
              },
            ],
            [
              {
                type: "static",
                value: "3",
                id: "1-0-0-4",
              },
              {
                type: "static",
                value:
                  "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                id: "1-0-0-4",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P1",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P2",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P3",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P4",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P5",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P6",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P7",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P8",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
              {
                id: "1-0-0-4",
                type: "checkbox",
                valKey: "P9",
                key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value: "4",
                id: "1-0-0-5",
              },
              {
                type: "static",
                value:
                  "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                id: "1-0-0-5",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P1",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P2",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P3",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P4",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P5",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P6",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P7",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P8",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
              {
                id: "1-0-0-5",
                type: "checkbox",
                valKey: "P9",
                key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
              },
            ],
            [
              {
                type: "static",
                value: "5",
                id: "1-0-0-6",
              },
              {
                type: "static",
                value:
                  "Specific commitments, goals and targets set by the entity with defined timelines if any",
                id: "1-0-0-6",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P1",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P2",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P3",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P4",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P5",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P6",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P7",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P8",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
              {
                id: "1-0-0-6",
                type: "checkbox",
                valKey: "P9",
                key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
              },
            ],
            [
              {
                type: "static",
                value: "6",
                id: "1-0-0-7",
              },
              {
                type: "static",
                value:
                  "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                id: "1-0-0-7",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P1",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P2",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P3",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P4",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P5",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P6",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P7",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P8",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
              {
                id: "1-0-0-7",
                type: "checkbox",
                valKey: "P9",
                key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
              },
            ],
          ],
        },
        {
          label: "Governance, leadership and oversight",
          th: ["SR", "Disclosure Question", "Value"],
          tr: [
            [
              {
                type: "static",
                value: 1,
                id: "1-0-1-0",
              },
              {
                type: "static",
                value:
                  "Statement by director responsible for the business responsibility report, highlighting ESG related challenges, targets and achievements (listed entity has flexibility regarding the placement of the disclosure",
                id: "1-0-1-0",
              },
              {
                id: "1-0-1-0",
                type: "text",
                valKey: "value",
                key: "Statement by director responsible for the business responsibility report, highlighting ESG related challenges, targets and achievements (listed entity has flexibility regarding the placement of the disclosure",
              },
            ],
            [
              {
                type: "static",
                value: 2,
                id: "1-0-1-1",
              },
              {
                type: "static",
                value:
                  "Details of the highest authority responsible for implementation and oversight of the buisness responsibility policy (ies.)",
                id: "1-0-1-1",
              },
              {
                id: "1-0-1-1",
                type: "text",
                valKey: "value",
                key: "Details of the highest authority responsible for implementation and oversight of the buisness responsibility policy (ies.)",
              },
            ],
            [
              {
                type: "static",
                value: 3,
                id: "1-0-1-2",
              },
              {
                type: "static",
                value:
                  "Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). Ifyes, provide details.",
                id: "1-0-1-2",
              },
              {
                id: "1-0-1-2",
                type: "text",
                valKey: "value",
                key: "Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). Ifyes, provide details.",
              },
            ],
          ],
        },
      ],
    },
    {
      type: "accordion",
      label: "Details of Review of NGRBCs by the Company",
      tables: [
        {
          label: "",
          th: [
            "SR",
            "Subject for Review",
            "P1",
            "P2",
            "P3",
            "P4",
            "P5",
            "P6",
            "P7",
            "P8",
            "P9",
          ],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "1-1-0-0",
              },
              {
                type: "static",
                value:
                  "Performance against above policies and follow up action",
                id: "1-1-0-0",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P1",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P2",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P3",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P4",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P5",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P6",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P7",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P8",
                key: "Performance against above policies and follow up action",
              },
              {
                id: "1-1-0-0",
                type: "checkbox",
                valKey: "P9",
                key: "Performance against above policies and follow up action",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "1-1-0-1",
              },
              {
                type: "static",
                value:
                  "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                id: "1-1-0-1",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P1",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P2",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P3",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P4",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P5",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P6",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P7",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P8",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
              {
                id: "1-1-0-1",
                type: "checkbox",
                valKey: "P9",
                key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "1-1-0-2",
              },
              {
                type: "static",
                value:
                  "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                id: "1-1-0-2",
                type: "checkbox",
                valKey: "P1",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
              },
              {
                type: "checkbox",
                valKey: "P2",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P3",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P4",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P5",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P6",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P7",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P8",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
              {
                type: "checkbox",
                valKey: "P9",
                key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                id: "1-1-0-2",
              },
            ],
          ],
        },
      ],
    },
    {
      type: "accordion",
      label:
        "If answer to question (1) above is “No” i.e. not all Principles are covered by a policy, reasons to be stated",
      tables: [
        {
          label: "",
          th: ["SR", "Questions", "P1", "P2", "P3", "P4", "P5", "P6"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "1-2-0-0",
              },
              {
                type: "static",
                value:
                  "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
              {
                type: "checkbox",
                valKey: "P1",
                key: "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
              {
                type: "checkbox",
                valKey: "P2",
                key: "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
              {
                type: "checkbox",
                valKey: "P3",
                key: "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
              {
                type: "checkbox",
                valKey: "P4",
                key: "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
              {
                type: "checkbox",
                valKey: "P5",
                key: "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
              {
                type: "checkbox",
                valKey: "P6",
                key: "The entity does not consider the Principles material to its business (Yes/No)",
                id: "1-2-0-0",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "1-2-0-1",
              },
              {
                type: "static",
                value:
                  "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
              {
                type: "checkbox",
                valKey: "P1",
                key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
              {
                type: "checkbox",
                valKey: "P2",
                key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
              {
                type: "checkbox",
                valKey: "P3",
                key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
              {
                type: "checkbox",
                valKey: "P4",
                key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
              {
                type: "checkbox",
                valKey: "P5",
                key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
              {
                type: "checkbox",
                valKey: "P6",
                key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                id: "1-2-0-1",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "1-2-0-2",
              },
              {
                type: "static",
                value:
                  "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
              {
                type: "checkbox",
                valKey: "P1",
                key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
              {
                type: "checkbox",
                valKey: "P2",
                key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
              {
                type: "checkbox",
                valKey: "P3",
                key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
              {
                type: "checkbox",
                valKey: "P4",
                key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
              {
                type: "checkbox",
                valKey: "P5",
                key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
              {
                type: "checkbox",
                valKey: "P6",
                key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                id: "1-2-0-2",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "1-2-0-3",
              },
              {
                type: "static",
                value:
                  "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
              {
                type: "checkbox",
                valKey: "P1",
                key: "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
              {
                type: "checkbox",
                valKey: "P2",
                key: "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
              {
                type: "checkbox",
                valKey: "P3",
                key: "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
              {
                type: "checkbox",
                valKey: "P4",
                key: "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
              {
                type: "checkbox",
                valKey: "P5",
                key: "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
              {
                type: "checkbox",
                valKey: "P6",
                key: "It is planned to be done in the next financial year (Yes/No)",
                id: "1-2-0-3",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "1-2-0-4",
              },
              {
                type: "static",
                value: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
              {
                type: "checkbox",
                valKey: "P1",
                key: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
              {
                type: "checkbox",
                valKey: "P2",
                key: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
              {
                type: "checkbox",
                valKey: "P3",
                key: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
              {
                type: "checkbox",
                valKey: "P4",
                key: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
              {
                type: "checkbox",
                valKey: "P5",
                key: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
              {
                type: "checkbox",
                valKey: "P6",
                key: "Any other reason (please specify)",
                id: "1-2-0-4",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "2-0-1-0-0",
                type: "static",
                value:
                  "Statement by the director responsible for the business responsibility report, highlighting ESG-related challenges, targets, and achievements. The placement of this disclosure is flexible according to the organization's preference.",
              },
              {
                id: "2-0-1-0-0",
                type: "textarea",
                valKey: "value",
                key: "Statement by the director responsible for the business responsibility report, highlighting ESG-related challenges, targets, and achievements. The placement of this disclosure is flexible according to the organization's preference.",
              },
            ],
            [
              {
                id: "2-0-1-0-1",
                type: "static",
                value:
                  "Does the entity have a specified Committee of the Board/Director responsible for decision making on sustainability related issues? (Yes/No). If yes, provide detail",
              },
              {
                id: "2-0-1-0-1",
                type: "textarea",
                valKey: "value",
                key: "Does the entity have a specified Committee of the Board/Director responsible for decision making on sustainability related issues? (Yes/No). If yes, provide detail",
              },
            ],
          ],
        },
      ],
    },
    
  ],
  SectionC: [
    {
      type: "accordion",
      label: "PRINCIPLE WISE PERFORMANCE DISCLOSURE",
      tables: [
        {
          label: "",
          th: ["SR", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "1-3-0-0",
              },
              {
                type: "static",
                value:
                  "This section is aimed at helping entities demonstrate their performance in integrating the Principles and Core Elements with key processes and decisions. The information sought is categorized as “Essential” and “Leadership”. While the essential indicators are expected to be disclosed by every entity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be socially, environmentally and ethically responsible.",
                id: "1-3-0-0",
              },
              {
                id: "1-3-0-0",
                type: "textarea",
                valKey: "value",
                key: "This section is aimed at helping entities demonstrate their performance in integrating the Principles and Core Elements with key processes and decisions. The information sought is categorized as “Essential” and “Leadership”. While the essential indicators are expected to be disclosed by every entity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be socially, environmentally and ethically responsible.",
              },
            ],
          ],
        },
      ],
    },
  ],
  Principle1: [
    {
      type: "accordion",
      label:
        "Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable.",
      tables: [
        {
          heading: "ESSENTIAL INDICATORS",
          label:
            "Percentage coverage by training and awareness programmes on any of the Principles during the financial year",
          th: [
            "Sr",
            "Segments",
            "Total number of training and awareness programmes held",
            "Topics / principles covered under the training and its impac",
            "%age of persons in respective category covered by the awareness programmes",
          ],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "2-0-0-0",
              },
              {
                type: "static",
                value: "Board of Directors",
                id: "2-0-0-0",
              },
              {
                type: "text",
                valKey:
                  "Total",
                key: "Board of Directors",
                id: "2-0-0-0",
              },
              {
                type: "text",
                valKey:
                  "Topics",
                key: "Board of Directors",
                id: "2-0-0-0",
              },
              {
                type: "text",
                valKey:
                  "%age",
                key: "Board of Directors",
                id: "2-0-0-0",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "2-0-0-1",
              },
              {
                type: "static",
                value: "Key Managerial Personnel",
                id: "2-0-0-1",
              },
              {
                type: "text",
                valKey:
                  "Total",
                key: "Key Managerial Personnel",
                id: "2-0-0-1",
              },
              {
                type: "text",
                valKey:
                  "Topics",
                key: "Key Managerial Personnel",
                id: "2-0-0-1",
              },
              {
                type: "text",
                valKey:
                  "%age",
                key: "Key Managerial Personnel",
                id: "2-0-0-1",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "2-0-0-2",
              },
              {
                type: "static",
                value: "Employees other than BoD and KMPs",
                id: "2-0-0-2",
              },
              {
                type: "text",
                valKey:
                  "Total",
                key: "Employees other than BoD and KMPs",
                id: "2-0-0-2",
              },
              {
                type: "text",
                valKey:
                  "Topics",
                key: "Employees other than BoD and KMPs",
                id: "2-0-0-2",
              },
              {
                type: "text",
                valKey:
                  "%age",
                key: "Employees other than BoD and KMPs",
                id: "2-0-0-2",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "2-0-0-3",
              },
              {
                type: "static",
                value: "Workers",
                id: "2-0-0-3",
              },
              {
                type: "text",
                valKey:
                  "Total",
                key: "Workers",
                id: "2-0-0-3",
              },
              {
                type: "text",
                valKey:
                  "Topics",
                key: "Workers",
                id: "2-0-0-3",
              },
              {
                type: "text",
                valKey:
                  "%age",
                key: "Workers",
                id: "2-0-0-3",
              },
            ],
          ],
        },
        {
          label:
            "Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount paid in proceedings (by the entity or by directors / KMPs) with regulators/ law enforcement agencies/ judicial institutions, in the financial year, in the following format (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on the entitys website",
          th: [
            "Sr",
            "Segments",
            "NGRBC Principle",
            "Name of the regulatory/ enforcement agencies/ judicial institutions",
            "Preffered? (Yes/No)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Monetary",
                colSpan: 5,
                id: "2-0-1-0",
              },
            ],
            [
              {
                type: "static",
                value: "a",
                id: "2-0-1-1",
              },
              {
                type: "static",
                value: "Penality fine",
                id: "2-0-1-1",
              },
              {
                id: "2-0-1-1",
                type: "text",
                valKey: "NGRBC",
                key: "Penality fine",
              },
              {
                id: "2-0-1-1",
                type: "text",
                valKey:
                  "Name",
                key: "Penality fine",
              },
              {
                id: "2-0-1-1",
                type: "text",
                valKey: "Preffered",
                key: "Penality fine",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "2-0-1-2",
              },
              {
                type: "static",
                value: "Settlement",
                id: "2-0-1-2",
              },
              {
                id: "2-0-1-2",
                type: "text",
                valKey: "NGRBC",
                key: "Settlement",
              },
              {
                id: "2-0-1-2",
                type: "text",
                valKey:
                  "Name",
                key: "Settlement",
              },
              {
                id: "2-0-1-2",
                type: "text",
                valKey: "Preffered",
                key: "Settlement",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "2-0-1-3",
              },
              {
                type: "static",
                value: "Compunding Fee",
                id: "2-0-1-3",
              },
              {
                id: "2-0-1-3",
                type: "text",
                valKey: "NGRBC",
                key: "Compunding Fee",
              },
              {
                id: "2-0-1-3",
                type: "text",
                valKey:
                  "Name",
                key: "Compunding Fee",
              },
              {
                id: "2-0-1-3",
                type: "text",
                valKey: "Preffered",
                key: "Compunding Fee",
              },
            ],
            [
              {
                type: "static",
                value: "Non-Monetary",
                colSpan: 5,
                id: "2-0-1-4",
              },
            ],
            [
              {
                type: "static",
                value: "a",
                id: "2-0-1-5",
              },
              {
                type: "static",
                value: "Imprisonment",
                id: "2-0-1-5",
              },
              {
                id: "2-0-1-5",
                type: "text",
                valKey: "NGRBC",
                key: "Imprisonment",
              },
              {
                id: "2-0-1-5",
                type: "text",
                valKey:
                  "Name",
                key: "Imprisonment",
              },
              {
                id: "2-0-1-5",
                type: "text",
                valKey: "Preffered",
                key: "Imprisonment",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "2-0-1-6",
              },
              {
                type: "static",
                value: "Punishement",
                id: "2-0-1-6",
              },
              {
                id: "2-0-1-6",
                type: "text",
                valKey: "NGRBC",
                key: "Punishement",
              },
              {
                id: "2-0-1-6",
                type: "text",
                valKey:
                  "Name",
                key: "Punishement",
              },
              {
                id: "2-0-1-6",
                type: "text",
                valKey: "Preffered",
                key: "Punishement",
              },
            ],
          ],
        },
        {
          label:
            "Of the instances disclosed in Question 2 above, details of the Appeal/Revision preferred in cases where monetary or non-monetary action has been appealed",
          th: [
            "Case Details",
            "Name of the regulatory/ enforcement Agencies/ judicial institutions",
          ],
          tr: [
            [
              {
                id: "3-0-0-0-0",
                type: "text",
                valKey: "Case",
                key: "1",
              },
              {
                id: "3-0-0-0-0",
                type: "text",
                valKey:
                  "Name",
                key: "1",
              },
            ],
            [
              {
                id: "3-0-0-0-1",
                type: "text",
                valKey: "Case",
                key: "2",
              },
              {
                id: "3-0-0-0-1",
                type: "text",
                valKey:
                  "Name",
                key: "2",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "3-0-1-0-0",
                type: "static",
                value:
                  "Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief and if available, provide a web-link to the policy",
              },
              {
                id: "3-0-1-0-0",
                type: "text",
                valKey: "value",
                key: "Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief and if available, provide a web-link to the policy",
              },
            ],
          ],
        },
        {
          label:
            "Number of Directors/KMPs/employees against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/corruption:",
          th: ["Categories", "Value"],
          tr: [
            [
              { id: "3-0-2-0-0", type: "static", value: "Directors" },
              {
                id: "3-0-2-0-0",
                type: "text",
                key: "Directors",
                valKey: "value",
              },
            ],
            [
              { id: "3-0-2-0-1", type: "static", value: "KMPs" },
              { id: "3-0-2-0-1", type: "text", key: "KMPs", valKey: "value" },
            ],
            [
              { id: "3-0-2-0-2", type: "static", value: "Employees" },
              {
                id: "3-0-2-0-2",
                type: "text",
                key: "Employees",
                valKey: "value",
              },
            ],
            [
              { id: "3-0-2-0-3", type: "static", value: "Workers" },
              {
                id: "3-0-2-0-3",
                type: "text",
                key: "Workers",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "Details of complaints with regard to conflict of interest:",
          th: ["Categories", "Value"],
          tr: [
            [
              {
                id: "3-0-3-0-0",
                type: "static",
                value:
                  "Number of complaints received in relation to issues of Conflict of Interest of the Directors",
              },
              {
                id: "3-0-3-0-0",
                type: "text",
                key: "Number of complaints received in relation to issues of Conflict of Interest of the Directors",
                valKey: "value",
              },
            ],
            [
              {
                id: "3-0-3-0-1",
                type: "static",
                value:
                  "Number of complaints received in relation to issues of Conflict of Interest of the KMPs",
              },
              {
                id: "3-0-3-0-1",
                type: "text",
                key: "Number of complaints received in relation to issues of Conflict of Interest of the KMPs",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                id: "3-1-4-0-0",
                type: "static",
                value:
                  "Provide details of any corrective action taken or underway on issues related to fines / penalties / action taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts of interest",
              },
              {
                id: "3-1-4-0-0",
                type: "text",
                valKey: "value",
                key: "Provide details of any corrective action taken or underway on issues related to fines / penalties / action taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts of interest",
              },
            ],
           
          ],
        },
        {
          label: "Number of days of accounts payables (Accounts payable *365) / Cost of goods/services procured) in the following format:",
          th: ["Categories", "value"],
          tr: [
            [
              {
                id: "3-7-7-0-0",
                type: "static",
                value:"Number of days of accounts payables",
              },
              {
                id: "3-7-7-0-0",
                type: "text",
                valKey: "value",
                key: "Number of days of accounts payables",
              },
            ],
          ],
        },
        {
          label:
            "Provide details of concentration of purchases and sales with trading houses, dealers and related parties along-with loans and advances & investments, with related parties in the following format:",
          th: ["Parameter", "Metrics", "Value"],
          tr: [
            [
              {
                id: "3-5-5-0-0",
                type: "static",
                value: "Concentration of purchases",
              },
              {
                id: "3-5-5-0-0",
                type: "static",
                value: "Purchases from trading houses as % of total purchases",
              },
              {
                id: "3-5-5-0-0",
                type: "text",
                valKey: "value",
                key: "Purchases from trading houses as % of total purchases",
              },
            ],
            [
              {
                id: "3-5-5-0-1",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-1",
                type: "static",
                value: "Number of trading houses where purchases are made from",
              },
              {
                id: "3-5-5-0-1",
                type: "text",
                valKey: "value",
                key: "Number of trading houses where purchases are made from",
              },
            ],
            [
              {
                id: "3-5-5-0-2",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-2",
                type: "static",
                value:
                  "Purchases from top 10 trading houses as % of total purchases from trading houses",
              },
              {
                id: "3-5-5-0-2",
                type: "text",
                valKey: "value",
                key: "Purchases from top 10 trading houses as % of total purchases from trading houses",
              },
            ],
            [
              {
                id: "3-5-5-0-3",
                type: "static",
                value: "Concentration of Sales",
              },
              {
                id: "3-5-5-0-3",
                type: "static",
                value: "Sales to dealers/distributors as % of total sales",
              },
              {
                id: "3-5-5-0-3",
                type: "text",
                valKey: "value",
                key: "Sales to dealers/distributors as % of total sales",
              },
            ],
            [
              {
                id: "3-5-5-0-4",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-4",
                type: "static",
                value: "Number of dealers/distributors to whom sales are made",
              },
              {
                id: "3-5-5-0-4",
                type: "text",
                valKey: "value",
                key: "Number of dealers/distributors to whom sales are made",
              },
            ],
            [
              {
                id: "3-5-5-0-5",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-5",
                type: "static",
                value:
                  "Sales to top 10 dealers/distributors as % of total sales to dealers/ distributors",
              },
              {
                id: "3-5-5-0-5",
                type: "text",
                valKey: "value",
                key: "Sales to top 10 dealers/distributors as % of total sales to dealers/ distributors",
              },
            ],
            [
              {
                id: "3-5-5-0-6",
                type: "static",
                value: "Share of RPTs in",
              },
              {
                id: "3-5-5-0-6",
                type: "static",
                value:
                  "Purchases (Purchases with related parties/Total Purchases)",
              },
              {
                id: "3-5-5-0-6",
                type: "text",
                valKey: "value",
                key: "Purchases (Purchases with related parties/Total Purchases)",
              },
            ],
            [
              {
                id: "3-5-5-0-7",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-7",
                type: "static",
                value: "Sales (Sales to related parties/Total Sales)",
              },
              {
                id: "3-5-5-0-7",
                type: "text",
                valKey: "value",
                key: "Sales (Sales to related parties/Total Sales)",
              },
            ],
            [
              {
                id: "3-5-5-0-8",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-8",
                type: "static",
                value:
                  "Loans & advances (Loans & advances given to related parties/Total loans & advances)",
              },
              {
                id: "3-5-5-0-8",
                type: "text",
                valKey: "value",
                key: "Loans & advances (Loans & advances given to related parties/Total loans & advances)",
              },
            ],
            [
              {
                id: "3-5-5-0-9",
                type: "static",
                value: "",
              },
              {
                id: "3-5-5-0-9",
                type: "static",
                value:
                  "Investments(Investments in related parties/ Total Investments made)",
              },
              {
                id: "3-5-5-0-9",
                type: "text",
                valKey: "value",
                key: "Investments(Investments in related parties/ Total Investments made)",
              },
            ],
          ],
        },
        {
          heading: "LEADERSHIP INDICATORS",
          label:"Awareness programmes conducted for value chain partners on any of the Principles during the financial year",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "2-0-2-0",
              },
              {
                type: "static",
                value: "Total number of awareness programmes held",
                id: "2-0-2-0",
              },
              {
                type: "text",
                valkey: "value",
                key: "Total number of awareness programmes held",
                id: "2-0-2-0",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "2-0-2-1",
              },
              {
                type: "static",
                value: "Topics / principles covered under the training",
                id: "2-0-2-1",
              },
              {
                id: "2-0-2-1",
                type: "text",
                valkey: "value",
                key: "Topics / principles covered under the training",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "2-0-2-2",
              },
              {
                type: "static",
                value:
                  "%age of value chain partners covered (by value of business done with such partners) under the awareness programmes",
                id: "2-0-2-2",
              },
              {
                id: "2-0-2-2",
                type: "text",
                valkey: "value",
                key: "%age of value chain partners covered (by value of business done with such partners) under the awareness programmes",
              },
            ],
          ]
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value: "4",
                id: "2-0-3-0",
              },
              {
                type: "static",
                value:
                  "Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board?",
                id: "2-0-3-0",
              },
              {
                id: "2-0-3-0",
                type: "text",
                valKey: "value",
                key: "Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board?",
              },
            ],
          ],
        },
     
      ],
    },
  ],
  Principle2: [
    {
      type: "accordion",
      label:
        "Businesses should provide goods and services in a manner that is sustainable and safe",
      tables: [
        {
          heading: "ESSENTIAL INDICATORS",
          label:
            "Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes to total R&D and capex investments made by the entity, respectively",
          th: [
            "Sr",
            "Title",
            "Current Financial Year",
            "Details of improvements in environmental and social impacts",
          ],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "3-0-0-0",
              },
              {
                type: "static",
                value: "R&D",
                id: "3-0-0-0",
              },
              {
                id: "3-0-0-0",
                type: "text",
                valKey: "Year",
                key: "R&D",
              },
              {
                id: "3-0-0-0",
                type: "text",
                valKey:
                  "Details",
                key: "R&D",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "3-0-0-1",
              },
              {
                type: "static",
                value: "Capex",
                id: "3-0-0-1",
              },
              {
                type: "text",
                valKey: "Year",
                key: "Capex",
                id: "3-0-0-1",
              },
              {
                type: "text",
                valKey:
                  "Details",
                key: "Capex",
                id: "3-0-0-1",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Does the entity have procedures in place for sustainable sourcing? If yes, what percentage of inputs were sourced sustainably?",
                id: "3-0-1-0",
              },
              {
                id: "3-0-1-0",
                type: "textarea",
                valKey: "value",
                key: "Does the entity have procedures in place for sustainable sourcing? If yes, what percentage of inputs were sourced sustainably?",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            // [
            //   {
            //     type: "static",
            //     value:
            //       "Does the entity have procedures in place for sustainable sourcing? (Yes/No)",
            //     id: "3-0-4-0",
            //   },
            //   {
            //     id: "3-0-4-0",
            //     type: "text",
            //     valKey: "value",
            //     key: "Does the entity have procedures in place for sustainable sourcing? (Yes/No)",
            //   },
            // ],
            // [
            //   {
            //     type: "static",
            //     value:
            //       "If yes, what percentage of inputs were sourced sustainably?",
            //     id: "3-0-4-1",
            //   },
            //   {
            //     id: "3-0-4-1",
            //     type: "text",
            //     valKey: "value",
            //     key: "If yes, what percentage of inputs were sourced sustainably?",
            //   },
            // ],
            [
              {
                type: "static",
                value:
                  "Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for (a) Plastics (including packaging) (b) E-waste (c) Hazardous waste and (d) other waste.",
                id: "3-0-4-2",
              },
              {
                id: "3-0-4-2",
                type: "textarea",
                valKey: "value",
                key: "Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for (a) Plastics (including packaging) (b) E-waste (c) Hazardous waste and (d) other waste.",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No). If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan submitted to Pollution Control Boards? If not, provide steps taken to address the same.",
                id: "3-0-4-3",
              },
              {
                id: "3-0-4-3",
                type: "textarea",
                valKey: "value",
                key: "Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No). If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan submitted to Pollution Control Boards? If not, provide steps taken to address the same.",
              },
            ],
          ],
        },

        { 
          heading: "LEADERSHIP INDICATORS",
          label:
            "Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following format?",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "3-0-2-0",
              },
              {
                type: "static",
                value: "NIC Code",
                id: "3-0-2-0",
              },
              {
                id: "3-0-2-0",
                type: "text",
                valKey: "value",
                key: "NIC Code",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "3-0-2-1",
              },
              {
                type: "static",
                value: "Name of Product /Service",
                id: "3-0-2-1",
              },
              {
                id: "3-0-2-1",
                type: "text",
                valKey: "value",
                key: "Name of Product /Service",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "3-0-2-2",
              },
              {
                type: "static",
                value: "% of total Turnover contributed",
                id: "3-0-2-2",
              },
              {
                id: "3-0-2-2",
                type: "text",
                valKey: "value",
                key: "% of total Turnover contributed",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "3-0-2-3",
              },
              {
                type: "static",
                value:
                  "Boundary for which the Life Cycle Perspective / Assessment was conducted",
                id: "3-0-2-3",
              },
              {
                id: "3-0-2-3",
                type: "text",
                valKey: "value",
                key: "Boundary for which the Life Cycle Perspective / Assessment was conducted",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "3-0-2-4",
              },
              {
                type: "static",
                value:
                  "Whether conducted by independent external agency (Yes/No)",
                id: "3-0-2-4",
              },
              {
                id: "3-0-2-4",
                type: "text",
                valKey: "value",
                key: "Whether conducted by independent external agency (Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value: "f",
                id: "3-0-2-5",
              },
              {
                type: "static",
                value:
                  "Results communicated in public domain (Yes/No) If yes, provide the web-link.",
                id: "3-0-2-5",
              },
              {
                id: "3-0-2-5",
                type: "text",
                valKey: "value",
                key: "Results communicated in public domain (Yes/No) If yes, provide the web-link.",
              },
            ],
          ],
        },
        {
          label:
            "If there are any significant social or environmental concerns and/or risks arising from production or disposal of your services, as identified in the Life Cycle Perspective/ Assessments (LCA) or through any other means, briefly describe the same along-with action taken to mitigate the same.",
          th: ["Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "Name of Product / Service",
                id: "3-0-5-0",
              },
              {
                id: "3-0-5-0",
                type: "text",
                valKey: "value",
                key: "Name of Product / Service",
              },
            ],
            [
              {
                type: "static",
                value: "Description of the risk / concern",
                id: "3-0-5-1",
              },
              {
                id: "3-0-5-1",
                type: "text",
                valKey: "value",
                key: "Description of the risk / concern",
              },
            ],
            [
              {
                type: "static",
                value: "Action Taken",
                id: "3-0-5-2",
              },
              {
                id: "3-0-5-2",
                type: "text",
                valKey: "value",
                key: "Action Taken",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Percentage of recycled or reused input material to total material (by value) used in production (for manufacturing industry) or providing services (for service industry).",
                id: "3-0-6-0",
              },
              {
                id: "3-0-6-0",
                type: "textarea",
                valKey: "value",
                key: "Percentage of recycled or reused input material to total material (by value) used in production (for manufacturing industry) or providing services (for service industry).",
              },
            ],
          ],
        },
        {
          label:
            "Of the products and packaging reclaimed at the end of life of products, amount (in metric tonnes) reused, recycled and safely disposed",
          th: ["Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "Reuse (MT)",
                id: "3-0-7-0",
              },
              {
                id: "3-0-7-0",
                type: "text",
                valKey: "value",
                key: "Reuse (MT)",
              },
            ],
            [
              {
                type: "static",
                value: "Recycling (MT)",
                id: "3-0-7-1",
              },
              {
                id: "3-0-7-1",
                type: "text",
                valKey: "value",
                key: "Recycling (MT)",
              },
            ],
            [
              {
                type: "static",
                value: "Disposed (MT)",
                id: "3-0-7-2",
              },
              {
                id: "3-0-7-2",
                type: "text",
                valKey: "value",
                key: "Disposed (MT)",
              },
            ],
          ],
        },
        {
          label:
            "Reclaimed products and their packaging materials (as percentage of products sold) for each product category.",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "3-0-3-0",
              },
              {
                type: "static",
                value: "Indicate product category",
                id: "3-0-3-0",
              },
              {
                id: "3-0-3-0",
                type: "text",
                valKey: "value",
                key: "Indicate product category",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "3-0-3-1",
              },
              {
                type: "static",
                value:
                  "Reclaimed products and their packaging materials as % of total products sold in respective category",
                id: "3-0-3-1",
              },
              {
                id: "3-0-3-1",
                type: "text",
                valKey: "value",
                key: "Reclaimed products and their packaging materials as % of total products sold in respective category",
              },
            ],
          ],
        },
       
      ],
    },
  ],
  Principle3: [
    {
      type: "accordion",
      label:
        "Businesses should respect and promote the well-being of all employees, including those in their value chains",
      tables: [
        {
          heading: "ESSENTIAL INDICATORS",
          label: "Details of measures for the well-being of employees:",
          th: [],
          tr: [
            [
              {
                type: "static",
                value: "Permanent Employees",
                id: "4-0-2-0",
                colSpan: 2,
              },
            ],
            [
              {
                type: "static",
                value: "Male",
                id: "4-0-2-1",
              },
              {
                id: "4-0-2-1",
                type: "number",
                valKey: "Value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "4-0-2-2",
              },
              {
                id: "4-0-2-2",
                type: "number",
                valKey: "Value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "Total",
                id: "4-0-2-3",
              },
              {
                id: "4-0-2-3",
                type: "calculated",
                valKey: "Value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-2-1', '4-0-2-2'], "Value");
                },
              },
            ],
          ],
        },
        {
          label: "",
          th: [
            "",
            "Number",
            "%(Male/Number)",
            "%(Female/Number)",
            "%(Total/Number)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Health insurance",
                id: "4-0-2-4",
              },
              {
                id: "4-0-2-4",
                type: "number",
                valKey: "Number",
                key: "Health insurance",
              },
              {
                id: "4-0-2-4",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Health insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-4').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-4",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Health insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-4').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-4",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Health insurance",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-2-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-2-2').value.Value || 0)
                  
                  const total = male + female
                  const b = data.find(e=>e.id==='4-0-2-4').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Accident insurance",
                id: "4-0-2-5",
              },
              {
                id: "4-0-2-5",
                type: "number",
                valKey: "Number",
                key: "Accident insurance",
              },
              {
                id: "4-0-2-5",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Accident insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-5').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-5",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Accident insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-5').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-5",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Accident insurance",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-2-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-2-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-2-5').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Maternity benefits",
                id: "4-0-2-6",
              },
              {
                id: "4-0-2-6",
                type: "number",
                valKey: "Number",
                key: "Maternity benefits",
              },
              {
                id: "4-0-2-6",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Maternity benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-6').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-6",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Maternity benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-6').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-6",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Maternity benefits",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-2-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-2-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-2-6').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Paternity Benefits",
                id: "4-0-2-7",
              },
              {
                id: "4-0-2-7",
                type: "number",
                valKey: "Number",
                key: "Paternity Benefits",
              },
              {
                id: "4-0-2-7",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Paternity Benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-7').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-7",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Paternity Benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-7').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-7",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Paternity Benefits",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-2-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-2-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-2-7').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Day Care facilities",
                id: "4-0-2-8",
              },
              {
                id: "4-0-2-8",
                type: "number",
                valKey: "Number",
                key: "Day Care facilities",
              },
              {
                id: "4-0-2-8",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Day Care facilities",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-4').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-8",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Day Care facilities",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-2-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-2-4').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-2-8",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Day Care facilities",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-2-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-2-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-2-8').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value: "Other Than Permanent Employees",
                id: "4-0-3-0",
                colSpan: 2,
              },
            ],
            [
              {
                type: "static",
                value: "Male",
                id: "4-0-3-1",
              },
              {
                id: "4-0-3-1",
                type: "number",
                valKey: "Value",
                key: "Male",
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "4-0-3-2",
              },
              {
                id: "4-0-3-2",
                type: "number",
                valKey: "Value",
                key: "Female",
              },
            ],
            [
              {
                type: "static",
                value: "Total",
                id: "4-0-3-3",
              },
              {
                id: "4-0-3-3",
                type: "calculated",
                valKey: "value",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-3-1', '4-0-3-2'], "Value");
                },
              },
            ],
          ],
        },
        {
          label: "",
          th: [
            "",
            "Number",
            "%(Male/Number)",
            "%(Female/Number)",
            "%(Total/Number)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Health insurance",
                id: "4-0-3-4",
              },
              
              {
                id: "4-0-3-4",
                type: "number",
                valKey: "Number",
                key: "Health insurance",
              },
              {
                id: "4-0-3-4",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Health insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-4').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
    
                },
              },
              {
                id: "4-0-3-4",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Health insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-4').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-4",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Health insurance",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-3-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-3-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-3-4').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
    
                },
              },
            ],
            [
              {
                type: "static",
                value: "Accident insurance",
                id: "4-0-3-5",
              },
              
              {
                id: "4-0-3-5",
                type: "number",
                valKey: "Number",
                key: "Accident insurance",
              },
              {
                id: "4-0-3-5",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Accident insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-5').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-5",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Accident insurance",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-5').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-5",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Accident insurance",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-3-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-3-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-3-5').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Maternity benefits",
                id: "4-0-3-6",
              },
             
              {
                id: "4-0-3-6",
                type: "number",
                valKey: "Number",
                key: "Maternity benefits",
              },
              {
                id: "4-0-3-6",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Maternity benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-6').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-6",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Maternity benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-6').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-6",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Maternity benefits",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-3-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-3-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-3-6').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Paternity Benefits",
                id: "4-0-3-7",
              },
              {
                id: "4-0-3-7",
                type: "number",
                valKey: "Number",
                key: "Paternity Benefits",
              },
              {
                id: "4-0-3-7",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Paternity Benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-7').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-7",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Paternity Benefits",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-7').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-7",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Paternity Benefits",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-3-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-3-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-3-7').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
            [
              {
                type: "static",
                value: "Day Care facilities",
                id: "4-0-3-8",
              },
              
              {
                id: "4-0-3-8",
                type: "number",
                valKey: "Number",
                key: "Day Care facilities",
              },
              {
                id: "4-0-3-8",
                type: "calculated",
                valKey: "%(Male/Number)",
                key: "Day Care facilities",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-1').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-8').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-8",
                type: "calculated",
                valKey: "%(Female/Number)",
                key: "Day Care facilities",
                calculation: ({ data  }) => {
                  const a= data.find(e=>e.id==='4-0-3-2').value.Value || 0
                  const b = data.find(e=>e.id==='4-0-3-8').value.Number || 0
                  return isFinite(a/b*100) ? (a/b*100 || 0).toFixed(2) : 0;
                },
              },
              {
                id: "4-0-3-8",
                type: "calculated",
                valKey: "%(Total/Number)",
                key: "Day Care facilities",
                calculation: ({ data  }) => {
                  const male= Number(data.find(e=>e.id==='4-0-3-1').value.Value || 0)
                  const female= Number(data.find(e=>e.id==='4-0-3-2').value.Value || 0)
                  const total = male+female || 0;
                  const b = data.find(e=>e.id==='4-0-3-8').value.Number || 0
                  return isFinite(total/b*100) ? (total/b*100 || 0).toFixed(2) : 0;
                },
              },
            ],
          ],
        },
        {
          label:
            "Is there a mechanism available to receive and redress grievances for the following categories of employees and worker? If yes, give details of the mechanism in brief",
          th: [
            "Sr",
            "Parameters",
            "Yes/NO",
            "IF yes then give the details of mechanism in details",
          ],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "4-0-0-0",
              },
              {
                type: "static",
                value: "Employees ( Permanent + Contract)",
                id: "4-0-0-0",
              },
              {
                id: "4-0-0-0",
                type: "text",
                valKey: "Yes/NO",
                key: "Employees ( Permanent + Contract)",
              },
              {
                id: "4-0-0-0",
                type: "text",
                valKey: "details",
                key: "Employees ( Permanent + Contract)",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "4-0-0-1",
              },
              {
                type: "static",
                value: "Workers ( Permanent + Contract)",
                id: "4-0-0-1",
              },
              {
                id: "4-0-0-1",
                type: "text",
                valKey: "Yes/NO",
                key: "Workers ( Permanent + Contract)",
              },
              {
                id: "4-0-0-1",
                type: "text",
                valKey: "details",
                key: "Workers ( Permanent + Contract)",
              },
            ],
          ],
        },
        {
          label:
            "Details of retirement benefits, for Current FY and Previous Financial Year.",
          th: [
            "Benifits",
            "No. of employees covered as a % of total employees",
            "No. of workers covered as a % of total workers",
            "Deducted and deposited with the authority (Y/N/N.A.)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "PF",
                id: "4-0-4-0",
              },
              {
                id: "4-0-4-0",
                type: "text",
                valKey: "employees",
                key: "PF",
              },
              {
                id: "4-0-4-0",
                type: "text",
                valKey: "workers",
                key: "PF",
              },
              {
                id: "4-0-4-0",
                type: "text",
                valKey: "Deducted",
                key: "PF",
              },
            ],
            [
              {
                type: "static",
                value: "Gratuity",
                id: "4-0-4-1",
              },
              {
                id: "4-0-4-1",
                type: "text",
                valKey: "employees",
                key: "Gratuity",
              },
              {
                id: "4-0-4-1",
                type: "text",
                valKey: "workers",
                key: "Gratuity",
              },
              {
                id: "4-0-4-1",
                type: "text",
                valKey: "Deducted",
                key: "Gratuity",
              },
            ],
            [
              {
                type: "static",
                value: "ESI",
                id: "4-0-4-2",
              },
              {
                id: "4-0-4-2",
                type: "text",
                valKey: "employees",
                key: "ESI",
              },
              {
                id: "4-0-4-2",
                type: "text",
                valKey: "workers",
                key: "ESI",
              },
              {
                id: "4-0-4-2",
                type: "text",
                valKey: "Deducted",
                key: "ESI",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are being taken by the entity in this regard.",
                id: "4-0-5-0",
              },
              {
                id: "4-0-5-0",
                type: "textarea",
                valKey: "value",
                key: "Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are being taken by the entity in this regard.",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy",
                id: "4-0-5-1",
              },
              {
                id: "4-0-5-1",
                type: "textarea",
                valKey: "value",
                key: "Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy",
              },
            ],
          ],
        },
        {
          label:
            "Return to work and Retention rates of permanent employees and workers that took parental leave",
          th: ["Gender", "Males", "Females"],
          tr: [
            [
              {
                type: "static",
                value: "Permanent Employees",
                id: "4-0-6-0",
              },
            ],
            [
              {
                type: "static",
                value: "Return to work rate",
                id: "4-0-6-1",
              },
    
              {
                id: "4-0-6-1",
                type: "number",
                valKey: "Males",
                key: "Return to work rate",
              },
              {
                id: "4-0-6-1",
                type: "number",
                valKey: "Females",
                key: "Return to work rate",
              },
            ],
            [
              {
                type: "static",
                value: "Retention Rate",
                id: "4-0-6-2",
              },
    
              {
                id: "4-0-6-2",
                type: "number",
                valKey: "Males",
                key: "Retention Rate",
              },
              {
                id: "4-0-6-2",
                type: "number",
                valKey: "Females",
                key: "Retention Rate",
              },
            ],
            [
              {
                type: "static",
                value: "Permanent Workers",
                id: "4-0-6-3",
                colspan: 2,
              },
            ],
            [
              {
                type: "static",
                value: "Return to work rate",
                id: "4-0-6-4",
              },
    
              {
                id: "4-0-6-4",
                type: "number",
                valKey: "Males",
                key: "Return to work rate",
              },
              {
                id: "4-0-6-4",
                type: "number",
                valKey: "Females",
                key: "Return to work rate",
              },
            ],
            [
              {
                type: "static",
                value: "Retention Rate",
                id: "4-0-6-5",
              },
    
              {
                id: "4-0-6-5",
                type: "number",
                valKey: "Males",
                key: "Retention Rate",
              },
              {
                id: "4-0-6-5",
                type: "number",
                valKey: "Females",
                key: "Retention Rate",
              },
            ],
          ],
        },
        {
          label:
            "Membership of employees and worker in association(s) or Unions recognized by the listed entity:",
          th: [
            "Category",
            "Total employees in respective category (A)",
            "No. of employees in respective category, who are part of association(s) or Union (B)",
            "% (B/A)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Total Permanent Employees",
                id: "4-0-7-0",
              },
              {
                id: "4-0-7-0",
                type: "number",
                valKey: "category_A",
                key: "Total Permanent Employees",
              },
              {
                id: "4-0-7-0",
                type: "number",
                valKey:"employees_B",
                key: "Total Permanent Employees",
              },
              {
                id: "4-0-7-0",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Total Permanent Employees",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-7-0'], "employees_B", "category_A");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Male",
                id: "4-0-7-1",
              },
              {
                id: "4-0-7-1",
                type: "number",
                valKey: "category_A",
                key: "Male",
              },
              {
                id: "4-0-7-1",
                type: "number",
                valKey:
                  "employees_B",
                key: "Male",
              },
              {
                id: "4-0-7-1",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-7-1'], "employees_B", "category_A");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "4-0-7-2",
              },
              {
                id: "4-0-7-2",
                type: "number",
                valKey: "category_A",
                key: "Female",
              },
              {
                id: "4-0-7-2",
                type: "number",
                valKey:
                  "employees_B",
                key: "Female",
              },
              {
                id: "4-0-7-2",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-7-2'], "employees_B", "category_A");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Others",
                id: "4-0-7-3",
              },
              {
                id: "4-0-7-3",
                type: "number",
                valKey: "category_A",
                key: "Others",
              },
              {
                id: "4-0-7-3",
                type: "number",
                valKey:
                  "employees_B",
                key: "Others",
              },
              {
                id: "4-0-7-3",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Others",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-7-3'], "employees_B", "category_A");
                },
              },
            ],
          ],
        },
        {
          label: "Details of training given to employees:",
          th: [
            "Category",
            "Total (A)",
            "No. (B)",
            "% (B/A)",
            "No. (C)",
            "% (C/A)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Male",
                id: "4-0-8-0",
              },
              {
                id: "4-0-8-0",
                type: "number",
                valKey: "Total (A)",
                key: "Male",
              },
              {
                id: "4-0-8-0",
                type: "number",
                valKey: "No. (B)",
                key: "Male",
              },
              {
                id: "4-0-8-0",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-8-0'], "No. (B)","Total (A)");
                },
              },
              {
                id: "4-0-8-0",
                type: "number",
                valKey: "No. (C)",
                key: "Male",
              },
              {
                id: "4-0-8-0",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-8-0'], "No. (C)","Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "4-0-8-1",
              },
              {
                id: "4-0-8-1",
                type: "number",
                valKey: "Total (A)",
                key: "Female",
              },
              {
                id: "4-0-8-1",
                type: "number",
                valKey: "No. (B)",
                key: "Female",
              },
              {
                id: "4-0-8-1",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-8-1'], "No. (B)","Total (A)");
                },
              },
              {
                id: "4-0-8-1",
                type: "number",
                valKey: "No. (C)",
                key: "Female",
              },
              {
                id: "4-0-8-1",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-8-1'], "No. (C)","Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Total",
                id: "4-0-8-2",
              },
              {
                id: "4-0-8-2",
                type: "calculated",
                valKey: "Total (A)",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-8-0','4-0-8-1'], "Total (A)");
                },
              },
              {
                id: "4-0-8-2",
                type: "calculated",
                valKey: "No. (B)",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-8-0','4-0-8-1'], "No. (B)");
                },
               
              },
              {
                id: "4-0-8-2",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Total",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-8-0','4-0-8-1'], "No. (B)","Total (A)");
                },
              },
              {
                id: "4-0-8-2",
                type: "calculated",
                valKey: "No. (C)",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-8-0','4-0-8-1'], "No. (C)");
                },
              },
              {
                id: "4-0-8-2",
                type: "calculated",
                valKey: "% (C/A)",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-8-0','4-0-8-1'], "No. (C)","Total (A)");
                },
                key: "Total",
              },
            ],
          ],
        },
        {
          label:
            "Details of performance and career development reviews of employees and worker:",
          th: ["Category", "Total (A)", "No. (B)", "% (B/A)"],
          tr: [
            [
              {
                type: "static",
                value: "Employees",
                id: "4-0-9-0",
              },
            ],
    
            [
              {
                type: "static",
                value: "Male",
                id: "4-0-9-1",
              },
              {
                id: "4-0-9-1",
                type: "number",
                valKey: "Total (A)",
                key: "Male",
              },
              {
                id: "4-0-9-1",
                type: "number",
                valKey: "No. (B)",
                key: "Male",
              },
              {
                id: "4-0-9-1",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-9-1'], "No. (B)","Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "4-0-9-2",
              },
              {
                id: "4-0-9-2",
                type: "number",
                valKey: "Total (A)",
                key: "Female",
              },
              {
                id: "4-0-9-2",
                type: "number",
                valKey: "No. (B)",
                key: "Female",
              },
              {
                id: "4-0-9-2",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-9-2'], "No. (B)","Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Others",
                id: "4-0-9-3",
              },
              {
                id: "4-0-9-3",
                type: "number",
                valKey: "Total (A)",
                key: "Others",
              },
              {
                id: "4-0-9-3",
                type: "number",
                valKey: "No. (B)",
                key: "Others",
              },
              {
                id: "4-0-9-3",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Others",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-9-3'], "No. (B)","Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Total",
                id: "4-0-9-3",
              },
              {
                id: "4-0-9-3",
                type: "calculated",
                valKey: "Total (A)",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-9-1','4-0-9-2','4-0-9-3'],"Total (A)");
                },
              },
              {
                id: "4-0-9-3",
                type: "calculated",
                valKey: "No. (B)",
                key: "Total",
                calculation: ({ data  }) => {
                  return sum(data, ['4-0-9-1','4-0-9-2','4-0-9-3'],"No. (B)");
                },
              },
              {
                id: "4-0-9-3",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Total",
                calculation: ({ data }) => {
                  return percentage(data, ['4-0-9-1','4-0-9-2','4-0-9-3'], "No. (B)","Total (A)");
                },
              },
            ],
          ],
        },
        {
          label: "Health and safety management system:",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Whether an occupational health and safety management system has been implemented by the entity? (Yes/No). If yes, the coverage of such system?",
                id: "4-0-10-0",
              },
              {
                id: "4-0-10-0",
                type: "text",
                valKey: "value",
                key: "Whether an occupational health and safety management system has been implemented by the entity? (Yes/No). If yes, the coverage of such system?",
              },
            ],
            [
              {
                type: "static",
                value:
                  "What are the processes used to identify work-related hazards and assess risks on a routine and non-routine basis by the entity?",
                id: "4-0-10-1",
              },
              {
                id: "4-0-10-1",
                type: "text",
                valKey: "value",
                key: "What are the processes used to identify work-related hazards and assess risks on a routine and non-routine basis by the entity?",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Whether you have processes for workers to report work-related hazards and to remove themselves from such risks. (Y/N)",
                id: "4-0-10-2",
              },
              {
                id: "4-0-10-2",
                type: "text",
                valKey: "value",
                key: "Whether you have processes for workers to report work-related hazards and to remove themselves from such risks. (Y/N)",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Do the employees/worker of the entity have access to non-occupational medical and healthcare services? (Yes/No)",
                id: "4-0-10-3",
              },
              {
                id: "4-0-10-3",
                type: "text",
                valKey: "value",
                key: "Do the employees/worker of the entity have access to non-occupational medical and healthcare services? (Yes/No)",
              },
            ],
          ],
        },
        {
          label:
            "Details of performance and career development reviews of employees and worker:",
          th: ["Safety Incident/Number", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "Employees",
                id: "4-0-11-0",
              },
            ],
    
            [
              {
                type: "static",
                value:
                  "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
                id: "4-0-11-1",
              },
              {
                id: "4-0-11-1",
                type: "number",
                valKey: "Value",
                key: "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
              },
            ],
            [
              {
                type: "static",
                value: "MaTotal recordable work-related injuriesle",
                id: "4-0-11-2",
              },
              {
                id: "4-0-11-2",
                type: "number",
                valKey: "Value",
                key: "MaTotal recordable work-related injuriesle",
              },
            ],
            [
              {
                type: "static",
                value: "No. of fatalities",
                id: "4-0-11-3",
              },
              {
                id: "4-0-11-3",
                type: "number",
                valKey: "Value",
                key: "No. of fatalities",
              },
            ],
            [
              {
                type: "static",
                value:
                  "High consequence work-related injury or ill-health (excluding fatalities)",
                id: "4-0-11-4",
              },
              {
                id: "4-0-11-4",
                type: "number",
                valKey: "Value",
                key: "High consequence work-related injury or ill-health (excluding fatalities)",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Describe the measures taken by the entity to ensure a safe and healthy work place",
                id: "4-0-12-0",
              },
              {
                id: "4-0-12-0",
                type: "textarea",
                valKey: "value",
                key: "Describe the measures taken by the entity to ensure a safe and healthy work place",
              },
            ],
          ],
        },
        {
          label:
            "Number of Complaints on the following made by employees and workers:",
          th: [
            "Categories",
            "Filed during the year",
            "Pending resolution at the end of year",
            "Remarks",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Working Conditions",
                id: "4-0-13-0",
              },
              {
                id: "4-0-13-0",
                type: "text",
                valKey: "Filed",
                key: "Working Conditions",
              },
              {
                id: "4-0-13-0",
                type: "text",
                valKey: "Pending",
                key: "Working Conditions",
              },
              {
                id: "4-0-13-0",
                type: "text",
                valKey: "Remarks",
                key: "Working Conditions",
              },
            ],
            [
              {
                type: "static",
                value: "Health & Safety",
                id: "4-0-13-1",
              },
              {
                id: "4-0-13-1",
                type: "text",
                valKey: "Filed",
                key: "Health & Safety",
              },
              {
                id: "4-0-13-1",
                type: "text",
                valKey: "Pending",
                key: "Health & Safety",
              },
              {
                id: "4-0-13-1",
                type: "text",
                valKey: "Remarks",
                key: "Health & Safety",
              },
            ],
          ],
        },
        {
          label: "Assessments for the year:",
          th: [
            "Categories",
            "% of your plants and offices that were assessed (by entity or statutory authorities orthird parties)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Health and safety practices",
                id: "4-0-14-0",
              },
              {
                id: "4-0-14-0",
                type: "text",
                valKey:
                  "plants",
                key: "Health and safety practices",
              },
            ],
            [
              {
                type: "static",
                value: "Working Conditions",
                id: "4-0-14-1",
              },
    
              {
                id: "4-0-14-1",
                type: "text",
                valKey:
                  "plants",
                key: "Working Conditions",
              },
            ],
          ],
        },
        {
          label: "",
          heading: "LEADERSHIP INDICATORS",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N).f",
                id: "4-0-1-0",
              },
              {
                id: "4-0-1-0",
                type: "text",
                valKey: "value",
                key: "Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N).f",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners",
                id: "4-0-1-1",
              },
              {
                id: "4-0-1-1",
                type: "text",
                valKey: "value",
                key: "Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Provide details of any corrective action taken or underway to address safety-related incidents (if any) and on significant risks/concerns arising from assessments of health & safety practices and working conditions.",
                id: "4-0-15-0",
              },
              {
                id: "4-0-15-0",
                type: "textarea",
                valKey: "value",
                key: "Provide details of any corrective action taken or underway to address safety-related incidents (if any) and on significant risks/concerns arising from assessments of health & safety practices and working conditions.",
              },
            ],
          ],
        },
        {
          lable: " ",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners.",
                id: "4-1-0-0",
              },
              {
                id: "4-1-0-0",
                type: "textarea",
                valKey: "value",
                key: "Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners.",
              },
            ],
          ],
        },
        {
          lable:
            "Provide the number of employees having suffered high consequence work- related injury/ ill-health/fatalities (as reported in Q11 of Essential Indicators above), who have been rehabilitated and placed in suitable employment or whose family members have been placed in suitable employment.",
          th: [
            "Categories",
            "Total no. of affected employees/ workers",
            "No. of employees/workers that are rehabilitated and placed in suitable employment or whose family members have been placed in suitable employment",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Employees",
                id: "4-1-1-0",
              },
              {
                id: "4-1-1-0",
                type: "text",
                valKey: "Total",
                key: "Employees",
              },
              {
                id: "4-1-1-0",
                type: "text",
                valKey:"employees",
                key: "Employees",
              },
            ],
            [
              {
                type: "static",
                value: "Workers",
                id: "4-1-1-1",
              },
              {
                id: "4-1-1-1",
                type: "text",
                valKey: "Total",
                key: "Workers",
              },
              {
                id: "4-1-1-1",
                type: "text",
                valKey:"employees",
                key: "Workers",
              },
            ],
          ],
        },
        {
          label: " ",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Does the entity provide transition assistance programs to facilitate continued employability and the management of career endings resulting from retirement or termination of employment? (Yes/No)",
                id: "4-1-2-0",
              },
              {
                id: "4-1-2-0",
                type: "text",
                valKey: "value",
                key: "Does the entity provide transition assistance programs to facilitate continued employability and the management of career endings resulting from retirement or termination of employment? (Yes/No)",
              },
            ],
          ],
        },
        {
          label: "Details on assessment of value chain partners:",
          th: [
            "Categories",
            "% of value chain partners (by value of business done with such partners) that were assessed",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Health and Safety",
                id: "4-3-1-0",
              },
              {
                id: "4-3-1-0",
                type: "text",
                valKey:"value",
                key: "Health and Safety",
              },
            ],
            [
              {
                type: "static",
                value: "Practices Working Conditions",
                id: "4-3-1-1",
              },
              {
                id: "4-3-1-1",
                type: "text",
                valKey:"value",
                key: "Practices Working Conditions",
              },
            ],
          ],
        },
      ],
    },
  ],
  Principle4: [
    {
      type: "accordion",
      label:
        "Businesses should respect the interests of and be responsive to all its stakeholders",
      tables: [
        {
          label: "",
          heading: "ESSENTIAL INDICATORS",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Describe the processes for identifying key stakeholder groups of the entity",
                id: "5-0-0-0",
              },
              {
                id: "5-0-0-0",
                type: "text",
                valKey: "value",
                key: "Describe the processes for identifying key stakeholder groups of the entity",
              },
            ],
          ],
        },
        {
          label:
            "List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "5-0-1-0",
              },
              {
                type: "static",
                value: "Stakeholder Group",
                id: "5-0-1-0",
              },
              {
                id: "5-0-1-0",
                type: "text",
                valKey: "value",
                key: "Stakeholder Group",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "5-0-1-1",
              },
              {
                type: "static",
                value:
                  "Whether identified as Vulnerable & Marginalized Group (Yes/No)",
                id: "5-0-1-1",
              },
              {
                id: "5-0-1-1",
                type: "text",
                valKey: "value",
                key: "Whether identified as Vulnerable & Marginalized Group (Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "5-0-1-2",
              },
              {
                type: "static",
                value: "Channels of communication",
                id: "5-0-1-2",
              },
              {
                id: "5-0-1-2",
                type: "text",
                valKey: "value",
                key: "Channels of communication",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "5-0-1-3",
              },
              {
                type: "static",
                value:
                  "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)",
                id: "5-0-1-3",
              },
              {
                id: "5-0-1-3",
                type: "text",
                valKey: "value",
                key: "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "5-0-1-4",
              },
              {
                type: "static",
                value:
                  "Purpose and scope of engagement including key topics and concerns raised during such engagement",
                id: "5-0-1-4",
              },
              {
                id: "5-0-1-4",
                type: "text",
                valKey: "value",
                key: "Purpose and scope of engagement including key topics and concerns raised during such engagement",
              },
            ],
          ],
        },
        {
          label: "",
          heading: "LEADERSHIP INDICATORS",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board",
                id: "5-0-2-0",
              },
              {
                id: "5-0-2-0",
                type: "text",
                valKey: "value",
                key: "Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity",
                id: "5-0-2-1",
              },
              {
                id: "5-0-2-1",
                type: "text",
                valKey: "value",
                key: "Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.",
                id: "5-0-2-2",
              },
              {
                id: "5-0-2-2",
                type: "text",
                valKey: "value",
                key: "Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.",
              },
            ],
          ],
        },
      ],
    },
  ],
  Principle5: [
    {
      label: "Businesses should respect and promote human rights",
      type: "accordion",
      tables: [
        {
          label: "",
          heading: "ESSENTIAL INDICATORS",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)",
                id: "6-0-0-0",
              },
              {
                id: "6-0-0-0",
                type: "text",
                valKey: "value",
                key: "Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Describe the internal mechanisms in place to redress grievances related to human rights issues.",
                id: "6-0-0-1",
              },
              {
                id: "6-0-0-1",
                type: "text",
                valKey: "value",
                key: "Describe the internal mechanisms in place to redress grievances related to human rights issues.",
              },
            ],
          ],
        },
        {
          label:
            "Number of Complaints on the following made by employees and workers:",
          th: [
            "Sr",
            "Title",
            "Filed during the year",
            "Pending resolution at the end of year",
            "Remarks",
          ],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "6-0-1-0",
              },
              {
                type: "static",
                value: "Sexual Harassment",
                id: "6-0-1-0",
              },
              {
                id: "6-0-1-0",
                type: "text",
                valKey: "Filed",
                key: "Sexual Harassment",
              },
              {
                id: "6-0-1-0",
                type: "text",
                valKey: "Pending",
                key: "Sexual Harassment",
              },
              {
                id: "6-0-1-0",
                type: "text",
                valKey: "Remarks",
                key: "Sexual Harassment",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "6-0-1-1",
              },
              {
                type: "static",
                value: "Discrimination at workplace",
                id: "6-0-1-1",
              },
              {
                id: "6-0-1-1",
                type: "text",
                valKey: "Filed",
                key: "Discrimination at workplace",
              },
              {
                id: "6-0-1-1",
                type: "text",
                valKey: "Pending",
                key: "Discrimination at workplace",
              },
              {
                id: "6-0-1-1",
                type: "text",
                valKey: "Remarks",
                key: "Discrimination at workplace",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "6-0-1-2",
              },
              {
                type: "static",
                value: "Child Labour",
                id: "6-0-1-2",
              },
              {
                id: "6-0-1-2",
                type: "text",
                valKey: "Filed",
                key: "Child Labour",
              },
              {
                id: "6-0-1-2",
                type: "text",
                valKey: "Pending",
                key: "Child Labour",
              },
              {
                id: "6-0-1-2",
                type: "text",
                valKey: "Remarks",
                key: "Child Labour",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "6-0-1-3",
              },
              {
                type: "static",
                value: "Forced Labour/Involuntary Labour",
                id: "6-0-1-3",
              },
              {
                id: "6-0-1-3",
                type: "text",
                valKey: "Filed",
                key: "Forced Labour/Involuntary Labour",
              },
              {
                id: "6-0-1-3",
                type: "text",
                valKey: "Pending",
                key: "Forced Labour/Involuntary Labour",
              },
              {
                id: "6-0-1-3",
                type: "text",
                valKey: "Remarks",
                key: "Forced Labour/Involuntary Labour",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "6-0-1-4",
              },
              {
                type: "static",
                value: "Wages",
                id: "6-0-1-4",
              },
              {
                id: "6-0-1-4",
                type: "text",
                valKey: "Filed",
                key: "Wages",
              },
              {
                id: "6-0-1-4",
                type: "text",
                valKey: "Pending",
                key: "Wages",
              },
              {
                id: "6-0-1-4",
                type: "text",
                valKey: "Remarks",
                key: "Wages",
              },
            ],
            [
              {
                type: "static",
                value: "f",
                id: "6-0-1-5",
              },
              {
                type: "static",
                value: "Other human",
                id: "6-0-1-5",
              },
              {
                id: "6-0-1-5",
                type: "text",
                valKey: "Filed",
                key: "Other human",
              },
              {
                id: "6-0-1-5",
                type: "text",
                valKey: "Pending",
                key: "Other human",
              },
              {
                id: "6-0-1-5",
                type: "text",
                valKey: "Remarks",
                key: "Other human",
              },
            ],
            [
              {
                type: "static",
                value: "g",
                id: "6-0-1-6",
              },
              {
                type: "static",
                value: "Rights related issues",
                id: "6-0-1-6",
              },
              {
                id: "6-0-1-6",
                type: "text",
                valKey: "Filed",
                key: "Rights related issues",
              },
              {
                id: "6-0-1-6",
                type: "text",
                valKey: "Pending",
                key: "Rights related issues",
              },
              {
                id: "6-0-1-6",
                type: "text",
                valKey: "Remarks",
                key: "Rights related issues",
              },
            ],
          ],
        },
        {
          label:
            "Employees and workers who have been provided training on human rights issues and policy (ies) of the entity, in the following format:",
          th: [
            "Sr",
            "Categories",
            "Total (A)",
            "No. of employees covered (B)",
            "% (B/A)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Employees",
                id: "6-0-4-0",
                colSpan: "5",
              },
            ],
            [
              {
                type: "static",
                value: "a",
                id: "6-0-4-1",
              },
              {
                type: "static",
                value: "Permanent",
                id: "6-0-4-1",
              },
              {
                id: "6-0-4-1",
                type: "number",
                valKey: "Total (A)",
                key: "Permanent",
              },
              {
                id: "6-0-4-1",
                type: "number",
                valKey: "employees_B",
                key: "Permanent",
              },
              {
                id: "6-0-4-1",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Permanent",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-4-1'], "employees_B", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "6-0-4-2",
              },
              {
                type: "static",
                value: "Other than Permanent",
                id: "6-0-4-2",
              },
              {
                id: "6-0-4-2",
                type: "number",
                valKey: "Total (A)",
                key: "Other than Permanent",
              },
              {
                id: "6-0-4-2",
                type: "number",
                valKey: "employees_B",
                key: "Other than Permanent",
              },
              {
                id: "6-0-4-2",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Other than Permanent",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-4-2'], "employees_B", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "6-0-4-3",
              },
              {
                type: "static",
                value: "Total Employees",
                id: "6-0-4-3",
              },
              {
                id: "6-0-4-3",
                type: "number",
                valKey: "Total (A)",
                key: "Total Employees",
              },
              {
                id: "6-0-4-3",
                type: "number",
                valKey: "employees_B",
                key: "Total Employees",
              },
              {
                id: "6-0-4-3",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Total Employees",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-4-3'], "employees_B", "Total (A)");
                },
              },
            ],
          ],
        },
        {
          label:
            "Details of minimum wages paid to employees, in the following format:",
          th: [
            "Categories",
            "Total (A)",
            "No. (B)",
            "% (B/A))",
            "No. (C)",
            "% (C/A)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Employees",
                id: "6-0-5-0",
                colSpan: "7",
              },
            ],
            [
              {
                type: "static",
                value: "Permanent",
                id: "6-0-5-1",
              },
              {
                id: "6-0-5-1",
                type: "number",
                valKey: "Total (A)",
                key: "Permanent",
              },
              {
                id: "6-0-5-1",
                type: "number",
                valKey: "No. (B)",
                key: "Permanent",
              },
              {
                id: "6-0-5-1",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Permanent",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-1'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-1",
                type: "number",
                valKey: "No. (C)",
                key: "Permanent",
              },
              {
                id: "6-0-5-1",
                type: "calculated",
                valKey: "%(C/A)",
                key: "Permanent",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-1'], "No. (C)", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Male",
                id: "6-0-5-2",
              },
              {
                id: "6-0-5-2",
                type: "text",
                valKey: "Total (A)",
                key: "Male",
              },
              {
                id: "6-0-5-2",
                type: "text",
                valKey: "No. (B)",
                key: "Male",
              },
              {
                id: "6-0-5-2",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-2'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-2",
                type: "text",
                valKey: "No. (C)",
                key: "Male",
              },
              {
                id: "6-0-5-2",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-2'], "No. (C)", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "6-0-5-3",
              },
              {
                id: "6-0-5-3",
                type: "text",
                valKey: "Total (A)",
                key: "Female",
              },
              {
                id: "6-0-5-3",
                type: "text",
                valKey: "No. (B)",
                key: "Female",
              },
              {
                id: "6-0-5-3",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-3'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-3",
                type: "text",
                valKey: "No. (C)",
                key: "Female",
              },
              {
                id: "6-0-5-3",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-3'], "No. (C)", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Others",
                id: "6-0-5-4",
              },
              {
                id: "6-0-5-4",
                type: "text",
                valKey: "Total (A)",
                key: "Others",
              },
              {
                id: "6-0-5-4",
                type: "text",
                valKey: "No. (B)",
                key: "Others",
              },
              {
                id: "6-0-5-4",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Others",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-4'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-4",
                type: "text",
                valKey: "No. (C)",
                key: "Others",
              },
              {
                id: "6-0-5-4",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Others",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-4'], "No. (C)", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Other than permanent",
                id: "6-0-5-5",
                colSpan: "7",
              },
            ],
            [
              {
                type: "static",
                value: "Male",
                id: "6-0-5-6",
              },
              {
                id: "6-0-5-6",
                type: "text",
                valKey: "Total (A)",
                key: "Male",
              },
              {
                id: "6-0-5-6",
                type: "text",
                valKey: "No. (B)",
                key: "Male",
              },
              {
                id: "6-0-5-6",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-6'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-6",
                type: "text",
                valKey: "No. (C)",
                key: "Male",
              },
              {
                id: "6-0-5-6",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Male",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-6'], "No. (C)", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Female",
                id: "6-0-5-7",
              },
              {
                id: "6-0-5-7",
                type: "text",
                valKey: "Total (A)",
                key: "Female",
              },
              {
                id: "6-0-5-7",
                type: "text",
                valKey: "No. (B)",
                key: "Female",
              },
              {
                id: "6-0-5-7",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-7'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-7",
                type: "text",
                valKey: "No. (C)",
                key: "Female",
              },
              {
                id: "6-0-5-7",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Female",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-7'], "No. (C)", "Total (A)");
                },
              },
            ],
            [
              {
                type: "static",
                value: "Others",
                id: "6-0-5-8",
              },
              {
                id: "6-0-5-8",
                type: "text",
                valKey: "Total (A)",
                key: "Others",
              },
              {
                id: "6-0-5-8",
                type: "text",
                valKey: "No. (B)",
                key: "Others",
              },
              {
                id: "6-0-5-8",
                type: "calculated",
                valKey: "% (B/A)",
                key: "Others",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-8'], "No. (B)", "Total (A)");
                },
              },
              {
                id: "6-0-5-8",
                type: "text",
                valKey: "No. (C)",
                key: "Others",
              },
              {
                id: "6-0-5-8",
                type: "calculated",
                valKey: "% (C/A)",
                key: "Others",
                calculation: ({ data }) => {
                  return percentage(data, ['6-0-5-8'], "No. (C)", "Total (A)");
                },
              },
            ],
          ],
        },
        {
          label: "Details of remuneration/salary/wages",
          th: [
            "Categories",
            "Number",
            "Median remuneration/ salary/ wages of respective category",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Board of Directors (BoD)",
                id: "6-0-6-0",
              },
              {
                id: "6-0-6-0",
                type: "number",
                valKey: "Number",
                key: "Board of Directors (BoD)",
              },
              {
                id: "6-0-6-0",
                type: "number",
                valKey:
                  "value",
                key: "Board of Directors (BoD)",
              },
            ],
            [
              {
                type: "static",
                value: "Key Managerial Personnel",
                id: "6-0-6-1",
              },
              {
                id: "6-0-6-1",
                type: "number",
                valKey: "Number",
                key: "Key Managerial Personnel",
              },
              {
                id: "6-0-6-1",
                type: "number",
                valKey:
                  "value",
                key: "Key Managerial Personnel",
              },
            ],
            [
              {
                type: "static",
                value: "Employees other than BoD and KMP",
                id: "6-0-6-2",
              },
              {
                id: "6-0-6-2",
                type: "number",
                valKey: "Number",
                key: "Employees other than BoD and KMP",
              },
              {
                id: "6-0-6-2",
                type: "number",
                valKey:
                  "value",
                key: "Employees other than BoD and KMP",
              },
            ],
            [
              {
                type: "static",
                value: "Workers",
                id: "6-0-6-3",
              },
              {
                id: "6-0-6-3",
                type: "number",
                valKey: "Number",
                key: "Workers",
              },
              {
                id: "6-0-6-3",
                type: "number",
                valKey:
                  "value",
                key: "Workers",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases",
                id: "6-0-7-0",
              },
              {
                id: "6-0-7-0",
                type: "text",
                valKey: "value",
                key: "Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Do human rights requirements form part of your business agreements and contracts? (Yes/No)",
                id: "6-0-7-1",
              },
              {
                id: "6-0-7-1",
                type: "text",
                valKey: "value",
                key: "Do human rights requirements form part of your business agreements and contracts? (Yes/No)",
              },
            ],
          ],
        },
        {
          label: "Complaints filed under the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 in the following format:",
          th: [
            " ",
            "(Current Financial Year)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Total Complaints reported under Sexual Harassment on of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 (POSH)",
                id: "6-0-8-0",
              },
              {
                id: "6-0-8-0",
                type: "text",
                valKey: "value",
                key: "Total Complaints reported under Sexual Harassment on of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 (POSH)",
              },
            ],
            [
              {
                type: "static",
                value: "Complaints on POSH as a % of female employees",
                id: "6-0-8-1",
              },
              {
                id: "6-0-8-1",
                type: "text",
                valKey: "value",
                key: "Complaints on POSH as a % of female employees",
              },
            ],
            [
              {
                type: "static",
                value: "Complaints on POSH upheld",
                id: "6-0-8-2",
              },
              {
                id: "6-0-8-2",
                type: "text",
                valKey: "value",
                key: "Complaints on POSH upheld",
              },
            ],
          
          ],
        },
        {
          label: "Assessments for the year:",
          th: [
            " ",
            "% Of your plants and offices that were assessed (By entity or statutory authorities or third parties)",
          ],
          tr: [
            [
              {
                type: "static",
                value: "Child Labor",
                id: "6-0-9-0",
              },
              {
                id: "6-0-9-0",
                type: "text",
                valKey: "value",
                key: "Child Labor",
              },
            ],
            [
              {
                type: "static",
                value: "Forced/involuntary Labor",
                id: "6-0-9-1",
              },
              {
                id: "6-0-9-1",
                type: "text",
                valKey: "value",
                key: "Forced/involuntary Labor",
              },
            ],
            [
              {
                type: "static",
                value: "Sexual harassment",
                id: "6-0-9-2",
              },
              {
                id: "6-0-9-2",
                type: "text",
                valKey: "value",
                key: "Sexual harassment",
              },
            ],
            [
              {
                type: "static",
                value: "Discrimination at workplace",
                id: "6-0-9-3",
              },
              {
                id: "6-0-9-3",
                type: "text",
                valKey: "value",
                key: "Discrimination at workplace",
              },
            ],
            [
              {
                type: "static",
                value: "Wages",
                id: "6-0-9-4",
              },
              {
                id: "6-0-9-4",
                type: "text",
                valKey: "value",
                key: "Wages",
              },
            ],
            [
              {
                type: "static",
                value: "Others- please specify",
                id: "6-0-9-5",
              },
              {
                id: "6-0-9-5",
                type: "text",
                valKey: "value",
                key: "Others- please specify",
              },
            ],
          
          ],
        },
        {
          heading: "LEADERSHIP INDICATORS",
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints",
                id: "6-0-2-0",
              },
              {
                id: "6-0-2-0",
                type: "text",
                valKey: "value",
                key: "Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints",
              },
            ],
            [
              {
                type: "static",
                value:
                  " Details of the scope and coverage of any Human rights due-diligence conducted.",
                id: "6-0-2-1",
              },
              {
                id: "6-0-2-1",
                type: "text",
                valKey: "value",
                key: " Details of the scope and coverage of any Human rights due-diligence conducted.",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?",
                id: "6-0-2-2",
              },
              {
                id: "6-0-2-2",
                type: "text",
                valKey: "value",
                key: "Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?",
              },
            ],
          ],
        },
        {
          label: "Details on assessment of value chain partners",
          th: [
            "Sr",
            "Categories",
            "% of value chain partners (by value of business done with such partners) that were assessed",
          ],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "6-0-3-0",
              },
              {
                type: "static",
                value: "Sexual Harassment",
                id: "6-0-3-0",
              },
              {
                id: "6-0-3-0",
                type: "text",
                valKey:"value",
                key: "Sexual Harassment",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "6-0-3-1",
              },
              {
                type: "static",
                value: "Discrimination at workplace",
                id: "6-0-3-1",
              },
              {
                id: "6-0-3-1",
                type: "text",
                valKey:"value",
                key: "Discrimination at workplace",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "6-0-3-2",
              },
              {
                type: "static",
                value: "Child Labour",
                id: "6-0-3-2",
              },
              {
                id: "6-0-3-2",
                type: "text",
                valKey:"value",
                key: "Child Labour",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "6-0-3-3",
              },
              {
                type: "static",
                value: "Forced Labour/Involuntary Labour",
                id: "6-0-3-3",
              },
              {
                id: "6-0-3-3",
                type: "text",
                valKey:"value",
                key: "Forced Labour/Involuntary Labour",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "6-0-3-4",
              },
              {
                type: "static",
                value: "Wages",
                id: "6-0-3-4",
              },
              {
                id: "6-0-3-4",
                type: "text",
                valKey:"value",
                key: "Wages",
              },
            ],
            [
              {
                type: "static",
                value: "f",
                id: "6-0-3-5",
              },
              {
                type: "static",
                value: "Others – please specify",
                id: "6-0-3-5",
              },
              {
                id: "6-0-3-5",
                type: "text",
                valKey:"value",
                key: "Others – please specify",
              },
            ],
            [
              {
                type: "static",
                value: "g",
                id: "6-0-3-6",
              },
              {
                type: "static",
                value: "Any Corrective Action ?",
                id: "6-0-3-6",
              },
              {
                id: "6-0-3-6",
                type: "text",
                valKey:"value",
                key: "Any Corrective Action ?",
              },
            ],
          ],
        },
      ],
    },
  ],
  Principle6: [
    // water accordion
    {
      type: "accordion",
      label: "Water",
      tables: [
        {
          label: "Water withdrawal by source (GRI 303-3, BRSR-P6)",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-0-0-0",
              },
              {
                type: "static",
                value: "Surface water",
                id: "7-0-0-0",
              },
              {
                id: "7-0-0-0",
                type: "dropdown",
                valKey: "Unit",
                key: "Surface water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-0-0",
                type: "number",
                valKey: "Current Value",
                key: "Surface water",
              },
              {
                id: "7-0-0-0",
                type: "number",
                valKey: "Target Value",
                key: "Surface water",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-0-0-1",
              },
              {
                type: "static",
                value: "Groundwater",
                id: "7-0-0-1",
              },
              {
                id: "7-0-0-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Groundwater",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-0-1",
                type: "number",
                valKey: "Current Value",
                key: "Groundwater",
              },
              {
                id: "7-0-0-1",
                type: "number",
                valKey: "Target Value",
                key: "Groundwater",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-0-0-2",
              },
              {
                type: "static",
                value: "Third party water",
                id: "7-0-0-2",
              },
              {
                id: "7-0-0-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Third party water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-0-2",
                type: "number",
                valKey: "Current Value",
                key: "Third party water",
              },
              {
                id: "7-0-0-2",
                type: "number",
                valKey: "Target Value",
                key: "Third party water",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-0-0-3",
              },
              {
                type: "static",
                value: "Produced water",
                id: "7-0-0-3",
              },
              {
                id: "7-0-0-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Produced water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-0-3",
                type: "number",
                valKey: "Current Value",
                key: "Produced water",
              },
              {
                id: "7-0-0-3",
                type: "number",
                valKey: "Target Value",
                key: "Produced water",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "7-0-0-4",
              },
              {
                type: "static",
                value: "Seawater / desalinated water",
                id: "7-0-0-4",
              },
              {
                id: "7-0-0-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Seawater / desalinated water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-0-4",
                type: "number",
                valKey: "Current Value",
                key: "Seawater / desalinated water",
              },
              {
                id: "7-0-0-4",
                type: "number",
                valKey: "Target Value",
                key: "Seawater / desalinated water",
              },
            ],
            [
              {
                type: "static",
                value: "vi",
                id: "7-0-0-5",
              },
              {
                type: "static",
                value: "Others",
                id: "7-0-0-5",
              },
              {
                id: "7-0-0-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Others",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-0-5",
                type: "number",
                valKey: "Current Value",
                key: "Others",
              },
              {
                id: "7-0-0-5",
                type: "number",
                valKey: "Target Value",
                key: "Others",
              },
            ],
          ],
        },
        {
          label: "Total Water Discharge (GRI 303-4, BRSR-P6)",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "",
                id: "7-0-1-0",
              },
              {
                id: "7-0-1-0",
                type: "dropdown",
                valKey: "Value",
                key: "Treatment",
                options: ["Without Treatment", "With Treatment"],
              },
            ],
            [
              {
                type: "static",
                value: "i",
                id: "7-0-1-1",
              },
              {
                type: "static",
                value: "Surface water",
                id: "7-0-1-1",
              },
              {
                id: "7-0-1-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Surface water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-1-1",
                type: "number",
                valKey: "Current Value",
                key: "Surface water",
              },
              {
                id: "7-0-1-1",
                type: "number",
                valKey: "Target Value",
                key: "Surface water",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-0-1-2",
              },
              {
                type: "static",
                value: "Groundwater",
                id: "7-0-1-2",
              },
              {
                id: "7-0-1-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Groundwater",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-1-2",
                type: "number",
                valKey: "Current Value",
                key: "Groundwater",
              },
              {
                id: "7-0-1-2",
                type: "number",
                valKey: "Target Value",
                key: "Groundwater",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-0-1-3",
              },
              {
                type: "static",
                value: "Seawater / desalinated water",
                id: "7-0-1-3",
              },
              {
                id: "7-0-1-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Seawater / desalinated water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-1-3",
                type: "number",
                valKey: "Current Value",
                key: "Seawater / desalinated water",
              },
              {
                id: "7-0-1-3",
                type: "number",
                valKey: "Target Value",
                key: "Seawater / desalinated water",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-0-1-4",
              },
              {
                type: "static",
                value: "Third party water",
                id: "7-0-1-4",
              },
              {
                id: "7-0-1-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Third party water",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-1-4",
                type: "number",
                valKey: "Current Value",
                key: "Third party water",
              },
              {
                id: "7-0-1-4",
                type: "number",
                valKey: "Target Value",
                key: "Third party water",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "7-0-1-5",
              },
              {
                type: "static",
                value: "Others",
                id: "7-0-1-5",
              },
              {
                id: "7-0-1-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Others",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-1-5",
                type: "number",
                valKey: "Current Value",
                key: "Others",
              },
              {
                id: "7-0-1-5",
                type: "number",
                valKey: "Target Value",
                key: "Others",
              },
            ],
          ],
        },
        {
          label: "Total volume of water consumption (GRI 303-5, BRSR-P6)",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-0-2-0",
              },
              {
                type: "static",
                value: "Total Fresh Water Consumption : Plant",
                id: "7-0-2-0",
              },
              {
                id: "7-0-2-0",
                type: "dropdown",
                valKey: "Unit",
                key: "Total Fresh Water Consumption : Plant",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-2-0",
                type: "number",
                valKey: "Current Value",
                key: "Total Fresh Water Consumption : Plant",
              },
              {
                id: "7-0-2-0",
                type: "number",
                valKey: "Target Value",
                key: "Total Fresh Water Consumption : Plant",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-0-2-1",
              },
              {
                type: "static",
                value: "Recycled Water consumption:",
                id: "7-0-2-1",
              },
              {
                id: "7-0-2-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Recycled Water consumption:",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-2-1",
                type: "number",
                valKey: "Current Value",
                key: "Recycled Water consumption:",
              },
              {
                id: "7-0-2-1",
                type: "number",
                valKey: "Target Value",
                key: "Recycled Water consumption:",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-0-2-2",
              },
              {
                type: "static",
                value: "Quantum of Effluent Generated (ETP)",
                id: "7-0-2-2",
              },
              {
                id: "7-0-2-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Quantum of Effluent Generated (ETP)",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-2-2",
                type: "number",
                valKey: "Current Value",
                key: "Quantum of Effluent Generated (ETP)",
              },
              {
                id: "7-0-2-2",
                type: "number",
                valKey: "Target Value",
                key: "Quantum of Effluent Generated (ETP)",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-0-2-3",
              },
              {
                type: "static",
                value: "Quantum of Effluent Recycled (ETP)",
                id: "7-0-2-3",
              },
              {
                id: "7-0-2-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Quantum of Effluent Recycled (ETP)",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-2-3",
                type: "number",
                valKey: "Current Value",
                key: "Quantum of Effluent Recycled (ETP)",
              },
              {
                id: "7-0-2-3",
                type: "number",
                valKey: "Target Value",
                key: "Quantum of Effluent Recycled (ETP)",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "7-0-2-4",
              },
              {
                type: "static",
                value: "Others",
                id: "7-0-2-4",
              },
              {
                id: "7-0-2-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Others",
                options: ["KL", "ML", "M3"],
              },
              {
                id: "7-0-2-4",
                type: "number",
                valKey: "Current Value",
                key: "Others",
              },
              {
                id: "7-0-2-4",
                type: "number",
                valKey: "Target Value",
                key: "Others",
              },
            ],
          ],
        },
        {
          label: "Water intensity (BRSR-P6)",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-0-3-0",
              },
              {
                type: "static",
                value: "Revenue",
                id: "7-0-3-0",
              },
              {
                id: "7-0-3-0",
                type: "dropdown",
                valKey: "Unit",
                key: "Revenue",
                options: ["(in ₹ Mn)", "(in $ Mn)", "(in € Mn)"],
              },
              {
                id: "7-0-3-0",
                type: "number",
                valKey: "Current Value",
                key: "Revenue",
              },
              {
                id: "7-0-3-0",
                type: "number",
                valKey: "Target Value",
                key: "Revenue",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-0-3-1",
              },
              {
                type: "static",
                value: "( Total Water consumption / Revenue )",
                id: "7-0-3-1",
              },
              {
                id: "7-0-3-1",
                type: "dropdown",
                valKey: "Unit",
                key: "( Total Water consumption / Revenue )",
                options: [
                  "(KL/revenue in ₹ Mn)",
                  "(Kl/revenue in $ Mn)",
                  "(Kl/revenue in € Mn)",
                  "(ML/revenue in ₹ Mn)",
                  "(Ml/revenue in $ Mn)",
                  "(Ml/revenue in € Mn)",
                  "(M3/revenue in ₹ Mn)",
                  "(M3/revenue in $ Mn)",
                  "(M3/revenue in € Mn)",
                ],
              },
              {
                id: "7-0-3-1",
                type: "number",
                valKey: "Current Value",
                key: "( Total Water consumption / Revenue )",
              },
              {
                id: "7-0-3-1",
                type: "number",
                valKey: "Target Value",
                key: "( Total Water consumption / Revenue )",
              },
            ],
          ],
        },

      ],
    },
    //wate accordian
    {
      type: "accordion",
      label: "Waste",
      tables: [
        {
          label:
            "Type of Waste Generated [GRI 305-6, GRI 305-7,GRI 306-1,2, GRI 306-3, BRSR P8]",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-1-0-0",
              },
              {
                type: "static",
                value: "Hazardous",
                id: "7-1-0-0",
              },
              {
                id: "7-1-0-0",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous",
                options: ["MT"],
              },
              {
                id: "7-1-0-0",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous",
              },
              {
                id: "7-1-0-0",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-1-0-1",
              },
              {
                type: "static",
                value: "Non-hazardous",
                id: "7-1-0-1",
              },
              {
                id: "7-1-0-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-hazardous",
                options: ["MT"],
              },
              {
                id: "7-1-0-1",
                type: "number",
                valKey: "Current Value",
                key: "Non-hazardous",
              },
              {
                id: "7-1-0-1",
                type: "number",
                valKey: "Target Value",
                key: "Non-hazardous",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-1-0-2",
              },
              {
                type: "static",
                value: "Plastic waste",
                id: "7-1-0-2",
              },
              {
                id: "7-1-0-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Plastic waste",
                options: ["MT"],
              },
              {
                id: "7-1-0-2",
                type: "number",
                valKey: "Current Value",
                key: "Plastic waste",
              },
              {
                id: "7-1-0-2",
                type: "number",
                valKey: "Target Value",
                key: "Plastic waste",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-1-0-3",
              },
              {
                type: "static",
                value: "E-waste",
                id: "7-1-0-3",
              },
              {
                id: "7-1-0-3",
                type: "dropdown",
                valKey: "Unit",
                key: "E-waste",
                options: ["MT"],
              },
              {
                id: "7-1-0-3",
                type: "number",
                valKey: "Current Value",
                key: "E-waste",
              },
              {
                id: "7-1-0-3",
                type: "number",
                valKey: "Target Value",
                key: "E-waste",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "7-1-0-4",
              },
              {
                type: "static",
                value: "Bio-medical waste",
                id: "7-1-0-4",
              },
              {
                id: "7-1-0-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Bio-medical waste",
                options: ["MT"],
              },
              {
                id: "7-1-0-4",
                type: "number",
                valKey: "Current Value",
                key: "Bio-medical waste",
              },
              {
                id: "7-1-0-4",
                type: "number",
                valKey: "Target Value",
                key: "Bio-medical waste",
              },
            ],
            [
              {
                type: "static",
                value: "vi",
                id: "7-1-0-5",
              },
              {
                type: "static",
                value: "Construction and demolition waste",
                id: "7-1-0-5",
              },
              {
                id: "7-1-0-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Construction and demolition waste",
                options: ["MT"],
              },
              {
                id: "7-1-0-5",
                type: "number",
                valKey: "Current Value",
                key: "Construction and demolition waste",
              },
              {
                id: "7-1-0-5",
                type: "number",
                valKey: "Target Value",
                key: "Construction and demolition waste",
              },
            ],
            [
              {
                type: "static",
                value: "vii",
                id: "7-1-0-6",
              },
              {
                type: "static",
                value: "Battery waste",
                id: "7-1-0-6",
              },
              {
                id: "7-1-0-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Battery waste",
                options: ["MT"],
              },
              {
                id: "7-1-0-6",
                type: "number",
                valKey: "Current Value",
                key: "Battery waste",
              },
              {
                id: "7-1-0-6",
                type: "number",
                valKey: "Target Value",
                key: "Battery waste",
              },
            ],
            [
              {
                type: "static",
                value: "viii",
                id: "7-1-0-7",
              },
              {
                type: "static",
                value: "Radioactive waste",
                id: "7-1-0-7",
              },
              {
                id: "7-1-0-7",
                type: "dropdown",
                valKey: "Unit",
                key: "Radioactive waste",
                options: ["MT"],
              },
              {
                id: "7-1-0-7",
                type: "number",
                valKey: "Current Value",
                key: "Radioactive waste",
              },
              {
                id: "7-1-0-7",
                type: "number",
                valKey: "Target Value",
                key: "Radioactive waste",
              },
            ],
            [
              {
                type: "static",
                value: "ix",
                id: "7-1-0-8",
              },
              {
                type: "static",
                value: "Other",
                id: "7-1-0-8",
              },
              {
                id: "7-1-0-8",
                type: "dropdown",
                valKey: "Unit",
                key: "Other",
                options: ["MT"],
              },
              {
                id: "7-1-0-8",
                type: "number",
                valKey: "Current Value",
                key: "Other",
              },
              {
                id: "7-1-0-8",
                type: "number",
                valKey: "Target Value",
                key: "Other",
              },
            ],
          ],
        },
        {
          label: "Waste Diverted from Disposal [GRI 306-4,GRI 306-5, BRSR P8]",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-1-1-0",
              },
              {
                type: "static",
                value: "Hazardous Waste",
                colSpan: 2,
                id: "7-1-1-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-1",
              },
              {
                type: "static",
                value: "Hazardous waste - Reuse",
                id: "7-1-1-1",
              },
              {
                id: "7-1-1-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Reuse",
                options: ["MT"],
              },
              {
                id: "7-1-1-1",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Reuse",
              },
              {
                id: "7-1-1-1",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Reuse",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-2",
              },
              {
                type: "static",
                value: "Hazardous waste - Composting",
                id: "7-1-1-2",
              },
              {
                id: "7-1-1-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Composting",
                options: ["MT"],
              },
              {
                id: "7-1-1-2",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Composting",
              },
              {
                id: "7-1-1-2",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Composting",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-3",
              },
              {
                type: "static",
                value: "Hazardous waste - Recycling",
                id: "7-1-1-3",
              },
              {
                id: "7-1-1-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Recycling",
                options: ["MT"],
              },
              {
                id: "7-1-1-3",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Recycling",
              },
              {
                id: "7-1-1-3",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Recycling",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-4",
              },
              {
                type: "static",
                value: "Hazardous waste - other",
                id: "7-1-1-4",
              },
              {
                id: "7-1-1-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - other",
                options: ["MT"],
              },
              {
                id: "7-1-1-4",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - other",
              },
              {
                id: "7-1-1-4",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - other",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-1-1-5",
              },
              {
                type: "static",
                value: "Non - Hazardous Waste",
                colSpan: 2,
                id: "7-1-1-5",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-6",
              },
              {
                type: "static",
                value: "Non-hazardous - Reuse",
                id: "7-1-1-6",
              },
              {
                id: "7-1-1-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-hazardous - Reuse",
                options: ["MT"],
              },
              {
                id: "7-1-1-6",
                type: "number",
                valKey: "Current Value",
                key: "Non-hazardous - Reuse",
              },
              {
                id: "7-1-1-6",
                type: "number",
                valKey: "Target Value",
                key: "Non-hazardous - Reuse",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-7",
              },
              {
                type: "static",
                value: "Non-hazardous - Composting",
                id: "7-1-1-7",
              },
              {
                id: "7-1-1-7",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-hazardous - Composting",
                options: ["MT"],
              },
              {
                id: "7-1-1-7",
                type: "number",
                valKey: "Current Value",
                key: "Non-hazardous - Composting",
              },
              {
                id: "7-1-1-7",
                type: "number",
                valKey: "Target Value",
                key: "Non-hazardous - Composting",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-1-8",
              },
              {
                type: "static",
                value: "Non-hazardous - Recycling",
                id: "7-1-1-8",
              },
              {
                id: "7-1-1-8",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-hazardous - Recycling",
                options: ["MT"],
              },
              {
                id: "7-1-1-8",
                type: "number",
                valKey: "Current Value",
                key: "Non-hazardous - Recycling",
              },
              {
                id: "7-1-1-8",
                type: "number",
                valKey: "Target Value",
                key: "Non-hazardous - Recycling",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-2-9",
              },
              {
                type: "static",
                value: "Non-hazardous - other",
                id: "7-1-2-9",
              },
              {
                id: "7-1-2-9",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-hazardous - other",
                options: ["MT"],
              },
              {
                id: "7-1-2-9",
                type: "number",
                valKey: "Current Value",
                key: "Non-hazardous - other",
              },
              {
                id: "7-1-2-9",
                type: "number",
                valKey: "Target Value",
                key: "Non-hazardous - other",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-1-2-10",
              },
              {
                type: "static",
                value: "E-waste",
                colSpan: 2,
                id: "7-1-2-10",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-2-11",
              },
              {
                type: "static",
                value: "E-waste - Recycle",
                id: "7-1-2-11",
              },
              {
                id: "7-1-2-11",
                type: "dropdown",
                valKey: "Unit",
                key: "E-waste - Recycle",
                options: ["MT"],
              },
              {
                id: "7-1-2-11",
                type: "number",
                valKey: "Current Value",
                key: "E-waste - Recycle",
              },
              {
                id: "7-1-2-11",
                type: "number",
                valKey: "Target Value",
                key: "E-waste - Recycle",
              },
            ],
          ],
        },
        {
          label: "Waste directed to Disposal [GRI 306-4,GRI 306-5, BRSR P8]",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-1-3-0",
              },
              {
                type: "static",
                value: "Hazardous Waste",
                colSpan: 2,
                id: "7-1-3-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-1",
              },
              {
                type: "static",
                value: "Hazardous waste - Incineration With Energy recovery",
                id: "7-1-3-1",
              },
              {
                id: "7-1-3-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Incineration With Energy recovery",
                options: ["MT"],
              },
              {
                id: "7-1-3-1",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Incineration With Energy recovery",
              },
              {
                id: "7-1-3-1",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Incineration With Energy recovery",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-2",
              },
              {
                type: "static",
                value: "Hazardous waste - Incineration Without Recovery",
                id: "7-1-3-2",
              },
              {
                id: "7-1-3-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Incineration Without Recovery",
                options: ["MT"],
              },
              {
                id: "7-1-3-2",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Incineration Without Recovery",
              },
              {
                id: "7-1-3-2",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Incineration Without Recovery",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-3",
              },
              {
                type: "static",
                value: "Hazardous waste - Landfilling",
                id: "7-1-3-3",
              },
              {
                id: "7-1-3-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Landfilling",
                options: ["MT"],
              },
              {
                id: "7-1-3-3",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Landfilling",
              },
              {
                id: "7-1-3-3",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Landfilling",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-4",
              },
              {
                type: "static",
                value: "Hazardous waste - Co-processing",
                id: "7-1-3-4",
              },
              {
                id: "7-1-3-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Co-processing",
                options: ["MT"],
              },
              {
                id: "7-1-3-4",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Co-processing",
              },
              {
                id: "7-1-3-4",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Co-processing",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-5",
              },
              {
                type: "static",
                value: "Hazardous waste - Deep well injection",
                id: "7-1-3-5",
              },
              {
                id: "7-1-3-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Deep well injection",
                options: ["MT"],
              },
              {
                id: "7-1-3-5",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Deep well injection",
              },
              {
                id: "7-1-3-5",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Deep well injection",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-6",
              },
              {
                type: "static",
                value: "Hazardous waste - Other",
                id: "7-1-3-6",
              },
              {
                id: "7-1-3-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Hazardous waste - Other",
                options: ["MT"],
              },
              {
                id: "7-1-3-6",
                type: "number",
                valKey: "Current Value",
                key: "Hazardous waste - Other",
              },
              {
                id: "7-1-3-6",
                type: "number",
                valKey: "Target Value",
                key: "Hazardous waste - Other",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-1-3-7",
              },
              {
                type: "static",
                value: "Non - Hazardous",
                colSpan: 2,
                id: "7-1-3-7",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-8",
              },
              {
                type: "static",
                value: "Non-Hazardous - Incineration With Energy recovery",
                id: "7-1-3-8",
              },
              {
                id: "7-1-3-8",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-Hazardous - Incineration With Energy recovery",
                options: ["MT"],
              },
              {
                id: "7-1-3-8",
                type: "number",
                valKey: "Current Value",
                key: "Non-Hazardous - Incineration With Energy recovery",
              },
              {
                id: "7-1-3-8",
                type: "number",
                valKey: "Target Value",
                key: "Non-Hazardous - Incineration With Energy recovery",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-9",
              },
              {
                type: "static",
                value: "Non-Hazardous - Incineration Without Recovery",
                id: "7-1-3-9",
              },
              {
                id: "7-1-3-9",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-Hazardous - Incineration Without Recovery",
                options: ["MT"],
              },
              {
                id: "7-1-3-9",
                type: "number",
                valKey: "Current Value",
                key: "Non-Hazardous - Incineration Without Recovery",
              },
              {
                id: "7-1-3-9",
                type: "number",
                valKey: "Target Value",
                key: "Non-Hazardous - Incineration Without Recovery",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-10",
              },
              {
                type: "static",
                value: "Non-Hazardous - Landfilling",
                id: "7-1-3-10",
              },
              {
                id: "7-1-3-10",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-Hazardous - Landfilling",
                options: ["MT"],
              },
              {
                id: "7-1-3-10",
                type: "number",
                valKey: "Current Value",
                key: "Non-Hazardous - Landfilling",
              },
              {
                id: "7-1-3-10",
                type: "number",
                valKey: "Target Value",
                key: "Non-Hazardous - Landfilling",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-11",
              },
              {
                type: "static",
                value: "Non-Hazardous - Deep well injection",
                id: "7-1-3-11",
              },
              {
                id: "7-1-3-11",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-Hazardous - Deep well injection",
                options: ["MT"],
              },
              {
                id: "7-1-3-11",
                type: "number",
                valKey: "Current Value",
                key: "Non-Hazardous - Deep well injection",
              },
              {
                id: "7-1-3-11",
                type: "number",
                valKey: "Target Value",
                key: "Non-Hazardous - Deep well injection",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-1-3-12",
              },
              {
                type: "static",
                value: "Non-Hazardous - Other",
                id: "7-1-3-12",
              },
              {
                id: "7-1-3-12",
                type: "dropdown",
                valKey: "Unit",
                key: "Non-Hazardous - Other",
                options: ["MT"],
              },
              {
                id: "7-1-3-12",
                type: "number",
                valKey: "Current Value",
                key: "Non-Hazardous - Other",
              },
              {
                id: "7-1-3-12",
                type: "number",
                valKey: "Target Value",
                key: "Non-Hazardous - Other",
              },
            ],
          ],
        },
      ],
    },

    //energy accordian
    {
      type: "accordion",
      label: "Energy",
      tables: [
        {
          label: "Total energy consumption & Intensity (BRSR-P6)",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-3-0-0",
              },
              {
                type: "static",
                value: "Total energy from renewable sources",
                colSpan: 2,
                id: "7-3-0-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-0-1",
              },
              {
                type: "static",
                value: "Biomass",
                id: "7-3-0-1",
              },
              {
                id: "7-3-0-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Biomass",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-0-1",
                type: "number",
                valKey: "Current Value",
                key: "Biomass",
              },
              {
                id: "7-3-0-1",
                type: "number",
                valKey: "Target Value",
                key: "Biomass",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-0-2",
              },
              {
                type: "static",
                value: "Solar rooftop (including solar street light)",
                id: "7-3-0-2",
              },
              {
                id: "7-3-0-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Solar rooftop (including solar street light)",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-0-2",
                type: "number",
                valKey: "Current Value",
                key: "Solar rooftop (including solar street light)",
              },
              {
                id: "7-3-0-2",
                type: "number",
                valKey: "Target Value",
                key: "Solar rooftop (including solar street light)",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-0-3",
              },
              {
                type: "static",
                value: "Windmills",
                id: "7-3-0-3",
              },
              {
                id: "7-3-0-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Windmills",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-0-3",
                type: "number",
                valKey: "Current Value",
                key: "Windmills",
              },
              {
                id: "7-3-0-3",
                type: "number",
                valKey: "Target Value",
                key: "Windmills",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-0-4",
              },
              {
                type: "static",
                value: "Renewable sources - power purchased",
                id: "7-3-0-4",
              },
              {
                id: "7-3-0-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Renewable sources - power purchased",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-0-4",
                type: "number",
                valKey: "Current Value",
                key: "Renewable sources - power purchased",
              },
              {
                id: "7-3-0-4",
                type: "number",
                valKey: "Target Value",
                key: "Renewable sources - power purchased",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-0-5",
              },
              {
                type: "static",
                value: "Steam purchased",
                id: "7-3-0-5",
              },
              {
                id: "7-3-0-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Steam purchased",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-0-5",
                type: "number",
                valKey: "Current Value",
                key: "Steam purchased",
              },
              {
                id: "7-3-0-5",
                type: "number",
                valKey: "Target Value",
                key: "Steam purchased",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-0-6",
              },
              {
                type: "static",
                value: "Others",
                id: "7-3-0-6",
              },
              {
                id: "7-3-0-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Others",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-0-6",
                type: "number",
                valKey: "Current Value",
                key: "Others",
              },
              {
                id: "7-3-0-6",
                type: "number",
                valKey: "Target Value",
                key: "Others",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-3-1-0",
              },
              {
                type: "static",
                value: "Total energy from non- renewable sources",
                colSpan: 2,
                id: "7-3-1-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-2",
              },
              {
                type: "static",
                value: "High speed diesel (HSD)",
                id: "7-3-1-1",
              },
              {
                id: "7-3-1-1",
                type: "dropdown",
                valKey: "Unit",
                key: "High speed diesel (HSD)",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-1",
                type: "number",
                valKey: "Current Value",
                key: "High speed diesel (HSD)",
              },
              {
                id: "7-3-1-1",
                type: "number",
                valKey: "Target Value",
                key: "High speed diesel (HSD)",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-2",
              },
              {
                type: "static",
                value: "Low Sulphur Heavy Stock ( LSHS)",
                id: "7-3-1-2",
              },
              {
                id: "7-3-1-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Low Sulphur Heavy Stock ( LSHS)",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-2",
                type: "number",
                valKey: "Current Value",
                key: "Low Sulphur Heavy Stock ( LSHS)",
              },
              {
                id: "7-3-1-2",
                type: "number",
                valKey: "Target Value",
                key: "Low Sulphur Heavy Stock ( LSHS)",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-3",
              },
              {
                type: "static",
                value: "Petrol",
                id: "7-3-1-3",
              },
              {
                id: "7-3-1-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Petrol",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-3",
                type: "number",
                valKey: "Current Value",
                key: "Petrol",
              },
              {
                id: "7-3-1-3",
                type: "number",
                valKey: "Target Value",
                key: "Petrol",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-4",
              },
              {
                type: "static",
                value: "Compressed natural gas (CNG)",
                id: "7-3-1-4",
              },
              {
                id: "7-3-1-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Compressed natural gas (CNG)",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-4",
                type: "number",
                valKey: "Current Value",
                key: "Compressed natural gas (CNG)",
              },
              {
                id: "7-3-1-4",
                type: "number",
                valKey: "Target Value",
                key: "Compressed natural gas (CNG)",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-5",
              },
              {
                type: "static",
                value: "Liquefied petroleum gas (LPG)",
                id: "7-3-1-5",
              },
              {
                id: "7-3-1-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Liquefied petroleum gas (LPG)",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-5",
                type: "number",
                valKey: "Current Value",
                key: "Liquefied petroleum gas (LPG)",
              },
              {
                id: "7-3-1-5",
                type: "number",
                valKey: "Target Value",
                key: "Liquefied petroleum gas (LPG)",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-6",
              },
              {
                type: "static",
                value: "Light diesel oil (LDO)",
                id: "7-3-1-6",
              },
              {
                id: "7-3-1-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Light diesel oil (LDO)",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-6",
                type: "number",
                valKey: "Current Value",
                key: "Light diesel oil (LDO)",
              },
              {
                id: "7-3-1-6",
                type: "number",
                valKey: "Target Value",
                key: "Light diesel oil (LDO)",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-7",
              },
              {
                type: "static",
                value: "Coal",
                id: "7-3-1-7",
              },
              {
                id: "7-3-1-7",
                type: "dropdown",
                valKey: "Unit",
                key: "Coal",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-7",
                type: "number",
                valKey: "Current Value",
                key: "Coal",
              },
              {
                id: "7-3-1-7",
                type: "number",
                valKey: "Target Value",
                key: "Coal",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-8",
              },
              {
                type: "static",
                value: "Grid electricity",
                id: "7-3-1-8",
              },
              {
                id: "7-3-1-8",
                type: "dropdown",
                valKey: "Unit",
                key: "Grid electricity",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-8",
                type: "number",
                valKey: "Current Value",
                key: "Grid electricity",
              },
              {
                id: "7-3-1-8",
                type: "number",
                valKey: "Target Value",
                key: "Grid electricity",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-3-1-9",
              },
              {
                type: "static",
                value: "Others",
                id: "7-3-1-9",
              },
              {
                id: "7-3-1-9",
                type: "dropdown",
                valKey: "Unit",
                key: "Others",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-9",
                type: "number",
                valKey: "Current Value",
                key: "Others",
              },
              {
                id: "7-3-1-9",
                type: "number",
                valKey: "Target Value",
                key: "Others",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-3-1-10",
              },
              {
                type: "static",
                value: "Revenue",
                id: "7-3-1-10",
              },
              {
                id: "7-3-1-10",
                type: "dropdown",
                valKey: "Unit",
                key: "Revenue",
                options: ["(in ₹ Mn)", "(in $ Mn)", "(in € Mn)"],
              },
              {
                id: "7-3-1-10",
                type: "number",
                valKey: "Current Value",
                key: "Revenue",
              },
              {
                id: "7-3-1-10",
                type: "number",
                valKey: "Target Value",
                key: "Revenue",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-3-1-11",
              },
              {
                type: "static",
                value: "Total Energy Consumption",
                id: "7-3-1-11",
              },
              {
                id: "7-3-1-11",
                type: "dropdown",
                valKey: "Unit",
                key: "Total Energy Consumption",
                options: ["Kwh", "G", "Mj", "Gj"],
              },
              {
                id: "7-3-1-11",
                type: "number",
                valKey: "Current Value",
                key: "Total Energy Consumption",
              },
              {
                id: "7-3-1-11",
                type: "number",
                valKey: "Target Value",
                key: "Total Energy Consumption",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-3-1-12",
              },
              {
                type: "static",
                value: "Intensity of Total Energy Consumption/Revenue",
                id: "7-3-1-12",
              },
              {
                id: "7-3-1-12",
                type: "dropdown",
                valKey: "Unit",
                key: "Intensity of Total Energy Consumption/Revenue",
                options: [
                  "(Kwh/revenue in ₹ Mn)",
                  "(Kwh/revenue in $ Mn)",
                  "(Kwh/revenue in € Mn)",
                  "(G/revenue in ₹ Mn)",
                  "(G/revenue in $ Mn)",
                  "(G/revenue in € Mn)",
                  "(Gj/revenue in ₹ Mn)",
                  "(Gj/revenue in $ Mn)",
                  "(Gj/revenue in € Mn)",
                  "(Mj/revenue in ₹ Mn)",
                  "(Mj/revenue in $ Mn)",
                  "(Mj/revenue in € Mn)",
                ],
              },
              {
                id: "7-3-1-12",
                type: "number",
                valKey: "Current Value",
                key: "Intensity of Total Energy Consumption/Revenue",
              },
              {
                id: "7-3-1-12",
                type: "number",
                valKey: "Target Value",
                key: "Intensity of Total Energy Consumption/Revenue",
              },
            ],
          ],
        },
      ],
    },
    //emission accordian
    {
      type: "accordion",
      label: "Emission",
      tables: [
        {
          label:
            "Direct (Scope 1) GHG emissions GRI 302-4 and 305-5,GRI 305-1,305-4",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-4-0-0",
              },
              {
                type: "static",
                value: "Scope 1 Emission",
                colSpan: 2,
                id: "7-4-0-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-0-1",
              },
              {
                type: "static",
                value: "Stationary combustion",
                id: "7-4-0-1",
              },
              {
                id: "7-4-0-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Stationary combustion",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-0-1",
                type: "number",
                valKey: "Current Value",
                key: "Stationary combustion",
              },
              {
                id: "7-4-0-1",
                type: "number",
                valKey: "Target Value",
                key: "Stationary combustion",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-0-2",
              },
              {
                type: "static",
                value: "Mobile combustion",
                id: "7-4-0-2",
              },
              {
                id: "7-4-0-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Mobile combustion",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-0-2",
                type: "number",
                valKey: "Current Value",
                key: "Mobile combustion",
              },
              {
                id: "7-4-0-2",
                type: "number",
                valKey: "Target Value",
                key: "Mobile combustion",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-0-3",
              },
              {
                type: "static",
                value: "Fugitive emission from air conditioning",
                id: "7-4-0-3",
              },
              {
                id: "7-4-0-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Fugitive emission from air conditioning",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-0-3",
                type: "number",
                valKey: "Current Value",
                key: "Fugitive emission from air conditioning",
              },
              {
                id: "7-4-0-3",
                type: "number",
                valKey: "Target Value",
                key: "Fugitive emission from air conditioning",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-4-0-4",
              },
              {
                type: "static",
                value: "Revenue",
                id: "7-4-0-4",
              },
              {
                id: "7-4-0-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Revenue",
                options: ["(in ₹ Mn)", "(in $ Mn)", "(in € Mn)"],
              },
              {
                id: "7-4-0-4",
                type: "number",
                valKey: "Current Value",
                key: "Revenue",
              },
              {
                id: "7-4-0-4",
                type: "number",
                valKey: "Target Value",
                key: "Revenue",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-4-0-5",
              },
              {
                type: "static",
                value: "Scope-1 Emission Intensity/Revenue",
                id: "7-4-0-5",
              },
              {
                id: "7-4-0-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Scope-1 Emission Intensity/Revenue",
                options: [
                  "(MT/revenue in ₹ Mn)",
                  "(MT/revenue in $ Mn)",
                  "(MT/revenue in € Mn)",
                  "(t-CO2/revenue in ₹ Mn)",
                  "(t-CO2/revenue in $ Mn)",
                  "(t-CO2/revenue in € Mn)",
                ],
              },
              {
                id: "7-4-0-5",
                type: "number",
                valKey: "Current Value",
                key: "Scope-1 Emission Intensity/Revenue",
              },
              {
                id: "7-4-0-5",
                type: "number",
                valKey: "Target Value",
                key: "Scope-1 Emission Intensity/Revenue",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-4-0-6",
              },
              {
                type: "static",
                value:
                  "Biogenic emissions data (Optional not include in Scope-1)",
                id: "7-4-0-6",
              },
              {
                id: "7-4-0-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Biogenic emissions data (Optional not include in Scope-1)",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-0-6",
                type: "number",
                valKey: "Current Value",
                key: "Biogenic emissions data (Optional not include in Scope-1)",
              },
              {
                id: "7-4-0-6",
                type: "number",
                valKey: "Target Value",
                key: "Biogenic emissions data (Optional not include in Scope-1)",
              },
            ],
          ],
        },
        {
          label:
            "Energy indirect (Scope 2) GHG Emission GRI 305-2 and 305-4, 53GRI 305-3",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-4-1-0",
              },
              {
                type: "static",
                value: "Scope 2 Emission",
                colSpan: 2,
                id: "7-4-1-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-1-1",
              },
              {
                type: "static",
                value: "Purchased Electricity",
                id: "7-4-1-1",
              },
              {
                id: "7-4-1-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Purchased Electricity",
                options: ["MT", "Kwh", "t-CO2"],
              },
              {
                id: "7-4-1-1",
                type: "number",
                valKey: "Current Value",
                key: "Purchased Electricity",
              },
              {
                id: "7-4-1-1",
                type: "number",
                valKey: "Target Value",
                key: "Purchased Electricity",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-1-2",
              },
              {
                type: "static",
                value: "Purchased Steam",
                id: "7-4-1-2",
              },
              {
                id: "7-4-1-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Purchased Steam",
                options: ["MT", "Kwh", "t-CO2"],
              },
              {
                id: "7-4-1-2",
                type: "number",
                valKey: "Current Value",
                key: "Purchased Steam",
              },
              {
                id: "7-4-1-2",
                type: "number",
                valKey: "Target Value",
                key: "Purchased Steam",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-1-3",
              },
              {
                type: "static",
                value: "Purchased Heating & Cooling",
                id: "7-4-1-3",
              },
              {
                id: "7-4-1-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Purchased Heating & Cooling",
                options: ["MT", "Kwh", "t-CO2"],
              },
              {
                id: "7-4-1-3",
                type: "number",
                valKey: "Current Value",
                key: "Purchased Heating & Cooling",
              },
              {
                id: "7-4-1-3",
                type: "number",
                valKey: "Target Value",
                key: "Purchased Heating & Cooling",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-4",
              },
              {
                type: "static",
                value: "Others",
                id: "7-4-1-4",
              },
              {
                id: "7-4-1-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Others",
                options: ["MT", "Kwh", "t-CO2"],
              },
              {
                id: "7-4-1-4",
                type: "number",
                valKey: "Current Value",
                key: "Others",
              },
              {
                id: "7-4-1-4",
                type: "number",
                valKey: "Target Value",
                key: "Others",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-4-1-5",
              },
              {
                type: "static",
                value: "Scope-2 Emission Intensity/Revenue",
                id: "7-4-1-5",
              },
              {
                id: "7-4-1-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Scope-2 Emission Intensity/Revenue",
                options: [
                  "(MT/revenue in ₹ Mn)",
                  "(MT/revenue in $ Mn)",
                  "(MT/revenue in € Mn)",
                  "(t-CO2/revenue in ₹ Mn)",
                  "(t-CO2/revenue in $ Mn)",
                  "(t-CO2/revenue in € Mn)",
                  "(kwh/revenue in ₹ Mn)",
                  "(kwh/revenue in $ Mn)",
                  "(kwh/revenue in € Mn)",
                ],
              },
              {
                id: "7-4-1-5",
                type: "number",
                valKey: "Current Value",
                key: "Scope-2 Emission Intensity/Revenue",
              },
              {
                id: "7-4-1-5",
                type: "number",
                valKey: "Target Value",
                key: "Scope-2 Emission Intensity/Revenue",
              },
            ],
          ],
        },
        {
          label: "Other indirect (Scope 3) GHG emission GRI-305-3",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-4-2-0",
              },
              {
                type: "static",
                value: "Upstream",
                colSpan: 2,
                id: "7-4-2-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-1",
              },
              {
                type: "static",
                value: "Purchased goods and services",
                id: "7-4-2-1",
              },
              {
                id: "7-4-2-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Purchased goods and services",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-1",
                type: "number",
                valKey: "Current Value",
                key: "Purchased goods and services",
              },
              {
                id: "7-4-2-1",
                type: "number",
                valKey: "Target Value",
                key: "Purchased goods and services",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-2",
              },
              {
                type: "static",
                value: "Capital Goods",
                id: "7-4-2-2",
              },
              {
                id: "7-4-2-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Capital Goods",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-2",
                type: "number",
                valKey: "Current Value",
                key: "Capital Goods",
              },
              {
                id: "7-4-2-2",
                type: "number",
                valKey: "Target Value",
                key: "Capital Goods",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-3",
              },
              {
                type: "static",
                value:
                  "Fuel- and energy-related activities (not included in Scope 1 or Scope 2",
                id: "7-4-2-3",
              },
              {
                id: "7-4-2-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Fuel- and energy-related activities (not included in Scope 1 or Scope 2",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-3",
                type: "number",
                valKey: "Current Value",
                key: "Fuel- and energy-related activities (not included in Scope 1 or Scope 2",
              },
              {
                id: "7-4-2-3",
                type: "number",
                valKey: "Target Value",
                key: "Fuel- and energy-related activities (not included in Scope 1 or Scope 2",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-4",
              },
              {
                type: "static",
                value: "Employee commute",
                id: "7-4-2-4",
              },
              {
                id: "7-4-2-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Employee commute",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-4",
                type: "number",
                valKey: "Current Value",
                key: "Employee commute",
              },
              {
                id: "7-4-2-4",
                type: "number",
                valKey: "Target Value",
                key: "Employee commute",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-5",
              },
              {
                type: "static",
                value: "Business travel",
                id: "7-4-2-5",
              },
              {
                id: "7-4-2-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Business travel",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-5",
                type: "number",
                valKey: "Current Value",
                key: "Business travel",
              },
              {
                id: "7-4-2-5",
                type: "number",
                valKey: "Target Value",
                key: "Business travel",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-6",
              },
              {
                type: "static",
                value: "Upstream transportation & distribution",
                id: "7-4-2-6",
              },
              {
                id: "7-4-2-6",
                type: "dropdown",
                valKey: "Unit",
                key: "Upstream transportation & distribution",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-6",
                type: "number",
                valKey: "Current Value",
                key: "Upstream transportation & distribution",
              },
              {
                id: "7-4-2-6",
                type: "number",
                valKey: "Target Value",
                key: "Upstream transportation & distribution",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-7",
              },
              {
                type: "static",
                value: "Upstream leased assets",
                id: "7-4-2-7",
              },
              {
                id: "7-4-2-7",
                type: "dropdown",
                valKey: "Unit",
                key: "Upstream leased assets",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-7",
                type: "number",
                valKey: "Current Value",
                key: "Upstream leased assets",
              },
              {
                id: "7-4-2-7",
                type: "number",
                valKey: "Target Value",
                key: "Upstream leased assets",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-8",
              },
              {
                type: "static",
                value: "Waste generated in operations",
                id: "7-4-2-8",
              },
              {
                id: "7-4-2-8",
                type: "dropdown",
                valKey: "Unit",
                key: "Waste generated in operations",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-8",
                type: "number",
                valKey: "Current Value",
                key: "Waste generated in operations",
              },
              {
                id: "7-4-2-8",
                type: "number",
                valKey: "Target Value",
                key: "Waste generated in operations",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-4-2-9",
              },
              {
                type: "static",
                value: "DownStream",
                colSpan: 2,
                id: "7-4-2-9",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-10",
              },
              {
                type: "static",
                value: "Downstream transportation and distribution",
                id: "7-4-2-10",
              },
              {
                id: "7-4-2-10",
                type: "dropdown",
                valKey: "Unit",
                key: "Downstream transportation and distribution",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-10",
                type: "number",
                valKey: "Current Value",
                key: "Downstream transportation and distribution",
              },
              {
                id: "7-4-2-10",
                type: "number",
                valKey: "Target Value",
                key: "Downstream transportation and distribution",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-11",
              },
              {
                type: "static",
                value: "Processing of sold products",
                id: "7-4-2-11",
              },
              {
                id: "7-4-2-11",
                type: "dropdown",
                valKey: "Unit",
                key: "Processing of sold products",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-11",
                type: "number",
                valKey: "Current Value",
                key: "Processing of sold products",
              },
              {
                id: "7-4-2-11",
                type: "number",
                valKey: "Target Value",
                key: "Processing of sold products",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-12",
              },
              {
                type: "static",
                value: "Use of sold products",
                id: "7-4-2-12",
              },
              {
                id: "7-4-2-12",
                type: "dropdown",
                valKey: "Unit",
                key: "Use of sold products",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-12",
                type: "number",
                valKey: "Current Value",
                key: "Use of sold products",
              },
              {
                id: "7-4-2-12",
                type: "number",
                valKey: "Target Value",
                key: "Use of sold products",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-13",
              },
              {
                type: "static",
                value: "End-of-life treatment of sold products",
                id: "7-4-2-13",
              },
              {
                id: "7-4-2-13",
                type: "dropdown",
                valKey: "Unit",
                key: "End-of-life treatment of sold products",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-13",
                type: "number",
                valKey: "Current Value",
                key: "End-of-life treatment of sold products",
              },
              {
                id: "7-4-2-13",
                type: "number",
                valKey: "Target Value",
                key: "End-of-life treatment of sold products",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-14",
              },
              {
                type: "static",
                value: "Downstream leased assets",
                id: "7-4-2-14",
              },
              {
                id: "7-4-2-14",
                type: "dropdown",
                valKey: "Unit",
                key: "Downstream leased assets",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-14",
                type: "number",
                valKey: "Current Value",
                key: "Downstream leased assets",
              },
              {
                id: "7-4-2-14",
                type: "number",
                valKey: "Target Value",
                key: "Downstream leased assets",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-15",
              },
              {
                type: "static",
                value: "Franchises",
                id: "7-4-2-15",
              },
              {
                id: "7-4-2-15",
                type: "dropdown",
                valKey: "Unit",
                key: "Franchises",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-15",
                type: "number",
                valKey: "Current Value",
                key: "Franchises",
              },
              {
                id: "7-4-2-15",
                type: "number",
                valKey: "Target Value",
                key: "Franchises",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-4-2-16",
              },
              {
                type: "static",
                value: "Investments",
                id: "7-4-2-16",
              },
              {
                id: "7-4-2-16",
                type: "dropdown",
                valKey: "Unit",
                key: "Investments",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-2-16",
                type: "number",
                valKey: "Current Value",
                key: "Investments",
              },
              {
                id: "7-4-2-16",
                type: "number",
                valKey: "Target Value",
                key: "Investments",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-4-2-17",
              },
              {
                type: "static",
                value: "Scope-3 Emission Intensity/Revenue",
                id: "7-4-2-17",
              },
              {
                id: "7-4-2-17",
                type: "dropdown",
                valKey: "Unit",
                key: "Scope-3 Emission Intensity/Revenue",
                options: [
                  "(MT/revenue in ₹ Mn)",
                  "(MT/revenue in $ Mn)",
                  "(MT/revenue in € Mn)",
                  "(t-CO2/revenue in ₹ Mn)",
                  "(t-CO2/revenue in $ Mn)",
                  "(t-CO2/revenue in € Mn)",
                ],
              },
              {
                id: "7-4-2-17",
                type: "number",
                valKey: "Current Value",
                key: "Scope-3 Emission Intensity/Revenue",
              },
              {
                id: "7-4-2-17",
                type: "number",
                valKey: "Target Value",
                key: "Scope-3 Emission Intensity/Revenue",
              },
            ],
          ],
        },
        {
          label: "Total Emission GRI 305-4",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-4-3-0",
              },
              {
                type: "static",
                value: "Scope-1",
                id: "7-4-3-0",
              },
              {
                id: "7-4-3-0",
                type: "dropdown",
                valKey: "Unit",
                key: "Scope-1",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-3-0",
                type: "number",
                valKey: "Current Value",
                key: "Scope-1",
              },
              {
                id: "7-4-3-0",
                type: "number",
                valKey: "Target Value",
                key: "Scope-1",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-4-3-1",
              },
              {
                type: "static",
                value: "Scope-2",
                id: "7-4-3-1",
              },
              {
                id: "7-4-3-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Scope-2",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-3-1",
                type: "number",
                valKey: "Current Value",
                key: "Scope-2",
              },
              {
                id: "7-4-3-1",
                type: "number",
                valKey: "Target Value",
                key: "Scope-2",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-4-3-2",
              },
              {
                type: "static",
                value: "Scope-3",
                id: "7-4-3-2",
              },
              {
                id: "7-4-3-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Scope-3",
                options: ["MT", "t-CO2"],
              },
              {
                id: "7-4-3-2",
                type: "number",
                valKey: "Current Value",
                key: "Scope-3",
              },
              {
                id: "7-4-3-2",
                type: "number",
                valKey: "Target Value",
                key: "Scope-3",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-4-3-3",
              },
              {
                type: "static",
                value: "Gases included in the calculation",
                id: "7-4-3-3",
              },
              {
                id: "7-4-3-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Gases included in the calculation",
                options: [
                  "CO2",
                  "CH4",
                  "N2O",
                  "HFCs",
                  "PFCs",
                  "NF3",
                  "SF6",
                  "All",
                ],
              },
              {
                id: "7-4-3-3",
                type: "number",
                valKey: "Current Value",
                key: "Gases included in the calculation",
              },
              {
                id: "7-4-3-3",
                type: "number",
                valKey: "Target Value",
                key: "Gases included in the calculation",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "7-4-3-4",
              },
              {
                type: "static",
                value: "GHG Emission Intensity/Revenue",
                id: "7-4-3-4",
              },
              {
                id: "7-4-3-4",
                type: "dropdown",
                valKey: "Unit",
                key: "GHG Emission Intensity/Revenue",
                options: [
                  "(MT/revenue in ₹ Mn)",
                  "(MT/revenue in $ Mn)",
                  "(MT/revenue in € Mn)",
                  "(t-CO2/revenue in ₹ Mn)",
                  "(t-CO2/revenue in $ Mn)",
                  "(t-CO2/revenue in € Mn)",
                ],
              },
              {
                id: "7-4-3-4",
                type: "number",
                valKey: "Current Value",
                key: "GHG Emission Intensity/Revenue",
              },
              {
                id: "7-4-3-4",
                type: "number",
                valKey: "Target Value",
                key: "GHG Emission Intensity/Revenue",
              },
            ],
          ],
        },
        {
          label:
            " Nitrogen oxides (NOx), sulphur oxides (SOx), and other significant air emissions GRI 305-7",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-4-4-0",
              },
              {
                type: "static",
                value: "NOx",
                id: "7-4-4-0",
              },
              {
                id: "7-4-4-0",
                type: "dropdown",
                valKey: "Unit",
                key: "NOx",
                options: ["MT", "t-CO2", "Kwh", "Kg"],
              },
              {
                id: "7-4-4-0",
                type: "number",
                valKey: "Current Value",
                key: "NOx",
              },
              {
                id: "7-4-4-0",
                type: "number",
                valKey: "Target Value",
                key: "NOx",
              },
            ],
            [
              {
                type: "static",
                value: "ii",
                id: "7-4-4-1",
              },
              {
                type: "static",
                value: "SOx",
                id: "7-4-4-1",
              },
              {
                id: "7-4-4-1",
                type: "dropdown",
                valKey: "Unit",
                key: "SOx",
                options: ["MT", "t-CO2", "Kwh", "Kg"],
              },
              {
                id: "7-4-4-1",
                type: "number",
                valKey: "Current Value",
                key: "SOx",
              },
              {
                id: "7-4-4-1",
                type: "number",
                valKey: "Target Value",
                key: "SOx",
              },
            ],
            [
              {
                type: "static",
                value: "iii",
                id: "7-4-4-2",
              },
              {
                type: "static",
                value: "Particulate Matter",
                id: "7-4-4-2",
              },
              {
                id: "7-4-4-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Particulate Matter",
                options: ["MT", "t-CO2", "Kwh", "Kg"],
              },
              {
                id: "7-4-4-2",
                type: "number",
                valKey: "Current Value",
                key: "Particulate Matter",
              },
              {
                id: "7-4-4-2",
                type: "number",
                valKey: "Target Value",
                key: "Particulate Matter",
              },
            ],
            [
              {
                type: "static",
                value: "iv",
                id: "7-4-4-3",
              },
              {
                type: "static",
                value: "Volatile organic compounds (VOC)",
                id: "7-4-4-3",
              },
              {
                id: "7-4-4-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Volatile organic compounds (VOC)",
                options: ["MT", "t-CO2", "Kwh", "Kg"],
              },
              {
                id: "7-4-4-3",
                type: "number",
                valKey: "Current Value",
                key: "Volatile organic compounds (VOC)",
              },
              {
                id: "7-4-4-3",
                type: "number",
                valKey: "Target Value",
                key: "Volatile organic compounds (VOC)",
              },
            ],
            [
              {
                type: "static",
                value: "v",
                id: "7-4-4-4",
              },
              {
                type: "static",
                value: "Persistent organic pollutants (POP)",
                id: "7-4-4-4",
              },
              {
                id: "7-4-4-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Persistent organic pollutants (POP)",
                options: ["MT", "t-CO2", "Kwh", "Kg"],
              },
              {
                id: "7-4-4-4",
                type: "number",
                valKey: "Current Value",
                key: "Persistent organic pollutants (POP)",
              },
              {
                id: "7-4-4-4",
                type: "number",
                valKey: "Target Value",
                key: "Persistent organic pollutants (POP)",
              },
            ],
            [
              {
                type: "static",
                value: "vi",
                id: "7-4-4-5",
              },
              {
                type: "static",
                value:
                  "Other standard categories of air emissions identified in relevant regulations",
                id: "7-4-4-5",
              },
              {
                id: "7-4-4-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Other standard categories of air emissions identified in relevant regulations",
                options: ["MT", "t-CO2", "Kwh", "Kg"],
              },
              {
                id: "7-4-4-5",
                type: "number",
                valKey: "Current Value",
                key: "Other standard categories of air emissions identified in relevant regulations",
              },
              {
                id: "7-4-4-5",
                type: "number",
                valKey: "Target Value",
                key: "Other standard categories of air emissions identified in relevant regulations",
              },
            ],
          ],
        },
      ],
    },

    //biodiversity accordian
    {
      type: "accordion",
      label: "Biodiversity",
      tables: [
        {
          label:
            "4 IUCN Red List species and national conservation list species with habitats in areas affected by operations GRI 304-4",
          th: ["Sr", "Title", "Unit", "Current Value", "Target Value"],
          tr: [
            [
              {
                type: "static",
                value: "i",
                id: "7-5-0-0",
              },
              {
                type: "static",
                value:
                  "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk:",
                colSpan: 4,
                id: "7-5-0-0",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-5-0-1",
              },
              {
                type: "static",
                value: "Critically endangered",
                id: "7-5-0-1",
              },
              {
                id: "7-5-0-1",
                type: "dropdown",
                valKey: "Unit",
                key: "Critically endangered",
                options: ["Nos"],
              },
              {
                id: "7-5-0-1",
                type: "number",
                valKey: "Current Value",
                key: "Critically endangered",
              },
              {
                id: "7-5-0-1",
                type: "number",
                valKey: "Target Value",
                key: "Critically endangered",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-5-0-2",
              },
              {
                type: "static",
                value: "Endangered",
                id: "7-5-0-2",
              },
              {
                id: "7-5-0-2",
                type: "dropdown",
                valKey: "Unit",
                key: "Endangered",
                options: ["Nos"],
              },
              {
                id: "7-5-0-2",
                type: "number",
                valKey: "Current Value",
                key: "Endangered",
              },
              {
                id: "7-5-0-2",
                type: "number",
                valKey: "Target Value",
                key: "Endangered",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-5-0-3",
              },
              {
                type: "static",
                value: "Vulnerable",
                id: "7-5-0-3",
              },
              {
                id: "7-5-0-3",
                type: "dropdown",
                valKey: "Unit",
                key: "Vulnerable",
                options: ["Nos"],
              },
              {
                id: "7-5-0-3",
                type: "number",
                valKey: "Current Value",
                key: "Vulnerable",
              },
              {
                id: "7-5-0-3",
                type: "number",
                valKey: "Target Value",
                key: "Vulnerable",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-5-0-4",
              },
              {
                type: "static",
                value: "Near threatened",
                id: "7-5-0-4",
              },
              {
                id: "7-5-0-4",
                type: "dropdown",
                valKey: "Unit",
                key: "Near threatened",
                options: ["Nos"],
              },
              {
                id: "7-5-0-4",
                type: "number",
                valKey: "Current Value",
                key: "Near threatened",
              },
              {
                id: "7-5-0-4",
                type: "number",
                valKey: "Target Value",
                key: "Near threatened",
              },
            ],
            [
              {
                type: "static",
                value: "*",
                id: "7-5-0-5",
              },
              {
                type: "static",
                value: "Least concern",
                id: "7-5-0-5",
              },
              {
                id: "7-5-0-5",
                type: "dropdown",
                valKey: "Unit",
                key: "Least concern",
                options: ["Nos"],
              },
              {
                id: "7-5-0-5",
                type: "number",
                valKey: "Current Value",
                key: "Least concern",
              },
              {
                id: "7-5-0-5",
                type: "number",
                valKey: "Target Value",
                key: "Least concern",
              },
            ],
          ],
        },
      ],
    },

    //Basic Parameter accordian
    {
      type: "accordion",
      label: "Basic Parameter",
      tables: [
        {
          label: "Environment Basic Parameter BRSR",
          th: ["Sr", "Title", "Description"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "7-6-0-0",
              },
              {
                type: "static",
                value:
                  "Does the entity have any sites / facilities identified as designated consumers (DCs) under the Performance, Achieve and Trade (PAT) Scheme of the Government of India? (Y/N) If yes, disclose whether targets set under the PAT scheme have been achieved. In case targets have not been achieved, provide the remedial action taken, if any.",
                id: "7-6-0-0",
              },
              {
                id: "7-6-0-0",
                type: "textarea",
                valKey: "Description",
                key: "Does the entity have any sites / facilities identified as designated consumers (DCs) under the Performance, Achieve and Trade (PAT) Scheme of the Government of India? (Y/N) If yes, disclose whether targets set under the PAT scheme have been achieved. In case targets have not been achieved, provide the remedial action taken, if any.",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "7-6-0-3",
              },
              {
                type: "static",
                value:
                  "Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its coverage and implementation.",
                id: "7-6-0-1",
              },
              {
                id: "7-6-0-1",
                type: "textarea",
                valKey: "Description",
                key: "Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its coverage and implementation.",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "7-6-0-2",
              },
              {
                type: "static",
                value:
                  "Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.",
                id: "7-6-0-2",
              },
              {
                id: "7-6-0-2",
                type: "textarea",
                valKey: "Description",
                key: "Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "7-6-0-3",
              },
              {
                type: "static",
                value:
                  "Disclose any significant adverse impact to the environment, arising from the value chain of the entity. What mitigation or adaptation measures have been taken by the entity in this regard?",
                id: "7-6-0-3",
              },
              {
                id: "7-6-0-3",
                type: "textarea",
                valKey: "Description",
                key: "Disclose any significant adverse impact to the environment, arising from the value chain of the entity. What mitigation or adaptation measures have been taken by the entity in this regard?",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "7-6-0-4",
              },
              {
                type: "static",
                value:
                  "Percentage of value chain partners (by value of business done with such partners) that were assessed for environmental impacts.",
                id: "7-6-0-4",
              },
              {
                id: "7-6-0-4",
                type: "textarea",
                valKey: "Description",
                key: "Percentage of value chain partners (by value of business done with such partners) that were assessed for environmental impacts.",
              },
            ],
           
          ],
        },
        {
          label:"",
          heading: "ESSENTIAL INDICATORS",
          th:[],
          tr:[
            [
              {
                type: "static",
                value:
                  "Does the entity have any project related to reducing Green House Gas emission? If Yes, then provide details.",
                id: "7-6-0-5",
              },
              {
                id: "7-6-0-5",
                type: "textarea",
                valKey: "Description",
                key: "Does the entity have any project related to reducing Green House Gas emission? If Yes, then provide details.",
              },
            ],
          ]

        },
        {
          label:"",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:"Briefly describe the waste management practices adopted in your establishments. Describe the strategy adopted by your company to reduce usage of hazardous and toxic chemicals in your products and processes and the practices adopted to manage such wastes.",
                id: "7-7-0-0",
              },
              {
                id: "7-7-0-0",
                type: "textarea",
                valKey: "Description",
                key:"Briefly describe the waste management practices adopted in your establishments. Describe the strategy adopted by your company to reduce usage of hazardous and toxic chemicals in your products and processes and the practices adopted to manage such wastes.",
              },
            ],
          ]
        },
        {
          label:"If the entity has operations/offices in/around ecologically sensitive areas (such as national parks, wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation zones etc.) where environmental approvals/clearances are required,please specify details in the following format:",
          th: ["Sr.No","Location of operations/offices","Type of operations","Whether the conditions of environmental approval/clearance are being complied with? (Y/N) If no, the reasons thereof and corrective action taken,if any"],
          tr: [
            [
              {
                type: "static",
                value:"I",
                id: "7-8-0-0",
              },
              {
                id: "7-8-0-0",
                type: "text",
                valKey: "value1",
                key:"I",
              },
              {
                id: "7-8-0-0",
                type: "text",
                valKey: "value2",
                key:"I",
              },
              {
                id: "7-8-0-0",
                type: "text",
                valKey: "value3",
                key:"I",
              },
            ],
            [
              {
                type: "static",
                value:"II",
                id: "7-8-0-1",
              },
              {
                id: "7-8-0-1",
                type: "text",
                valKey: "value1",
                key:"II",
              },
              {
                id: "7-8-0-1",
                type: "text",
                valKey: "value2",
                key:"II",
              },
              {
                id: "7-8-0-1",
                type: "text",
                valKey: "value3",
                key:"II",
              },
            ],
            [
              {
                type: "static",
                value:"III",
                id: "7-8-0-2",
              },
              {
                id: "7-8-0-2",
                type: "text",
                valKey: "value1",
                key:"III",
              },
              {
                id: "7-8-0-2",
                type: "text",
                valKey: "value2",
                key:"III",
              },
              {
                id: "7-8-0-2",
                type: "text",
                valKey: "value3",
                key:"III",
              },
            ],
          ]
        },
        {
          label:"Details of Environmental Impact Assessments of projects undertaken by the entity based on applicable laws, in the current financial year:",
          th: ["Sr.No","Name and brief details of project","EIA Notification No","Date","Whether conductedby independentexternal agency(Yes/No)" ,"Results communicatedin public domain(Yes/No)","Relevant Web link"],
          tr: [
            [
              {
                type: "static",
                value:"I",
                id: "7-9-0-0",
              },
              {
                id: "7-9-0-0",
                type: "text",
                valKey: "value1",
                key:"I",
              },
              {
                id: "7-9-0-0",
                type: "text",
                valKey: "value2",
                key:"I",
              },
              {
                id: "7-9-0-0",
                type: "text",
                valKey: "value3",
                key:"I",
              },
              {
                id: "7-9-0-0",
                type: "text",
                valKey: "value4",
                key:"I",
              },
              {
                id: "7-9-0-0",
                type: "text",
                valKey: "value5",
                key:"I",
              },
              {
                id: "7-9-0-0",
                type: "text",
                valKey: "value6",
                key:"I",
              },
            ],
            [
              {
                type: "static",
                value:"II",
                id: "7-9-0-1",
              },
              {
                id: "7-9-0-1",
                type: "text",
                valKey: "value1",
                key:"II",
              },
              {
                id: "7-9-0-1",
                type: "text",
                valKey: "value2",
                key:"II",
              },
              {
                id: "7-9-0-1",
                type: "text",
                valKey: "value3",
                key:"II",
              },
              {
                id: "7-9-0-1",
                type: "text",
                valKey: "value4",
                key:"II",
              },
              {
                id: "7-9-0-1",
                type: "text",
                valKey: "value5",
                key:"II",
              },
              {
                id: "7-9-0-1",
                type: "text",
                valKey: "value6",
                key:"II",
              },
            ],
            [
              {
                type: "static",
                value:"III",
                id: "7-9-0-2",
              },
              {
                id: "7-9-0-2",
                type: "text",
                valKey: "value1",
                key:"III",
              },
              {
                id: "7-9-0-2",
                type: "text",
                valKey: "value2",
                key:"III",
              },
              {
                id: "7-9-0-2",
                type: "text",
                valKey: "value3",
                key:"III",
              },
              {
                id: "7-9-0-2",
                type: "text",
                valKey: "value4",
                key:"III",
              },
              {
                id: "7-9-0-2",
                type: "text",
                valKey: "value5",
                key:"III",
              },
              {
                id: "7-9-0-2",
                type: "text",
                valKey: "value6",
                key:"III",
              },
            ],
          ]
        },
        {
          label:"",
          heading: "LEADERSHIP INDICATORS",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:"With respect to the ecologically sensitive areas reported at Question 11 of Essential Indicators above, provide details of significant direct & indirect impact of the entity on biodiversity in such areas along-with prevention and remediation activities",
                id: "7-10-0-0",
              },
              {
                id: "7-10-0-0",
                type: "textarea",
                valKey: "Description",
                key:"With respect to the ecologically sensitive areas reported at Question 11 of Essential Indicators above, provide details of significant direct & indirect impact of the entity on biodiversity in such areas along-with prevention and remediation activities",
              },
            ],
          ]
        },
        {
          label:"If the entity has undertaken any specific initiatives or used innovative technology or solutions to improve resource efficiency, or reduce impact due to emissions/effluent discharge/waste generated, please provide details of the same as well as outcome of such initiatives, as per the following format:",
          th: ["Sr.No","Initiative undertaken","Details of the initiative (Web-link, if any, may be provided along-with summary","Outcome of the initiative"],
          tr: [
            [
              {
                type: "static",
                value:"1",
                id: "7-11-0-0",
              },
              {
                type: "static",
                value:"Energy Conservation programs",
                id: "7-11-0-0",
              },
              {
                id: "7-11-0-0",
                type: "textarea",
                valKey: "value1",
                key:"Energy Conservation programs",
              },
              {
                id: "7-11-0-0",
                type: "text",
                valKey: "value2",
                key:"Energy Conservation programs",
              },
            ],
            [
              {
                type: "static",
                value:"2",
                id: "7-11-0-1",
              },
              {
                type: "static",
                value:"Water Conservation programs",
                id: "7-11-0-1",
              },
              {
                id: "7-11-0-1",
                type: "textarea",
                valKey: "value1",
                key:"Water Conservation programs",
              },
              {
                id: "7-11-0-1",
                type: "text",
                valKey: "value2",
                key:"Water Conservation programs",
              },
            ],
            [
              {
                type: "static",
                value:"3",
                id: "7-11-0-2",
              },
              {
                type: "static",
                value:"Waste Management",
                id: "7-11-0-2",
              },
              {
                id: "7-11-0-2",
                type: "textarea",
                valKey: "value1",
                key:"Waste Management",
              },
              {
                id: "7-11-0-2",
                type: "text",
                valKey: "value2",
                key:"Waste Management",
              },
            ],
          ]
        },

      ],
    },
  ],
  Principle7: [
    {
      type: "accordion",
      label:
        "Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent",
      tables: [
        {
          heading: "ESSENTIAL INDICATORS",
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Number of affiliations with trade and industry chambers/ associations.",
                id: "8-0-0-0",
              },
              {
                type: "text",
                valKey: "value",
                key: "Number of affiliations with trade and industry chambers/ associations.",
                id: "8-0-0-0",
              },
            ],
          ],
        },
        {
          label:
            "List the top 10 trade and industry chambers/ associations (determined based on the total members of such body) the entity is a member of/ affiliated to.",
          th: ["Sr", "Title", "A", "B", "C", "D", "E", "F", "G"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "8-0-1-0",
              },
              {
                type: "static",
                value: "Name of the trade and industry chambers/ associations",
                id: "8-0-1-0",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "A",
                key: "Name of the trade and industry chambers/ associations",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "B",
                key: "Name of the trade and industry chambers/ associations",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "C",
                key: "Name of the trade and industry chambers/ associations",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "D",
                key: "Name of the trade and industry chambers/ associations",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "E",
                key: "Name of the trade and industry chambers/ associations",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "F",
                key: "Name of the trade and industry chambers/ associations",
              },
              {
                id: "8-0-1-0",
                type: "text",
                valKey: "G",
                key: "Name of the trade and industry chambers/ associations",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "8-0-1-1",
              },
              {
                type: "static",
                value:
                  "Reach of trade and industry chambers/ associations ( state & National)",
                id: "8-0-1-1",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "A",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "B",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "C",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "D",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "E",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "F",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
              {
                id: "8-0-1-1",
                type: "text",
                valKey: "G",
                key: "Reach of trade and industry chambers/ associations ( state & National)",
              },
            ],
          ],
        },
        {
          label:
            "Provide details of corrective action taken or underway on any issues related to anti- competitive conduct by the entity, based on adverse orders from regulatory authorities.",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "8-0-2-0",
              },
              {
                type: "static",
                value: "Name of authority",
                id: "8-0-2-0",
              },
              {
                type: "text",
                id: "8-0-2-0",
                key: "Name of authority",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "8-0-2-1",
              },
              {
                type: "static",
                value: "Brief of the case",
                id: "8-0-2-1",
              },
              {
                type: "text",
                id: "8-0-2-1",
                key: "Brief of the case",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "8-0-2-2",
              },
              {
                type: "static",
                value: "Corrective action taken",
                id: "8-0-2-2",
              },
              {
                type: "text",
                id: "8-0-2-2",
                key: "Corrective action taken",
                valKey: "value",
              },
            ],
          ],
        },
        {
          heading: "LEADERSHIP INDICATORS",
          label: "Details of public policy positions advocated by the entity.",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "8-0-3-0",
              },
              {
                type: "static",
                value: "Public policy advocated",
                id: "8-0-3-0",
              },
              {
                type: "text",
                id: "8-0-3-0",
                key: "Public policy advocated",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "8-0-3-1",
              },
              {
                type: "static",
                value: "Method resorted for such advocacy",
                id: "8-0-3-1",
              },
              {
                type: "text",
                id: "8-0-3-1",
                key: "Method resorted for such advocacy",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "8-0-3-2",
              },
              {
                type: "static",
                value:
                  "Whether information available in public domain? (Yes/No)",
                id: "8-0-3-2",
              },
              {
                type: "text",
                id: "8-0-3-2",
                key: "Whether information available in public domain? (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "8-0-3-3",
              },
              {
                type: "static",
                value:
                  "Frequency of Review by Board (Annually/ Half yearly/ Quarterly / Others – please specify)",
                id: "8-0-3-3",
              },
              {
                type: "text",
                id: "8-0-3-3",
                key: "Frequency of Review by Board (Annually/ Half yearly/ Quarterly / Others – please specify)",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "8-0-3-4",
              },
              {
                type: "static",
                value: "Web Link, if available",
                id: "8-0-3-4",
              },
              {
                type: "text",
                id: "8-0-3-4",
                key: "Web Link, if available",
                valKey: "value",
              },
            ],
          ],
        },
      ],
    },
  ],
  Principle8: [
    {
      type: "accordion",
      label:
        "Businesses should promote inclusive growth and equitable development",
      tables: [
        {
          heading: "ESSENTIAL INDICATORS",
          label:
            "Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the current financial year.",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "9-0-0-0",
              },
              {
                type: "static",
                value: "Name and brief details of project",
                id: "9-0-0-0",
              },
              {
                id: "9-0-0-0",
                type: "text",
                key: "Name and brief details of project",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "9-0-0-1",
              },
              {
                type: "static",
                value: "SIA Notification No.",
                id: "9-0-0-1",
              },
              {
                type: "text",
                id: "9-0-0-1",
                key: "SIA Notification No.",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "9-0-0-2",
              },
              {
                type: "static",
                value: "Date of notification",
                id: "9-0-0-2",
              },
              {
                type: "text",
                id: "9-0-0-2",
                key: "Date of notification",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "9-0-0-3",
              },
              {
                type: "static",
                value:
                  "Whether conducted by independent external agency (Yes / No)",
                id: "9-0-0-3",
              },
              {
                id: "9-0-0-3",
                type: "text",
                key: "Whether conducted by independent external agency (Yes / No)",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "9-0-0-4",
              },
              {
                type: "static",
                value: "Results communicated in public domain (Yes / No)",
                id: "9-0-0-4",
              },
              {
                id: "9-0-0-4",
                type: "text",
                key: "Results communicated in public domain (Yes / No)",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "f",
                id: "9-0-0-5",
              },
              {
                type: "static",
                value: "Relevant Web link",
                id: "9-0-0-5",
              },
              {
                type: "text",
                id: "9-0-0-5",
                key: "Relevant Web link",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label:
            "Percentage of input material (inputs to total inputs by value) sourced from suppliers",
          th: ["Sr", "Title", "Current Financial Year"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "9-0-1-0",
              },
              {
                type: "static",
                value: "Directly sourced from MSMEs/ small producers",
                id: "9-0-1-0",
              },
              {
                id: "9-0-1-0",
                type: "text",
                key: "Directly sourced from MSMEs/ small producers",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "9-0-1-1",
              },
              {
                type: "static",
                value:
                  "Sourced directly from within the district and neighbouring districts",
                id: "9-0-1-1",
              },
              {
                id: "9-0-1-1",
                type: "text",
                key: "Sourced directly from within the district and neighbouring districts",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "9-0-1-2",
              },
              {
                type: "static",
                value: "MSMEs/small producers ",
                id: "9-0-1-2",
              },
              {
                id: "9-0-1-2",
                type: "text",
                key: "MSMEs/small producers ",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "9-0-1-3",
              },
              {
                type: "static",
                value:
                  "Directly from within India - MSME - Non MSME Sourced outside India",
                id: "9-0-1-3",
              },
              {
                id: "9-0-1-3",
                type: "text",
                key: "Directly from within India - MSME - Non MSME Sourced outside India",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Describe the mechanisms to receive and redress grievances of the community",
                id: "9-1-0-0",
              },
              {
                id: "9-1-0-0",
                type: "text",
                valKey: "value",
                key: "Describe the mechanisms to receive and redress grievances of the community",
              },
            ],
          ],
        },
        {
          label: "Job creation in smaller towns – Disclose wages paid to persons employed (including employees employed on a permanent or non-permanent/on contract basis) in the following locations, as % of total wage cost.",
          th: ["Location", "(Current Financial Year)"],
          tr: [
            [
              {
                type: "static",
                value: "Rural",
                id: "9-5-0-0",
              },
              {
                type: "text",
                id: "9-5-0-0",
                key: "Rural",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "Semi-urban",
                id: "9-5-0-1",
              },
              {
                type: "text",
                id: "9-5-0-1",
                key: "Semi-urban",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "Urban",
                id: "9-5-0-2",
              },
              {
                type: "text",
                id: "9-5-0-2",
                key: "Urban",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "Metropolitan",
                id: "9-5-0-3",
              },
              {
                type: "text",
                id: "9-5-0-3",
                key: "Metropolitan",
                valKey: "value",
              },
            ],
            
          ],
        },

        {
          heading: "LEADERSHIP INDICATORS",
          label:
            "Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "9-0-2-0",
              },
              {
                type: "static",
                value: "Intellectual Property based on traditional knowledge",
                id: "9-0-2-0",
              },
              {
                id: "9-0-2-0",
                type: "text",
                key: "Intellectual Property based on traditional knowledge",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "9-0-2-1",
              },
              {
                type: "static",
                value: "Owned/ Acquired (Yes/No)",
                id: "9-0-2-1",
              },
              {
                id: "9-0-2-1",
                type: "text",
                key: "Owned/ Acquired (Yes/No)",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "9-0-2-2",
              },
              {
                type: "static",
                value: "Benefit shared (Yes / No)",
                id: "9-0-2-2",
              },
              {
                id: "9-0-2-2",
                type: "text",
                key: "Benefit shared (Yes / No)",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "9-0-2-3",
              },
              {
                type: "static",
                value: "Basis of calculating benefit share",
                id: "9-0-2-3",
              },
              {
                id: "9-0-2-3",
                type: "text",
                key: "Basis of calculating benefit share",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label:
            "Details of corrective actions taken or underway, based on any adverse order in intellectual property related disputes wherein usage of traditional knowledge is involved",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "9-0-3-0",
              },
              {
                type: "static",
                value: "Name of authority",
                id: "9-0-3-0",
              },
              {
                type: "text",
                id: "9-0-3-0",
                key: "Name of authority",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "9-0-3-1",
              },
              {
                type: "static",
                value: "Brief of the Case",
                id: "9-0-3-1",
              },
              {
                type: "text",
                id: "9-0-3-1",
                key: "Brief of the Case",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "9-0-3-2",
              },
              {
                type: "static",
                value: "Corrective action taken",
                id: "9-0-3-2",
              },
              {
                type: "text",
                id: "9-0-3-2",
                key: "Corrective action taken",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "Details of beneficiaries of CSR Projects:",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "9-0-5-0",
              },
              {
                type: "static",
                value: "CSR Project",
                id: "9-0-5-0",
              },
              {
                type: "text",
                id: "9-0-5-0",
                key: "CSR Project",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "9-0-5-1",
              },
              {
                type: "static",
                value: "No. Of persons benefitted From CSR Projects",
                id: "9-0-5-1",
              },
              {
                type: "text",
                id: "9-0-5-1",
                key: "No. Of persons benefitted From CSR Projects",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "9-0-5-2",
              },
              {
                type: "static",
                value:
                  "% Of Beneficiaries from Vulnerable and Marginalized Groups",
                id: "9-0-5-2",
              },
              {
                type: "text",
                id: "9-0-5-2",
                key: "% Of Beneficiaries from Vulnerable and Marginalized Groups",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value: "A",
                id: "9-2-0-0",
              },
              {
                type: "static",
                value:
                  "Do you have a preferential procurement policy where you give preference to purchase from suppliers comprising marginalized/vulnerable groups?(Yes/No)",
                id: "9-2-0-0",
              },
              {
                id: "9-2-0-0",
                type: "text",
                valKey: "value",
                key: "Do you have a preferential procurement policy where you give preference to purchase from suppliers comprising marginalized/vulnerable groups?(Yes/No)",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value: "B",
                id: "9-3-0-0",
              },
              {
                type: "static",
                value:
                  "From which marginalized/vulnerable groups do you procure?",
                id: "9-3-0-0",
              },
              {
                id: "9-3-0-0",
                type: "text",
                valKey: "value",
                key: "From which marginalized/vulnerable groups do you procure?",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value: "C",
                id: "9-4-0-0",
              },
              {
                type: "static",
                value:
                  "What percentage of total procurement (by value) does it constitute?",
                id: "9-4-0-0",
              },
              {
                id: "9-4-0-0",
                type: "text",
                valKey: "value",
                key: "What percentage of total procurement (by value) does it constitute?",
              },
            ],
          ],
        },
        {
          label:
            "Provide the following information on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies:",
          th: ["Sr", "Title", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "9-0-4-0",
              },
              {
                type: "static",
                value: "States",
                id: "9-0-4-0",
              },
              {
                type: "text",
                id: "9-0-4-0",
                key: "States",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "9-0-4-1",
              },
              {
                type: "static",
                value: "Aspirational District",
                id: "9-0-4-1",
              },
              {
                type: "text",
                id: "9-0-4-1",
                key: "Aspirational District",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "9-0-4-2",
              },
              {
                type: "static",
                value: "Amount Spent in INR ",
                id: "9-0-4-2",
              },
              {
                type: "text",
                id: "9-0-4-2",
                key: "Amount Spent in INR ",
                valKey: "value",
              },
            ],
          ],
        },
        {
          label: "Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact Assessments (Reference: Question 1 of Essential Indicators above):",
          th: ["Parameter", "Details"],
          tr: [
            [
              {
                type: "static",
                value: "Details of negative social impact identified",
                id: "9-7-0-0",
              },
              {
                type: "textarea",
                id: "9-7-0-0",
                key: "Details of negative social impact identified",
                valKey: "value",
              },
            ],
            [
              {
                type: "static",
                value: "Corrective action taken",
                id: "9-7-0-1",
              },
              {
                type: "textarea",
                id: "9-7-0-1",
                key: "Corrective action taken",
                valKey: "value",
              },
            ],
          ],
        },
      ],
    },
  ],
  Principle9: [
    {
      type: "accordion",
      label:
        "Businesses should engage with and provide value to their consumers in a responsible manner",
      tables: [
        {
          label: "",
          heading: "ESSENTIAL INDICATORS",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Describe the mechanisms in place to receive and respond to consumer complaints and feedback.",
                id: "10-0-0-0",
              },
              {
                id: "10-0-0-0",
                type: "textarea",
                valKey: "value",
                key: "Describe the mechanisms in place to receive and respond to consumer complaints and feedback.",
              },
            ],
          ],
        },
        {
          label:
            "Turnover of products and/ services as a percentage of turnover from all products/service that carry information about:",
          th: ["Sr", "Categories", "As a percentage to total turnover"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "10-0-1-0",
              },
              {
                type: "static",
                value:
                  "Environmental and social parameters relevant to the product",
                id: "10-0-1-0",
              },
              {
                id: "10-0-1-0",
                type: "text",
                key: "Environmental and social parameters relevant to the product",
                valKey: "percentage",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "10-0-1-1",
              },
              {
                type: "static",
                value: "Safe and responsible usage",
                id: "10-0-1-1",
              },
              {
                id: "10-0-1-1",
                type: "text",
                key: "Safe and responsible usage",
                valKey: "percentage",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "10-0-1-2",
              },
              {
                type: "static",
                value: "Recycling and/or safe disposal",
                id: "10-0-1-2",
              },
              {
                id: "10-0-1-2",
                type: "text",
                key: "Recycling and/or safe disposal",
                valKey: "percentage",
              },
            ],
          ],
        },
        {
          label: "Number of consumer complaints in respect of the following:",
          th: [
            "Sr",
            "Categories",
            "Received during the year",
            "Pending resolution at end of year",
            "Remarks",
          ],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "10-0-2-0",
              },
              {
                type: "static",
                value: "Data privacy",
                id: "10-0-2-0",
              },
              {
                id: "10-0-2-0",
                type: "text",
                key: "Data privacy",
                valKey: "Received",
              },
              {
                id: "10-0-2-0",
                type: "text",
                key: "Data privacy",
                valKey: "Pending",
              },
              {
                type: "text",
                id: "10-0-2-0",
                key: "Data privacy",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "10-0-2-1",
              },
              {
                type: "static",
                value: "Advertising",
                id: "10-0-2-1",
              },
              {
                id: "10-0-2-1",
                type: "text",
                key: "Advertising",
                valKey: "Received",
              },
              {
                id: "10-0-2-1",
                type: "text",
                key: "Advertising",
                valKey: "Pending",
              },
              {
                type: "text",
                id: "10-0-2-1",
                key: "Advertising",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "c",
                id: "10-0-2-2",
              },
              {
                type: "static",
                value: "Cyber-security",
                id: "10-0-2-2",
              },
              {
                type: "text",
                id: "10-0-2-2",
                key: "Cyber-security",
                valKey: "Received",
              },
              {
                id: "10-0-2-2",
                type: "text",
                key: "Cyber-security",
                valKey: "Pending",
              },
              {
                type: "text",
                id: "10-0-2-2",
                key: "Cyber-security",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "d",
                id: "10-0-2-3",
              },
              {
                type: "static",
                value: "Delivery of essential",
                id: "10-0-2-3",
              },
              {
                id: "10-0-2-3",
                type: "text",
                key: "Delivery of essential",
                valKey: "Received",
              },
              {
                id: "10-0-2-3",
                type: "text",
                key: "Delivery of essential",
                valKey: "Pending",
              },
              {
                type: "text",
                id: "10-0-2-3",
                key: "Delivery of essential",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "e",
                id: "10-0-2-4",
              },
              {
                type: "static",
                value: "services",
                id: "10-0-2-4",
              },
              {
                id: "10-0-2-4",
                type: "text",
                key: "services",
                valKey: "Received",
              },
              {
                id: "10-0-2-4",
                type: "text",
                key: "services",
                valKey: "Pending",
              },
              {
                type: "text",
                id: "10-0-2-4",
                key: "services",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "f",
                id: "10-0-2-5",
              },
              {
                type: "static",
                value: "Restrictive Trade Practices",
                id: "10-0-2-5",
              },
              {
                type: "text",
                id: "10-0-2-5",
                key: "Restrictive Trade Practices",
                valKey: "Received",
              },
              {
                id: "10-0-2-5",
                type: "text",
                key: "Restrictive Trade Practices",
                valKey: "Pending",
              },
              {
                id: "10-0-2-5",
                type: "text",
                key: "Restrictive Trade Practices",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "g",
                id: "10-0-2-6",
              },
              {
                type: "static",
                value: "Unfair Trade Practices",
                id: "10-0-2-6",
              },
              {
                id: "10-0-2-6",
                type: "text",
                key: "Unfair Trade Practices",
                valKey: "Received",
              },
              {
                id: "10-0-2-6",
                type: "text",
                key: "Unfair Trade Practices",
                valKey: "Pending",
              },
              {
                id: "10-0-2-6",
                type: "text",
                key: "Unfair Trade Practices",
                valKey: "Remarks",
              },
            ],
            [
              {
                type: "static",
                value: "h",
                id: "10-0-2-7",
              },
              {
                type: "static",
                value: "Other",
                id: "10-0-2-7",
              },
              {
                id: "10-0-2-7",
                type: "text",
                key: "Other",
                valKey: "Received",
              },
              {
                id: "10-0-2-7",
                type: "text",
                key: "Other",
                valKey: "Pending",
              },
              {
                type: "text",
                id: "10-0-2-7",
                key: "Other",
                valKey: "Remarks",
              },
            ],
          ],
        },
        {
          label:
            "Details of instances of product recalls on account of safety issues:",
          th: ["Sr", "Categories", "Number", "Reason for recall"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "10-0-3-0",
              },
              {
                type: "static",
                value: "Voluntary Recalls",
                id: "10-0-3-0",
              },
              {
                type: "text",
                id: "10-0-3-0",
                key: "Voluntary Recalls",
                valKey: "Number",
              },
              {
                id: "10-0-3-0",
                type: "text",
                key: "Voluntary Recalls",
                valKey: "Reason",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "10-0-3-1",
              },
              {
                type: "static",
                value: "Forced Recalls",
                id: "10-0-3-1",
              },
              {
                type: "text",
                id: "10-0-3-1",
                key: "Forced Recalls",
                valKey: "Number",
              },
              {
                id: "10-0-3-1",
                type: "text",
                key: "Forced Recalls",
                valKey: "Reason",
              },
            ],
          ],
        },
        {
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy",
                id: "10-0-4-1",
              },
              {
                id: "10-0-4-0",
                type: "textarea",
                valKey: "value",
                key: "Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy",
              },
            ],
          
            [
              {
                type: "static",
                value:
                  "Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by regulatory authorities on safety of products / services.",
                id: "10-0-4-2",
              },
              {
                id: "10-0-4-2",
                type: "textarea",
                valKey: "value",
                key: "Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by regulatory authorities on safety of products / services.",
              },
            ],
            
          ],
        },
        {
          label: "Provide the following information relating to data breaches:",
          th: ["Sr", "Categories", "Value"],
          tr: [
            [
              {
                type: "static",
                value: "a",
                id: "10-0-5-0",
              },
              {
                type: "static",
                value: "Number of instances of data breaches along-with impact",
                id: "10-0-5-0",
              },
              {
                type: "text",
                id: "10-0-5-0",
                key: "Number of instances of data breaches along-with impact",
                valKey: "Value",
              },
            ],
            [
              {
                type: "static",
                value: "b",
                id: "10-0-5-1",
              },
              {
                type: "static",
                value:
                  "Percentage of data breaches involving personally identifiable information of customer",
                id: "10-0-5-1",
              },
              {
                type: "text",
                id: "10-0-5-1",
                key: "Percentage of data breaches involving personally identifiable information of customer",
                valKey: "Value",
              },
            ],
          ],
        },
        {
          heading: "LEADERSHIP INDICATORS",
          label: "",
          th: [],
          tr: [
            [
              {
                type: "static",
                value:
                  "Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)",
                id: "10-0-4-1",
              },
              {
                id: "10-0-4-1",
                type: "textarea",
                valKey: "value",
                key: "Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available).",
                id: "10-0-4-3",
              },
              {
                id: "10-0-4-3",
                type: "textarea",
                valKey: "value",
                key: "Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available).",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Steps taken to inform and educate consumers about safe and responsible usage of products and/or services.",
                id: "10-0-4-4",
              },
              {
                id: "10-0-4-4",
                type: "textarea",
                valKey: "value",
                key: "Steps taken to inform and educate consumers about safe and responsible usage of products and/or services.",
              },
            ],
            [
              {
                type: "static",
                value:
                  "Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.",
                id: "10-0-4-5",
              },
              {
                id: "10-0-4-5",
                type: "textarea",
                valKey: "value",
                key: "Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.",
              },
            ],
          ],
        
        }
      ],
    },
  ],
};

const WITHOUT_ENVIORNMENT_BRSR_SECTION_TABS = [
  "SectionA",
  "SectionB",
  "SectionC",
  "Principle1",
  "Principle2",
  "Principle3",
  "Principle4",
  "Principle5",
  "Principle6",
  "Principle7",
  "Principle8",
  "Principle9",
 
];

export {
  WITHOUT_ENVIORNMENT_BRSR_FORM_FIELDS,
  WITHOUT_ENVIORNMENT_BRSR_SECTION_TABS,
};
