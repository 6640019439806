import DashboardStore from "./dashboard-store";
import UserStore from "./user-store";
import CompanyStore from "./company-store";
import WaterStore from "./water-store";
import WasteManangementStore from "./waste-mangement-store";
import EmployeeStore from "./employee-store";
import WasteStore from "./waste-store";
import FormStore from "./form-store";
import GovernanceStore from "./governance-store";
import Social from "./social-store";

export default class RootStore {
  userStore = {};
  dashboardStore = {};
  companyStore = {};
  waterStore = {};
  wasteManagementStore = {};
  wasteStore = {};
  employeeStore = {};
  formStore = {};
  governance = {};
  social = {};
  constructor() {
    this.userStore = new UserStore();
    this.dashboardStore = new DashboardStore(this);
    this.companyStore = new CompanyStore(this);
    this.waterStore = new WaterStore();
    this.wasteManagementStore = new WasteManangementStore();
    this.employeeStore = new EmployeeStore();
    this.wasteStore = new WasteStore();
    this.formStore = new FormStore(this);
    this.governance = new GovernanceStore(this);
    this.social = new Social(this);
  }
}
