import React, { useEffect, useState } from "react";
import Layout from "../../utils/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./company.css";
import CompanyAssign from "./AssignTabs/companyAssign";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useAuth } from "../../context/authProvider";
import useWindowSize from "../../hooks/useWindowSize";

const AddCompany = () => {
  const location = useLocation();

  const companyData = location.state;

  const [step, setStep] = useState(0);

  const { width } = useWindowSize();

  const steps = [width < 992 ? "Basic" : "Basic Details", "Assign", "Unit"];

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 ">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Add Company</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Company</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="step-container">
              {steps.map((heading, index) => (
                <div
                  key={index}
                  className={`col step ${
                    index === step ? "active-step" : "inactive-step"
                  }`}
                  onClick={() => setStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <span className="number-wrapper">{index + 1}. </span>
                  {heading}
                </div>
              ))}
            </div>

            <div className="mt-4">
              {step === 0 && <Step1 setStep={setStep} data={companyData} />}

              {step === 1 && <CompanyAssign setStep={setStep} />}

              {/* {step === 2 && (
              <section>
                {unitFields.map((unit, index) => (
                  <div className="card p-4">
                    <div className="col text-end d-flex justify-content-end align-items-center gap-4 ">
                      {index !== 0 && (
                        <button
                          type="button"
                          className="btn-close "
                          onClick={() => removeUnit(index)}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      )}
                    </div>
                    <UnitInputFields key={unit} />
                    <div className="row">
                      <div className="col-md-12 text-end mt-3 ">
                        {index === unitFields.length - 1 && (
                          <button
                            className="btn btn-primary "
                            onClick={addUnit}
                          >
                            <i className="mdi mdi-plus me-1"></i>
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            )} */}
              {step === 2 && <Step3 data={companyData} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddCompany;

export const Step1 = ({ setStep, data }) => {
  const { token, showAlert } = useAuth();
  const navigate = useNavigate();
  const [basicFields, setBasicFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    employeeRange: "",
    companyName: "",
    region: "",
    industry: "",
    password: "",
    allowedEmployee: "",
    confirmPassword: "",
    cinNumber:"",
    paidUpCapital:"",
    nameOfStoke:"",
    showPassword: false,
  });

  const [validated, setValidated] = useState(false);

  const handleBasicData = (e) => {
    const { name, value } = e.target;
    setBasicFields({
      ...basicFields,
      [name]: value,
    });
  };
  const togglePasswordVisibility = () => {
    setBasicFields({
      ...basicFields,
      showPassword: !basicFields.showPassword,
    });
  };

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).substring(2, 10);
    setBasicFields({
      ...basicFields,
      password: randomPassword,
      showPassword: true, // Show the password after generating it
    });
    navigator.clipboard.writeText(randomPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const [minRange, maxRange] = basicFields.employeeRange.split('-').map(Number);
      if (basicFields.allowedEmployee < minRange || basicFields.allowedEmployee > maxRange) {
        showAlert("Allowed Employee count must be within the selected Employee Range", false);
        return;
      }
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const raw = JSON.stringify({
        firstName: basicFields?.firstName,
        lastName: basicFields?.lastName,
        email: basicFields?.email,
        phoneNumber: basicFields?.phoneNumber,
        employeeRange: basicFields?.employeeRange,
        companyName: basicFields?.companyName,
        region: basicFields?.region,
        industry: basicFields?.industry,
        password: basicFields?.password,
        allowedEmployee: basicFields?.allowedEmployee,
        address: basicFields?.address,
        cinNumber: basicFields?.cinNumber,
        paidUpCapital: basicFields?.paidUpCapital,
        nameOfStoke: basicFields?.nameOfStoke

      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASE_URL}company/${
          data?.id ? "update/" + data?.id : "create"
        }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.status) {
            // toast.success(result?.message);
            navigate("/admin/add-company", { state: result.data });
            setStep(1);
          }
          showAlert(result.msg || result.message, result.status || false);
        })
        .catch((error) => console.error(error));
    }
    setValidated(true);
  };

  useEffect(() => {
    if (data) {
      setBasicFields(data);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom01"
          >
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your First Name"
              name="firstName"
              value={basicFields.firstName}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid first name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom02"
          >
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your Last Name"
              name="lastName"
              value={basicFields.lastName}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid last name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom03"
          >
            <Form.Label>Phone</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Enter Your Phone No."
              name="phoneNumber"
              value={basicFields.phoneNumber}
              onChange={handleBasicData}
              onWheel={(e) => e.target.blur()}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom04"
          >
            <Form.Label>Business Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Your Email ID"
              name="email"
              value={basicFields.email}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom06"
          >
            <Form.Label>Employee Range</Form.Label>
            <Form.Select
              required
              name="employeeRange"
              value={basicFields.employeeRange}
              onChange={handleBasicData}
            >
              <option value="">Select Employee Range</option>
              <option value="0-50">0-50</option>
              <option value="50-100">50-100</option>
              <option value="100-250">100-250</option>
              <option value="250-500">250-500</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid employee range.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom05"
          >
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Company Name"
              name="companyName"
              value={basicFields.companyName}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid company name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom06"
          >
            <Form.Label>Region</Form.Label>
            <Form.Select
              required
              name="region"
              value={basicFields.region}
              onChange={handleBasicData}
            >
              <option value="">Select Region</option>
              <option value="India">India</option>
              <option value="United States">United States</option>
              <option value="Africa">Africa</option>
              <option value="Australia / New Zealand">
                Australia / New Zealand
              </option>
              <option value="Brazil">Brazil</option>
              <option value="Canada">Canada</option>
              <option value="Central America">Central America</option>
              <option value="China">China</option>
              <option value="Europe">Europe</option>
              <option value="Japan">Japan</option>
              <option value="Korea">Korea</option>
              <option value="Mexico">Mexico</option>
              <option value="Middle East">Middle East</option>
              <option value="South America">South America</option>
              <option value="South East Asia">South East Asia</option>
              <option value="Turkey">Turkey</option>
              <option value="UK">UK</option>
              <option value="Other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid region.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom06"
          >
            <Form.Label>Industry</Form.Label>
            <Form.Select
              required
              name="industry"
              value={basicFields.industry}
              onChange={handleBasicData}
            >
              <option value="">Select Industry</option>
              <option value="Aerospace and Defense">
                Aerospace and Defense
              </option>
              <option value="Automotive">Automotive</option>
              <option value="Business Services">Business Services</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Construction">Construction</option>
              <option value="Education">Education</option>
              <option value="Financial Services">Financial Services</option>
              <option value="Government">Government</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Metals,Mining,Resources">
                Metals &amp; Mining, Resources
              </option>
              <option value="Oil,Gas">Oil &amp; Gas</option>
              <option value="Petrochemicals">Petrochemicals</option>
              <option value="Power,Utility">Power &amp; Utility</option>
              <option value="Retail,Consumer Goods">
                Retail &amp; Consumer Goods
              </option>
              <option value="Technology">Technology</option>
              <option value="Transport,Logistics">
                Transport &amp; Logistics
              </option>
              <option value="Others">Others</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid company name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom08"
          >
            <Form.Label>Password</Form.Label>
            <div className="input-group">
              <Form.Control
                required={!data} // Only require if creating a new employee
                type={basicFields.showPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="password"
                value={basicFields.password}
                onChange={handleBasicData}
              />
              <div className="input-group-text" style={{ height: "45px" }}>
                <button
                  className="btn btn-light"
                  type="button"
                  id="password-addon"
                  onClick={togglePasswordVisibility}
                >
                  {basicFields.showPassword ? (
                    <i className="mdi mdi-eye-outline"></i>
                  ) : (
                    <i className="mdi mdi-eye-off-outline"></i>
                  )}
                </button>
              </div>
            </div>
            <Button className="mt-2" onClick={generatePassword}>
              Generate & Copy
            </Button>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom06"
          >
            <Form.Label>Allowed Employee</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Enter Allowed Employee"
              name="allowedEmployee"
              value={basicFields.allowedEmployee}
              onChange={handleBasicData}
              onWheel={(e) => e.target.blur()}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid allowed employee.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3" controlId="validationCustom07">
            <Form.Label>Address</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your Address"
              name="address"
              value={basicFields.address}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3" controlId="validationCustom09">
            <Form.Label>CIN Number</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your CIN Number"
              name="cinNumber"
              value={basicFields.cinNumber}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid CIN-Number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3" controlId="validationCustom10">
            <Form.Label>Paid-up Cpital</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your Paid-up Cpital"
              name="paidUpCapital"
              value={basicFields.paidUpCapital}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Paid-up Cpital.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Name of the Stock Exchange(s) where shares are listed(If Available)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name of the Stock Exchange(s)"
              name="nameOfStoke"
              value={basicFields.nameOfStoke}
              onChange={handleBasicData}
            />
          </Form.Group>
        </Row>
        <div className="text-center">
          <Button type="submit" className="px-4">
            Next
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export const Step3 = ({ data }) => {
  const navigate = useNavigate();
  const { token, showAlert } = useAuth();
  const [formFields, setFormFields] = useState([
    {
      name: "",
      pincode: "",
      address: "",
    },
  ]);
  const [update, setUpdate] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");
      const raw = JSON.stringify({
        companyId: data?.id,
        fields: formFields,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASE_URL}assign-companies/${
          update ? "updateunit" : "createunit"
        }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          showAlert(result.msg || result.message, result.status || false);
          if (result.status) {
            navigate("/admin/company");
          }
        })
        .catch((error) => console.error("error", error));
    }
    setValidated(true);
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };
  const addFields = () => {
    let object = {
      name: "",
      age: "",
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}assign-companies/unit/${data?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          if (result.data.length > 0) {
            setFormFields(result.data);
            setUpdate(true);
          }
        }
      });
  }, [token, data?.id]);

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {formFields?.map((form, index) => {
          return (
            <div
              className="mb-3 border card-body position-relative"
              key={index}
            >
              <div className="row">
                {index !== 0 && (
                  <div
                    role="button"
                    className="text-end fs-5 fw-bold text-danger close-unit"
                    onClick={() => removeFields(index)}
                  >
                    x
                  </div>
                )}
                <Form.Group
                  as={Col}
                  md="4"
                  controlId={"validationUnitName" + index}
                  className="md-4"
                >
                  <Form.Label>Unit Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Unit Name"
                    name="name"
                    onChange={(event) => handleFormChange(event, index)}
                    value={form.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a unit name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  controlId={"validationPinCode" + index}
                  className="md-4"
                >
                  <Form.Label>Pin-code</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter Pin"
                    name="pincode"
                    onChange={(event) => handleFormChange(event, index)}
                    value={form.pincode}
                    onWheel={(e) => e.target.blur()}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid pin code.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  controlId={"validationAddress" + index}
                  className="md-4"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={2}
                    placeholder="Enter Address"
                    name="address"
                    onChange={(event) => handleFormChange(event, index)}
                    value={form.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide an address.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          );
        })}
        <div className="text-center">
          <Button variant="primary" type="button" onClick={addFields}>
            <i className="mdi mdi-plus me-1"></i>
            Add More
          </Button>
        </div>
        {formFields.length > 0 && (
          <div className="text-end mt-5">
            <Button type="submit">Submit</Button>
          </div>
        )}
      </Form>
    </React.Fragment>
  );
};
