import React, { useState, useEffect } from "react";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";

const FieldModal = ({ open, onClose, mode, initialState, fieldsPayload }) => {
  const {
    dashboardStore: {
      categories,
      subCategories,
      kpi,
      createFields,
      updateFields,
    },
  } = useStore();

  const emptyState = {
    name: "",
    group: "",
    kpiId: "",
    subCatId: "",
    catId: "",
    guidance: "",
  };

  const [formData, setFormData] = useState({ ...emptyState });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (mode === "edit" && initialState) {
      const { catId, subCatId, kpiId, name, group, guidance } = initialState;
      setFormData(
        mode === "edit"
          ? {
              catId,
              subCatId,
              kpiId,
              name,
              group,
              guidance,
            }
          : { ...emptyState }
      );
    }
    return () => {
      setFormData(emptyState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, initialState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === "edit" && initialState) {
      const payload = { ...formData, status: true };
      updateFields(payload, initialState.id);
    } else {
      const payload = { ...formData, status: true };
      createFields(payload, fieldsPayload);
    }
    onClose();
  };

  if (!open) {
    return;
  }

  return (
    <div
      className={`modal fade ${open ? "show" : ""}`}
      id="newCustomerModal"
      tabIndex="-1"
      onClick={onClose}
      aria-labelledby="newCustomerModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content border-0">
          <div className="modal-header">
            <h5 className="mb-0" id="newCustomerModalLabel">
              {mode === "create" ? "Add Fields" : "Edit Fields"}
            </h5>
            <button
              type="button"
              className="btn-close "
              onClick={onClose}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body ">
            <form
              autoComplete="off"
              className="needs-validation createCustomer-form"
              id="createCustomer-form"
              noValidate
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label htmlFor="username-input" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      value={formData?.name}
                      onChange={handleChange}
                      name="name"
                      id="username-input"
                      className="form-control"
                      placeholder="Enter name"
                      required
                    />
                    <div className="invalid-feedback">Please enter a name.</div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="username-input" className="form-label">
                      Group
                    </label>
                    <input
                      type="text"
                      value={formData?.group}
                      onChange={handleChange}
                      name="group"
                      id="username-input"
                      className="form-control"
                      placeholder="Enter name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter a group.
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="username-input" className="form-label">
                      Guidance
                    </label>
                    <input
                      type="text"
                      value={formData?.guidance}
                      onChange={handleChange}
                      name="guidance"
                      id="username-input"
                      className="form-control"
                      placeholder="Enter name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter a Guidance.
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="category-input" className="form-label">
                      Category
                    </label>
                    <select
                      value={formData?.catId}
                      onChange={handleChange}
                      name="catId"
                      id="category-input"
                      className="form-control"
                      required
                    >
                      <option value="">Select category</option>
                      {toJS(categories).map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select a category.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="sub-input" className="form-label">
                      Sub Category
                    </label>
                    <select
                      value={formData?.subCatId}
                      onChange={handleChange}
                      name="subCatId"
                      id="sub-input"
                      className="form-control"
                      required
                    >
                      <option value="">Select sub category</option>
                      {toJS(subCategories)
                        ?.filter((subCat) => subCat.catId === formData.catId)
                        ?.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select a sub category.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="kpi-input" className="form-label">
                      KPI
                    </label>
                    <select
                      value={formData?.kpiId}
                      onChange={handleChange}
                      name="kpiId"
                      id="kpi-input"
                      className="form-control"
                      required
                    >
                      <option value="">Select KPI</option>
                      {toJS(kpi)
                        ?.filter(
                          (kpi) =>
                            kpi.subCatId === formData.subCatId &&
                            kpi.catId === formData.catId
                        )
                        .map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">Please select a KPI.</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex gap-1 justify-content-end">
                    <button
                      type="button"
                      onClick={onClose}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      id="addCustomer-btn"
                      className="btn btn-success"
                      onClick={handleSubmit}
                    >
                      {mode === "create" ? "Add Fields" : "Update"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(FieldModal);
