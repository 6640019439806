import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";

export default class WasteManangementStore {
  wasteForms = [];
  wasteFormData = [];
  constructor() {
    makeObservable(this, {
      wasteForms: observable,
      wasteFormData: observable,
      //action
      fetchWasteForms: action.bound,
      createWaste: action.bound,
      fetchWasteFormData: action.bound,
      //computed
      wasteFormsList: computed,
      wasteFormDataList: computed,
      reset: action.bound,
    });
  }

  reset() {
    this.wasteFormData = [];
    this.wasteForms = [];
  }

  fetchWasteForms({ signal }) {
    fetchData(`waste-management/getAllWasteForms`, {
      method: "POST",
      signal: signal,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.wasteForms = data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  get wasteFormsList() {
    return toJS(this.wasteForms);
  }

  createWaste({ payload, groupPayload, showAlert, navigate }) {
    const token = JSON.parse(
      localStorage.getItem("access-token") ||
        localStorage.getItem("admin-access-token")
    )?.token;
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    formData.append("groupPayload", JSON.stringify(groupPayload));
    payload.forEach((pay, index) => {
      if (pay.attachment) {
        formData.append(`file[]`, pay.attachment);
        formData.append(`index[]`, index);
      }
    });
    fetch(process.env.REACT_APP_BASE_URL + "waste-management/create", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        showAlert?.(data.message, data.status || false);
        if (data.status) {
          navigate("/company/waste");
        }
      })
      .catch((err) => {
        showAlert?.("Please Try Again");
        console.error(err);
      });
  }

  fetchWasteFormData({ formId, showAlert }) {
    fetchData(`waste-management/getFormData?formId=${formId}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.wasteFormData = data.data;
          return;
        }
        showAlert?.(data.message);
      })
      .catch((err) => {
        showAlert?.(err.message);
        console.error(err);
      });
  }

  get wasteFormDataList() {
    return toJS(this.wasteFormData);
  }
}
