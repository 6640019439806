import React from "react";
import logo from "../../assets/images/logo-dark-esg.png";
import { Link, useLocation } from "react-router-dom";


const ForgotPassword = () => {
  return (
    <div className="account-pages">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card ">
              <div className="card-body">

                <div className="p-4">
                  <div className="auth-logo mb-5">
                    <img src={logo} alt="" className="rounded-circle" />
                  </div>
                  <div className="mb-4">
                    <p className="text-center font-size-16">
                      Please contact the admin for assistance.
                    </p>
                    <div className="mt-4 d-grid">
                      <Link to="/" className="btn btn-primary waves-effect waves-light d-flex align-items-center justify-content-center">OK</Link>
                    </div>
                  </div>
                  {/* <form
                    className="form-horizontal"
                    action="https://themesbrand.com/skote/layouts/index.html"
                  >
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="useremail"
                        placeholder="Enter email"
                      />
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <div>
                <p>
                  © 2024 ESG. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
