import { observer } from "mobx-react";
import BrsrSkeleton from "./brsrSkeleton";
import { useRef, useState,useEffect } from "react";
import html2pdf from "html2pdf.js";
import { Modal } from "react-bootstrap";
import {generateYearArray} from "../../../utils/helpers";
import fetchData from "../../../utils/fetchData";


function BRSRReport() {
  const [titleImage, setTitleImage] = useState(null);
  const ref = useRef(null);

  const [show, setShow] = useState(false);
  const [year, setYear] = useState(0);
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = (data) => setShow(true);
  useEffect(() => {
    fetchData(`brsr/getAll?year=${year}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status && res.data.length > 0) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log("[Error]", err);
      });
    return () => {
      setData([]);
    };
  }, [year]);
  return (
    <div
      style={{
        borderRadius: "2px",
        padding: "8px",
      }}
    >
      <div className="card mt-2 ">
        <div className="row p-4 d-flex gap-2 flex-wrap report-download-button  ">
          <div className="col-xl-5 gap-2 d-flex px-2">
            <h5 className="card-title">BRSR Report</h5>
          </div>
          <div className="col-xl-4 gap-2 d-flex px-2">
            <label className="form-label fs-6 ">Year</label>
            <select
              value={year}
              onChange={(event) => setYear(event.target.value)}
              className="form-select bg-white"
            ><option key="default" value=''>Select Year</option>
              {generateYearArray(5).map((y) => (
                <option value={y}>{`FY ${y}-${y + 1}`}</option>
              ))}
            </select>
          </div>
          {data && year ? (
          <div className="col-xl-2 d-flex gap-2 flex-wrap">
             <button
              className="btn btn-secondary"
              onClick={handleShow}
            >
              Preview
            </button>
            <button
              className="btn btn-secondary"
              onClick={async () => {
                const printWindow = window.open("", "", "height=800,width=800");
                printWindow.document.open();
                printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print</title>
                      <style>
                        /* Add print-specific styles here */
                        body { font-family: Arial, sans-serif; margin: 20px; }
                        @page { size: A4 landscape; }
                      </style>
                    </head>
                    <body>
                    ${document.getElementById("brsr-report").innerHTML}
                    </body>
                  </html>
                `);
                printWindow.document.close();
                printWindow.onload = function () {
                  printWindow.focus();
                  printWindow.print();
                  printWindow.onafterprint = function () {
                    printWindow.close();
                  };
                };
              }}
            >
              Download
            </button>
            <div className="custom-file-input">
              <input
                type="file"
                accept="image/*"
                id="fileUpload"
                className="form-control h-100"
                onChange={(e) => setTitleImage(e.target.files[0])}
              />
            </div>
           
          </div>
          ):(null)}
        </div>
      </div>
      {data && year ? (
      <div className="card" ref={ref} style={{ display: "" }}>
          <BrsrSkeleton
            mainPageUrl={titleImage ? URL.createObjectURL(titleImage) : "" }
            year={year}
            data={data}
          />
        </div>
      ) : (
        null
      )}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>BRSR Report Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {data && year ? (
            <BrsrSkeleton
              mainPageUrl={titleImage ? URL.createObjectURL(titleImage) : "" }
              year={year}
              data={data}
            />
        ) : (
          null
        )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default observer(BRSRReport);
