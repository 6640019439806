import React, { useMemo } from "react";
import "./mobileTable.css";
import useStore from "../../hooks/useStore";
import { USER_ROLE } from "../../constants/constants";
import { findKpi } from "../../utils/helpers";

/// This  is to render view Tables in mobile view
const MobileTable = ({ handleModalOpen, handleView, handleEdit }) => {
  const {
    userStore: { employeeKpis, user },
    companyStore: { kpiName },
    formStore: { forms },
  } = useStore();

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );

  return (
    <div className="mobile-view-wrapper">
      <div className="mobile-view  p-3">
        {forms?.records?.map((item, index) => (
          <div className="mobile-row" key={item.formId}>
            <div className="mobile-cell">
              <span className="mobile-th">Sr No:</span>
              <span className="mobile-td">{index + 1}</span>
            </div>
            <div className="mobile-cell">
              <span className="mobile-th">Frequency Type:</span>
              <span className="mobile-td">{item.frequencyType || "-"}</span>
            </div>
            <div className="mobile-cell">
              <span className="mobile-th">Frequency Date:</span>
              <span className="mobile-td">{item.frequencyDate || "-"}</span>
            </div>
            <div className="mobile-cell">
              <span className="mobile-th">Frequency Month:</span>
              <span className="mobile-td">{item.frequencyMonth || "-"}</span>
            </div>
            <div className="mobile-cell">
              <span className="mobile-th">Frequency Year:</span>
              <span className="mobile-td">{item.frequencyYear || "-"}</span>
            </div>

            <div className="mobile-cell">
              <span className="mobile-th">Status:</span>
              <span className="mobile-td">{item.approvalStatus || "-"}</span>
            </div>

            <div className="mobile-cell">
              <span className="mobile-th">Approved By:</span>
              <span className="mobile-td">
                {item.approver.first_name} {item.approver.last_name}
              </span>
              <span>
                {item.approver.first_name
                  ? new Date(item.approvedAt).toLocaleDateString("en-GB")
                  : "-"}
              </span>
            </div>

            <div className="mobile-cell">
              <span className="mobile-th">Updated By:</span>
              <span className="mobile-td">
                {item.updater.first_name} {item.updater.last_name}
              </span>
              <br />
              <span>
                {item.updater.first_name
                  ? new Date(item.updatedAt).toLocaleDateString("en-GB")
                  : "-"}
              </span>
            </div>
            <div className="mobile-cell">
              <span className="mobile-th">View:</span>
              <button
                onClick={() => handleView(item?.formId)}
                className="btn btn-primary btn-sm btn-action waves-effect waves-light"
              >
                View
              </button>
            </div>
            <div className="mobile-cell ">
              <span className="mobile-th">Actions:</span>
              <span className="mobile-td mobile-table-actions">
                {user.role === USER_ROLE.Company || kpi.isApproved ? (
                  <>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleModalOpen("approve", item.formId)}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-primary btn-sm "
                      onClick={() => handleEdit(item.formId)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleModalOpen("delete", item.formId)}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <>
                    {kpi.isEdited && (
                      <button
                        className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        onClick={() => handleEdit(item.formId)}
                      >
                        Edit
                      </button>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileTable;
