import ColumnChart from "../charts/columnChart";
import DonutChart from "../charts/donutChart";
import { useMemo, useState } from "react";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { Accordion } from "react-bootstrap";
import BiodiversityAccordion from "./biodiversityAccordion";
import ShowPerformance from "../showPerformance";
import { getRoundedValue } from "../../utils/helpers";
import CustomSpinner from "../spinner/Spinner";

function DashboardBiodiversity({ year }) {
  const {
    dashboardStore: { dashboardDetails },
  } = useStore();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.biodiversity;
    const result = ["A", "B1"].map((group, index) => {
      return {
        name: [
          "Total number of species affected(Water bodies & Habitats)",
          "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk",
        ][index],
        data: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.prevValue || 0
        ),
        target: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.target || 0
        ),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.biodiversity, year]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.biodiversity?.[year];
    const result = ["A", "B1"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = [
      "Total number of species affected(Water bodies & Habitats)",
      "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk",
    ];
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.biodiversity, year]);

  return (
    <div className="row h-100">
      <div className="col-xl-12">
        <div className="card h-100 dashboard-card">
          <div className="card-body">
            <div className="d-flex flex-wrap align-items-start">
              <h5 className="mb-3 text-muted"> Biodiversity Report</h5>
              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 ">
                  <div className="">
                    <p className="text-muted mb-1">Unit</p>
                    <h5 className="unit-value">
                      {dashboardDetails?.charts?.biodiversity?.unit}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {dashboardDetails?.charts ? (
              <ColumnChart
                data={
                  columnChartData?.map(({ data, name }) => ({
                    data: data.map((e) => getRoundedValue(e, 0)),
                    name,
                  })) || []
                }
                unit={dashboardDetails?.charts?.biodiversity?.unit}
                categories={[
                  `FY${(year - 1).toString().slice(2)}`,
                  `FY${year.toString().slice(2)}`,
                ]}
                yaxis={[]}
              />
            ) : (
              <div className="d-flex align-items-center justify-content-center h-75">
                <CustomSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-12 h-100 mt-4">
        {/* achievements and target  */}

        <div className="row">
          <div className="col-md-6 mb-4">
            <ShowPerformance label="biodiversity" />
          </div>

          {/* donut chart  */}
          <div className="col-md-6 mb-4">
            <div className="card mb-0 h-100 dashboard-donut-card">
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-start">
                  <h5 className="mb-3 text-muted donut-heading ">
                    Biodiversity Overall Analysis
                  </h5>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">
                          {dashboardDetails?.charts?.biodiversity?.unit}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {dashboardDetails?.charts ? (
                  <div className="donut">
                    <DonutChart
                      data={
                        doughNutChartData.data.map((e) =>
                          getRoundedValue(e, 0)
                        ) || []
                      }
                      labels={doughNutChartData.labels || []}
                      unit={dashboardDetails?.charts?.biodiversity?.unit}
                      height={450}
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-75">
                    <CustomSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion
        className="dashboard-chart-accordion"
        onSelect={handleAccordionToggle}
      >
        <Accordion.Item key={`biodiversity-accordian`} eventKey={0}>
          <Accordion.Header>
            {isAccordionOpen ? "See less" : "See more"}
          </Accordion.Header>
          <Accordion.Body>
            <BiodiversityAccordion year={year} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
export default observer(DashboardBiodiversity);
