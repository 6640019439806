import { observer } from "mobx-react";
import {
  findKpi,
  generateYearArray,
  getBrsrInitialData,
} from "../../../../utils/helpers";
import { useEffect, useMemo, useState } from "react";
import AddForm from "./addForm";
import {
  WITH_ENVIORNMENT_BRSR_FORM_FIELDS,
  WITH_ENVIORNMENT_BRSR_SECTION_TABS,
} from "../../../../constants/withEnviornmentBrsrConstant";
import {
  WITHOUT_ENVIORNMENT_BRSR_FORM_FIELDS,
  WITHOUT_ENVIORNMENT_BRSR_SECTION_TABS,
} from "../../../../constants/withoutEnviornmentBrsrConstant";
import fetchData from "../../../../utils/fetchData";
import { useAuth } from "../../../../context/authProvider";
import useStore from "../../../../hooks/useStore";
import { Modal,Button } from "react-bootstrap";
import LogBody from "./logBody";
import { toJS } from "mobx";

const BrsrPage = () => {
  const {
    companyStore: { kpiName, companyData },
    userStore: { employeeKpis, user },
    formStore: { logData },
  } = useStore();

  // Determine if the company is assigned to the "Environment" category
  const isOnlyBrsr = !toJS(companyData)?.assignCategory?.find(
    (e) => e.catIdAssignCategory.name === "Environment"
  );

  // Select the correct set of section tabs and form fields based on `isOnlyBrsr`
  const BRSR_SECTION_TABS = isOnlyBrsr
    ? WITHOUT_ENVIORNMENT_BRSR_SECTION_TABS
    : WITH_ENVIORNMENT_BRSR_SECTION_TABS;

  const BRSR_FORM_FIELDS = isOnlyBrsr
    ? WITHOUT_ENVIORNMENT_BRSR_FORM_FIELDS
    : WITH_ENVIORNMENT_BRSR_FORM_FIELDS;

  const initialValues = useMemo(
    () => getBrsrInitialData(isOnlyBrsr),
    [isOnlyBrsr]
  );

  const [year, setYear] = useState(0);
  const [activeTab, setActiveTab] = useState(BRSR_SECTION_TABS[0]); // Track the active tab
  const [data, setData] = useState(initialValues);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenAction, setModalOpenAction] = useState(false);

  const [actionType, setActionType] = useState(null); // Track action type: approve or delete
  const { showAlert } = useAuth();
  useEffect(() => {
   
      setData(initialValues);

  }, [initialValues, isOnlyBrsr]);
  useEffect(() => {
    if (year) {
      fetchData(`brsr/getAll?year=${year}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status && res.data.length > 0) {
            setData(res.data);
          }
        })
        .catch((err) => {
          console.log("[Error]", err);
        });
    }
    return () => {
      setData(initialValues);
    };
  }, [showAlert, year]);

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );
  const formId = data?.[0]?.formId;
  const isFullySaved = data?.every((e) => e.action && e?.action === "edit");
  const approvalHandler = (id, isRejected) => {
    if (!id) {
      showAlert("Please select form");
      return;
    }
    fetchData(`brsr/approve`, {
      method: "POST",
      body: JSON.stringify({
        formId: id,
        ...(isRejected ? { isRejected } : {}),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          showAlert(res.message, true);
        }
      })
      .catch((err) => {
        showAlert("Internal Server Error");
        console.error(err);
      });
  };

  // Function to open the modal
  const openModal = (action) => {
    setActionType(action);
    setModalOpenAction(true);
  };

  // Handle modal confirmation
  const handleModalConfirm = () => {
    if (actionType === "approved") {
      approvalHandler(formId, false);
    } else if (actionType === "deleted") {
      approvalHandler(formId, true);
    }
    setModalOpenAction(false); // Close the modal after action
  };
  return (
    <div className="add-water-page">
      <div className="card p-4">
        <div className="mb-3">
          <label className="form-label">Year</label>
          <select
            value={year}
            className="form-select"
            onChange={(event) => setYear(event.target.value)}
          >
            <option key="default" value={0}>
              Select Year
            </option>
            {generateYearArray().map((val) => (
              <option key={`${val}-options`} value={val}>{`FY${val}`}</option>
            ))}
          </select>
        </div>
        {Boolean(year) && formId && (
          <div className="table-responsive mt-3 approval-table mb-4">
            <table className="table align-middle table-bordered table-nowrap mb-0">
              <thead>
                <th>Updated By</th>
                <th className=" p-3">Status</th>
                <th className=" p-3">Approved By:</th>
                <th>Log</th>
              </thead>
              <tbody>
                <td>
                  <div>
                    <div>
                      {data?.[0]?.updater?.first_name ?? "-"}{" "}
                      {data?.[0]?.updater?.last_name ?? "-"}
                    </div>
                    {data?.[0]?.updatedBy && (
                      <div style={{ fontSize: "0.8em", color: "gray" }}>
                        (
                        {data?.[0]?.updatedBy
                          ? new Date(data?.[0]?.updatedAt).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"}
                        )
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  {data?.[0]?.approvalStatus === "approved"
                    ? "Approved"
                    : "Pending"}{" "}
                </td>
                <td>
                  <div>
                    <div>
                      {data?.[0]?.approver?.first_name ?? "-"}{" "}
                      {data?.[0]?.approver?.last_name ?? "-"}
                    </div>
                    {data?.[0]?.approvedAt && (
                      <div style={{ fontSize: "0.8em", color: "gray" }}>
                        (
                        {data?.[0]?.approvedAt
                          ? new Date(data?.[0]?.approvedAt).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"}
                        )
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <i
                    type="button"
                    onClick={() => {
                      setModalOpen(true);
                      logData({ formId });
                    }}
                    className="mdi mdi-dots-vertical"
                  ></i>
                </td>
              </tbody>
            </table>
          </div>
        )}
        <div className="mb-3">
          {BRSR_SECTION_TABS.map((label) => (
            <button
              className={`btn ${
                activeTab === label ? "btn-primary" : "btn-light"
              }`}
              onClick={() => setActiveTab(label)}
            >
              {label}
            </button>
          ))}
        </div>
        <div>
          <AddForm
            fields={BRSR_FORM_FIELDS[activeTab]}
            data={data}
            setData={setData}
            year={year}
          />
        </div>
        {user.role === "company" &&
          data?.[0]?.approvalStatus !== "approved" &&
          isFullySaved && (
            <div className="d-flex justify-content-end mt-3 gap-2">
              <button
                className="btn btn-primary"
                onClick={() => openModal("approved")}
              >
                Approve
              </button>
              <button
                className="btn btn-danger"
                onClick={() => openModal("deleted")}
              >
                Delete
              </button>
            </div>
          )}
      </div>
      {/* log list modal */}

      <Modal
        show={modalOpen}
        onHide={() => {
          setModalOpen(false);
        }}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Log Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ overflowX: "auto" }}>
            <table className="table table-responsive scroll">
              <thead>
                <tr key={12} className="waterTabletr">
                  <th key={0}>Username</th>
                  <th key={1}>Date</th>
                  <th key={2}>Action</th>
                  <th key={3}>Ip</th>
                  <th key={4}>Request</th>
                </tr>
              </thead>
              <LogBody />
            </table>
          </div>
        </Modal.Body>
      </Modal>

      {/* Approve and delete modal */}
        <Modal
        show={modalOpenAction}
        onHide={() => {
          setModalOpenAction(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
          {actionType === "approved"
              ? "Approve Form"
              : actionType === "deleted"
              ? "Delete Form"
              : " "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {actionType === "approved" && (
            <p>Are you sure you want to approve this form?</p>
          )}
          {actionType === "deleted" && (
            <p>
              Are you sure you want to delete this form? Once deleted, all data
              associated with this form will be permanently removed and cannot
              be recovered.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={() => setModalOpenAction(false)}>
            Cancel
          </Button>
          {actionType === "approved" && (
            <Button variant="primary" onClick={handleModalConfirm}>
              Approve
            </Button>
          )}
          {actionType === "deleted" && (
            <Button variant="primary" onClick={handleModalConfirm}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default observer(BrsrPage);
