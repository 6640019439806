import React from "react";
import logo from "../../assets/images/logo-dark-esg.png";


const ResetPassword = () => {

  return (
    <div className="account-pages">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card">

              <div className="card-body ">

                <div className="p-2">

                <div className="auth-logo">
                    <img src={logo} alt="" className="rounded-circle" />
                  </div>

                  <form className="form-horizontal">
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        Old Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="useremail"
                        placeholder="Enter old password"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="useremail"
                        placeholder="Enter new password"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="useremail"
                        placeholder="Confirm password"
                      />
                    </div>


                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Reset
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <div>
                <p>
                  © 2024 ESG. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
