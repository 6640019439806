const PaginationControls = ({ totalPages, page, setPage }) => {
  return (
    <div>
      <button onClick={() => setPage(1)} disabled={page === 1}>
        {"<<"}
      </button>
      <button onClick={() => setPage((p) => p - 1)} disabled={page === 1}>
        {"<"}
      </button>
      {page}
      <button
        onClick={() => setPage((p) => p + 1)}
        disabled={page === totalPages}
      >
        {">"}
      </button>
      <button
        onClick={() => setPage(totalPages)}
        disabled={totalPages === page}
      >
        {">>"}
      </button>
    </div>
  );
};

export default PaginationControls;
