const PrimaryRoute = {
  CategoryMaster: "category-master/",
  Kpi: "kpi/",
  SubCategoryMaster: "sub-category-master/",
  Auth: "auth/",
  UnitMaster: "unit-master/",
  FieldMaster: "fields-master/",
  Companies: "company/"
};

const SecondaryRoute = {
  Create: "create/",
  UpdateCategory: "update-category",
  GetAllCategory: "getAllCategory/",
  Update: "update",
  GetAllKpi: "getAllKpi",
  GetAllSubCategory: "getAllSubCategory",
  GetAllUnit: "getAll",
  GetAllFields: "getAllFields",
  GetAllCompany: "companies",
};

export { PrimaryRoute, SecondaryRoute };
