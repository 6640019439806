import React, { useState } from "react";
import Layout from "../../../utils/layout";
import { Link } from "react-router-dom";
import WaterRecycleFilters from "../water-recycle/WaterRecycleFilters";

import WaterConsumptionTable from "./WaterConsumptionTable";

function AddWaterConsumption() {
  const categoryList = ["KWH", "INR", "Number", "Percentage", "m3"];
  const Month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const Year = ["2023", "2022"];
  const [filter, setFilter] = useState({
    month: "",
    year: "",
  });
  const [data, setData] = useState([
    {
      waterRecycle: "Total Fresh Water Consumption : Plant",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Total Fresh Water Consumption : Colony",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Recycled Water consumption",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Recycled Water consumption: Process",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Recycled Water consumption: Utility",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Quantum of Effluent Generated (STP)",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Quantum of Effluent Recycled (STP)",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Quantum of Effluent Generated (ETP)",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
    {
      waterRecycle: "Quantum of Effluent Recycled (ETP)",
      selectedCategory: categoryList[0],

      prevValue: "",
      target: "",
      value: "",
    },
  ]);

  const [step, setStep] = useState(0);

  const steps = ["Consumption", "Intake"];

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        [name]: value,
      };
      return updatedFormData;
    });
  };

  const handleSelectChange = (e, index) => {
    const { value } = e.target;
    const newData = [...data];
    newData[index].selectedCategory = value;
    setData(newData);
  };

  const handleFilter = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 ">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Add Water Consumption</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Add Water Consumption
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
        <div className="card-body">
          <div className="step-container">
            {steps.map((heading, index) => (
              <div
                key={index}
                className={`col step ${
                  index === step ? "active-step" : "inactive-step"
                }`}
                onClick={() => setStep(index)}
                style={{ cursor: "pointer" }}
              >
                <span className="number-wrapper">{index + 1}. </span>
                {heading}
              </div>
            ))}
          </div>

          <div className="p-4">
            {/* step 1  */}
            {step === 0 && (
              <>
                <div className="row">
                  <div className="col-lg-3 col-sm-12">
                    <WaterRecycleFilters
                      label={"Month"}
                      onChange={(e) => handleFilter("month", e.target.value)}
                      selectedValue={filter.month}
                      options={Month}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <WaterRecycleFilters
                      label={"Year"}
                      onChange={(e) => handleFilter("year", e.target.value)}
                      selectedValue={filter.year}
                      options={Year}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <section>
                  <div className="table-responsive ">
                                <table className="table align-middle table-bordered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Water Management </th>
                            <th>Unit</th>
                            <th>Prev. Value</th>
                            <th>Target</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <WaterConsumptionTable
                          data={data}
                          categoryList={categoryList}
                          handleInputChange={handleInputChange}
                          handleSelectChange={handleSelectChange}
                        />
                      </table>
                    </div>
                  </section>
                  <div className="d-flex justify-content-end gap-2 mt-3">
                    <div className="text-end">
                      <button className="btn btn-primary">Save</button>
                    </div>
                    <div className="text-end">
                      <button className="btn btn-secondary">Cancel</button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* step one ends  */}
            {/* step 2  */}
            {step === 1 && (
              <>
                <div className="row">
                  <div className="col-lg-3 col-sm-12">
                    <WaterRecycleFilters
                      label={"Month"}
                      onChange={(e) => handleFilter("month", e.target.value)}
                      selectedValue={filter.month}
                      options={Month}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <WaterRecycleFilters
                      label={"Year"}
                      onChange={(e) => handleFilter("year", e.target.value)}
                      selectedValue={filter.year}
                      options={Year}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <section>
                  <div className="table-responsive ">
                                <table className="table align-middle table-bordered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Water Management </th>
                            <th>Unit</th>
                            <th>Prev. Value</th>
                            <th>Target</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <WaterConsumptionTable
                          data={data}
                          categoryList={categoryList}
                          handleInputChange={handleInputChange}
                          handleSelectChange={handleSelectChange}
                        />
                      </table>
                    </div>
                  </section>
                  <div className="d-flex justify-content-end gap-2 mt-3">
                    <div className="text-end">
                      <button className="btn btn-primary">Save</button>
                    </div>
                    <div className="text-end">
                      <button className="btn btn-secondary">Cancel</button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row mt-3">
              <div className="col text-end">
                {step > 0 && (
                  <button className="btn btn-primary me-2" onClick={handlePrev}>
                    Previous
                  </button>
                )}
                {step < steps.length - 1 && (
                  <button className="btn btn-primary" onClick={handleNext}>
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddWaterConsumption;
