import React from "react";
import Chart from "react-apexcharts";

const ColumnDataLabels = ({ data, categories, title }) => {
  const options = {
    series: [
      {
        name: "Inflation",
        data:
          data?.length > 0
            ? data
            : [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },

    colors: ["#556EE6"],

    plotOptions: {
      bar: {
        width: 5,
        horizontal: false,
        barHeight: "80%",
        distributed: true,

        dataLabels: {
          position: "top", // top, center, bottom
        },
        columnWidth: 30,
      },
    },
    dataLabels: {
      enabled: true,

      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    legend: {
      show: false,
    },

    xaxis: {
      categories:
        categories?.length > 0
          ? categories
          : [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    // title: {
    //   text: title || "Monthly Inflation in Argentina, 2002",
    //   floating: true,
    //   offsetY: 330,
    //   align: "center",
    //   style: {
    //     color: "#B1B8C0",
    //     fontWeight: "Bold",
    //     fontSize: "15px",
    //     padding: "120px 0 0 0",
    //   },
    // },
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ColumnDataLabels;
