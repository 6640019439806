import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/authProvider";

const AssignKPI = ({ setTab }) => {
  const { token, showAlert } = useAuth();
  const location = useLocation();
  const companyData = location.state;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [KpiList, setKpiList] = useState([]);
  const [kpis, setKpis] = useState([]);
  useEffect(() => {
    if (selectedCategory && selectedSubCategory) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}assign-companies/kpis/${companyData?.id}/${selectedCategory}/${selectedSubCategory}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            const assignedKpis = result.data.filter(({ status }) => status);
            setKpiList(result.data);
            setKpis(assignedKpis.map(({ id }) => id));
          }
        })
        .catch((error) => console.error(error));
    } else {
      setKpiList([]);
      setKpis([]);
    }
  }, [token, selectedCategory, selectedSubCategory, companyData?.id]);

  const handleSave = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        companyId: companyData?.id,
        catId: selectedCategory,
        subCatId: selectedSubCategory,
        kpis: kpis,
      }),
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}assign-companies/${
        kpis.length > 0 ? "updateassignkpi" : "assignkpi"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showAlert(result.msg || result.error, result.status || false);
        // if (result.status) {
        //   setTab("KPI fields");
        // }
      })
      .catch((error) => console.error(error));
  };
  
  return (
    <div className="mt-10">
      <div className="">
        <AssignDropdownRow
          token={token}
          data={companyData}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
        />
      </div>

      <div className="table-responsive ">
                                <table className="table align-middle table-bordered table-nowrap mb-0">
        <thead >
          <tr>
            <th>Sr. No.</th>
            <th>KPI Name</th>
            <th>Assign</th>
          </tr>
        </thead>
        <tbody>
            {KpiList.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  <div className="form-check font-size-20 p-0">
                    <input
                      className="form-check-input m-0 float-none"
                      type="checkbox"
                      checked={kpis.includes(item.id)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        if (checked) {
                          setKpis([...kpis, item.id]);
                        } else {
                          setKpis(kpis.filter((id) => id !== item.id));
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
      </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <button className="btn btn-primary px-4" onClick={handleSave}>
            Save
          </button>
          <button
            className="btn btn-primary px-4 mx-4"
            onClick={() => {
              handleSave();
              setTab("KPI fields");
            }}
          >
            Save & Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignKPI;

const AssignDropdownRow = ({
  token,
  data,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + "assign-companies/category/" + data?.id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setCategoryList(result.data);
          const isselected = result.data.find((item) => item.status)?.catId;
          setSelectedCategory(isselected);
        }
      })
      .catch((error) => console.error(error));
  }, [data?.id, token, setSelectedCategory]);

  const [subcategoryList, setSubCategoryList] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      fetch(
        process.env.REACT_APP_BASE_URL +
          "assign-companies/assignedsubcategory/" +
          data?.id +
          "/" +
          selectedCategory,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setSubCategoryList(result.data);
            const isselected = result.data.find(
              (item) => item.status
            )?.subCatId;
            setSelectedSubCategory(isselected);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSubCategoryList([]);
    }
  }, [data?.id, token, selectedCategory, setSelectedSubCategory]);

  return (

      <div className="row align-items-end mb-4">
        <div className="col-md-3">
          <div className="">
            <label className="form-label">Category</label>
            <select
              className="form-select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categoryList?.map((item, index) => (
                <option key={index} value={item.catId}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="">
            <label className="form-label">Category</label>
            <select
              className="form-select"
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {subcategoryList?.map((item, index) => (
                <option key={index} value={item.subCatId}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-4 d-flex ">
          <button className="btn btn-primary px-4 mt-3 btn-45">Search</button>
        </div>
      </div>

  );
};
