import React, { useEffect, useLayoutEffect, useState } from "react";
import logo from "../../assets/images/logo-dark-esg.png";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { useAuth } from "../../context/authProvider";

function Login() {
  const navigate = useNavigate();
  const { showAlert } = useAuth();
  const [isMasked, setIsMasked] = useState(true);
  const [formData, setFormData] = useState({ username: "", password: "" });

  const {
    userStore: { loginUser, error, isAdminLoggedIn, setError, reset },
    employeeStore,
    wasteManagementStore,
    dashboardStore,
    companyStore,
    waterStore,
  } = useStore();
  const handleLogin = (e) => {
    e.preventDefault();
    loginUser?.(
      {
        email: formData.username,
        password: formData.password,
      },
      true
    );
  };

  useLayoutEffect(() => {
    reset?.();
    localStorage.clear();
  }, [reset]);

  useEffect(() => {
    if (error) showAlert(error);
    return () => {
      setError(null);
    };
  }, [error, setError, showAlert]);

  const handleOnChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const maskHandler = () => setIsMasked((prev) => !prev);

  useEffect(() => {
    if (isAdminLoggedIn) {
      navigate("/admin/dashboard");
    }
  }, [isAdminLoggedIn, navigate]);

  useEffect(
    () => () => {
      employeeStore?.reset();
      wasteManagementStore?.reset();
      waterStore?.reset();
      companyStore?.reset();
      dashboardStore?.reset();
    },
    [
      companyStore,
      dashboardStore,
      employeeStore,
      wasteManagementStore,
      waterStore,
    ]
  );

  return (
    <div className="account-pages">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card">

              <div className="card-body">
                <div className="p-2">
                  <div className="auth-logo">
                    <img src={logo} alt="" className="rounded-circle" />
                  </div>
                  <form className="form-horizontal" action="">
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter username"
                        value={formData.username}
                        onChange={handleOnChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <div className="input-group auth-pass-inputgroup">
                        <input
                          type={isMasked ? "password" : "text"}
                          id="password"
                          className="form-control"
                          placeholder="Enter password"
                          aria-label="Password"
                          aria-describedby="password-addon"
                          password={formData.password}
                          onChange={handleOnChange}
                        />
                        <button
                          className="btn btn-light "
                          type="button"
                          id="password-addon"
                          onClick={maskHandler}
                        >
                          <i className="mdi mdi-eye-outline"></i>
                        </button>
                      </div>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-check"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="remember-check"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3 d-grid" onClick={handleLogin}>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1"></i> Forgot your
                        password?
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <div>
                <p>
                  © 2024 ESG. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Login);
