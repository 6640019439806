import React from "react";
import "./Category.css";
import { formatDateForTable } from "../../utils/helpers";
import useStore from "../../hooks/useStore";

const SubCategoryRow = ({
  category,
  // handleDeleteCategory,
  handleEditCategory,
  index,
}) => {
  const {
    dashboardStore: { categoryList },
  } = useStore();
  // const [showActionPopup, setShowActionPopup] = useState(false);

  // const handleDelete = () => {
  //   // setShowActionPopup(false);
  //   handleDeleteCategory();
  // };

  const handleEdit = () => {
    // setShowActionPopup(false);
    handleEditCategory();
  };

  return (
    <tr className="odd">
      <td>{index}</td>
      <td>{categoryList?.find((val) => val.id === category.catId)?.name}</td>
      <td>{category?.name}</td>

      <td>{category?.slug}</td>
      <td>{category?.position}</td>
      <td>{formatDateForTable(category?.createdAt)}</td>
      <td>
        <div className="status">
          <span
            className={`status-badge ${
              category?.status ? "active" : "inactive"
            }`}
          />
          <span>{category?.status}</span>
        </div>
      </td>
      <td>
        <div className="d-flex ">
          <button
            className="btn btn-primary btn-sm btn-action waves-effect waves-light"
            href="#newCustomerModal"
            data-bs-toggle="modal"
            onClick={handleEdit}
            data-edit-id="12"
          >
            <i className="mdi mdi-pencil font-size-14 "></i> Edit
          </button>
          {/* <button
            className="btn btn-outlined p-0"
            href="#removeItemModal"
            data-bs-toggle="modal"
            onClick={handleDelete}
            data-remove-id="12"
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger"></i>{" "}
            Delete
          </button> */}
        </div>
      </td>
    </tr>
  );
};

export default SubCategoryRow;
