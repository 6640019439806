import AuthProvider from "./authProvider";
import StoreProvider from "./storeProvider";

export default function ContextWrapper({ children }) {
  return (
    <StoreProvider>
      <AuthProvider>{children}</AuthProvider>
    </StoreProvider>
  );
}
