import Dropdown from "./dropdown";
import { MONTHS_ARRAY_FULLNAME, WATER_UNITS_1 } from "../constants/constants";
import { Form } from "react-bootstrap";
import useStore from "../hooks/useStore";
import { filterArray } from "../utils/helpers";

const FormDropdown = ({ setFrequency, frequency, isDisabled }) => {
  const frequencyTypeList = ["Monthly", "Daily"];

  const yearList = Array.from({ length: 5 }).map(
    (_, index) => new Date().getFullYear() - index
  );

  const onChangeHandler = (event) => {
    setFrequency((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div className="row mb-2">
      <div className="col-md-4">
        <Dropdown
          label="Frequency Type"
          options={frequencyTypeList}
          disabled={isDisabled}
          selectedValue={frequency.frequencyType}
          onChange={(e) =>
            setFrequency((prev) => ({
              ...prev,
              frequencyType: e.target.value,
              frequencyMonth: "",
              frequencyYear: "",
              frequencyDate: "",
            }))
          }
          name="frequencyType"
        />
      </div>
      {frequency.frequencyType === "Daily" && (
        <div className=" frequency-date-input ">
          <div className="mb-3">
            <label htmlFor="toDate" className="form-label">
              Frequency Date *
            </label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                id="toDate"
                required
                disabled={isDisabled}
                placeholder="Select date"
                name="frequencyDate"
                value={frequency.frequencyDate}
                onChange={onChangeHandler}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
        </div>
      )}
      {frequency.frequencyType !== "Daily" && (
        <>
          <div className="col-md-4">
            <Dropdown
              disabled={isDisabled}
              label="Year"
              options={yearList}
              name="frequencyYear"
              selectedValue={frequency.frequencyYear}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-4">
            <Dropdown
              disabled={isDisabled}
              label="Month"
              name="frequencyMonth"
              options={MONTHS_ARRAY_FULLNAME.filter((mon, index) =>
                frequency.frequencyYear == new Date().getFullYear()
                  ? new Date().getMonth() >= index
                  : true
              )}
              selectedValue={frequency.frequencyMonth}
              onChange={onChangeHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormDropdown;
