import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/authProvider";
import useStore from "../../../../hooks/useStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";

const AssignKPI = ({ setStep }) => {
  const { showAlert } = useAuth();
  const location = useLocation();
  const companyData = location.state;
  const emoloyeeData = location.state;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [renderList, setRenderList] = useState([]);
  const {
    companyStore: { companyDataJson },
    employeeStore: { fetchEmployeeData,assignKpi, employeeDetailsRaw },
  } = useStore();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchEmployeeData({ signal, employeeId: emoloyeeData?.id });
    return () => {
      abortController.abort();
    };
  }, [emoloyeeData?.id, fetchEmployeeData]);
  const assignedKpis = useMemo(
    () =>
      toJS(employeeDetailsRaw)
        ?.assignKpi?.filter(
          ({ catId, subCatId }) =>
            catId === selectedCategory && subCatId === selectedSubCategory
        )
        .map(({ kpiId }) => kpiId),
    [employeeDetailsRaw, selectedCategory, selectedSubCategory]
  );

  const kpiList = useMemo(
    () =>
      companyDataJson?.assignKpi
        ?.map((ele) => ({
          id: ele.kpiId,
          catId: ele.catId,
          subCatId: ele.subCatId,
          name: ele.kpiIdAssignKpi.name,
          isEdited: toJS(employeeDetailsRaw)?.assignKpi?.find(
            (el) => ele.kpiId === el.kpiId
          )?.isEdited,
          isApproved: toJS(employeeDetailsRaw)?.assignKpi?.find(
            (el) => ele.kpiId === el.kpiId
          )?.isApproved,
          status: assignedKpis?.includes(ele.kpiId),
        }))
        .filter(
          (kpi) =>
            kpi.subCatId === selectedSubCategory &&
            kpi.catId === selectedCategory
        ),
    [
      assignedKpis,
      companyDataJson?.assignKpi,
      employeeDetailsRaw,
      selectedCategory,
      selectedSubCategory,
    ]
  );
  useEffect(() => {
    setRenderList(kpiList);
  }, [kpiList]);

  const handleAssignKpi = () => {
    const tempKpis = renderList.map((kpi) => ({
      kpiId: kpi.id,
      status: kpi.status,
      isEdited: kpi.isEdited || false,
      isApproved: kpi.isApproved || false,
    }));
    const body = JSON.stringify({
      empId: companyData?.id,
      catId: selectedCategory,
      subCatId: selectedSubCategory,
      kpis: tempKpis,
    });
    assignKpi({ body, showAlert, setStep, employeeId: companyData?.id });
  };
  // console.log('employeeDetailsRaw',toJS(employeeDetailsRaw)?.companiesData[0]?.department);
  return (
    <div className="mt-10">
      <AssignDropdownRow
        companyDataJson={companyDataJson}
        setSelectedCategory={setSelectedCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCategory}
      />
      <div className="table-responsive">
        <table className="table align-middle table-bordered table-nowrap mb-0">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>KPI Name</th>
              <th>
                <input
                  style={{ marginRight: "5px", verticalAlign: "middle" }}
                  type="checkbox"
                  checked={
                    selectedSubCategory
                      ? renderList?.every((e) => e.status)
                      : false
                  }
                  onChange={(event) =>
                    setRenderList((ele) =>
                      ele?.map((e) => ({
                        ...e,
                        status: event.target.checked,
                        // ...(event.target.checked
                        //   ? {
                        //       isApproved: false,
                        //       isEdited: false,
                        //     }
                        //   : {}),
                      }))
                    )
                  }
                />
                Assign
              </th>
              {toJS(employeeDetailsRaw)?.companiesData[0]?.department !==
                "User" && (
                <React.Fragment>
                  <th>
                    <input
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                      type="checkbox"
                      onChange={(e) =>
                        setRenderList((prev) =>
                          prev?.map((ele) => ({
                            ...ele,
                            isEdited: e.target.checked || ele.isApproved,
                          }))
                        )
                      }
                      checked={
                        selectedSubCategory
                          ? renderList?.every((e) => e.isEdited)
                          : false
                      }
                    />
                    Editor
                  </th>
                  <th>
                    <input
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                      type="checkbox"
                      onChange={(event) =>
                        setRenderList((prev) =>
                          prev?.map((ele) => ({
                            ...ele,
                            isEdited: event.target.checked || ele.isApproved,
                            isApproved: event.target.checked,
                          }))
                        )
                      }
                      checked={
                        selectedSubCategory
                          ? renderList?.every((e) => e.isApproved) &&
                            renderList?.every((e) => e.isEdited)
                          : false
                      }
                    />
                    Approver
                  </th>
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {renderList?.map((item, index) => (
              <tr key={item.id || index}>
                {" "}
                {/* Ensure unique key here */}
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td>
                  <div className="form-check font-size-20 p-0">
                    <input
                      className="form-check-input m-0 float-none"
                      type="checkbox"
                      checked={item.status}
                      id={`assign-${item.id}`}
                      onChange={(ev) =>
                        setRenderList((ele) =>
                          ele?.map((e) => {
                            if (e.id === item.id) {
                              return {
                                ...e,
                                status: ev.target.checked,
                                ...(!ev.target.checked
                                  ? {
                                      isApproved: false,
                                      isEdited: false,
                                    }
                                  : {}),
                              };
                            }
                            return e;
                          })
                        )
                      }
                    />
                  </div>
                </td>
                {toJS(employeeDetailsRaw)?.companiesData[0]?.department !==
                  "User" && (
                  <React.Fragment>
                    <td>
                      <div className="form-check font-size-20 p-0">
                        <input
                          className="form-check-input m-0 float-none"
                          type="checkbox"
                          checked={item.isEdited}
                          id={`edit-${item.id}`}
                          onChange={(e) =>
                            setRenderList((prev) =>
                              prev?.map((ele) => {
                                if (ele.id === item.id) {
                                  return {
                                    ...ele,
                                    // isEdited: e.target.checked,
                                    isEdited: e.target.checked || ele.isApproved,
                                  };
                                }
                                return ele;
                              })
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check font-size-20 p-0">
                        <input
                          className="form-check-input m-0 float-none"
                          type="checkbox"
                          checked={item.isApproved}
                          id={`approve-${item.id}`}
                          onChange={(e) =>
                            setRenderList((prev) =>
                              prev?.map((ele) => {
                                if (ele.id === item.id) {
                                  return {
                                    ...ele,
                                    ...(e.target.checked
                                      ? {
                                          isEdited: e.target.checked,
                                          isApproved: e.target.checked,
                                        }
                                      : {
                                          isApproved: false,
                                        }),
                                  };
                                }
                                return ele;
                              })
                            )
                          }
                        />
                      </div>
                    </td>
                  </React.Fragment>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <button className="btn btn-primary px-4" onClick={handleAssignKpi}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(AssignKPI);
const AssignDropdownRow = ({
  companyDataJson,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const {
    employeeStore: { employeeDetails },
  } = useStore();
  const allcategories = employeeDetails?.assignCategory?.map((ele) => ({
    id: ele.catId,
    name: ele?.catIdAssignCategory?.name,
  }));

  const allSubCategories = employeeDetails?.assignSubCategory?.map((ele) => ({
    id: ele.subCatId,
    catId: ele.catId,
    name: ele.subCatIdAssignSubCategory.name,
  }));
  return (
    <div className="row align-items-center">
      <div className="col-md-3">
        <div className="mb-3">
          <label className="form-label">Category</label>
          <select
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {allcategories?.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md-3">
        <div className="mb-3">
          <label className="form-label">Sub Category</label>
          <select
            className="form-select"
            value={selectedSubCategory}
            onChange={(e) => setSelectedSubCategory(e.target.value)}
          >
            <option value="">Select Sub Category</option>
            {allSubCategories
              ?.filter((sub) => selectedCategory === sub.catId)
              ?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};
