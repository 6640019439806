import { observer } from "mobx-react";
import useStore from "../hooks/useStore";
import { filterArray } from "../utils/helpers";

function GlobalUnitDropdown({
  setData,
  filterUnit,
  global,
  setGlobal,
  disabled,
  width = "col-md-4 mb-4",
}) {
  const {
    companyStore: { units },
  } = useStore();
  const data = filterArray(units, filterUnit);
  return (
    <div className={width}>
      <label className="form-label">Unit</label>
      <select
        value={global}
        disabled={disabled}
        className="form-select"
        onChange={(event) => {
          setData((prev) =>
            prev.map((ele) => ({
              ...ele,
              value: ele.value.map((e) => ({ ...e, unit: event.target.value })),
            }))
          );
          setGlobal?.(event.target.value);
        }}
        required
      >
        <option value="">Select Unit</option>
        {data.map((unit) => (
          <option value={unit.id}>{unit.shortName}</option>
        ))}
      </select>
    </div>
  );
}

export default observer(GlobalUnitDropdown);
