import { useMemo } from "react";
import useStore from "../../hooks/useStore";
import { STATIC_GROUPS, REVENUE_LABELS } from "../../constants/constants";
import BarChart from "../charts/barChart";
import { observer } from "mobx-react";
import ColumnChart from "../charts/columnChart";

function AqiDashboardAccordion({ year }) {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { revenuePageData },
  } = useStore();
  const barChartsGroupData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi;
    const result = ["A1", "B1", "C"].map((group) => {
      const data = [year - 1, year].map((year) => {
        const temp = tempData?.[year]?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        if (group === "C") {
          return temp?.["C1"]?.prevValue || 0 + temp?.["C2"]?.prevValue || 0;
        }
        return temp?.[group]?.prevValue || 0;
      });
      
      return {
        label : STATIC_GROUPS.aqi[group] ,
        data,
      };
    
    });

    const totalGhgEmission = {
      label: "GHG Emission",
      data: [
        result.reduce((acc, curr) => acc + curr.data[0], 0),
        result.reduce((acc, curr) => acc + curr.data[1], 0),
      ],
    };
    result.push(totalGhgEmission);

    if (tempData?.Scope3) {
      result.push({...tempData.Scope3, label: "Scope 3 Emission"});
    }

    if (tempData?.groupGEmission) {
      Object.keys(tempData?.groupGEmission || {}).forEach((key) => {
        const data = tempData?.groupGEmission[key];
        const name = tempData?.groupGEmission[key].name || key;
        result.push({...data, label: name});
      });
    }
    

    return result;
  }, [dashboardDetails?.charts?.aqi, year]);

 // Prepare emission data
  const revenueData = useMemo(() => {
    const filteredData =
      revenuePageData?.fieldData?.filter(
        (e) => e?.type === "emission" && e?.title
      ) || [];

    const revenueUnit =
      revenuePageData?.revenue?.find((e) => e?.type === "all")?.unit
        ?.shortName || "";

    // Define the groups from REVENUE_LABELS for energy
    const groups = Object.keys(REVENUE_LABELS.emission);

    // Create a map of groups with zero values for missing years
    const years = [year - 1, year];
    const dataMap = groups.reduce((acc, group) => {
      acc[group] = years.reduce((yearAcc, y) => {
        yearAcc[y] = 0; // Default to zero for each year
        return yearAcc;
      }, {});
      return acc;
    }, {});

    // Populate dataMap with actual values
    filteredData.forEach((ele) => {
      const title = ele?.title;
      const value = ele?.value || 0;
      const matchingGroup = Object.keys(REVENUE_LABELS.emission).find(
        (group) => REVENUE_LABELS.emission[group] === title
      );

      if (matchingGroup) {
        years.forEach((y) => {
          if (ele?.year.toString() === y.toString()) {
            dataMap[matchingGroup][y] = value;
          }
        });
      }
    });

    // Transform dataMap into the format required for charts
    return groups.map((group) => ({
      name: REVENUE_LABELS.emission[group],
      unit: revenueUnit,
      data: years.map((y) => dataMap[group][y]),
    }));
  }, [revenuePageData?.fieldData, revenuePageData?.revenue, year]);

  return (
    <div className="row h-100  ">
      {barChartsGroupData.length !== 0 &&
        barChartsGroupData?.map?.(({ label, data }, index) => (
          <div key={index} className="col-xl-6  mb-3">
            <div className="card h-100  ">
              <div className="card-body">
                <div className="d-flex  justify-content-between ">
                  <h4 className="card-title mb-4">
                    {label}
                    {/* {index === 0 ? "(million)" : "(Kl)"} */}
                  </h4>

                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      {" "}
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6>{dashboardDetails?.charts?.aqi?.unit}</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <BarChart
                  series={[{ data, name: label }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.aqi?.unit}
                />
              </div>
            </div>
          </div>
        ))}
      {/* Render bar charts for revenue data */}
      {
        revenueData.map(({ name, data, unit }, index) => (
          <div key={`revenue-chart-${index}`} className="col-xl-6 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4 donut-heading">
                  {name}
                  </h4>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2">
                      <div>
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">{dashboardDetails?.charts?.aqi?.unit} / {unit} </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <BarChart
                  series={[{ data, name }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.aqi?.unit +"/"+ unit}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default observer(AqiDashboardAccordion);
