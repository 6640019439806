import React from "react";
import Chart from "react-apexcharts";

const DumbbellTimelineCharts = () => {
  const series = [
    {
      data: [
        { x: "Operations", y: [2800, 4500] },
        { x: "Customer Success", y: [3200, 4100] },
        { x: "Engineering", y: [2950, 7800] },
        { x: "Marketing", y: [3000, 4600] },
        { x: "Product", y: [3500, 4100] },
        { x: "Data Science", y: [4500, 6500] },
        { x: "Sales", y: [4100, 5600] },
      ],
    },
  ];

  const options = {
    chart: {
      height: 390,
      type: "rangeBar",
      zoom: { enabled: false },
      toolbar: {
        show: false,
      },
    },
    colors: ["#556EE6", "#35C38F"],
    plotOptions: {
      bar: {
        horizontal: true,
        isDumbbell: true,
        dumbbellColors: [["#556EE6", "#556EE6"]],
      },
    },
    title: {
      text: "Paygap Disparity",
      style: {
        color: "#B1B8C0",
        fontWeight: "Bold",
        fontSize: "15px",
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      customLegendItems: ["Female", "Male"],
    },
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#36BDCB"],
        inverseColors: false,
        stops: [0, 100],
      },
    },
    grid: {
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
  };

  return (
    <div>
      <div id="chart">
        <Chart options={options} series={series} type="rangeBar" height={390} />
      </div>
    </div>
  );
};

export default DumbbellTimelineCharts;
