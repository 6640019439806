const addGroupData = (data) =>
  data?.reduce(
    (acc, curr) => ({
      target: acc.target + (Number(curr?.target) || 0),
      prevValue: acc.prevValue + (Number(curr?.prevValue) || 0),
    }),
    {
      target: 0,
      prevValue: 0,
    }
  );

const calculateWaterData = (data) => {
  // total water withdrawl
  const w = addGroupData(data?.find((ele) => ele.group === "A")?.value);
  // total water discharge
  // const d = addGroupData(data?.find((ele) => ele.group === "B")?.value);
  // const c = addGroupData(data?.find((ele) => ele.group === "C")?.value);

  const c = addGroupData(
    data
      ?.find((ele) => ele.group === "C")
      ?.value.filter(
        (e) =>
          ["Total Fresh Water Consumption : Plant", "Recycled Water consumption:"].includes(
            e.fieldName
          )
      )
  );
  
  // revenue
  const r = data
    ?.find((ele) => ele.group === "D")
    ?.value.find((e) => e.fieldName === "Revenue/per year");
  // finished goods
  const g = data
    ?.find((ele) => ele.group === "D")
    ?.value.find((e) => e.fieldName === "Finished Goods Quantity Produced");
  const result = data?.map((ele) => {
    if (ele.group === "F") {
      const temp = ele?.value?.map((e) => {
        if (
          e.fieldName ===
          "Percentage of water recycle/reuse: (Total water quantity recycled and reused /  Water withdrawal by source)*100"
        ) {
          const n = ele?.value?.find(
            (f) => f.fieldName === "Total water quantity recycled and reused"
          );
          const val = {
            target: (Number(n?.target) / (w?.target || 1)) * 100,
            prevValue: (Number(n?.prevValue) / (w?.prevValue || 1)) * 100,
          };
          return { ...e, ...val };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    if (ele.group === "E") {
      const temp = ele.value.map((e) => {
        if (e.fieldName === "( Total Water consumption / Revenue )") {
          const prevValue = Number(c?.prevValue) / (Number(r?.prevValue) || 1);
          const target = Number(c?.target) / (Number(r?.prevValue) || 1);
          return {
            ...e,
            prevValue: prevValue.toFixed(3) || 0,
            target: target.toFixed(3) || 0,
          };
        }
        if (
          e.fieldName ===
          "( Total Water consumption / Finished Goods Quantity Produced )"
        ) {
          const prevValue = Number(c?.prevValue) / (Number(g?.prevValue) || 1);
          const target = Number(c?.target) / (Number(g?.prevValue) || 1);
          return {
            ...e,
            prevValue: prevValue.toFixed(3) || 0,
            target: target.toFixed(3) || 0,
          };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    return ele;
  });
  return result;
};

const calculateAqiData = (data) => {
  const revenueData = data
    .find((e) => e.group === "A")
    .value.find((e) => e.fieldName === "Revenue");
  const finishedGoodsData = data
    .find((e) => e.group === "A")
    .value.find((e) => e.fieldName === "Finished goods quantity produced");

  const prevRevenue = revenueData?.prevValue || 1;
  const targetRevenue = revenueData?.target || 1;
  const prevFinishedGoods = finishedGoodsData?.prevValue || 1;
  const targetFinishedGoods = finishedGoodsData?.target || 1;

  const roundToThree = (num) => parseFloat(num.toFixed(3));

  const result = data.map((ele) => {
    if (ele.group === "A") {
      const temp = ele.value.map((e) => {
        if (e.fieldName === "Scope-1 Emission Intensity/Revenue") {
          const t = addGroupData(ele.value.filter((e) => e.group === "A1"));
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevRevenue),
            target: roundToThree(t.target / targetRevenue),
          };
        }
        if (
          e.fieldName.includes(
            "Scope-1 Emission Intensity/Finished goods quantity produced"
          )
        ) {
          const t = addGroupData(ele.value.filter((e) => e.group === "A1"));
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevFinishedGoods),
            target: roundToThree(t.target / targetFinishedGoods),
          };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    if (ele.group === "B") {
      const temp = ele.value.map((e) => {
        if (e.fieldName === "Scope-2 Emission Intensity/Revenue") {
          const t = addGroupData(ele.value.filter((e) => e.group === "B1"));
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevRevenue),
            target: roundToThree(t.target / targetRevenue),
          };
        }
        if (
          e.fieldName.includes(
            "Scope-2 Emission Intensity/Finished goods quantity produced"
          )
        ) {
          const t = addGroupData(ele.value.filter((e) => e.group === "B1"));
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevFinishedGoods),
            target: roundToThree(t.target / targetFinishedGoods),
          };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    if (ele.group === "C") {
      const temp = ele.value.map((e) => {
        if (e.fieldName === "Scope-3 Emission Intensity/Revenue") {
          const t = addGroupData(
            ele.value.filter((e) => e.group === "C1" || e.group === "C2")
          );
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevRevenue),
            target: roundToThree(t.target / targetRevenue),
          };
        }
        if (
          e.fieldName.includes(
            "Scope-3 Emission Intensity/Finished goods quantity produced"
          )
        ) {
          const t = addGroupData(
            ele.value.filter((e) => e.group === "C1" || e.group === "C2")
          );
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevFinishedGoods),
            target: roundToThree(t.target / targetFinishedGoods),
          };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    if (ele.group === "D") {
      const temp = ele.value.map((e) => {
        if (e.fieldName === "Scope-1") {
          const t = addGroupData(
            data
              .find((e) => e.group === "A")
              .value.filter((e) => e.group === "A1")
          );
          return { ...e, ...t };
        }
        if (e.fieldName === "Scope-2") {
          const t = addGroupData(
            data
              .find((e) => e.group === "B")
              .value.filter((e) => e.group === "B1")
          );
          return { ...e, ...t };
        }
        if (e.fieldName === "Scope-3") {
          const t = addGroupData(
            data
              .find((e) => e.group === "C")
              .value.filter((e) => e.group === "C1" || e.group === "C2")
          );
          return { ...e, ...t };
        }
        return e;
      });

      const tempData = temp.map((e) => {
        if (e.fieldName === "GHG Emission Intensity/Revenue") {
          const t = addGroupData(
            temp.filter((f) => f.fieldName.includes("Scope-"))
          );
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevRevenue),
            target: roundToThree(t.target / targetRevenue),
          };
        }
        if (
          e.fieldName.includes(
            "GHG Emission Intensity/Finished goods quantity produced"
          )
        ) {
          const t = addGroupData(
            temp.filter((f) => f.fieldName.includes("Scope-"))
          );
          return {
            ...e,
            prevValue: roundToThree(t.prevValue / prevFinishedGoods),
            target: roundToThree(t.target / targetFinishedGoods),
          };
        }
        return e;
      });

      return { ...ele, value: tempData };
    }
    return ele;
  });

  return result;
};

function calculateEnergyData(data) {
  const revenue =
    data
      ?.find((e) => e.group === "A")
      ?.value?.find((e) => e.fieldName === "Revenue") || {};
  const goodsProcessed =
    data
      ?.find((e) => e.group === "C")
      ?.value?.find(
        (e) => e.fieldName === "Finished goods quantity produced"
      ) || {};

  const roundToThree = (num) => parseFloat((num || 0).toFixed(3));

  const result = data.map((ele) => {
    if (ele.group === "A") {
      const temp = ele.value.map((e) => {
        if (
          e.fieldName.includes(
            "Total energy consumption within the organization"
          )
        ) {
          const r = addGroupData(
            ele.value.filter(
              (f) =>
                [
                  "Total fuel consumption within the organization from non-renewable sources",
                  "Total fuel consumption within the organization from Renewable sources,",
                ].includes(f.fieldName) || f.group === "A1"
            )
          );
          const s = addGroupData(ele.value.filter((f) => f.group === "A2"));
          return {
            ...e,
            target: roundToThree(r?.target - s?.target),
            prevValue: roundToThree(r?.prevValue - s?.prevValue),
          };
        }
        if (
          e.fieldName ===
          "Intensity of Energy Consumption Within the Organisation/Revenue"
        ) {
          const r = addGroupData(
            ele.value.filter(
              (f) =>
                [
                  "Total fuel consumption within the organization from non-renewable sources",
                  "Total fuel consumption within the organization from Renewable sources,",
                ].includes(f.fieldName) || f.group === "A1"
            )
          );
          const s = addGroupData(ele.value.filter((f) => f.group === "A2"));
          const n = {
            ...e,
            target: roundToThree(r?.target - s?.target),
            prevValue: roundToThree(r?.prevValue - s?.prevValue),
          };
          const d = ele.value.find((e) => e.fieldName === "Revenue");
          return {
            ...e,
            prevValue: roundToThree(
              Number(n.prevValue) / (Number(d?.prevValue) || 1)
            ),
            target: roundToThree(Number(n.target) / (Number(d?.target) || 1)),
          };
        }
        if (
          e.fieldName.includes(
            "Intensity of Energy Consumption Within the Organisation/Finished goods quantity produced"
          )
        ) {
          const r = addGroupData(
            ele.value.filter(
              (f) =>
                [
                  "Total fuel consumption within the organization from non-renewable sources",
                  "Total fuel consumption within the organization from Renewable sources,",
                ].includes(f.fieldName) || f.group === "A1"
            )
          );
          const s = addGroupData(ele.value.filter((f) => f.group === "A2"));
          const n = {
            ...e,
            target: roundToThree(r?.target - s?.target),
            prevValue: roundToThree(r?.prevValue - s?.prevValue),
          };
          const g = ele.value.find(
            (e) => e.fieldName === "Finished goods quantity produced"
          );
          return {
            ...e,
            prevValue: roundToThree(
              Number(n.prevValue) / (Number(g?.prevValue) || 1)
            ),
            target: roundToThree(Number(n.target) / (Number(g?.target) || 1)),
          };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    if (ele.group === "B") {
      const temp = ele.value.map((e) => {
        if (
          e.fieldName === "Total Energy Consumption outside of the Organisation"
        ) {
          const a = addGroupData(
            ele.value.filter((f) => f.group === "B1" || f.group === "B2")
          );
          return { ...e, ...a };
        }
        if (
          e.fieldName ===
          "Intensity of Energy Consumption outside of the organisation/Revenue"
        ) {
          const a = addGroupData(
            ele.value.filter((f) => f.group === "B1" || f.group === "B2")
          );

          return {
            ...e,
            prevValue: roundToThree(
              Number(a.prevValue) / (Number(revenue?.prevValue) || 1)
            ),
            target: roundToThree(
              Number(a.target) / (Number(revenue?.target) || 1)
            ),
          };
        }
        if (
          e.fieldName ===
          "Intensity of Energy Consumption outside of the organisation/Finished goods quantity produced"
        ) {
          const a = addGroupData(
            ele.value.filter((f) => f.group === "B1" || f.group === "B2")
          );
          return {
            ...e,
            prevValue: roundToThree(
              Number(a.prevValue) / (Number(goodsProcessed?.prevValue) || 1)
            ),
            target: roundToThree(
              Number(a.target) / (Number(goodsProcessed?.target) || 1)
            ),
          };
        }

        return e;
      });
      return { ...ele, value: temp };
    }
    if (ele.group === "C") {
      const temp = ele.value.map((e) => {
        if (e.fieldName === "Total Energy Consumption") {
          const a = addGroupData(
            ele.value.filter((f) => f.group === "C1" || f.group === "C2")
          );
          return { ...e, ...a };
        }
        if (
          e.fieldName.includes("Intensity of Total Energy Consumption/Revenue")
        ) {
          const a = addGroupData(
            ele.value.filter((f) => f.group === "C1" || f.group === "C2")
          );
          return {
            ...e,
            prevValue: roundToThree(
              Number(a.prevValue) / (Number(revenue?.prevValue) || 1)
            ),
            target: roundToThree(
              Number(a.target) / (Number(revenue?.target) || 1)
            ),
          };
        }
        if (
          e.fieldName.includes(
            "Intensity of Total Energy Consumption/Finished goods quantity produced"
          )
        ) {
          const a = addGroupData(
            ele.value.filter((f) => f.group === "C1" || f.group === "C2")
          );
          return {
            ...e,
            prevValue: roundToThree(
              Number(a.prevValue) / (Number(goodsProcessed?.prevValue) || 1)
            ),
            target: roundToThree(
              Number(a.target) / (Number(goodsProcessed?.target) || 1)
            ),
          };
        }
        return e;
      });
      return { ...ele, value: temp };
    }
    return ele;
  });
  return result;
}

const filterData = (data, key, value) =>
  data?.filter((e) => e?.[key] === value);

const environmentCalculationHandler = (type) => {
  switch (type) {
    case "water":
      return (data) => {
        // total water withdrawal
        const w = addGroupData(data?.filter((ele) => ele.group === "A"));
        // total water discharge
        // const d = addGroupData(data?.filter((ele) => ele.group === "B"));
        // total water consumption
        const c = addGroupData(data?.filter((ele) => ele.group === "C"));
        // revenue
        const r = data?.find((e) => e.fieldName === "Revenue/per year");
        // finished goods
        const g = data?.find(
          (e) => e.fieldName === "Finished Goods Quantity Produced"
        );
        const result = data?.map((ele) => {
          if (
            ele.fieldName ===
            "Percentage of water recycle/reuse: (Total water quantity recycled and reused /  Water withdrawal by source)*100"
          ) {
            const n = data?.find(
              (f) => f.fieldName === "Total water quantity recycled and reused"
            );
            const val = {
              target: (Number(n?.target) / (w?.target || 1)) * 100,
              prevValue: (Number(n?.prevValue) / (w?.prevValue || 1)) * 100,
            };
            return { ...ele, ...val };
          }
          if (ele.fieldName === "( Total Water consumption / Revenue )") {
            const prevValue =
              Number(c?.prevValue) / (Number(r?.prevValue) || 1);
            const target = Number(c?.target) / (Number(r?.prevValue) || 1);
            return {
              ...ele,
              prevValue: prevValue.toFixed(3) || 0,
              target: target.toFixed(3) || 0,
            };
          }
          if (
            ele.fieldName ===
            "( Total Water consumption / Finished Goods Quantity Produced )"
          ) {
            const prevValue =
              Number(c?.prevValue) / (Number(g?.prevValue) || 1);
            const target = Number(c?.target) / (Number(g?.prevValue) || 1);
            return {
              ...ele,
              prevValue: prevValue.toFixed(3) || 0,
              target: target.toFixed(3) || 0,
            };
          }
          return ele;
        });
        return result;
      };
    case "biodiversity":
      return (data) => data;

    case "aqi":
    case "emission":
      return (data) => {
        const revenueData = data?.find((e) => e.fieldName === "Revenue");
        const finishedGoodsData = data?.find(
          (e) => e.fieldName === "Finished goods quantity produced"
        );

        const prevRevenue = revenueData?.prevValue || 1;
        const targetRevenue = revenueData?.target || 1;
        const prevFinishedGoods = finishedGoodsData?.prevValue || 1;
        const targetFinishedGoods = finishedGoodsData?.target || 1;

        const roundToThree = (num) => parseFloat(num.toFixed(3));

        const result = data.map((ele) => {
          if (ele.fieldName === "Scope-1 Emission Intensity/Revenue") {
            const t = addGroupData(data?.filter((e) => e.group === "A1"));
            return {
              ...ele,
              prevValue: roundToThree(t.prevValue / prevRevenue),
              target: roundToThree(t.target / targetRevenue),
            };
          }
          if (
            ele.fieldName.includes(
              "Scope-1 Emission Intensity/Finished goods quantity produced"
            )
          ) {
            const t = addGroupData(data?.filter((e) => e.group === "A1"));
            return {
              ...ele,
              prevValue: roundToThree(t.prevValue / prevFinishedGoods),
              target: roundToThree(t.target / targetFinishedGoods),
            };
          }
          if (ele.fieldName === "Scope-2 Emission Intensity/Revenue") {
            const t = addGroupData(data?.filter((e) => e.group === "B1"));
            return {
              ...ele,
              prevValue: roundToThree(t.prevValue / prevRevenue),
              target: roundToThree(t.target / targetRevenue),
            };
          }
          if (
            ele.fieldName.includes(
              "Scope-2 Emission Intensity/Finished goods quantity produced"
            )
          ) {
            const t = addGroupData(data?.filter((e) => e.group === "B1"));
            return {
              ...ele,
              prevValue: roundToThree(t.prevValue / prevFinishedGoods),
              target: roundToThree(t.target / targetFinishedGoods),
            };
          }
          if (ele.fieldName === "Scope-3 Emission Intensity/Revenue") {
            const t = addGroupData(
              data?.filter((e) => e.group === "C1" || e.group === "C2")
            );
            return {
              ...ele,
              prevValue: roundToThree(t.prevValue / prevRevenue),
              target: roundToThree(t.target / targetRevenue),
            };
          }
          if (
            ele.fieldName.includes(
              "Scope-3 Emission Intensity/Finished goods quantity produced"
            )
          ) {
            const t = addGroupData(
              data?.filter((e) => e.group === "C1" || e.group === "C2")
            );
            return {
              ...ele,
              prevValue: roundToThree(t.prevValue / prevFinishedGoods),
              target: roundToThree(t.target / targetFinishedGoods),
            };
          }
          if (ele.fieldName === "Scope-1") {
            const t = addGroupData(data?.filter((e) => e.group === "A1"));
            return { ...ele, ...t };
          }
          if (ele.fieldName === "Scope-2") {
            const t = addGroupData(data?.filter((e) => e.group === "B1"));
            return { ...ele, ...t };
          }
          if (ele.fieldName === "Scope-3") {
            const t = addGroupData(
              data?.filter((e) => e.group === "C1" || e.group === "C2")
            );
            return { ...ele, ...t };
          }
          return ele;
        });
        return result.map((e) => {
          if (e.fieldName === "GHG Emission Intensity/Revenue") {
            const t = addGroupData(
              result?.filter((f) => f.fieldName.includes("Scope-"))
            );
            return {
              ...e,
              prevValue: roundToThree(t.prevValue / prevRevenue),
              target: roundToThree(t.target / targetRevenue),
            };
          }
          if (
            e.fieldName.includes(
              "GHG Emission Intensity/Finished goods quantity produced"
            )
          ) {
            const t = addGroupData(
              result?.filter((f) => f.fieldName.includes("Scope-"))
            );
            return {
              ...e,
              prevValue: roundToThree(t.prevValue / prevFinishedGoods),
              target: roundToThree(t.target / targetFinishedGoods),
            };
          }
          return e;
        });
      };
    case "energy":
      return (data) => {
        const revenue = data?.find((e) => e.fieldName === "Revenue") || {};
        const goodsProcessed =
          data?.find(
            (e) => e.fieldName === "Finished goods quantity produced"
          ) || {};

        const roundToThree = (num) => parseFloat((num || 0).toFixed(3));

        const result = data.map((ele) => {
          if (
            ele.fieldName.includes(
              "Total energy consumption within the organization"
            )
          ) {
            const r = addGroupData(
              data?.filter(
                (f) =>
                  [
                    "Total fuel consumption within the organization from non-renewable sources",
                    "Total fuel consumption within the organization from Renewable sources,",
                  ].includes(f.fieldName) || f.group === "A1"
              )
            );
            const s = addGroupData(data.filter((f) => f.group === "A2"));
            return {
              ...ele,
              target: roundToThree(r?.target - s?.target),
              prevValue: roundToThree(r?.prevValue - s?.prevValue),
            };
          }
          if (
            ele.fieldName ===
            "Intensity of Energy Consumption Within the Organisation/Revenue"
          ) {
            const r = addGroupData(
              data?.filter(
                (f) =>
                  [
                    "Total fuel consumption within the organization from non-renewable sources",
                    "Total fuel consumption within the organization from Renewable sources,",
                  ].includes(f.fieldName) || f.group === "A1"
              )
            );
            const s = addGroupData(data?.filter((f) => f.group === "A2"));
            const n = {
              ...ele,
              target: roundToThree(r?.target - s?.target),
              prevValue: roundToThree(r?.prevValue - s?.prevValue),
            };
            const d = data?.find((e) => e.fieldName === "Revenue");
            return {
              ...ele,
              prevValue: roundToThree(
                Number(n.prevValue) / (Number(d?.prevValue) || 1)
              ),
              target: roundToThree(Number(n.target) / (Number(d?.target) || 1)),
            };
          }
          if (
            ele.fieldName.includes(
              "Intensity of Energy Consumption Within the Organisation/Finished goods quantity produced"
            )
          ) {
            const r = addGroupData(
              data?.filter(
                (f) =>
                  [
                    "Total fuel consumption within the organization from non-renewable sources",
                    "Total fuel consumption within the organization from Renewable sources,",
                  ].includes(f.fieldName) || f.group === "A1"
              )
            );
            const s = addGroupData(data?.filter((f) => f.group === "A2"));
            const n = {
              ...ele,
              target: roundToThree(r?.target - s?.target),
              prevValue: roundToThree(r?.prevValue - s?.prevValue),
            };
            const g = data?.find(
              (e) => e.fieldName === "Finished goods quantity produced"
            );
            return {
              ...ele,
              prevValue: roundToThree(
                Number(n.prevValue) / (Number(g?.prevValue) || 1)
              ),
              target: roundToThree(Number(n.target) / (Number(g?.target) || 1)),
            };
          }
          if (
            ele.fieldName ===
            "Total Energy Consumption outside of the Organisation"
          ) {
            const a = addGroupData(
              data?.filter((f) => f.group === "B1" || f.group === "B2")
            );
            return { ...ele, ...a };
          }
          if (
            ele.fieldName ===
            "Intensity of Energy Consumption outside of the organisation/Revenue"
          ) {
            const a = addGroupData(
              data?.filter((f) => f.group === "B1" || f.group === "B2")
            );

            return {
              ...ele,
              prevValue: roundToThree(
                Number(a.prevValue) / (Number(revenue?.prevValue) || 1)
              ),
              target: roundToThree(
                Number(a.target) / (Number(revenue?.target) || 1)
              ),
            };
          }
          if (
            ele.fieldName ===
            "Intensity of Energy Consumption outside of the organisation/Finished goods quantity produced"
          ) {
            const a = addGroupData(
              data?.filter((f) => f.group === "B1" || f.group === "B2")
            );
            return {
              ...ele,
              prevValue: roundToThree(
                Number(a.prevValue) / (Number(goodsProcessed?.prevValue) || 1)
              ),
              target: roundToThree(
                Number(a.target) / (Number(goodsProcessed?.target) || 1)
              ),
            };
          }
          if (ele.fieldName === "Total Energy Consumption") {
            const a = addGroupData(
              data?.filter((f) => f.group === "C1" || f.group === "C2")
            );
            return { ...ele, ...a };
          }
          if (
            ele.fieldName.includes(
              "Intensity of Total Energy Consumption/Revenue"
            )
          ) {
            const a = addGroupData(
              data?.filter((f) => f.group === "C1" || f.group === "C2")
            );
            return {
              ...ele,
              prevValue: roundToThree(
                Number(a.prevValue) / (Number(revenue?.prevValue) || 1)
              ),
              target: roundToThree(
                Number(a.target) / (Number(revenue?.target) || 1)
              ),
            };
          }
          if (
            ele.fieldName.includes(
              "Intensity of Total Energy Consumption/Finished goods quantity produced"
            )
          ) {
            const a = addGroupData(
              data?.filter((f) => f.group === "C1" || f.group === "C2")
            );
            return {
              ...ele,
              prevValue: roundToThree(
                Number(a.prevValue) / (Number(goodsProcessed?.prevValue) || 1)
              ),
              target: roundToThree(
                Number(a.target) / (Number(goodsProcessed?.target) || 1)
              ),
            };
          }
          return ele;
        });
        return result;
      };
    case "waste":
      return (data) => data;
    default:
      return (data) => data;
  }
};

const governanceCalculations = (type) => {
  switch (type) {
    case "EconomicPerformance":
      return (data) => {
        const evd =
          data?.find(
            (item) =>
              item.fieldName ===
              "Economic value distributed: operating costs, employee wages and benefits, payments to providers of capital, payments to government by country, and community investments"
          )?.value || 1;
        const devg =
          data?.find(
            (item) =>
              item.fieldName === "Direct economic value generated: revenues"
          )?.value || 1;
        const result = data?.map((e) => {
          if (e.fieldName === "% of Economic Value Shared  Note: (ii/i)*100") {
            const percentage = (Number(evd) / Number(devg)) * 100;
            return { ...e, value: percentage.toFixed(3) };
          }
          return e;
        });
        return result;
      };

    default:
      return (data) => data;
  }
};

export {
  calculateWaterData,
  addGroupData,
  calculateAqiData,
  calculateEnergyData,
  filterData,
  environmentCalculationHandler,
  governanceCalculations,
};
