import React from "react";
import ReactApexChart from "react-apexcharts";

const RadialBar = ({ series, labels, unit, max, heading, position,download}) => {
  // Scale the series value relative to the max value
  const scaledSeries = (series / max) * 100; // Scale to percentage
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        dataLabels: {
          name: {
            show: false,
            offsetY: 20,
            fontSize: "14px",
          },
          value: {
            show: false,

            // Hide the value
          },
        },
      },
    },
    labels: labels || ["Intensity"],
    legend: {
      show: true,
      position: position || "bottom",
    },
  };

  return (
    <div>
      <div id="chart">
      <h5>{heading ? heading +" ("+ unit +")" : ""}</h5>
        <ReactApexChart
          options={options}
          series={[scaledSeries]} // Wrap series in an array as required by ApexCharts
          type="radialBar"
          height={380}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default RadialBar;
