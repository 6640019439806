import React, { useEffect, useState } from "react";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { Tree } from "primereact/tree";
import { useAuth } from "../../context/authProvider";

const AddFavourite = () => {
  const {
    companyStore: {
      favCreate,
      fetchFavAssigne,
      fetchFavAssigneLimit,
      fetchFavExisit,
      fetchFavExisitLimit,
    },
  } = useStore();

  const [nodes, setNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const { showAlert } = useAuth();

  useEffect(() => {
    fetchFavAssigne();
    fetchFavExisit();
  }, [fetchFavAssigne, fetchFavExisit]);

  useEffect(() => {
    const mapDataToTreeNodes = (data) => {
      return data.map((categoryItem, categoryIndex) => ({
        key: `category_${categoryIndex}`,
        label: categoryItem.category,
        children: categoryItem.subCategories.map(
          (subCategoryItem, subCategoryIndex) => ({
            key: `category_${categoryIndex}_subcategory_${subCategoryIndex}`,
            label: subCategoryItem.subCategory,
            children: subCategoryItem.kpis.map((kpi, kpiIndex) => ({
              key: `category_${categoryIndex}_subcategory_${subCategoryIndex}_kpi_${kpiIndex}`,
              label: kpi,
            })),
          })
        ),
      }));
    };

    if (fetchFavAssigneLimit.length) {
      const treeNodes = mapDataToTreeNodes(fetchFavAssigneLimit);
      setNodes(treeNodes);

      // Compare with existing favorites to set the selectedKeys
      const initialSelectedKeys = {};
      fetchFavExisitLimit[0]?.favData.forEach((categoryItem) => {
        treeNodes.forEach((node, categoryIndex) => {
          if (node.label === categoryItem.category) {
            initialSelectedKeys[`category_${categoryIndex}`] = {
              checked: true, // fully checked
              partialChecked: false, // not partially checked
            };

            categoryItem.subCategories.forEach((subCategoryItem) => {
              node.children.forEach((subNode, subCategoryIndex) => {
                if (subNode.label === subCategoryItem.subCategory) {
                  initialSelectedKeys[
                    `category_${categoryIndex}_subcategory_${subCategoryIndex}`
                  ] = {
                    checked: true, // fully checked
                    partialChecked: false, // not partially checked
                  };

                  subCategoryItem.kpis.forEach((kpiItem) => {
                    subNode.children.forEach((kpiNode, kpiIndex) => {
                      if (kpiNode.label === kpiItem) {
                        initialSelectedKeys[
                          `category_${categoryIndex}_subcategory_${subCategoryIndex}_kpi_${kpiIndex}`
                        ] = {
                          checked: true, // fully checked
                          partialChecked: false, // not partially checked
                        };
                      }
                    });
                  });
                }
              });
            });
          }
        });
      });

      setSelectedKeys(initialSelectedKeys);
    }
  }, [fetchFavAssigneLimit, fetchFavExisitLimit]);

  const saveSelectedData = () => {
    if (!selectedKeys || Object.keys(selectedKeys).length === 0) {
      showAlert("No items selected", false);
      return;
    }

    const selectedItems = [];
    const addedCategories = new Set();

    const traverseNodes = (
      nodeList = [],
      parentCategory = null,
      parentSubCategory = null
    ) => {
      nodeList.forEach((node) => {
        if (selectedKeys[node.key]) {
          // Check if it's a category node
          if (!parentCategory && !addedCategories.has(node.label)) {
            const newCategory = {
              category: node.label,
              subCategories: [],
            };
            selectedItems.push(newCategory);
            addedCategories.add(node.label);
            traverseNodes(node.children || [], newCategory, null);
          }
          // Check if it's a subcategory node
          else if (parentCategory && node.children) {
            const newSubCategory = {
              subCategory: node.label,
              kpis: [],
            };
            parentCategory.subCategories.push(newSubCategory);
            traverseNodes(node.children || [], parentCategory, newSubCategory);
          }
          // If it's a KPI node
          else if (parentSubCategory && !node.children) {
            if (!parentSubCategory.kpis.includes(node.label)) {
              parentSubCategory.kpis.push(node.label);
            }
          }
        }

        if (node.children) {
          traverseNodes(node.children, parentCategory, parentSubCategory);
        }
      });
    };
    traverseNodes(nodes);

    // Remove categories with no subcategories and KPIs
    const filteredItems = selectedItems.filter((category) =>
      category.subCategories.some((subCategory) => subCategory.kpis.length > 0)
    );
    const payload = {
      data: filteredItems,
    };
    favCreate({ payload, showAlert });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <div className="p-4">
              <h4>Add Favourite</h4>
            </div>
          </div>
          <div className="card-body">
            <div className=" flex justify-content-center">
              <Tree
                value={nodes}
                selectionMode="checkbox"
                selectionKeys={selectedKeys}
                onSelectionChange={(e) => setSelectedKeys(e.value)}
                className="custom-tree"
              />
              <button
                className="btn btn-primary mt-4"
                onClick={saveSelectedData}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddFavourite);
