import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import useStore from "../../hooks/useStore";
import DashboardWater from "../../components/dashboard/dashboardWater";
import DashboardWaste from "../../components/dashboard/dashboardWaste";
import DashboardEnergy from "../../components/dashboard/dashboardEnergy";
import DashboardAqi from "../../components/dashboard/dashboardAqi";
import DashboardBiodiversity from "../../components/dashboard/dashboardBiodiversity";
import { generateYearArray, getRoundedValue } from "../../utils/helpers";
import {
  GOVERNANCE_STATIC_GROUPS,
  GOVERNANCE_KPI_DASHBOARD,
  REVENUE_FIELDS,
  CHARTS_LABEL,
} from "../../constants/constants";
import ColumnChart from "../../components/charts/columnChart";
import DonutChart from "../../components/charts/donutChart";
import PieChart from "../../components/charts/pieChart";
import { useMemo } from "react";
import { toJS } from "mobx";

import { filterData } from "../../helpers/groupDataCalculations";
import {
  CustomerAndPrivacyChart,
  CustomerHealthAndSafetyChart,
  DiversityAndEqualOpportunityChart,
  EmployeeHireGraph,
  MarketAndLabellingChart,
  NonDiscriminationChart,
  OhcChart,
  // OhcChartInDashboard,
  SupplierSocialAssesmentChart,
  MonthlyParameter,
  // TrainingAndEducationGraph,
} from "./socialCharts";
import RadialBar from "../../components/charts/radialBar";
import RadialBarEmission from "../../components/charts/radialBarEmission";
import NewPieChart from "../../components/charts/newPieChart";
import GradientDonutChart from "../../components/charts/gradientDonutChart";
import BasicBarChart from "../../components/charts/basicBarChart";
import ColumnChartRotatedLabels from "../../components/charts/ColumnChartRotatedLabels";
import MultiRadialChart from "../../components/charts/mulitRadialChart";
import CustomSpinner from "../../components/spinner/Spinner";
import { Report } from "../../pages/report/index";
const EnvironmentDashboardTab = observer(() => {
  const {
    dashboardStore: { fetchDashboardDetails, showAll },
    companyStore: {
      fetchFinancialData,
      getPerformance,
      fetchRevenueData,
      companyData,
      companyUnit,
      fetchRevenueChartData,
      revenuePageChartData,
    },
  } = useStore();
  const company = toJS(companyData);
  const assignCategory =
    company?.assignCategory.map(({ catId }) => catId) || [];
  const category =
    company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const assignSubCategory =
    company?.assignSubCategory
      .filter(({ catId }) => assignCategory.includes(catId))
      .map(({ subCatId }) => subCatId) || [];

  const assignKpi =
    company?.assignKpi
      .filter(({ catId }) => assignCategory.includes(catId))
      .filter(({ name }) => category.includes("Environment"))
      .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
      .map(({ kpiIdAssignKpi: { name } }) => name) || [];

  const [year, setYear] = useState(new Date().getFullYear());
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchFinancialData({ year });
    getPerformance({ year });
    fetchDashboardDetails({ signal, year, companyUnit });
    // fetchRevenueData({ year });
    fetchRevenueChartData({ year });
    return () => {
      abortController.abort();
    };
  }, [
    fetchDashboardDetails,
    fetchFinancialData,
    fetchRevenueData,
    getPerformance,
    year,
    companyUnit,
    showAll,
    fetchRevenueChartData,
  ]);

  const chartData = useMemo(() => {
    return ["waste", "all"].map((ele) => {
      const filteredData = revenuePageChartData?.revenue?.filter(
        (e) => e?.type === ele
      );
      const unit = filteredData?.[0]?.unit?.shortName || ""; // Assuming the unit is consistent within each type
      return {
        name: ele === "waste" ? "Revenue due to Waste" : "Total Revenue",
        unit,
        data: [Number(year) - 1, year].map((f) => {
          return (
            filteredData?.find((e) => f.toString() === e?.year?.toString())
              ?.value || 0
          );
        }),
      };
    });
  }, [revenuePageChartData, year]);

  console.log("chartData", chartData);
  return (
    <>
      <div className="d-flex justify-content-end ">
        <div className="w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center ">
          <label className="form-label fs-6 ">Year</label>

          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white "
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      {category.includes("Environment") && (
        <>
          {assignKpi.includes("Water") && (
            <div>
              <DashboardWater key="water-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Waste") && (
            <div>
              {/* <h3>Waste</h3> */}
              <DashboardWaste key="waste-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Energy") && (
            <div>
              {/* <h3>Energy</h3> */}
              <DashboardEnergy key="energy-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Emission") && (
            <div>
              {/* <h3>Emission</h3> */}
              <DashboardAqi key="aqi-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("BIODIVERSITY") && (
            <div>
              {/* <h3>Biodiversity</h3> */}
              <DashboardBiodiversity key="biodiversity-dashboard" year={year} />
            </div>
          )}
          {company.companiesData[0]?.role !== "employee" && (
            <div className="row h-100">
              <div className="col-xl-12">
                <div className="card h-100 dashboard-card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-start">
                      <h5 className="mb-3 text-muted"> Revenue Report</h5>
                      <div className="ms-auto">
                        <div className="toolbar d-flex flex-wrap gap-2 ">
                          <div className="">
                            <p className="text-muted mb-1">Unit</p>
                            <h5 className="unit-value">
                              {chartData.length > 0 ? chartData[0].unit : " "}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ColumnChart
                      data={chartData}
                      categories={[
                        `FY${(year - 1).toString().slice(2)}`,
                        `FY${year.toString().slice(2)}`,
                      ]}
                      yaxis={[]}
                      unit={chartData.length > 0 ? chartData[0].unit : " "}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
});

const GovernanceDashboardTab = observer(() => {
  const {
    governance: { fetchDashboard, dashboard },
    companyStore: { companyUnit },
    dashboardStore: { showAll },
  } = useStore();
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchDashboard({ year, companyUnit });
  }, [fetchDashboard, year, companyUnit, showAll]);

  const specificFieldsForPieChart = useMemo(
    () => [
      "Existence and effectiveness of a risk management framework.",
      "Existence of a whistleblower protection policy.",
      "Voting results of key shareholder resolutions.",
      "Timeliness and accuracy of financial and non-financial reporting.",
      "Compliance with regulatory reporting requirements.",
      "Frequency of ESG-related disclosures and updates.",
      "Frequency of shareholder engagement meetings.",
      "Existence of a documented corporate governance framework.",
      "Compliance with international governance standards and best practices.",
      "Existence of data privacy and cybersecurity policies.",
    ],
    []
  );

  const getColumnChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const previous =
        dashboard?.[(Number(year) - 1).toString()]?.[group] || [];
      const toBeUsed = current?.length <= previous?.length ? previous : current;
      const result = toBeUsed
        ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
        ?.map((ele) => {
          return {
            name: ele.fieldName,
            data: [
              Number(previous.find((e) => e.fieldId === ele.fieldId)?.value) ||
                0,
              Number(current.find((e) => e.fieldId === ele.fieldId)?.value) ||
                0,
            ],
          };
        });
      return result || [];
    },
    [dashboard, specificFieldsForPieChart]
  );

  const getDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
  const getPieChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];

      const filteredData =
        current
          ?.filter((ele) => specificFieldsForPieChart.includes(ele.fieldName))
          ?.flatMap((ele) => {
            // Split the value if it's comma-separated (e.g., "Yes, No, No")
            const values = ele.value.split(",").map((val) => val.trim());
            // Map the split values to their corresponding numerical representation
            return values.map((val) => ({
              label: ele.fieldName,
              value: val === "Yes" ? 100 : val === "No" ? 90 : Number(val), // Handle numeric values directly
            }));
          }) || [];

      const groupedData = filteredData.reduce((acc, { label, value }) => {
        if (!acc[label]) {
          acc[label] = [];
        }
        acc[label].push(value);
        return acc;
      }, {});

      return groupedData;
    },
    [dashboard, specificFieldsForPieChart]
  );

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {GOVERNANCE_KPI_DASHBOARD.map((group) => (
          <div className="row h-100 align-items-stretch" key={group}>
            {/* Render ColumnChart and DonutChart for groups other than "F" */}
            {group !== "F" && (
              <>
                <div className="col-xl-8">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-start">
                        <h5 className="mb-3 text-muted">
                          {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                        </h5>
                      </div>
                      <div>
                        <ColumnChart
                          data={getColumnChartData({ group, year })}
                          categories={[
                            `FY${(year - 1).toString().slice(2)}`,
                            `FY${year.toString().slice(2)}`,
                          ]}
                          max={null}
                          yaxis={[]}
                          unit={" "}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-start">
                        <h5 className="mb-3 text-muted">
                          {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                        </h5>
                      </div>
                      <div className="donut">
                        <DonutChart
                          height={512}
                          data={getDonutChartData({ group, year }).data}
                          labels={getDonutChartData({ group, year }).labels}
                          unit={" "}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {Object.entries(getPieChartData({ group, year })).length > 0 && (
              <div className="w-100">
                <div className="mb-0 h-100 col-xl-12">
                  <div className="d-flex flex-wrap align-items-start">
                    {group === "F" && (
                      <h5 className="mb-3 text-muted">
                        {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                      </h5>
                    )}
                  </div>
                  <div className="mb-0 h-100 col-xl-12">
                    <div className="pie row">
                      {Object.entries(getPieChartData({ group, year })).map(
                        ([label, data], index) => {
                          // Map series values to corresponding labels
                          const labels = data.map((value) =>
                            value === 100 ? "Yes" : value === 90 ? "No" : ""
                          );

                          return (
                            <div className="col-xl-4 mt-3" key={index}>
                              <div className="card h-100">
                                <div className="card-body">
                                  <h6>{label}</h6>
                                  <PieChart
                                    height={380}
                                    series={data}
                                    labels={labels}
                                    unit={" "}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

const SocialDashboardTab = observer(() => {
  const {
    social: { fetchChartData, chartData },
    companyStore: { companyData, companyUnit },
    dashboardStore: { showAll },
  } = useStore();

  const company = toJS(companyData);
  const assignCategory =
    company?.assignCategory.map(({ catId }) => catId) || [];
  const category =
    company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const assignSubCategory =
    company?.assignSubCategory
      .filter(({ catId }) => assignCategory.includes(catId))
      .map(({ subCatId }) => subCatId) || [];

  const assignKpi =
    company?.assignKpi
      .filter(({ catId }) => assignCategory.includes(catId))
      .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
      .map(({ kpiIdAssignKpi: { name } }) => name) || [];
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchChartData({ year, companyUnit });
  }, [fetchChartData, year, companyUnit, showAll]);
  const categories = [
    `FY${(year - 1).toString().slice(2)}`,
    `FY${year.toString().slice(2)}`,
  ];

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      {category.includes("Social") && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div className="row">
              <div className="col-xl-6">
                {assignKpi.includes("Employment") && (
                  <EmployeeHireGraph
                    categories={categories}
                    data={chartData?.filter((e) => e.kpiName === "Employment")}
                    year={Number(year)}
                  />
                )}
              </div>
              <div className="col-xl-6">
                {assignKpi.includes("OH&C") && (
                  <OhcChart
                    categories={categories}
                    data={filterData(chartData, "kpiName", "OH&C")}
                    year={Number(year)}
                  />
                )}
              </div>
            </div>

            {/* {assignKpi.includes("Training and Education") && (
              <TrainingAndEducationGraph
                data={filterData(
                  chartData,
                  "kpiName",
                  "Training and Education"
                )}
                year={Number(year)}
                categories={categories}
              />
            )} */}
            {assignKpi.includes("Diversity And Equal Opportunity") && (
              <DiversityAndEqualOpportunityChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Diversity And Equal Opportunity"
                )}
                year={Number(year)}
                categories={categories}
              />
            )}
            {assignKpi.includes("Non-Discrimination") && (
              <NonDiscriminationChart
                data={filterData(chartData, "kpiName", "Non-Discrimination")}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Supplier Social Assessment") && (
              <SupplierSocialAssesmentChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Supplier Social Assessment"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Customer Health and Safety") && (
              <CustomerHealthAndSafetyChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Customer Health and Safety"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Marketing and Labeling") && (
              <MarketAndLabellingChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Marketing and Labeling"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Customer Privacy") && (
              <CustomerAndPrivacyChart
                data={filterData(chartData, "kpiName", "Customer Privacy")}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Monthly Parameter") && (
              <MonthlyParameter
                data={filterData(chartData, "kpiName", "Monthly Parameter")}
                year={Number(year)}
                categories={categories}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

const CommonDashboardTab = observer(() => {
  const [year, setYear] = useState(new Date().getFullYear());

  const {
    governance: { fetchDashboard, dashboard },
    social: { fetchChartData, chartData },
    companyStore: {
      companyUnit,
      fetchRevenueData,
      revenuePageData,
      companyData,
    },
    dashboardStore: { showAll, fetchDashboardDetails, dashboardDetails },
  } = useStore();
  const navigate = useNavigate();
  const company = toJS(companyData);
  const categoryCheck =
    company?.assignCategory?.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const kpiCheck =
    company?.assignKpi
      ?.filter(({ name }) => categoryCheck)
      ?.map(({ kpiIdAssignKpi: { name } }) => name) || [];

  //   const assignCategory =
  //   company?.assignCategory.map(({ catId }) => catId) || [];
  // const category =
  //   company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
  //   [];

  // const assignSubCategory =
  //   company?.assignSubCategory
  //     .filter(({ catId }) => assignCategory.includes(catId))
  //     .map(({ subCatId }) => subCatId) || [];

  // const assignKpi =
  //   company?.assignKpi
  //     .filter(({ catId }) => assignCategory.includes(catId))
  //     .filter(({ name }) => category.includes("Environment"))
  //     .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
  //     .map(({ kpiIdAssignKpi: { name } }) => name) || [];

  // console.log(kpiCheck,"assignKpi");
  const specificFieldsForPieChart = useMemo(
    () => ["Existence and effectiveness of a risk management framework."],
    []
  );
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchDashboard({ year, companyUnit });
    fetchChartData({ year, companyUnit });
    fetchRevenueData({ year, companyUnit });
    fetchDashboardDetails({ signal, year, companyUnit });
  }, [
    fetchDashboard,
    fetchChartData,
    year,
    companyUnit,
    showAll,
    fetchRevenueData,
    fetchDashboardDetails,
  ]);
  const getGovernanceDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName))
          ?.sort((a, b) => Number(a.value) - Number(b.value)) // sort by value
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName))
          ?.sort((a, b) => Number(a.value) - Number(b.value)) // sort by value
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
  const barChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi || {}; // Ensure tempData is always an object

    // Initialize arrays to hold series and categories
    const seriesData = [];
    const categories = [];

    // Mapping through emission scopes (like A1, B1, etc.)
    ["A1", "B1"].forEach((group) => {
      const groupDataForYear =
        tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {}) ||
        {};

      // Calculate data for each group (handling C1 and C2 specially)
      const dataValue =
        group === "C"
          ? (groupDataForYear["C1"]?.prevValue || 0) +
            (groupDataForYear["C2"]?.prevValue || 0)
          : groupDataForYear[group]?.prevValue || 0;

      // Push name and data for this group
      categories.push(CHARTS_LABEL.energy[group]); // Add the label to categories
      seriesData.push(dataValue); // Add the data to series
    });

    // Handling additional data like Scope3 and totalGhgEmissionFinished
    if (tempData?.Scope3) {
      categories.push("Scope 3"); // Add Scope 3 to categories
      seriesData.push(tempData.Scope3?.data?.[1] || 0); // Add Scope 3 value
    }

    if (tempData?.totalGhgEmissionData) {
      categories.push("Total GHG Emission Emission"); // Add Total GHG Emission to categories
      seriesData.push(tempData.totalGhgEmissionData?.data?.[1] || 0); // Add Total GHG emission value
    }

    return { series: [{ name: "", data: seriesData }], categories };
  }, [dashboardDetails?.charts?.aqi, year]);

  const getWaterIntenityChartData = useCallback(
    (type, year) => {
      const fieldData = revenuePageData?.fieldData || [];
      const currentYear = Number(year).toString();
      const currentYearData = fieldData.filter(
        (data) => data.type === type && data.year === currentYear
      );
      // Create maps with the title as keys to match the field.valKey (if needed)
      const currentYearMap = currentYearData.reduce((acc, data) => {
        acc[data.title] = data;
        return acc;
      }, {});

      // Map REVENUE_FIELDS to the chart format
      return REVENUE_FIELDS.filter((field) => field.type === type).map(
        (field) => {
          const dataValues = [
            Number(currentYearMap[field.label]?.value.toFixed(0)) || 0,
          ];
          const maxValues = [
            (Number(currentYearMap[field.label]?.value.toFixed(0)) || 0) * 1.2,
          ];
          const unitName = currentYearMap[field.label]?.unitName || " ";

          return {
            name: `${field.legend} : ${dataValues}`,
            data: dataValues,
            max: maxValues,
            unitName: unitName,
          };
        }
      );
    },
    [revenuePageData?.fieldData]
  );

  const getRevenueUnit = (year) => {
    const revenueData =
      revenuePageData?.revenue?.find(
        (e) => e.type === "all" && e.year === year.toString()
      ) || {};
    return revenueData?.unit?.shortName || " "; // Get the short name of the unit for the selected year
  };

  const socialPieChartData = useMemo(() => {
    // Assuming chartData contains the monthly parameter data for different years
    const data =
      chartData?.filter((e) => e.kpiName === "Monthly Parameter") || [];

    if (!year) return { dataSeries: [], labels: [] }; // Return empty if no year is passed

    const results = {
      Male: {
        label: "Male",
        sum: 0,
      },
      Female: {
        label: "Female",
        sum: 0,
      },
    };

    // Define months from April 2024 to March 2025 for the financial year
    const months = [
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "January",
      "February",
      "March",
    ];

    data.forEach((item) => {
      const value = item.value || 0; // Assume there's a value field to sum

      // Check if the item falls within the selected financial year
      const itemMonthIndex = months.indexOf(item.month); // Get the month index
      const isWithinFinancialYear = itemMonthIndex !== -1; // Ensure the month is valid (within the financial year)

      // Adjust the year comparison depending on the month (April to March)
      const expectedYear = itemMonthIndex < 9 ? Number(year) : Number(year) + 1; // April to December (selected year), January to March (next year)

      if (isWithinFinancialYear && item.year === expectedYear) {
        if (item.fieldGroup === "A_A_A") {
          results.Male.sum += value; // Sum values for Male
        } else if (item.fieldGroup === "A_A_B") {
          results.Female.sum += value; // Sum values for Female
        }
      }
    });

    // Return the sums in a format suitable for the NewPieChart component
    return {
      dataSeries: [results.Male.sum, results.Female.sum],
      labels: [
        `${results.Male.label} : ${results.Male.sum}`,
        `${results.Female.label} :${results.Female.sum}`,
      ],
    };
  }, [chartData, year]);

  const socialMonthlyChartData = useMemo(() => {
    const data =
      chartData?.filter((e) => e.kpiName === "Monthly Parameter") || [];

    if (year) {
      // Define months from April 2024 to March 2025
      const months = [
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "January",
        "February",
        "March",
      ];

      return months.map((month, index) => {
        const currentYear = index < 9 ? Number(year) : Number(year) + 1; // For January to March, use `year + 1`

        const work =
          data.find(
            (item) =>
              item.fieldGroup === "C_A" &&
              item.month === month &&
              item.year === currentYear &&
              item.fieldName === "The number of hours worked."
          ) || {};

        const ltifr =
          data.find(
            (item) =>
              item.fieldGroup === "C_A" &&
              item.month === month &&
              item.year === currentYear &&
              item.fieldName === "LTIFR"
          ) || {};

        return {
          categories: month,
          value: work?.value
            ? (((ltifr?.value || 0) * 1000000) / work?.value).toFixed(2)
            : 0,
          year: currentYear,
        };
      });
    }
    return data;
  }, [chartData, year]);

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="g-sm-0 g-md-0 g-lg-1 g-1  row">
        <div className="row h-100 g-2 g-sm-0 g-md-0 g-lg-2">
          {categoryCheck.includes("Governance") &&
            kpiCheck.includes("Basic Parameter") && (
              <div className="col-xl-4 ">
                {/* Board Composition and diversity */}
                <div className="card h-100 mb-0">
                  <div className="card-body mb-3">
                    <div className="d-flex flex-wrap align-items-start">
                      <label className=" card-title  text-muted">
                        Board Composition and diversity
                      </label>
                    </div>
                    {getGovernanceDonutChartData({ group: "A", year }).data ? (
                      <MultiRadialChart
                        series={
                          getGovernanceDonutChartData({ group: "A", year }).data
                        }
                        labels={
                          getGovernanceDonutChartData({ group: "A", year })
                            .labels || []
                        }
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-75">
                        <CustomSpinner />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          <div className="col-xl-8">
            <div className="row g-2 g-sm-0 g-md-0 g-lg-2">
              {categoryCheck.includes("Environment") &&
                kpiCheck.includes("Emission") && (
                  <div className="col-xl-9">
                    {/* GHG */}
                    <div className="card h-100">
                      <div className="card-body">
                        <div className="d-flex flex-wrap align-items-start">
                          <label className=" card-title  text-muted">GHG</label>
                          <div className="ms-auto">
                            <div className="toolbar align-items-center d-flex flex-wrap gap-2 ">
                              <p className="text-muted mb-1">Unit</p>
                              <h5 className="unit-value new-unit-value ">
                                {dashboardDetails?.charts?.aqi?.unit || "MT"}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {barChartData.series ? (
                          <BasicBarChart
                            series={barChartData.series}
                            categories={barChartData.categories}
                            unit={dashboardDetails?.charts?.aqi?.unit || "MT"}
                          />
                        ) : (
                          <div className="d-flex align-items-center justify-content-center h-75">
                            <CustomSpinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {categoryCheck.includes("Environment") &&
                kpiCheck.includes("Emission") && (
                  <div className="col-xl-3">
                    {/* eMISSION */}
                    <div className="card h-100">
                      <div className="card-body ">
                        <div className="d-flex flex-wrap align-items-start">
                          <label
                            className="card-title  text-muted "
                            style={{ maxWidth: "70%" }}
                          >
                            Emission saved from renewable energy
                          </label>
                          <div className="ms-auto">
                            <div className="toolbar d-flex flex-wrap gap-2 align-items-center ">
                              <p className="text-muted mb-1">Unit</p>
                              <h5 className="unit-value new-unit-value ">
                                {"MT"}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center h-100 ">
                          {dashboardDetails?.charts ? (
                            <RadialBarEmission
                              series={
                                (
                                  dashboardDetails?.charts?.energy
                                    ?.emissionIntensity?.value * 0.825
                                ).toFixed(0) || [0]
                              } // Extracting the first value directly
                              labels={[
                                `${
                                  dashboardDetails?.charts?.energy
                                    ?.emissionIntensity?.label || "Intensity"
                                }: ${
                                  (
                                    dashboardDetails?.charts?.energy
                                      ?.emissionIntensity?.value * 0.825
                                  ).toFixed(0) || 0
                                }`,
                              ]} // Formatted label
                              unit={"MT"}
                              max={
                                (
                                  dashboardDetails?.charts?.energy
                                    ?.emissionIntensity?.value * 0.825
                                ).toFixed(0) * 1.2 || 0
                              }
                            />
                          ) : (
                            <div className="d-flex align-items-center justify-content-center h-75">
                              <CustomSpinner />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {categoryCheck.includes("Environment") &&
                kpiCheck.includes("Water") && (
                  <div className="col-xl-6">
                    {/* Water Intensity */}
                    <div className="card h-100 mb-0 ">
                      <div className="card-body mb-2">
                        <div className="d-flex flex-wrap align-items-start">
                          <label className=" card-title  text-muted">
                            Water Intensity
                          </label>
                          <div className="ms-auto">
                            <div className="toolbar d-flex flex-wrap gap-2 align-items-center ">
                              <p className="text-muted mb-1">Unit</p>
                              <h5 className="unit-value new-unit-value ">
                                {getWaterIntenityChartData("water", year)[0]
                                  ?.unitName
                                  ? `${
                                      getWaterIntenityChartData(
                                        "water",
                                        year
                                      )[0]?.unitName
                                    } / ${getRevenueUnit(year)}`
                                  : "unit"}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {revenuePageData ? (
                          <RadialBar
                            series={
                              getWaterIntenityChartData("water", year)[0]
                                ?.data[0] || 0
                            } // Extracting the first value directly
                            labels={[
                              `Water Intensity : ${
                                getWaterIntenityChartData("water", year)[0]
                                  ?.data[0] || 0
                              }`,
                            ]} // Make sure labels is an array
                            max={
                              getWaterIntenityChartData("water", year)[0]
                                ?.max[0] * 1.2 || 0
                            }
                            unit={
                              getWaterIntenityChartData("water", year)[0]
                                ?.unitName
                                ? `${
                                    getWaterIntenityChartData("water", year)[0]
                                      ?.unitName
                                  } / ${getRevenueUnit(year)}`
                                : "unit"
                            }
                          />
                        ) : (
                          <div className="d-flex align-items-center justify-content-center h-75">
                            <CustomSpinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {categoryCheck.includes("Social") &&
                kpiCheck.includes("Monthly Parameter") && (
                  <div className="col-xl-6 ">
                    {/* Gender Diversity */}
                    <div className="card h-100 mb-0  ">
                      <div className="card-body mb-3">
                        <label className=" card-title  text-muted">
                          Gender Diversity
                        </label>
                        {socialPieChartData ? (
                          <NewPieChart
                            dataSeries={socialPieChartData.dataSeries || 0}
                            labels={
                              socialPieChartData.labels || ["Male", "Female"]
                            }
                          />
                        ) : (
                          <div className="d-flex align-items-center justify-content-center h-75">
                            <CustomSpinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="row h-100 g-1 g-sm-0 g-md-0 g-lg-1">
          {/* Safety performance */}
          {categoryCheck.includes("Social") &&
            kpiCheck.includes("Monthly Parameter") && (
              <div className="col-xl-8">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-start">
                      <label className=" card-title  text-muted">
                        Safety performance
                      </label>
                    </div>
                    {socialMonthlyChartData ? (
                      <ColumnChartRotatedLabels
                        series={[
                          {
                            name: "LTIFR",
                            data: socialMonthlyChartData.map(
                              (item) => item.value
                            ), // Values for Y-axis
                          },
                        ]}
                        categories={socialMonthlyChartData.map(
                          (item) => item.categories
                        )} // Categories for X-axis (months)
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-75">
                        <CustomSpinner />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          {/* Energy */}
          {categoryCheck.includes("Environment") &&
            kpiCheck.includes("Energy") && (
              <div className="col-xl-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-start">
                      <label className=" card-title  text-muted">Energy</label>
                      <div className="ms-auto">
                        <div className="toolbar d-flex flex-wrap gap-2 align-items-center ">
                          <p className="text-muted mb-1">Unit</p>
                          <h5 className="unit-value new-unit-value ">kwh</h5>
                        </div>
                      </div>
                    </div>

                    {dashboardDetails?.charts ? (
                      <div className="donut">
                        <GradientDonutChart
                          data={
                            dashboardDetails?.charts?.energy?.doughnutChart.map(
                              (ele) => getRoundedValue(ele.value)
                            ) || [0]
                          }
                          labels={
                            dashboardDetails?.charts?.energy?.doughnutChart.map(
                              (ele) => ele.label
                            ) || ["Energy"]
                          }
                          unit={dashboardDetails?.charts?.energy?.unit || "kwh"}
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-75">
                        <CustomSpinner />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>

        {/* report component  */}
        <div className="card p-4 mt-2">
          <h4>Generate Report</h4>

          <Row className="d-flex align-items-center flex-wrap">
            {["Environment", "Social", "Governance"].some((category) =>
              categoryCheck?.includes(category)
            ) && (
              <Col md={6}>
                <div className="card mt-2 p-3">
                  <div className="card-body p-4 d-flex gap-2 flex-wrap report-download-button">
                    <div className="card-title">GRI Report</div>
                    <div>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() =>
                          navigate("/company/report", {
                            state: { reportType: "GRI" },
                          })
                        }
                      >
                        Click Here
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            )}

            {categoryCheck?.includes("BRSR") && (
              <Col md={6}>
                <div className="card mt-2 p-3">
                  <div className="card-body p-4 d-flex gap-2 flex-wrap report-download-button">
                    <div className="card-title">BRSR Report</div>
                    <div>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() =>
                          navigate("/company/report", {
                            state: { reportType: "BRSR" },
                          })
                        }
                      >
                        Click Here
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          {/* <Report categoryData={categoryCheck} reportType="dashboard" /> */}
        </div>
      </div>
    </>
  );
});

export {
  EnvironmentDashboardTab,
  GovernanceDashboardTab,
  SocialDashboardTab,
  CommonDashboardTab,
};
