import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";
import { getLocalStorage } from "../utils/localStorage";
import { USER_ROLE } from "../constants/constants";

export default class UserStore {
  userData = {};
  company = [];
  isLoggedIn = localStorage.getItem("access-token") ? true : false;
  isAdminLoggedIn = localStorage.getItem("admin-access-token") ? true : false;
  error = null;
  employeeKpisRaw = [];
  constructor() {
    makeObservable(this, {
      userData: observable,
      isLoggedIn: observable,
      error: observable,
      isAdminLoggedIn: observable,
      company: observable,
      employeeKpisRaw: observable,
      fetchCompany: action.bound,

      loginUser: action.bound,
      logoutUser: action.bound,
      logoutSuperAdmin: action.bound,
      path: computed,
      fetchUserData: action.bound,
      token: computed,
      companyList: computed,
      user: computed,
      employeeKpis: computed,

      setError: action.bound,
      reset: action.bound,
      fetchEmployeeKpis: action.bound,
    });
  }

  reset() {
    this.userData = {};
    this.company = [];
    this.isLoggedIn = false;
    this.isAdminLoggedIn = false;
    this.error = null;
  }

  setError(payload) {
    this.error = payload;
  }

  get employeeKpis() {
    return toJS(this.employeeKpisRaw);
  }

  get path() {
    return this.isAdminLoggedIn ? "admin" : this.isLoggedIn ? "company" : "";
  }

  get token() {
    return (
      getLocalStorage("access-token") || getLocalStorage("admin-access-token")
    )?.token;
  }

  get user() {
    return toJS(this.userData);
  }

  loginUser(payload, isAdmin = false) {
    localStorage.clear();
    fetchData(`${isAdmin ? "super-admin" : "company"}/login`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          if (isAdmin) {
            this.userData = data.superAdminUser;
            this.isAdminLoggedIn = true;
          } else {
            this.userData = data.companyUser;
            this.isLoggedIn = true;
            this.fetchEmployeeKpis();
          }
          localStorage.setItem(
            `${isAdmin ? "admin-" : ""}access-token`,
            JSON.stringify(data.tokens.access)
          );
          localStorage.setItem(
            `${isAdmin ? "admin-" : ""}refresh-token`,
            JSON.stringify(data.tokens.access)
          );
          return;
        }
        this.error = data.message;
      })
      .catch((error) => {
        this.error = error;
        console.error(error);
      });
  }
  logoutSuperAdmin() {
    localStorage.clear();
    this.isAdminLoggedIn = false;
  }

  // logoutUser(accessToken, isAdmin = false) {
  //   // localStorage.clear();
  //   fetchData(`${isAdmin ? "super-admin" : "company"}/logout`, {
  //     method: "POST",
  //     body: [{ accessToken: accessToken }],
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data.status === 200) {
  //         if (isAdmin) {
  //           this.isAdminLoggedIn = false;
  //         } else {
  //           this.isLoggedIn = false;
  //         }
  //         return;
  //       }
  //       this.error = data.message;
  //     })
  //     .catch((error) => {
  //       this.error = error;
  //       console.error(error);
  //     });
  // }

  async logoutUser(isAdmin = false) {
    try {
      const accessToken = localStorage.getItem(`${isAdmin ? "admin-" : ""}access-token`);
      const res = await fetchData(`${isAdmin ? "super-admin" : "company"}/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ accessToken: accessToken }),
      });
      const data = await res.json();
      if (data.status === 200) {
        localStorage.removeItem(`${isAdmin ? "admin-" : ""}access-token`);
        if (isAdmin) {
          this.isAdminLoggedIn = false;
        } else {
          this.isLoggedIn = false;
        }
      } else {
        this.error = data.message;
      }
    } catch (error) {
      this.error = error;
      console.error(error);
    }
  }

  fetchUserData() {
    fetchData("super-admin/superAdminProfile")
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.userData = data.data.userData;
          this.fetchEmployeeKpis();
          return;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchCompany({ signal }) {
    fetchData("company/company", { signal })
      .then((res) => res.json())
      .then((data) => {
        console.error("hgfhgdsfhsdfsdhg", data.data);
        this.company = data.data;
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }

  get companyList() {
    return toJS(this.company);
  }
}

UserStore.prototype.fetchEmployeeKpis = function () {
  if (this.user.role === USER_ROLE.Employee) {
    fetchData(`company/employeeKpis`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          this.employeeKpisRaw = res.data;
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }
};
