import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast, ToastContainer } from "react-bootstrap";
import { useAuth } from "../context/authProvider";

const CustomToast = () => {
  const { alert, hideAlert } = useAuth();

  if (!alert.message) return null;

  return (
    <React.Fragment>
      {/* <Alert variant={alert.type} onClose={hideAlert} dismissible>
        {alert.message}
      </Alert> */}

      <ToastContainer
        position="top-end"
        className="p-3 position-fixed"
        style={{ zIndex: 1060 }}
      >
        <Toast
          onClose={hideAlert}
          show={alert}
          delay={3000}
          bg={alert.type}
          autohide
          className="text-white"
        >
          <Toast.Body>
            {alert.message}
            <label
              role="button"
              onClick={hideAlert}
              className="float-end fw-bold"
            >
              X
            </label>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </React.Fragment>
  );
};

export default CustomToast;
