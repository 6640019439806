import "./waterFormRow.css";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tooltip } from "../../../utils";
import info from "../../info.svg";
import numToRoman from "../../../helpers/numToRoman";

export const ADD_FIELD_FORM_VALUES = {
  prevValue: 0,
  target: 0,
  comment: "",
  value: "",
  attachment: "",
  unit: "",
};

const WaterFormRow = ({ category, allUnitData, unitValidated, setPt, id }) => {
  const fieldName = category?.fieldsIdAssignFields?.name;
  const isField = fieldName?.includes("Percentage of water recycle/reuse");
  const [formData, setFormData] = useState(ADD_FIELD_FORM_VALUES);
  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "attachment" ? files[0] : value,
    }));
  };

  useEffect(() => {
    setPt((prev) => {
      const id = category.id;
      const index = [...prev].findIndex((e) => e.id === id);
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          ...formData,
          ...(isField
            ? { prevValue: category.prevValue, target: category.target }
            : {}),
        },
        ...prev.slice(index + 1),
      ];
    });
  }, [
    category.id,
    category.prevValue,
    category.target,
    formData,
    isField,
    setPt,
  ]);

  const handleCancel = () => {
    setFormData((prev) => ({
      ...prev,
      attachment: "",
    }));
  };

  return (
    <tr className={`odd removeinput ${category.id}`}>
      <td>{numToRoman(id + 1)}</td>
      <td colspan="2">
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {category?.fieldsIdAssignFields?.name}
          {category?.fieldsIdAssignFields?.guidance && (
            <Tooltip
              position="top"
              content={category?.fieldsIdAssignFields?.guidance}
            >
              <img src={info} alt="" />
            </Tooltip>
          )}
        </span>
      </td>
      <td>
        <select
          className="form-select"
          name="unit"
          required={unitValidated.find((id) => category.id === id)}
          onChange={onChangeHandler}
          value={formData.unit}
          style={{ width: "135px" }}
        >
          <option value="">Select Unit</option>
          {allUnitData &&
            allUnitData.length > 0 &&
            allUnitData?.map((ele) => (
              <option key={ele.id} value={ele.id}>
                {ele?.name}
              </option>
            ))}
        </select>
      </td>

      <td>
        <div>{category?.frequencyType || ""}</div>
        <div>{category?.frequencyDate || category?.frequencyMonth || ""}</div>
        <div>{category?.frequencyYear || ""}</div>
      </td>

      <td>
        <input
          disabled={isField}
          type="number"
          name="prevValue"
          onChange={onChangeHandler}
          value={isField ? category.prevValue : formData.prevValue}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      <td>
        <input
          disabled={isField}
          type="number"
          name="target"
          onChange={onChangeHandler}
          value={isField ? category.target : formData.target}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      <td>
        <textarea
          name="comment"
          value={formData.comment}
          onChange={onChangeHandler}
          className="rounded form-control w-100 addFromTxtArea"
        />
      </td>
      {/* <td>
        <input
          name="value"
          value={formData.value}
          onChange={onChangeHandler}
          className="rounded form-control w-100"
        />
      </td> */}

      <td>
        {formData.attachment ? (
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="fileUploader"
          >
            <span>{formData?.attachment?.name.slice(0, 10) + "..."}</span>
            <span
              onClick={handleCancel}
              style={{
                fontWeight: "700",
                cursor: "pointer",
                fontSize: "16px",
                lineHeight: "20px",
              }}
              className="crossBtn"
            >
              X
            </span>
          </span>
        ) : (
          <span
            w-100
            style={{
              position: "relative",
            }}
          >
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose File
            </div>
            <input
              name="attachment"
              onChange={onChangeHandler}
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        )}
      </td>
    </tr>
  );
};

export default observer(WaterFormRow);
