import React from "react";
import Chart from "react-apexcharts";
const NewPieChart = ({ dataSeries,labels,heading,position,download }) => {
  const options = {
    chart: {
      type: "pie",
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },

    labels,
    legend: {
      position: position || "bottom",
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: {
        formatter: (value) => " ", // Tooltip should not show value
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div>
      <div id="chart">
      {heading && <h5>{heading ? heading : ""}</h5>}
        <Chart options={options} series={dataSeries || [0, 0]} type="pie" height={380} />
      </div>
    </div> 
      
  );
};

// // PropTypes for type-checking
// NewPieChart.propTypes = {
//   dataSeries: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired,
//   labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired,
// };

export default NewPieChart;
