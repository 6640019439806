// RangeAreaChart.js
import React from "react";
import Chart from "react-apexcharts";

const RangeAreaChart = () => {
  const options = {
    series: [
      {
        name: "New York Temperature",
        data: [
          { x: "Jan", y: [-2, 4] },
          { x: "Feb", y: [-1, 6] },
          { x: "Mar", y: [3, 10] },
          { x: "Apr", y: [8, 16] },
          { x: "May", y: [13, 22] },
          { x: "Jun", y: [18, 26] },
          { x: "Jul", y: [21, 29] },
          { x: "Aug", y: [21, 28] },
          { x: "Sep", y: [17, 24] },
          { x: "Oct", y: [11, 18] },
          { x: "Nov", y: [6, 12] },
          { x: "Dec", y: [1, 7] },
        ],
      },
    ],
    chart: {
      height: 350,
      type: "rangeArea",
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: false,
      },
    },
    colors: ["#556EE6"],

    stroke: {
      curve: "monotoneCubic",
    },
    title: {
      text: "New York Temperature (all year round)",
      align: "left",
      color: "#495057",
      style: {
        color: "#B1B8C0",
        fontWeight: "Semi Bold",
        fontSize: "15px",
      },
    },
    markers: {
      hover: {
        sizeOffset: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return val + "°C";
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={options.series}
        type="rangeArea"
        height={options.chart.height}
      />
    </div>
  );
};

export default RangeAreaChart;
