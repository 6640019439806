import { Navigate, Outlet } from "react-router-dom";
import useStore from "../hooks/useStore";

const PrivateRoutes = ({ admin }) => {
  const {
    userStore: { isAdminLoggedIn, isLoggedIn },
  } = useStore();
  return admin ? (
    isAdminLoggedIn ? (
      <Outlet />
    ) : (
      <Navigate to="/admin" />
    )
  ) : isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/company" />
  );
};

export default PrivateRoutes;
