import { observer } from "mobx-react";
import Layout from "../../utils/layout";
import useStore from "../../hooks/useStore";
import React, { useEffect, useState } from "react";
import PaginationControls from "../../datatable/paginationControls";
import LogActivityTableBody from "./LogActivityTableBody";
import LogActivityFilter from "./LogActivityFilter";

const LogActivity = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    dateRange: { startDate: null, endDate: null },
    year: null,
    category: [],
    subCategory: [],
    kpi: [],
    employee:[],
    action: null,
    department: null,
    username: null,
    email: null,
    formId: null,
  });

  const {
    companyStore: { fetchLogActivity, logActivity },

  } = useStore();
  const tablehead = [
    "Category",
    "Sub Category",
    "Kpi Name",
    "FormId",
    "User Name",
    "Role",
    "Department",
    "Action",
    "Activity Date",
    // "Frequecny Date",
    "Year",
    "Ip",
    "Request",
  ];
  useEffect(() => {
    fetchLogActivity({
      page,
      pageSize: 10,
      filter: {
        ...filters,
        category: filters.category?.map((e) => e.value),
        subCategory: filters.subCategory?.map((e) => e.value),
        kpi: filters.kpi?.map((e) => e.value),
        employee: filters.employee?.map((e) => e.value),
      },
    });
  }, [fetchLogActivity, page, filters]);

  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="">
            <div className="">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Activity Log</h4>
                  <div className="page-title-right"></div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <LogActivityFilter
                      filters={filters}
                      setFilters={setFilters}
                    />

                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table align-middle table-bordered table-nowrap mb-0">
                            <thead>
                              <tr>
                                {tablehead?.map((item, index) => (
                                  <th key={index}>{item}</th>
                                ))}
                              </tr>
                            </thead>
                            <LogActivityTableBody
                              logRaw={logActivity?.records || 0}
                            />
                          </table>
                        </div>
                        <PaginationControls
                          page={page}
                          setPage={setPage}
                          totalPages={logActivity?.totalPages}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default observer(LogActivity);
