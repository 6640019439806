import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";

const CompanyList = () => {
  const navigate = useNavigate();
  const {
    companyStore: { getCompanyData, companiesList },
  } = useStore();

  const tablehead = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Employee Range",
    "Company Name",
    "Region",
    "Industry",
    "Allowed Employee",
    "Address",
    "Status",
    "Action",
  ];

  useEffect(() => {
    getCompanyData();
  }, [getCompanyData]);

  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Companies</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Companies</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            id="searchTableList"
                            placeholder="Search..."
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="text-sm-end">
                        <Link to={"/admin/add-company"}>
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#newCustomerModal"
                            className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                          >
                            <i className="mdi mdi-plus me-1"></i> New Company
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive table-wrap">
                    <table className="table align-middle table-bordered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          {tablehead?.map((item, index) => (
                            <th key={index}>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {companiesList?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.firstName}</td>
                            <td>{item?.lastName}</td>
                            <td>{item?.email}</td>
                            <td>{item?.phoneNumber}</td>
                            <td>{item?.employeeRange}</td>
                            <td>{item?.companyName}</td>
                            <td>{item?.region}</td>
                            <td>{item?.industry}</td>
                            <td>{item?.allowedEmployee}</td>
                            <td>{item?.address}</td>
                            <td>{item?.status ? "Active" : "Inactive"}</td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                                onClick={() =>
                                  navigate("/admin/add-company", {
                                    state: item,
                                  })
                                }
                              >
                                <i className="mdi mdi-pencil font-size-14"></i>{" "}
                                Edit
                              </button>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="table">
                    <table
                      className="table align-middle table-nowrap dt-responsive nowrap w-100"
                      id="customerList-table"
                    >
                      <thead className="table-light">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name</th>
                          <th>Slug</th>
                          <th>Position</th>
                          <th>Create Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(caregoryList) &&
                          caregoryList.map((category, id) => {
                            return (
                              <CategoryRow
                                handleDeleteCategory={handleDeleteCategory}
                                handleEditCategory={handleEditCategory}
                                category={category}
                                key={id}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CategoryModal
        open={showCategoryModal}
        onClose={handleCloseNewCategoryMdoal}
        mode={mode}
      /> */}
      {/* <RemoveCategoryModal
        open={showDeleteModal}
        onClose={handleCloseDeleteCategoryMdoal}
      /> */}
    </Layout>
  );
};

export default observer(CompanyList);
