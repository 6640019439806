import CustomTooltip from "../utils/tooltip/tooltip";
import info from "../assets/icons/info.svg";

const InfoIcon = ({ content, position }) => (
  <CustomTooltip position={position} content={content}>
    <img src={info} alt="" />
  </CustomTooltip>
);

export default InfoIcon;
