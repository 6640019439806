import { useMemo } from "react";
import useStore from "../../hooks/useStore";
import BarChart from "../charts/barChart";
import { observer } from "mobx-react";
import DonutChart from "../charts/donutChart";
import useWindowSize from "../../hooks/useWindowSize";
import { STATIC_GROUPS,REVENUE_LABELS } from "../../constants/constants";

import ColumnChart from "../charts/columnChart";

function EnergyDashboardAccordion({ year }) {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { revenuePageData },
  } = useStore();
  const data = useMemo(
    () => dashboardDetails?.charts?.energy?.barChart,
    [dashboardDetails?.charts?.energy?.barChart]
  );

  const { width } = useWindowSize();
// Prepare energy data
const revenueData = useMemo(() => {
  const filteredData =
    revenuePageData?.fieldData?.filter(
      (e) => e?.type === "energy" && e?.title
    ) || [];

  const revenueUnit =
    revenuePageData?.revenue?.find((e) => e?.type === "all")?.unit
      ?.shortName || "";

  // Define the groups from REVENUE_LABELS for energy
  const groups = Object.keys(REVENUE_LABELS.energy);

  // Create a map of groups with zero values for missing years
  const years = [year - 1, year];
  const dataMap = groups.reduce((acc, group) => {
    acc[group] = years.reduce((yearAcc, y) => {
      yearAcc[y] = 0; // Default to zero for each year
      return yearAcc;
    }, {});
    return acc;
  }, {});

  // Populate dataMap with actual values
  filteredData.forEach((ele) => {
    const title = ele?.title;
    const value = ele?.value || 0;
    const matchingGroup = Object.keys(REVENUE_LABELS.energy).find(
      (group) => REVENUE_LABELS.energy[group] === title
    );

    if (matchingGroup) {
      years.forEach((y) => {
        if (ele?.year.toString() === y.toString()) {
          dataMap[matchingGroup][y] = value;
        }
      });
    }
  });

  // Transform dataMap into the format required for charts
  return groups.map((group) => ({
    name: REVENUE_LABELS.energy[group],
    unit: revenueUnit,
    data: years.map((y) => dataMap[group][y]),
  }));
}, [revenuePageData?.fieldData, revenuePageData?.revenue, year]);
  return (
    <>
    <div className="row h-100  ">
      {/* {data &&
        data.length !== 0 &&
        data?.map?.((ele, index) => (
          <div key={index} className="col-xl-6  mb-3">
            <div className="card h-100  ">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between ">
                  <h4 className="card-title mb-4 donut-heading">
                    {ele?.name}
                  </h4>

                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6>{dashboardDetails?.charts?.energy?.unit}</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <BarChart
                  series={[ele] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.energy?.unit}
                />
              </div>
            </div>
          </div>
        ))} */}
      { revenueData.map(({ name, data, unit }, index) => (
          <div key={`revenue-chart-${index}`} className="col-xl-6 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4 donut-heading">
                  {name}
                  </h4>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2">
                      <div>
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">{dashboardDetails?.charts?.energy?.unit} / {unit} </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <BarChart
                  series={[{ data, name }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.energy?.unit +"/"+ unit}
                />
              </div>
            </div>
          </div>
      ))}
      
    </div>
    <div className="row h-100  ">
      <div key="renerableenergy" className="col-xl-6  mb-3">
        <div className="card h-100  ">
          <div className="card-body">
            <div className="d-flex justify-content-between ">
              <h4 className="card-title mb-4 donut-heading">
                Renewable energy
              </h4>

              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 ">
                  {" "}
                  <div className="">
                    <p className="text-muted mb-1">Unit</p>
                    <h6>{dashboardDetails?.charts?.energy?.unit}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="donut">
              <DonutChart
                position={width < 1500 ? "bottom" : "right"}
                data={
                  dashboardDetails?.charts?.energy?.renewableBarChart?.map(
                    (ele) => ele.value
                  ) || []
                }
                labels={
                  dashboardDetails?.charts?.energy?.renewableBarChart?.map(
                    (ele) => ele.label
                  ) || []
                }
                unit={dashboardDetails?.charts?.energy?.unit}
              />
            </div>
          </div>
        </div>
      </div>

      <div key="nonrenerableenergy" className="col-xl-6  mb-3">
        <div className="card h-100  ">
          <div className="card-body">
            <div className="d-flex  justify-content-between ">
              <h4 className="card-title mb-4">Non-Renewable energy</h4>

              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 ">
                  {" "}
                  <div className="">
                    <p className="text-muted mb-1">Unit</p>
                    <h6>{dashboardDetails?.charts?.energy?.unit}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="donut">
              <DonutChart
                position={width < 1500 ? "bottom" : "right"}
                data={
                  dashboardDetails?.charts?.energy?.nonRenewableBarChart?.map(
                    (ele) => ele.value
                  ) || []
                }
                unit={dashboardDetails?.charts?.energy?.unit}
                labels={
                  dashboardDetails?.charts?.energy?.nonRenewableBarChart?.map(
                    (ele) => ele.label
                  ) || []
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</>
  );
}

export default observer(EnergyDashboardAccordion);
