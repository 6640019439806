import React from "react";
import Layout from "../../utils/layout";
import LineChart from "../../components/charts/lineChart";
import DashedLineChart from "../../components/charts/dashedLineChart";
import AreaChart from "../../components/charts/areaChart";
import ColumnChart from "../../components/charts/columnChart";
import DumbbellColumnChart from "../../components/charts/dumbbellColumnChart";
import RangeAreaChart from "../../components/charts/rangeAreaChart";
import DumbbellTimelineCharts from "../../components/charts/dumbbellTimelineChart";
import FunnelChart from "../../components/charts/funnelChart";
import ColumnDataLabels from "../../components/charts/columnWithDataLabels";
import BarChart from "../../components/charts/barChart";
import LineAreaColumnChart from "../../components/charts/lineColumnAreaChart";
import RadialBarChart from "../../components/charts/RadialBarChart";
import PieChart from "../../components/charts/pieChart";
import DonutChart from "../../components/charts/donutChart";
import StackedBarChart from "../../components/charts/stackedChart";
import GroupStackedChart from "../../components/charts/groupStackedChart";

const Charts = () => {
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-flex flex-column gap-4 flex-col">
          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Line with Data Labels</h4>
                  <LineChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Dashed Line</h4>
                  <DashedLineChart />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Spline Area</h4>
                  <AreaChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Column Chart</h4>
                  <ColumnChart />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Dumbbell Column Chart</h4>
                  <DumbbellColumnChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Range Area Chart</h4>
                  <RangeAreaChart />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Dumbbell TimeLine Chart</h4>
                  <DumbbellTimelineCharts />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Funnel Chart</h4>
                  <FunnelChart />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Column with Data label</h4>
                  <ColumnDataLabels />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Bar Chart</h4>
                  <BarChart />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Line Area Column Chart</h4>
                  <LineAreaColumnChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Radial Bar Chart</h4>
                  <RadialBarChart />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Pie Chart</h4>
                  <PieChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Donut Chart</h4>
                  <DonutChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Stacked Chart</h4>
                  <StackedBarChart />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4"> Group Stacked Chart</h4>
                  <GroupStackedChart />
                </div>
              </div>
            </div>
          </div>

          {/* end of charts  */}
        </div>
      </div>
    </Layout>
  );
};

export default Charts;
