import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import useStore from "../hooks/useStore";
import { useAuth } from "../context/authProvider";
import info from "../assets/icons/info.svg";
import CustomTooltip from "../utils/tooltip/tooltip";

function Financials({ groups, guidance, units }) {
  const {
    companyStore: { setPermissibleLimit, fetchFinancialData, permissibleLimit,kpiId,catId,subCatId },
  } = useStore();
  const [validated, setValidated] = useState(false);
  const { showAlert } = useAuth();
  const [show, setShow] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [payload, setPayload] = useState(
    groups.map((ele) => ({ ...ele, value: 0, unit: 0 }))
  );
  const [payloadData, setPayloadData] = useState();


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    fetchFinancialData({
      year,
      payloadData: {
        kpiId,
        subCatId,
        catId,
        year: `${year}`,
      }
    },[year,payloadData]);
  };

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    setPayload((prev) =>
      prev.map((ele) => ({
        ...ele,
        ...(ele.group === name && { value: Number(value) }),
      }))
    );
  }, []);

  const onUnitChangeHandler = useCallback(({ target: { name, value } }) => {
    setPayload((prev) =>
      prev.map((ele) => ({
        ...ele,
        ...(ele.group === name && { unit: Number(value) }),
      }))
    );
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity()) {
      setPermissibleLimit({ payload, showAlert, setShow, year });
    }
    setValidated(true);
  };

  useEffect(() => {
    if (year)
      fetchFinancialData({
        year,
        payloadData: {
          kpiId,
          subCatId,
          catId,
          year: `${year}`,
        }
      
      });
  }, [catId, fetchFinancialData, kpiId, subCatId, year,payloadData]);

  useEffect(() => {
    if (permissibleLimit) {
      setPayload((prev) =>
        prev.map((e) => ({
          ...e,
          value:
            permissibleLimit?.find((p) => p.group === e.group)?.value || "",
          unit:
            permissibleLimit?.find((p) => p.group === e.group)?.unitId || "",
        }))
      );
    }
  }, [permissibleLimit]);

  return (
    <>
      <button
        className="btn btn-success waves-effect waves-light addCustomers-modal"
        onClick={handleShow}
        style={{
          marginRight: "10px",
        }}
      >
        Absolute Data
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Form onSubmit={handleSave} validated={validated} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Absolute Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column gap-3">
              <label>Year</label>
              <select
                className="form-select"
                value={year || new Date().getFullYear()}
                onChange={(event) => setYear(Number(event.target.value))}
              >
                {Array.from({ length: 5 })
                  .map((_, i) => new Date().getFullYear() - i)
                  .map((v) => (
                    <option key={v} value={v}>{`FY ${v}-${v + 1}`}</option>
                  ))}
              </select>
              {payload.map((ele, index) => (
                <div
                  className="d-flex gap-2 flex-column"
                  key={ele.group || index}
                >
                  <label>
                    {ele.label}
                    <CustomTooltip position="top" content={guidance}>
                      <img src={info} alt="" />
                    </CustomTooltip>
                  </label>
                  <div className="d-flex flex-row gap-2">
                    <input
                      name={ele.group}
                      value={ele?.value || ""}
                      type="number"
                      className="rounded form-control w-100 numberCountInput"
                      onChange={onChangeHandler}
                      required
                      readOnly={!!permissibleLimit && permissibleLimit.length > 0}
                    />
                    <select
                      value={ele.unit}
                      name={ele.group}
                      onChange={onUnitChangeHandler}
                      className="form-select w-50"
                      required
                      readOnly={!!permissibleLimit && permissibleLimit.length > 0}
                    >
                      <option value="">Select Unit</option>
                      {units?.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.shortName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={handleClose}>
              Close
            </Button>
            {(!permissibleLimit || permissibleLimit.length === 0) && (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default observer(Financials);
