import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./style.css";

function DynamicTable({ columns, pageSize, data }) {
  const getTrProps = (state, rowInfo, column, instance) => {
    if (rowInfo) {
      const { original } = rowInfo;

      const rowClass =
        original.approvalStatus === "approved" ? "row-approved" : " ";
      return {
        className: rowClass,
      };
    }
    return {};
  };

  return (
    <ReactTable
      columns={columns}
      //   loading={true}
      data={data}
      showPaginationBottom={false}
      pageSize={pageSize}
      getTrProps={getTrProps}
    />
  );
}

export default DynamicTable;
