import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/authProvider";

const AssignCategory = ({ setTab }) => {
  const { token, showAlert } = useAuth();
  const location = useLocation();
  const companyData = location.state;

  const [categoryList, setCategoryList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (companyData?.id) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}assign-companies/allcategories/${companyData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setCategoryList(result.data);
            const assignedCategories = result.data.filter(({ status }) => status);
            setCategories(
              assignedCategories.length === 0
                ? []
                : assignedCategories.map(({ id }) => id)
            );
            setUpdate(assignedCategories.length > 0);
          }
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
          showAlert("Failed to load categories", false);
        });
    }
  }, [companyData?.id, token, showAlert]);

  const handleSave = useCallback(() => {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const body = JSON.stringify({
      companyId: companyData?.id,
      categories,
    });
    const requestOptions = {
      method: "POST",
      headers,
      body,
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}assign-companies/${
        update ? "updatecategory" : "createCategory"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showAlert(result.msg || result.message, result.status || false);
      })
      .catch((error) => {
        console.error("Error saving categories:", error);
        showAlert("Failed to save categories", false);
      });
  }, [categories, companyData?.id, token, update, showAlert]);

  const handleCheckboxChange = (id) => {
    setCategories((prevCategories) =>
      prevCategories.includes(id)
        ? prevCategories.filter((catId) => catId !== id)
        : [...prevCategories, id]
    );
  };

  const handleAssignCategory = () => {
    handleSave();
    setTab("Sub Category");
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table align-middle table-bordered table-nowrap mb-0">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Category</th>
              <th>Assign</th>
            </tr>
          </thead>
          <tbody>
            {categoryList.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  <div className="form-check font-size-20 p-0">
                    <input
                      className="form-check-input m-0 float-none"
                      type="checkbox"
                      checked={categories.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <button onClick={handleSave} className="btn btn-primary px-4">
            Save
          </button>
          <button onClick={handleAssignCategory} className="btn btn-primary px-4 mx-4">
            Save & Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AssignCategory;
