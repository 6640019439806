import { observer } from "mobx-react";
import React from "react";
import BrsrReport from "./components/brsrReport";
import GriReport from "./components/griReport";
import { useLocation, useNavigate } from "react-router-dom";

const Report = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  if (!state?.reportType) {
    navigate("/company/dashboard");
    return null;
  }
  return (
    <>
      {state?.reportType === "BRSR" && <BrsrReport />}
      {state?.reportType === "GRI" && <GriReport />}
    </>
  );
};

export default observer(Report);
