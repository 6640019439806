const RecordExists = () => (
  <div
    style={{
      display: "flex",
      height: "10rem",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <h3 className="mb-sm-0 font-size-18">
      A record already exists with either monthly or daily frequencies.
    </h3>
  </div>
);

export default RecordExists;
