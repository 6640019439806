export default async function fetchData(endPoint, options) {
  const url = `${process.env.REACT_APP_BASE_URL}${endPoint}`;
  const token = JSON.parse(
    localStorage.getItem("access-token") ||
      localStorage.getItem("admin-access-token")
  );
  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token.token}`;
  }
  return await fetch(url, {
    headers,
    ...options,
  });
}
