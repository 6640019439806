import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../utils/layout";
import { Link } from "react-router-dom";

import FieldModal from "./FieldModal";
import CategoryRow from "./FieldRow";
import RemoveSubCategoryModal from "./RemoveFieldModal";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";

const Fields = () => {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteCategory] = useState(false);
  const [mode, setMode] = useState("");
  const [updateField, setUpdateField] = useState(null);
  const [fieldsPayload, setFieldsPayload] = useState({
    catId: "",
    subCatId: "",
    kpiId: "",
  });

  const {
    dashboardStore: {
      fetchCategories,
      fetchSubCategories,
      fetchKpi,
      fetchFields,
      fieldsList,
      searchFields,
      filterFields,
      categoryList,
      subCategoryList,
      kpiList,
    },
  } = useStore();

  const handleNewCategory = () => {
    setShowCategoryModal(!showCategoryModal);
    setMode("create");
  };

  const handleDeleteCategory = () => {
    setShowDeleteCategory(true);
  };

  const handleEditCategory = (field) => {
    setShowCategoryModal(!showCategoryModal);
    setMode("edit");
    setUpdateField(field);
  };

  const handleCloseNewCategoryModal = () => {
    setShowCategoryModal(false);
    setUpdateField(null);
  };

  const handleCloseDeleteCategoryModal = () => {
    setShowDeleteCategory(!showDeleteModal);
  };

  useEffect(() => {
    fetchCategories();
    fetchKpi();
    fetchSubCategories();
  }, [fetchCategories, fetchKpi, fetchSubCategories]);

  useEffect(() => {
    if (Object.values(fieldsPayload).every((val) => val)) {
      fetchFields(JSON.stringify(fieldsPayload));
    }
  }, [fetchFields, fieldsPayload]);

  const filterationData = useCallback(() => {
    const categoryData = categoryList.map((cat) => ({
      id: cat.id,
      name: cat.name,
    }));
    const subCategoryData = subCategoryList
      ?.map((subCat) => ({
        id: subCat.id,
        name: subCat.name,
        catId: subCat.catId,
      }))
      ?.filter((subCat) =>
        fieldsPayload.catId ? subCat.catId === fieldsPayload.catId : true
      );
    const kpiData = kpiList
      .map((kpi) => ({
        id: kpi.id,
        name: kpi.name,
        catId: kpi.catId,
        subCatId: kpi.subCatId,
      }))
      ?.filter((kpi) =>
        fieldsPayload.catId && fieldsPayload.subCatId
          ? kpi.subCatId === fieldsPayload.subCatId &&
            kpi.catId === fieldsPayload.catId
          : true
      );
    return [
      { key: "catId", data: categoryData, label: "Category" },
      { key: "subCatId", data: subCategoryData, label: "Sub Category" },
      { key: "kpiId", data: kpiData, label: "Kpi" },
    ];
  }, [categoryList, fieldsPayload, kpiList, subCategoryList]);

  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Fields</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Field</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2 align-items-center">
                    <div className="col-sm-8">
                      <div className="search-filter">
                        {filterationData().map((filter) => (
                          <select
                            key={filter.key}
                            onChange={(e) =>
                              setFieldsPayload((prev) => ({
                                ...prev,
                                [filter.key]: e.target.value,
                                ...(filter.key === "catId" && {
                                  subCatId: null,
                                  kpiId: null,
                                }),
                                ...(filter.key === "subCatId" && {
                                  kpiId: null,
                                }),
                              }))
                            }
                            value={fieldsPayload[filter.key]}
                            className="adminFiledSelect form-control"
                          >
                            <option value="">Select {filter.label}</option>
                            {filter.data.map((f) => (
                              <option key={f.id} value={f.id}>
                                {f.name}
                              </option>
                            ))}
                          </select>
                        ))}

                        <div className="search-box d-inline-block">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              id="searchTableList"
                              placeholder="Search..."
                              value={searchFields}
                              onChange={(e) => filterFields(e.target.value)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="text-sm-end">
                        <button
                          type="button"
                          onClick={handleNewCategory}
                          data-bs-toggle="modal"
                          data-bs-target="#newCustomerModal"
                          className="btn btn-success waves-effect waves-light  me-2 addCustomers-modal"
                        >
                          <i className="mdi mdi-plus me-1"></i> New Field
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light me-2 addCustomers-modal"
                        >
                          <i className="mdi mdi-plus me-1"></i> Import
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-light  me-2 addCustomers-modal"
                        >
                          <i className="mdi mdi-download me-1"></i>
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                  {Object.values(fieldsPayload).every((val) => val) && (
                    <div className="table-responsive ">
                      <table className="table align-middle table-bordered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Category</th>
                            <th>Sub-category</th>
                            <th>KPI</th>
                            <th> Name</th>
                            <th>Group</th>
                            <th>Create Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(fieldsList) &&
                            fieldsList?.map((category, id) => {
                              return (
                                <CategoryRow
                                  handleDeleteCategory={handleDeleteCategory}
                                  handleEditCategory={() =>
                                    handleEditCategory(category)
                                  }
                                  category={category}
                                  key={id}
                                  index={id + 1}
                                />
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FieldModal
        open={showCategoryModal}
        onClose={handleCloseNewCategoryModal}
        mode={mode}
        initialState={updateField}
        fieldsPayload={fieldsPayload}
      />
      <RemoveSubCategoryModal
        open={showDeleteModal}
        onClose={handleCloseDeleteCategoryModal}
      />
    </Layout>
  );
};

export default observer(Fields);
