import { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import ColumnChart from "../charts/columnChart";
import { MONTHS_ARRAY_FULLNAME, TARGET_LABEL } from "../../constants/constants";
import { useAuth } from "../../context/authProvider";
import { getRoundedValue } from "../../utils/helpers";
import Spinner from "../spinner/Spinner";

const DashboardHistogram = ({ title, group, reportType }) => {
  const { showAlert } = useAuth();
  const [unit, setUnit] = useState("");
  const [data, setData] = useState();
  const [waterFilter, setWaterFilter] = useState({
    month: "",
    fromMonth: "",
    toMonth: "",
    year: new Date().getFullYear(),
    type: "",
  });
  const [head, setHead] = useState([
    MONTHS_ARRAY_FULLNAME[new Date().getMonth()],
  ]);
  const [permissibleLimit, setPermissibleLimit] = useState(null);

  const onChangeHandler = (event) => {
    setWaterFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    setHead([
      waterFilter.type === "monthly"
        ? waterFilter.month
        : `${waterFilter.fromMonth} - ${waterFilter.toMonth}`,
    ]);
    fetchData(waterFilter, showAlert);
  };

  const fetchData = useCallback(
    (waterFilter, showAlert) => {
      const raw =
        waterFilter.type === "yearly"
          ? {
              type: waterFilter.type,
              fromMonth: waterFilter.fromMonth,
              toMonth: waterFilter.toMonth,
              year: waterFilter.year,
              group,
              reportType,
            }
          : {
              type: waterFilter.type,
              month:
                waterFilter.month ||
                MONTHS_ARRAY_FULLNAME[new Date().getMonth()],
              group,
              reportType,
            };
      const params = new URLSearchParams(raw).toString();
      fetch(process.env.REACT_APP_BASE_URL + `company/dashboard?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("access-token"))?.token
          }`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setData(
              result.data?.chartData?.map((ele) => ({
                ...ele,
                data: ele.data.map((e) => getRoundedValue(e)),
              }))
            );
            setUnit(result?.data.unit);
            if (result?.data?.limit) {
              setPermissibleLimit({ limit: result?.data?.limit });
            }
          }
          showAlert?.(result.msg || result.message, result.status || false);
        });
    },
    [group, reportType]
  );

  useEffect(() => {
    fetchData(waterFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="card dashboard-histogram h-100" key={title}>
      <div className="card-body">
        <div
          className="w-100"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex flex-wrap w-75 align-items-start">
            <h6 className="mb-3 text-muted">{title}</h6>
          </div>
          <div className="">
            <p className="text-muted mb-1">Unit</p>
            <div className="d-flex flex-wrap  align-items-end">
              <h3 className="card-title me-2 mb-4">{unit}</h3>
            </div>
          </div>
        </div>
        <div className="dashboard-histogram-chart">
          <div className="dashboard-chart-grid mb-4">
            <Row>
              <Col lg="3" sm="3">
                <Form.Label>Filter Type</Form.Label>
                <Form.Select
                  name="type"
                  value={waterFilter.type}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Type</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </Form.Select>
              </Col>
              {waterFilter.type === "monthly" ? (
                <Col lg="3" sm="3">
                  <Form.Label>Month</Form.Label>
                  <Form.Select
                    name="month"
                    value={waterFilter?.month}
                    onChange={onChangeHandler}
                  >
                    <option value="">Select Month</option>
                    {MONTHS_ARRAY_FULLNAME.filter(
                      (_, index) => index <= new Date().getUTCMonth()
                    ).map((month) => (
                      <option value={month}>{month}</option>
                    ))}
                  </Form.Select>
                </Col>
              ) : waterFilter.type === "yearly" ? (
                <>
                  <Col lg="3" sm="3">
                    <Form.Label>Year</Form.Label>
                    <Form.Select
                      name="year"
                      value={waterFilter?.year}
                      onChange={onChangeHandler}
                    >
                      <option value="">Select Year</option>
                      {Array.from({ length: 20 })
                        .map((_, index) => new Date().getFullYear() - index)
                        .map((year) => (
                          <option value={year}>{year}</option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col lg="3" sm="3">
                    <Form.Label>From Month</Form.Label>
                    <Form.Select
                      name="fromMonth"
                      value={waterFilter?.fromMonth}
                      onChange={onChangeHandler}
                    >
                      <option value="">Select Month</option>
                      {MONTHS_ARRAY_FULLNAME.map((month) => (
                        <option value={month}>{month}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col lg="3" sm="3">
                    <Form.Label>To Month</Form.Label>
                    <Form.Select
                      name="toMonth"
                      value={waterFilter?.toMonth}
                      onChange={onChangeHandler}
                    >
                      <option value="">Select Month</option>
                      {MONTHS_ARRAY_FULLNAME.map((month) => (
                        <option value={month}>{month}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </>
              ) : null}
            </Row>
            <div className="dashboardSearchBtn">
              <Button onClick={onSearchHandler}>Search</Button>
            </div>
          </div>
          <div className="dashboard-histogram-chart ">
            <ColumnChart
              data={data}
              categories={head}
              title={title}
              hover={title}
              unit={unit}
              yaxis={
                permissibleLimit
                  ? [
                      {
                        y: getRoundedValue(permissibleLimit.limit) || 0,
                        borderColor: "#FF4560",
                        label: {
                          borderColor: "#FF4560",
                          style: {
                            color: "#fff",
                            background: "#FF4560",
                          },
                          text: TARGET_LABEL[reportType][group],
                        },
                      },
                    ]
                  : []
              }
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHistogram;
