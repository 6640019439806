import React from "react";

const RemoveSubCategoryModal = ({ open, onClose }) => {
  return (
    <div
      className={`modal fade ${open ? "show": ""}`}
      id="removeItemModal"
      tabIndex="-1"
      onClick={onClose}
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-body px-4 py-5 text-center">
            <button
              type="button"
              onClick={onClose}
              className="btn-close position-absolute end-0 top-0 m-3"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to remove this Kpi ?
            </p>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-danger" id="remove-item">
                Remove Now
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
                id="close-removeCustomerModal"
                data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveSubCategoryModal;
