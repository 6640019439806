import React from "react";

function CustomSpinner() {
  return (
    <div
      className="spinner-border text-dark "
      style={{ width: "3rem", height: "3rem", margin: "0 auto" }}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default CustomSpinner;
