import ColumnChart from "../charts/columnChart";
import DonutChart from "../charts/donutChart";
import { CHARTS_LABEL } from "../../constants/constants";
import { useMemo, useState } from "react";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { Accordion } from "react-bootstrap";
import AqiAccordion from "./aqiAccordion";
import ShowPerformance from "../showPerformance";
import { getRoundedValue } from "../../utils/helpers";
import useWindowSize from "../../hooks/useWindowSize";
import CustomSpinner from "../spinner/Spinner";

function DashboardAqi({ year }) {
  const {
    dashboardStore: { dashboardDetails },
  } = useStore();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi;
    const result = ["A1", "B1"].map((group) => {
      return {
        name: CHARTS_LABEL.energy[group],
        data: [year - 1, year]?.map((year) =>
          group === "C"
            ? tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )["C1"]?.prevValue ||
              0 +
                tempData?.[year]?.reduce(
                  (acc, curr) => ({ ...acc, ...curr }),
                  {}
                )["C2"]?.prevValue ||
              0
            : tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
        ),
        target: [year - 1, year]?.map((year) =>
          group === "C"
            ? tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )["C1"]?.prevValue ||
              0 +
                tempData?.[year]?.reduce(
                  (acc, curr) => ({ ...acc, ...curr }),
                  {}
                )["C2"]?.prevValue ||
              0
            : tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
        ),
      };
    });
    // if (tempData?.totalGhgEmission) {
    //   result.push(tempData.totalGhgEmission);
    // }
    if (tempData?.Scope3) {
      result.push(tempData.Scope3);
    }
    if (tempData?.totalGhgEmissionFinished) {
      result.push(tempData.totalGhgEmissionFinished);
    }
    return result;
  }, [dashboardDetails?.charts?.aqi, year]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi?.[year];
    const result = ["A1", "B1"].map((group) =>
      group === "C"
        ? tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.["C1"]
            ?.prevValue ||
          0 +
            tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.["C2"]
              ?.prevValue ||
          0 +
          tempData?.["Scope3"]?.data[0] ||
          0
        : tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
            ?.prevValue || 0
    );
    const labels = ["A1", "B1"].map((group) => CHARTS_LABEL.energy[group]);
    // if (dashboardDetails?.charts?.aqi?.ghgEmissionIntensity) {
    //   result.push(dashboardDetails?.charts?.aqi?.ghgEmissionIntensity?.data[1]);
    //   labels.push("GHG Emission Intensity/Revenue");
    // }
    //  if (dashboardDetails?.charts?.aqi?.totalGhgEmissionFinished) {
    //   result.push(dashboardDetails?.charts?.aqi?.totalGhgEmissionFinished?.data[1]);
    //   labels.push("Total GHG Emission Intensity/Finished goods quantity produced");
    // }
    if (dashboardDetails?.charts?.aqi?.Scope3) {
      result.push(dashboardDetails?.charts?.aqi?.Scope3?.data[1]);
      labels.push("Scope 3 Emission");
    }

    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.aqi, year]);

  const { width } = useWindowSize();

  return (
    <div className="row h-100">
      <div className="col-xl-12">
        <div className="card h-100 dashboard-card ">
          <div className="card-body ">
            <div className="d-flex flex-wrap align-items-start">
              <h5 className="mb-3 text-muted"> Emission Report</h5>
              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 ">
                  {" "}
                  <div className="">
                    <p className="text-muted mb-1">Unit</p>
                    <h5 className="unit-value">
                      {dashboardDetails?.charts?.aqi?.unit}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {dashboardDetails?.charts ? (
              <ColumnChart
                columnWidth={width < 768 ? "100%" : ""}
                data={
                  columnChartData?.map(({ data, name }) => ({
                    data: data.map((e) => getRoundedValue(e, 0)),
                    name,
                  })) || []
                }
                unit={dashboardDetails?.charts?.aqi?.unit}
                categories={[
                  `FY${(year - 1).toString().slice(2)}`,
                  `FY${year.toString().slice(2)}`,
                ]}
                yaxis={[]}
              />
            ) : (
              <div className="d-flex align-items-center justify-content-center h-75">
                <CustomSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-12 h-100 mt-4">
        {/* achievements and target  */}
        <div className="row">
          <div className="col-md-6 mb-4">
            <ShowPerformance label="aqi" />
          </div>

          {/* donut chart  */}
          <div className="col-md-6 mb-4">
            <div className="card mb-0 h-100 dashboard-donut-card">
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-start">
                  <h5 className="mb-3 text-muted donut-heading">
                    Emission Generated by Sources
                  </h5>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">
                          {dashboardDetails?.charts?.aqi?.unit}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {dashboardDetails?.charts ? (
                  <div className="donut">
                    <DonutChart
                      data={doughNutChartData.data || []}
                      labels={doughNutChartData.labels || []}
                      unit={dashboardDetails?.charts?.aqi?.unit}
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-75">
                    <CustomSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion
        className="dashboard-chart-accordion"
        onSelect={handleAccordionToggle}
      >
        <Accordion.Item key={`aqi-accordian`} eventKey={0}>
          <Accordion.Header>
            {isAccordionOpen ? "See less" : "See more"}
          </Accordion.Header>
          <Accordion.Body>
            <AqiAccordion year={year} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
export default observer(DashboardAqi);
