import React from "react";
import Chart from "react-apexcharts";

const RadialBarChart = ({ series, labels }) => {
  const options = {
    series: series || [44, 55],
    chart: {
      height: 390,
      type: "radialBar",
    },
    colors: [ "#35C38F", "#F46A6A"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: (w) => w.globals.series.reduce((a, b) => a + b, 0),
          },
        },
      },
    },
    labels: labels || ["Apples", "Oranges", ],
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={options.series}
        type="radialBar"
        height={options.chart.height}
      />
    </div>
  );
};

export default RadialBarChart;
