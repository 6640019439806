import logo from "../../assets/images/logo-dark-esg.png";
import { Link } from "react-router-dom";

function Signup() {
  return (
    <div className="account-pages">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card overflow-hidden">

              <div className="card-body">
                <div className="p-2">
                <div className="auth-logo">
                    <img src={logo} alt="" className="rounded-circle" />
                  </div>
                  <form className="needs-validation" noValidate action="">
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="useremail"
                        placeholder="Enter email"
                        required
                      />
                      <div className="invalid-feedback">Please Enter Email</div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter username"
                        required
                      />
                      <div className="invalid-feedback">
                        Please Enter Username
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="userpassword" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="userpassword"
                        placeholder="Enter password"
                        required
                      />
                      <div className="invalid-feedback">
                        Please Enter Password
                      </div>
                    </div>

                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>


                    <div className="mt-4 text-center">
                        By registering you agree to the ESG{" "}
                        <Link to="/"className="text-muted">
                          Terms of Use
                        </Link>
                    </div>

                  </form>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <div>
                <p>
                  © 2024 ESG. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
