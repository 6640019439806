import { observer } from "mobx-react";
import useStore from "../hooks/useStore";
import { useMemo } from "react";
import CustomSpinner from "./spinner/Spinner";

const ShowPerformance = ({ label }) => {
  const {
    companyStore: { performance, performanceLoading },
  } = useStore();
  const filteredData = useMemo(
    () =>
      performance
        .filter((ele) => ele.type === label)
        .map(({ title, value }) => ({ title, value })),
    [label, performance]
  );

  const data = useMemo(
    () =>
      Object.entries(
        filteredData.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.title]: [...(acc[curr.title] || []), curr.value],
          }),
          {}
        )
      ).map(([title, values]) => ({
        title,
        values,
      })),
    [filteredData]
  );
  return (
    <div className="card h-100">
      <div className="card-body ">
        <h5
          style={{ textTransform: "capitalize" }}
          className="mb-3 text-muted"
        >{`${label === "aqi" ? "Emission" : label} Performance`}</h5>

        {data.length > 0 ? (
          <div className="d-flex flex-column  p-3  gap-2 ">
            {data.map((ele) =>
              ele.values.every((e) => !e) ? null : (
                <div
                  className={`${ele.title} d-flex flex-column align-items-start`}
                  key={ele.title}
                >
                  <h6 className="mb-3 text-muted">{ele.title}</h6>
                  <ul className="highlights-list w-100 ">
                    {ele.values.map((val, index) =>
                      !val || !val.replaceAll(" ", "") ? null : (
                        <li key={`${ele.title}-${index}-list`}>
                          <p
                            className="text-muted mb-0  "
                            style={{ marginRight: "10px" }}
                          >
                            {val}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center h-75">
            {performanceLoading ? (
              <CustomSpinner />
            ) : (
              <div className="text-center ">
                <img
                  style={{ width: "100px", opacity: "0.4" }}
                  src="/no-data-img.png"
                  alt="No Data Found"
                />
                <h6 className="opacity-50 mt-2">No Data Found</h6>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ShowPerformance);
