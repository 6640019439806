import { observer } from "mobx-react";
import useStore from "../../../hooks/useStore";
import { Accordion, Form } from "react-bootstrap";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GOVERNANCE_STATIC_GROUPS,
  GOVERNANCE_KPI_GROUPS,
  SMALL_GROUPS,
} from "../../../constants/constants";
import { groupByConsecutiveGroups } from "../../../utils/helpers";
import numToRoman from "../../../helpers/numToRoman";
import { useAuth } from "../../../context/authProvider";
import CustomTooltip from "../../../utils/tooltip/tooltip";
import info from "../../info.svg";

const FORM_VALUES = { value: "", attachment: "", comment: "" };

const AddForm = ({ year, dropDownValues, routeSubPath, groupConstant }) => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  // global states
  const { formData, groupsFilled, formId } = location.state || {};

  const {
    formStore: { fields, fetchFields },
    governance: { create },
  } = useStore();

  const { showAlert } = useAuth();
  const [validated, setValidated] = useState(false);

  // states
  const [expanded, setExpanded] = useState(0);
  const [data, setData] = useState([]);
  const [editForm, setEditForm] = useState("");

  // calculations
  const tempGroupPayload = useMemo(
    () =>
      formData ||
      Object.entries(
        fields?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group[0]]: [
              ...(acc[curr.group[0]] ? acc[curr.group[0]] : []),
              { ...curr, ...FORM_VALUES },
            ],
          }),
          {}
        )
      ).map(([key, value]) => ({
        group: key,
        value: value.sort((a, b) => Number(a.position) - Number(b.position)),
        label: GOVERNANCE_STATIC_GROUPS[groupConstant][key],
      })),
    [fields, formData, groupConstant]
  );
  const specificFieldsForPieChart = useMemo(() => [
    "Existence and effectiveness of a risk management framework.",
    "Existence of a whistleblower protection policy.",
    "Voting results of key shareholder resolutions.",
    "Timeliness and accuracy of financial and non-financial reporting.",
    "Compliance with regulatory reporting requirements.",
    "Frequency of ESG-related disclosures and updates.",
    "Frequency of shareholder engagement meetings.",
    "Existence of a documented corporate governance framework.",
    "Compliance with international governance standards and best practices.",
    "Existence of data privacy and cybersecurity policies.",
], []);
dropDownValues = specificFieldsForPieChart;
  // side effects

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  useEffect(() => {
    if (tempGroupPayload) {
      setData(tempGroupPayload);
    }
  }, [tempGroupPayload]);

  useEffect(() => {
    setExpanded(groupsFilled?.length || 0);
  }, [groupsFilled]);

  return (
    <div>
      <Accordion
        className="water-table-accordion"
        defaultActiveKey={"A"}
        activeKey={expanded}
        onSelect={(eventKey) => {
          setExpanded(eventKey);
        }}
      >
        {data?.map((accordion, index) => (
          <Accordion.Item
            key={`${accordion.group}-accordion-item`}
            eventKey={index}
          >
            <Accordion.Header>
              <tr style={{ display: "flex", gap: "4px" }}>
                <td>{index + 1}</td>
                <td colSpan={7}>{accordion.label}</td>
              </tr>
            </Accordion.Header>
            <Accordion.Body>
            <Form validated={validated} noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  if (year && event.target.checkValidity()) {
                    create({
                      data,
                      payload: accordion.value,
                      update: groupsFilled?.includes(accordion.group),
                      formId,
                      showAlert,
                      navigate,
                      setEditForm,
                      totalGroups: data.length,
                      redirectUrl: `/company/${routeSubPath}`,
                      pageUrl: `/company/${routeSubPath}`,
                      year,
                    });
                    return;
                  }
                  setValidated(true);
                  showAlert(!year ? "Please select Year.": "Please fill the required fields.");
                }}
              >
                <table className="table align-middle table-bordered table-nowrap mb-0">
                  <thead>
                    <tr>
                      {["Sr. No.", "Title", "Value", "Attachment"].map(
                        (head) => (
                          <th key={head}>{head}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <TableBody
                    data={accordion}
                    editForm={editForm}
                    setData={setData}
                    setEditForm={setEditForm}
                    dropDownValues={dropDownValues}
                    groupConstant={groupConstant}
                  />
                </table>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

function TableBody({
  data,
  editForm,
  setData,
  setEditForm,
  dropDownValues,
  groupConstant,
}) {
  const { groupsFilled } = useLocation().state || {};
  let id = 0;
  return (
    <tbody>
      {groupByConsecutiveGroups(data.value)
        ?.map((value) => ({
          label: ["A", "B", "C", "D", "E", "F", "G", "H", "I","J"].includes(
            value[0].group
          )
            ? ""
            : GOVERNANCE_STATIC_GROUPS[groupConstant][value[0].group],
          value,
          group: ["A", "B", "C", "D", "E", "F", "G", "H", "I","J"].includes(
            value[0].group
          )
            ? ""
            : value[0].group,
        }))
        .map((ele, ind) => {
          if (!ele.group) {
            return ele?.value?.map((e) =>
              groupsFilled?.includes(data.group) && editForm !== data.group ? (
                <ShowTableRow data={e} sr={SMALL_GROUPS[id++]} />
              ) : (
                <>
                  <TableRow
                    data={e}
                    setData={setData}
                    group={data.group}
                    sr={SMALL_GROUPS[id++]}
                    dropDownValues={dropDownValues}
                  />
                </>
              )
            );
          }
          return (
            <Fragment key={`${ind}-fragment-${ele.group}`}>
              {ele.label ? (
                <tr key={`${ele.group}-tr-sub-tr`}>
                  <td>{SMALL_GROUPS[id++]}</td>
                  <td colSpan={7}>{ele.label}</td>
                </tr>
              ) : (
                <>{console.log(id++)}</>
              )}
              {ele?.value?.map((e, i) =>
                groupsFilled?.includes(data.group) &&
                editForm !== data.group ? (
                  <ShowTableRow data={e} sr={numToRoman(i + 1)} />
                ) : (
                  <>
                    <TableRow
                      data={e}
                      sr={numToRoman(i + 1)}
                      setData={setData}
                      group={data.group}
                      setEditForm={setEditForm}
                      dropDownValues={dropDownValues}
                    />
                  </>
                )
              )}
            </Fragment>
          );
        })}

      {!groupsFilled?.includes(data.group) &&
      GOVERNANCE_KPI_GROUPS[groupsFilled?.length || 0] === data.group ? (
        <tr key="save-button">
          <td colSpan={9} className="text-end">
            <button name={data.group} className="btn btn-primary" type="submit">
              Save
            </button>
          </td>
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={5} className="text-end">
            <CustomTooltip
              content={"Please complete the previous form(s) to activate this button."}
              position="left"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}
      {groupsFilled?.includes(data.group) && (
        <tr key="edit-button">
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function TableRow({ data, sr, setData, group, dropDownValues }) {
  const onChangeHandler = useCallback(
    (event) =>
      setData((prev) =>
        prev.map((ele) => {
          if (ele.group === group) {
            const temp = ele?.value?.map((e) => {
              if (e.fieldName === data.fieldName) {
                const { name, value, files } = event.target;
                return {
                  ...e,
                  ...(name === "attachment"
                    ? {
                        [name]: files[0],
                      }
                    : {
                        [name]: value,
                      }),
                };
              }
              return e;
            });
            return { ...ele, value: temp };
          }
          return ele;
        })
      ),
    [data.fieldName, group, setData]
  );
  const onCancelHandler = useCallback(
    () =>
      setData((prev) =>
        prev.map((ele) => {
          if (ele.group === group) {
            const temp = ele?.value?.map((e) => {
              if (e.fieldName === data.fieldName) {
                return {
                  ...e,
                  attachment: "",
                };
              }
              return e;
            });
            return { ...ele, value: temp };
          }
          return ele;
        })
      ),
    [data.fieldName, group, setData]
  );
  return (
    <tr key={`${data.fieldId}-table-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
       
        {dropDownValues?.includes(data.fieldName) ? (
          <select
            name="value"
            className="form-select"
            onChange={onChangeHandler}
            value={data?.value}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        ) : (
          <input
            className="form-control"
            name="value"
            value={data?.value}
            onChange={onChangeHandler}
            type="number"
            required
          />
        )}
      </td>
      <td>
        {data?.attachment ? (
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="fileUploader"
          >
            <span>{data?.attachment?.name.slice(0, 10) + "..."}</span>
            <span
              onClick={onCancelHandler}
              style={{
                fontWeight: "700",
                cursor: "pointer",
                fontSize: "16px",
                lineHeight: "20px",
              }}
              className="crossBtn"
            >
              X
            </span>
          </span>
        ) : (
          <span
            w-100
            style={{
              position: "relative",
            }}
          >
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose File
            </div>
            <input
              name="attachment"
              onChange={onChangeHandler}
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        )}
      </td>
    </tr>
  );
}

function ShowTableRow({ data, sr }) {
  const attachment = data?.attachment?.name?.slice?.(0, 12);
  return (
    <tr key={`${data.fieldId}-show-table-row`}>
      <td>{sr}</td>
      <td>{data?.fieldName}</td>
      <td>{data?.value}</td>
      {/* <td>{data?.comment || ""}</td> */}
      <td>{attachment ? `${attachment}...` : ""}</td>
    </tr>
  );
}

export default observer(AddForm);
