import { observer } from "mobx-react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import info from "../../../../info.svg";
import { STATIC_GROUPS } from "../../../../../constants/constants";
import Row from "./row";
import useStore from "../../../../../hooks/useStore";
import numToRoman from "../../../../../helpers/numToRoman";
import CustomTooltip from "../../../../../utils/tooltip/tooltip";
import {
  getRoundedValue,
  groupByConsecutiveGroups,
} from "../../../../../utils/helpers";
import { addGroupData } from "../../../../../helpers/groupDataCalculations";

function TableBody({
  data,
  setData,
  frequency,
  editForm,
  setEditForm,
  allGroups,
  groupData,
}) {
  const { groupsFilled } = useLocation().state || {};
  // const {
  //   companyStore: { permissibleLimit },
  // } = useStore();
  const values = useMemo(
    () =>
      (groupByConsecutiveGroups(data?.value) || [])?.map((value) => ({
        label: ["A", "B", "C", "D"].includes(value[0].group)
          ? ""
          : STATIC_GROUPS.energy[value[0].group],
        value,
        group: ["A", "B", "C", "D"].includes(value[0].group)
          ? ""
          : value[0].group,
      })),
    [data?.value]
  );
  let id = 0;
  return (
    <tbody>
      {values?.map((ele, index) => {
        if (!ele.group) {
          return ele?.value?.map((e, i) =>
            groupsFilled?.includes(data.group) && editForm !== data.group ? (
              <ShowDataRow data={e} id={id++} />
            ) : (
              <Row
                data={e}
                index={id++}
                setData={setData}
                group={data.group}
                frequency={frequency}
                totalData={groupData}
              />
            )
          );
        }
        return (
          <>
            <tr key={`${ele.group}-tr-sub-tr`}>
              <td>{numToRoman(id++ + 1)}</td>
              <td colSpan={7}>{ele.label}</td>
            </tr>
            {ele?.value?.map((e, i) =>
              groupsFilled?.includes(data.group) && editForm !== data.group ? (
                <ShowDataRow data={e} id={null} />
              ) : (
                <>
                  <Row
                    data={e}
                    index={null}
                    setData={setData}
                    group={data.group}
                    frequency={frequency}
                    totalData={groupData}
                  />
                </>
              )
            )}
            {ele.group !== "D1" && (
              <RowTotal id={"asdfasacc"} data={addGroupData(ele.value)} />
            )}
          </>
        );
      })}
      {data.group === "D" && (
        <RowTotal
          id={"asdfasacc"}
          data={data.group === "D" ? addGroupData(data.value) : data}
        />
      )}
      {/* {["A"].includes(data.group) && (
        <PermissbleLimit
          value={permissibleLimit?.find((e) => e.group === data.group)?.value}
          type={frequency?.frequencyType}
        />
      )} */}
      {!groupsFilled?.includes(data.group) &&
      allGroups[groupsFilled?.length || 0] === data.group ? (
        <tr key="save-button">
          <td colSpan={9} className="text-end">
            <button name={data.group} className="btn btn-primary" type="submit">
              Save
            </button>
          </td>
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={8} className="text-end">
            <CustomTooltip
              content={
                "Please complete the previous form(s) to activate this button."
              }
              position="left"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}
      {groupsFilled?.includes(data.group) && (
        <tr key="edit-button">
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function RowTotal({ id, data, className }) {
  return (
    <tr className={`subTotalTr ${id}-footer ${className}`}>
      <td>
        <b>{""}</b>
      </td>
      <td
        style={{
          fontWeight: "600",
        }}
      >
        Total
      </td>
      <td></td>
      <td></td>
      <td className="totalTd">{getRoundedValue(data?.prevValue)}</td>
      <td className="totalTd">{getRoundedValue(data?.target)}</td>
      <td></td>
      <td></td>
    </tr>
  );
}

function ShowDataRow({ data, id }) {
  const {
    companyStore: { units },
  } = useStore();
  return (
    <tr className="odd removeinput" key={id}>
      <td>{numToRoman(id + 1)}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <span>
          {
            units?.find((ele) => ele.id === (data?.unitId || data?.unit))
              ?.shortName
          }
        </span>
      </td>

      <td>
        <div>{data?.frequencyType || ""}</div>
        <div>{data?.frequencyDate || data?.frequencyMonth || ""}</div>
        <div>{data?.frequencyYear || ""}</div>
      </td>

      <td className="text-center">
        <span>{getRoundedValue(data.prevValue)}</span>
      </td>
      <td className="text-center">
        <span>{getRoundedValue(data.target)}</span>
      </td>
      <td>
        <span>{data.comment}</span>
      </td>
      <td>
        {data?.attachment?.name
          ? data?.attachment?.name?.slice(0, 10) + "..."
          : ""}
      </td>
    </tr>
  );
}

export default observer(TableBody);
