import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../utils/layout";
import { Link } from "react-router-dom";
import KpiModal from "./KpiModal";
import RemoveKpiModal from "./RemoveKpiModal";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import DynamicTable from "../../datatable/DynamicTable";
import { formatDateForTable } from "../../utils/helpers";
import KpiRow from "./KpiRow";
import Select from "react-select";

const Kpi = () => {
  const [showCategoryModal, setshowCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteCategory] = useState(false);
  const [mode, setMode] = useState("");
  const [updateKpi, setUpdateKpi] = useState(null);
  const [kpiPayload, setKpiPayload] = useState({
    catIds: [],
    subCatId: "",
  });

  const {
    dashboardStore: {
      fetchKpi,
      kpiList,
      searchKpi,
      filterKpi,
      fetchSubCategories,
      categoryList,
      subCategoryList,
      fetchCategories,
    },
    userStore: { userData },
  } = useStore();

  const handleNewCategory = () => {
    setshowCategoryModal(!showCategoryModal);
    setMode("create");
  };

  const handleEditCategory = (category) => {
    setshowCategoryModal(!showCategoryModal);
    setMode("edit");
    setUpdateKpi(category);
  };

  const handleCloseNewCategoryMdoal = () => {
    setshowCategoryModal(false);
    setUpdateKpi(null);
  };

  const handleCloseDeleteCategoryMdoal = () => {
    setShowDeleteCategory(!showDeleteModal);
  };

  useEffect(() => {
    fetchKpi(kpiPayload); // Pass filters here
    fetchSubCategories();
    fetchCategories();
  }, [kpiPayload, fetchCategories, fetchKpi, fetchSubCategories]);
  const filterationData = useCallback(() => {
    const categoryData = categoryList.map((cat) => ({
      id: cat.id,
      name: cat.name,
    }));

    return [
      { key: "catIds", data: categoryData, label: "Categories" }, // Adjust key for multiple selection
    ];
  }, [categoryList]);

  const filteredKpiCategories = useCallback(() => {
    return kpiList.filter((kpi) =>
      kpiPayload.catIds.length > 0
        ? kpiPayload.catIds.map((e) => e.value).includes(kpi.catId)
        : true
    );
  }, [kpiList, kpiPayload.catIds]);

  // const handleCategoryChange = (e) => {
  //   const { options } = e.target;
  //   const selectedValues = Array.from(options)
  //     .filter(option => option.selected)
  //     .map(option => option.value);
  //   setKpiPayload((prev) => ({ ...prev, catIds: selectedValues }));
  // };

  const handleCategoryChange = (e) => {
    setKpiPayload((prev) => ({
      ...prev,
      catIds: e,
    }));
  };

  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Kpi</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link
                        to={
                          userData.role === "owner"
                            ? "/admin/dashboard"
                            : "/company/dashboard"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">KPI</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-8 ">
                      <div className="search-filter">
                        {filterationData().map((filter) => (
                          <div key={filter.key} className="mb-2 w-25 ">
                            <Select
                              isMulti
                              isClearable
                              value={kpiPayload.catIds}
                              classNamePrefix="react-select-custom"
                              options={filter.data.map((e) => ({
                                value: `${e.id}`,
                                label: e.name,
                              }))}
                              onChange={handleCategoryChange}
                            />
                          </div>
                        ))}

                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              id="searchTableList"
                              placeholder="Search..."
                              value={searchKpi}
                              onChange={(e) => filterKpi(e.target.value)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="text-sm-end">
                        <button
                          type="button"
                          onClick={handleNewCategory}
                          data-bs-toggle="modal"
                          data-bs-target="#newCustomerModal"
                          className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                        >
                          <i className="mdi mdi-plus me-1"></i> New KPI
                        </button>
                      </div>
                    </div>
                  </div>
                  {filteredKpiCategories().length > 0 && (
                    <div className="table">
                      <table
                        className="table align-middle table-nowrap dt-responsive nowrap w-100"
                        id="customerList-table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th>Sr. No.</th>
                            <th>Category</th>
                            <th>Sub-Category</th>
                            <th>Name</th>
                            <th>Slug</th>
                            <th>Create Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredKpiCategories().map((category, id) => (
                            <KpiRow
                              handleDeleteCategory={
                                handleCloseDeleteCategoryMdoal
                              }
                              handleEditCategory={() =>
                                handleEditCategory(category)
                              }
                              category={category}
                              key={id}
                              index={id + 1}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KpiModal
        open={showCategoryModal}
        onClose={handleCloseNewCategoryMdoal}
        mode={mode}
        initialState={updateKpi}
      />
      <RemoveKpiModal
        open={showDeleteModal}
        onClose={handleCloseDeleteCategoryMdoal}
      />
    </Layout>
  );
};

export default observer(Kpi);
