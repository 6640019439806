import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import useStore from "../hooks/useStore";

export const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const {
    companyStore: { getAllCompanyData, companyData, removeAllError },
    userStore: {
      userData,
      fetchUserData,
      isAdminLoggedIn,
      isLoggedIn,
      token: accessToken,
    },
  } = useStore();
  const [token, setToken] = useState("");
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [dashboardTab, setDashboardTab] = useState("Environment");

  const showAlert = useCallback(
    (message, type) => {
      removeAllError();
      setAlert({ message, type: type ? "success" : "danger" });
    },
    [removeAllError]
  );

  const hideAlert = () => {
    setAlert({ message: "", type: "" });
  };

  const valueData = {
    token,
    setToken,
    alert,
    showAlert,
    hideAlert,
    dashboardTab,
    setDashboardTab,
  };

  useLayoutEffect(() => {
    const localadmintoken = localStorage.getItem(
      "admin-access-token" || "access-token"
    );
    const localtoken = localStorage.getItem("access-token");
    setToken(
      accessToken ||
        JSON.parse(localadmintoken)?.token ||
        JSON.parse(localtoken)?.token
    );
  }, [accessToken, isAdminLoggedIn, isLoggedIn]);

  useLayoutEffect(() => {
    if (isLoggedIn || isAdminLoggedIn) {
      if (Object.keys(toJS(companyData)).length === 0) {
        const url =
          userData?.role === "employee"
            ? "company/getAllEmployeeData"
            : "company/getAllCompanyData";
        getAllCompanyData(url);
      }
      if (Object.keys(toJS(userData)).length === 0) {
        fetchUserData();
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn, isAdminLoggedIn, companyData, userData]);

  return (
    <AuthContext.Provider value={valueData}>{children}</AuthContext.Provider>
  );
}

export default observer(AuthProvider);

export function useAuth() {
  return React.useContext(AuthContext);
}
