import Layout from "../../utils/layout";
import TileComponent from "../../components/tiles";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import CompanyDashboard from "../company-pages/Company-Dashboard";

function Dashboard() {
  const {
    userStore: { isAdminLoggedIn, isLoggedIn },
  } = useStore();
  return (
    <Layout>
      <div className="dashboard">
        {isAdminLoggedIn && <TileComponent />}
        {isLoggedIn && <CompanyDashboard />}
      </div>
    </Layout>
  );
}

export default observer(Dashboard);
