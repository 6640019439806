import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";

export default class WasteStore {
  // inital value
  formsRaw = [];
  formDataRaw = [];
  formId = null;
  groupsFilled = [];

  // constructor
  constructor() {
    makeObservable(this, {
      // observable values
      formsRaw: observable,
      formDataRaw: observable,
      formId: observable,
      groupsFilled: observable,
      // computed values
      forms: computed,
      formsSubmitted: computed,
      formData: computed,
      // actions
      create: action.bound,
      fetchForms: action.bound,
      fetchFormData: action.bound,
    });
  }

  // computed
  get forms() {
    return toJS(this.formsRaw);
  }

  get formsSubmitted() {
    return toJS(this.formsRaw);
  }

  get formData() {
    return toJS(this.formDataRaw);
  }
}

// actions

WasteStore.prototype.fetchForms = function ({ showAlert }) {
  fetchData("add-water/getAllWaterForms", {
    method: "POST",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        this.formsRaw = data.data;
        return;
      }
      showAlert?.(data.message);
    })
    .catch((err) => {
      showAlert?.(err.message);
      console.error(err);
    });
};

WasteStore.prototype.create = function ({
  data,
  companyId,
  companyUnit,
  frequency,
  kpiId,
  subCatId,
  catId,
  showAlert,
  navigate,
  totalGroups,
  update,
  groupData,
  formId,
  setEditForm,
}) {
  const payload = data?.value?.map((e) => ({
    ...e,
    prevValue: Number(e.prevValue) || 0,
    target: Number(e.target) || 0,
    unit: Number(e.unit),
    companyId: Number(companyId),
    companyUnit: Number(companyUnit),
    fieldId: Number(e.fieldId),
    fieldGroup: data.group,
    value: e.value,
    groupName: e.group,
    comment: e.comment,
    attachment: e.attachment,
  }));
  const groupPayload = [
    {
      ...frequency,
      kpiId,
      subCatId,
      catId,
      targetValue: data.target,
      prevValue: data.prevValue,
      unitId: companyUnit,
      companyUnit,
      groupName: data.group,
    },
  ];
  const token = JSON.parse(
    localStorage.getItem("access-token") ||
      localStorage.getItem("admin-access-token")
  )?.token;
  const formData = new FormData();
  formData.append("data", JSON.stringify(payload));
  formData.append("groupPayload", JSON.stringify(groupPayload));
  payload.forEach((pay, index) => {
    if (pay.attachment) {
      formData.append(`file[]`, pay.attachment);
      formData.append(`index[]`, index);
    }
  });
  fetch(
    process.env.REACT_APP_BASE_URL +
      `waste-management/${update ? "update" : "create"}${
        formId ? `?formId=${formId}` : ""
      }`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      if (response.status) {
        showAlert(response.message, true);
        if (update) {
          setEditForm("");
        }
        if (response.data.groupsFilled.length === totalGroups) {
          navigate("/company/waste");
          return;
        }
        navigate("/company/waste/add-waste", {
          state: {
            freq: frequency,
            formid: response.data.formId,
            groupsFilled: response.data.groupsFilled,
            formData: groupData,
          },
        });
        return;
      }
      showAlert(response.message);
    })
    .catch((err) => {
      showAlert(err.message);
      console.error(err);
    });
};

WasteStore.prototype.fetchFormData = function ({ formId, showAlert }) {
  fetchData(`waste-management/getFormData?formId=${formId}`, {
    method: "POST",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        this.formDataRaw = data.data;
        return;
      }
      showAlert?.(data.message);
    })
    .catch((err) => {
      showAlert?.(err.message);
      console.error(err);
    });
};
