import { observer } from "mobx-react";
import useStore from "../../../hooks/useStore";
import { Accordion } from "react-bootstrap";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  GOVERNANCE_STATIC_GROUPS,
  SMALL_GROUPS,
} from "../../../constants/constants";
import { groupByConsecutiveGroups } from "../../../utils/helpers";
import numToRoman from "../../../helpers/numToRoman";
import CustomTooltip from "../../../utils/tooltip/tooltip";
import info from "../../info.svg";

const ShowForm = ({ groupConstant }) => {
  const {
    governance: { formData },
  } = useStore();

  // states
  const [expanded, setExpanded] = useState(0);
  const [data, setData] = useState([]);

  // calculations
  const tempGroupPayload = useMemo(
    () =>
      Object.entries(
        formData?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group[0]]: [
              ...(acc[curr.group[0]] ? acc[curr.group[0]] : []),
              { ...curr },
            ],
          }),
          {}
        )
      ).map(([key, value]) => ({
        group: key,
        value: value.sort((a, b) => Number(a.position) - Number(b.position)),
        label: GOVERNANCE_STATIC_GROUPS[groupConstant][key],
      })),
    [formData, groupConstant]
  );

  // side effects

  useEffect(() => {
    if (tempGroupPayload) {
      setData(tempGroupPayload);
    }
  }, [tempGroupPayload]);

  return (
    <div>
      <Accordion
        className="water-table-accordion"
        defaultActiveKey={"A"}
        activeKey={expanded}
        onSelect={(eventKey) => {
          setExpanded(eventKey);
        }}
      >
        {data?.map((accordion, index) => (
          <Accordion.Item
            key={`${accordion.group}-accordion-item`}
            eventKey={index}
          >
            <Accordion.Header>
              <tr style={{ display: "flex", gap: "4px" }}>
                <td>{index + 1}</td>
                <td colSpan={7}>{accordion.label}</td>
              </tr>
            </Accordion.Header>
            <Accordion.Body>
              <table className="table align-middle table-bordered table-nowrap mb-0">
                <thead>
                  <tr>
                    {["Sr. No.", "Title", "Value", "Comment", "Attachment"].map(
                      (head) => (
                        <th key={head}>{head}</th>
                      )
                    )}
                  </tr>
                </thead>
                <TableBody data={accordion} groupConstant={groupConstant} />
              </table>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

function TableBody({ data, groupConstant }) {
  let id = 0;
  return (
    <tbody>
      {groupByConsecutiveGroups(data.value)
        ?.map((value) => ({
          label: ["A", "B", "C", "D", "E", "F", "G", "H", "I"].includes(
            value[0].group
          )
            ? ""
            : GOVERNANCE_STATIC_GROUPS[groupConstant][value[0].group],
          value,
          group: ["A", "B", "C", "D", "E", "F", "G", "H", "I"].includes(
            value[0].group
          )
            ? ""
            : value[0].group,
        }))
        .map((ele, ind) => {
          if (!ele.group) {
            return ele?.value?.map((e) => (
              <ShowTableRow data={e} sr={SMALL_GROUPS[id++]} />
            ));
          }
          return (
            <Fragment key={`${ind}-fragment-${ele.group}`}>
              {ele.label ? (
                <tr key={`${ele.group}-tr-sub-tr`}>
                  <td>{SMALL_GROUPS[id++]}</td>
                  <td colSpan={7}>{ele.label}</td>
                </tr>
              ) : (
                <>{console.log(id++)}</>
              )}
              {ele?.value?.map((e, i) => (
                <ShowTableRow data={e} sr={numToRoman(i + 1)} />
              ))}
            </Fragment>
          );
        })}
    </tbody>
  );
}

function ShowTableRow({ data, sr }) {
  return (
    <tr key={`${data.fieldId}-show-table-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName?.name}
          {data?.fieldName?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>{data?.value}</td>
      <td>
        <p style={{ maxWidth: "1000px" }}>{data?.comment || ""} </p>
      </td>
      <td>{data.attachment && <Link to={data.attachment}>Download</Link>}</td>
    </tr>
  );
}

export default observer(ShowForm);
