import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PERFORMANCE_HEADING } from "../constants/constants";
import useStore from "../hooks/useStore";
import { useAuth } from "../context/authProvider";
import { generateYearArray } from "../utils/helpers";
const Performance = ({ label }) => {
  const {
    companyStore: { createPerformance, getPerformance, performance },
  } = useStore();
  const { showAlert } = useAuth();
  const [year, setYear] = useState(new Date().getFullYear());
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [data, setData] = useState(
    PERFORMANCE_HEADING.filter(
      (e) => !(label === "Biodiversity" && e === "Target")
    ).map((ele) => ({
      label: ele,
      values: [{ id: 0, value: "" }],
    }))
  );

  useEffect(() => {
    getPerformance({ type: label.toLowerCase(), year });
  }, [getPerformance, label, year]);

  const addInput = useCallback((ele, id) => {
    setData((prev) =>
      prev.map((e) => {
        if (e.label === ele) {
          return { ...e, values: [...e.values, { id: id + 1, value: "" }] };
        }
        return e;
      })
    );
  }, []);

  useEffect(() => {
    const temp = performance?.reduce(
      (acc, curr, index) => ({
        ...acc,
        [curr.title]: [
          ...(acc[curr.title] || []),
          { id: index, value: curr.value },
        ],
      }),
      {}
    );
    setData((prev) =>
      prev?.map((ele) => ({
        ...ele,
        values: temp[ele.label] || [{ id: 0, value: "" }],
      }))
    );
  }, [performance]);

  const removeInput = useCallback((label, id) => {
    setData((prev) =>
      prev.map((e) => {
        if (e.label === label) {
          return { ...e, values: e.values.filter((f) => f.id !== id) };
        }
        return e;
      })
    );
  }, []);

  const onChangeHandler = useCallback(({ event, id }) => {
    setData((prev) =>
      prev.map((ele) => {
        if (ele.label === event.target.name) {
          return {
            ...ele,
            values: ele.values.map((e) => {
              if (e.id === id) {
                return { ...e, value: event.target.value };
              }
              return e;
            }),
          };
        }
        return ele;
      })
    );
  }, []);

  const payload = data?.reduce((acc, curr) => {
    const result = curr?.values?.map((ele) => ({
      value: ele.value,
      title: curr.label,
      year,
    }));
    return [...acc, ...(result || [])];
  }, []);

  const handleSave = (event) => {
    event.preventDefault();
    const form = event.target;
    if (form.checkValidity()) {
      createPerformance({
        payload,
        type: label.toLowerCase(),
        showAlert,
        setShow,
        year,
      });
    }
  };

  return (
    <>
      <button
        className="btn btn-success waves-effect waves-light addCustomers-modal"
        onClick={handleShow}
        style={{
          marginRight: "10px",
        }}
      >

        {`${label === 'Aqi' ? 'Emission' : label} Performance`}

      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Form onSubmit={handleSave} validated noValidate>
          <Modal.Header closeButton>
            <Modal.Title>
              {`${label === 'Aqi' ? 'Emission' : label} Performance`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="px-3 mb-4">
              <label className="form-label">Year</label>
              <select
                value={year}
                onChange={(event) => setYear(Number(event.target.value))}
                className="form-select"
                required
              >
                {generateYearArray(5).map((y) => (
                  <option value={y}>{`FY${y}-${y + 1}`}</option>
                ))}
              </select>
            </div>
            {data?.map((ele) => (
              <form>
                <div className="d-flex flex-column  p-3  gap-2 ">
                  <div className="HighLight d-flex flex-column align-items-start gap-3">
                    <h6 className="mb-3 text-muted">{ele?.label}</h6>
                    {ele.values.map((inp, valIndex) => (
                      <div
                        key={`${ele.label}${inp.id}`}
                        className="d-flex w-100"
                      >
                        <input
                          className="rounded form-control w-100  numberCountInput"
                          type="text"
                          required
                          value={inp.value}
                          name={ele.label}
                          onChange={(event) =>
                            onChangeHandler({ event, id: inp.id })
                          }
                          readOnly={!!performance && performance.length > 0}
                        />
                        {valIndex !== ele.values.length - 1 ? (
                          <button
                            className="btn btn-danger mx-1 w-5"
                            onClick={() => removeInput(ele.label, inp.id)}
                            disabled={performance && performance.length > 0}
                          >
                            -
                          </button>
                        ) : (
                          <button
                            className="btn btn-success mx-1 w-5"
                            onClick={() => addInput(ele.label, inp.id)}
                            disabled={performance && performance.length > 0}
                          >
                            +
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </form>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={handleClose}>
              Close
            </Button>
            {(!performance || performance.length === 0) && (
            <Button variant="primary" type="submit">
              Save
            </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default observer(Performance);
