const Dropdown = ({
  label,
  options,
  selectedValue,
  onChange,
  name,
  disabled,
}) => {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <select
        name={name}
        required
        className="form-select"
        value={selectedValue}
        disabled={disabled}
        onChange={onChange}
      >
        <option value="">Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
