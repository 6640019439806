import { createContext } from "react";
import RootStore from "../stores/root-store";

export const StoreContext = createContext({});

export default function StoreProvider({ children }) {
  const rootStore = new RootStore();
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
}
