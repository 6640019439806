import React, { useEffect, useState } from "react";
import Layout from "../../../utils/layout";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import useStore from "../../../hooks/useStore";

const EmployeePage = () => {
  const navigate = useNavigate();
  const {
    employeeStore: { fetchEmployee, employeesList },
    userStore: { fetchCompany, companyList },
  } = useStore();
  const [serach, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const tablehead = [
    "User Name",
    "Email",
    "Phone Number",
    // "Region",
    "Department",
    "Unit",
    "Status",
    "Action",
  ];

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchEmployee({ signal });
    fetchCompany({ signal });
    return () => {
      abortController.abort();
    };
  }, [fetchEmployee, fetchCompany]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Function to handle closing the modal
  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Employees</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/company/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Employees</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2 align-items-center">
                    <div className="col-md-3 col-lg-3 ">
                      <div className="search-box mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            id="searchTableList"
                            placeholder="Search name,department,email..."
                            value={serach}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="text-sm-end">
                        {employeesList?.length >=
                        companyList?.allowedEmployee ? (
                          <button
                            type="button"
                            className="btn btn-success waves-effect waves-light mb-2  addCustomers-modal"
                            onClick={openModal}
                          >
                            <i className="mdi mdi-plus me-1"></i> New{" "}
                            {/* <span className="employee-button-text"> */}
                            Employee
                            {/* </span> */}
                          </button>
                        ) : (
                          <Link to={"/company/employee/add-employee"}>
                            <button
                              type="button"
                              className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                            >
                              <i className="mdi mdi-plus me-1"></i> New Employee
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive ">
                    <table className="table align-middle table-bordered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          {tablehead?.map((item, index) => (
                            <th key={index}>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {employeesList

                          ?.filter(({ firstName, email, department }) => {
                            const searchRegex = new RegExp(serach, "i");
                            return (
                              searchRegex.test(firstName) ||
                              searchRegex.test(email) ||
                              searchRegex.test(department)
                            );
                          })
                          ?.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item?.firstName} {item?.lastName ?? ""}
                              </td>
                              <td>{item?.email}</td>
                              <td>{item?.phoneNumber}</td>
                              <td>{item?.department}</td>
                              {/* <td>{item?.region}</td> */}
                              <td>
                                {item?.companyAssignUnits
                                  ? item.companyAssignUnits.map(
                                      (unit, index) => (
                                        <React.Fragment key={unit.id}>
                                          <span>{unit.name}</span>
                                          {index <
                                            item.companyAssignUnits.length -
                                              1 && ", "}
                                        </React.Fragment>
                                      )
                                    )
                                  : "-"}
                              </td>

                              <td>{item?.status ? "Active" : "Inactive"}</td>
                              <td>
                                <button
                                  className="btn btn-primary btn-sm btn-action  waves-effect waves-light"
                                  onClick={() =>
                                    navigate("/company/employee/add-employee", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i className="mdi mdi-pencil font-size-14"></i>{" "}
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal ${showModal ? "show" : ""}`}
        id="newEmployeeModal"
        tabIndex="-1"
        aria-labelledby="newEmployeeModalLabel"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="newEmployeeModalLabel">
                Employee Limit
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Modal body content */}
              <p className="text-danger font-size-14">
                You have reached the limit of {companyList?.allowedEmployee}{" "}
                employees for your company. To add more employees, please
                contact your super admin.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={closeModal}
              >
                OK
              </button>
              {/* <Link to={"/company/employee/add-employee"}>
                <button type="button" className="btn btn-primary" disabled={employeesList?.length >= companyList?.allowedEmployee}
                  data-bs-toggle="modal"
                  data-bs-target="#newCustomerModal">
                  OK
                </button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(EmployeePage);