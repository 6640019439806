import React, { useState } from "react";
import Layout from "../../utils/layout";
import { Link } from "react-router-dom";
import AssignRow from "./AssignRow";

const fields = [
  {
    name: "Surface water",
    isRequired: true,
    group: "A",
    type: "Dropdown",
    assigned: true,
  },
  {
    name: "Groundwater",
    isRequired: true,
    group: "A",
    type: "text",
    assigned: true,
  },
  {
    name: "Third party water",
    isRequired: true,
    group: "A",

    type: "Dropdown",
    assigned: true,
  },
  {
    name: "Produced water",
    isRequired: true,
    group: "A",
    type: "Dropdown",
    assigned: true,
  },
  {
    name: "Seawater / desalinated water",
    isRequired: true,
    group: "A",
    type: "Dropdown",
    assigned: true,
  },
  {
    name: "Others",
    isRequired: true,
    group: "A",
    type: "Dropdown",
    assigned: true,
  },
];

const AssignPage = () => {
  const [search, setSearchResult] = useState("");
  const [companySelected, setCompanySelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);

  // const [dropdownData, setDropdownData] = useState({
  //   selectedCategory: "",
  //   selectedSubCategory: "",
  //   selectedKPI: "",
  // });

  const [fieldList, setFieldList] = useState(fields);

  const onCompanySelected = () => {
    setCompanySelected(true);
  };

  const handleDropdownData = (data) => {
    if (data) {
      // setDropdownData(data);
      setCategorySelected(true);
    }
  };

  const handleSelectAll = () => {
    const updatedFields = fieldList.map((field) => ({
      ...field,
      assigned: true,
    }));
    setFieldList(updatedFields);
  };

  return (
    <Layout>
      <div className="assign-page">
        <div className="container-fluid">
          {/* page title    */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Assign Fields</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Assign Fields</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* search row  */}
          <div className="d-flex align-items-center gap-2 ">
            <form className="app-search d-none d-lg-block flex-grow-1 me-2 ">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for a company"
                  autoComplete="on"
                  value={search}
                  onChange={(e) => setSearchResult(e.target.value)}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
            <button
              className="btn btn-primary px-4"
              onClick={onCompanySelected}
            >
              Search
            </button>
          </div>

          {/* if the company is selected  */}
          {companySelected && (
            <>
              <div className="card mt-4 p-4">
                <h3>WebSultanate</h3>
                <p className="w-50">
                  At Websultanate, we believe in the power of collaboration and
                  transparency. We work closely with our clients to understand
                  their goals, challenges, and vision, allowing us to develop
                  personalized software solutions that align with their
                  objectives. From initial consultation to final delivery, our
                  streamlined development process ensures efficient project
                  management and timely delivery, all while maintaining the
                  highest standards of quality.
                </p>
              </div>

              {/* drop down row */}
              <div className="card p-4">
                <AssignDropdownRow onDropdownData={handleDropdownData} />
              </div>
            </>
          )}

          {/* table section  */}

          {categorySelected && (
            <div className="card p-4">
              <div className="table-responsive ">
                <table className="table align-middle table-bordered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Group</th>
                      <th>Required</th>
                      <th>
                        Assigned
                        <button
                          className="btn btn-outlined"
                          onClick={handleSelectAll}
                        >
                          select all
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(fieldList) &&
                      fieldList.map((category, id) => {
                        return (
                          <AssignRow
                            category={category}
                            key={id}
                            index={id + 1}
                            onFieldChange={(updatedField) => {
                              const updatedFields = fieldList.map((f) =>
                                f.name === updatedField.name ? updatedField : f
                              );
                              setFieldList(updatedFields);
                            }}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-12 text-end mt-3 ">
                  <button className="btn btn-success px-4 mx-2">Reset</button>
                  <button className="btn btn-primary px-4 ">Save</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

const Dropdown = ({ label, options, selectedValue, onChange }) => {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <select className="form-select" value={selectedValue} onChange={onChange}>
        <option value="">Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const AssignDropdownRow = ({ onDropdownData }) => {
  const categoryList = ["Environment", "Social", "Governance"];
  const subCategoryList = [
    "Climate change",
    "Carbon emission reduction",
    "Water pollution & water scarcity",
    "Energy efficiency",
    "Air Pollution",
    "Greenhouse gas emissions",
    "Biodiversity",
  ];
  const kpiList = ["Surface Water"];

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedKPI, setSelectedKPI] = useState("");

  const handleSearch = () => {
    const data = {
      selectedCategory,
      selectedSubCategory,
      selectedKPI,
    };
    onDropdownData(data);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <Dropdown
            label="Category"
            options={categoryList}
            selectedValue={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Dropdown
            label="Sub-Category"
            options={subCategoryList}
            selectedValue={selectedSubCategory}
            onChange={(e) => setSelectedSubCategory(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Dropdown
            label="KPI"
            options={kpiList}
            selectedValue={selectedKPI}
            onChange={(e) => setSelectedKPI(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <button className="btn btn-primary px-4" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignPage;
