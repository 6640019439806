import React, { useEffect } from "react";
import {
  FaListAlt,
  FaChartLine,
  FaClipboardList,
  FaProjectDiagram,
  FaTachometerAlt,
  FaBuilding,
  FaTint,
} from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

import noPoverty from "../../assets/images/no-poverty.png";
import noHunger from "../../assets/images/no-hunger.png";
import wellBeing from "../../assets/images/well-being.png";

import profile from "../../assets/images/users/user-dummy-img.jpg";
import avatar from "../../assets/images/users/user-dummy-img.jpg";

import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import ColumnChart from "../charts/columnChart";
import RadialBarChart from "../charts/RadialBarChart";

// export const routeMapping = {
//   Categories: "/admin/categories",
//   "Sub Categories": "/admin/subcategories",
//   KPI: "/admin/kpi",
//   Fields: "/admin/fields",
//   Assign: "/admin/assign",
//   Unit: "/admin/unit",
//   Companies: "/admin/company",
//   Water: "/admin/water",
// };

export const routeMapping = {
  Categories: {
    path: "/admin/categories",
    icon: <FaListAlt />,
  },
  "Sub Categories": {
    path: "/admin/subcategories",
    icon: <FaClipboardList />,
  },
  KPI: {
    path: "/admin/kpi",
    icon: <FaChartLine />,
  },
  Fields: {
    path: "/admin/fields",
    icon: <FaProjectDiagram />,
  },
  Assign: {
    path: "/admin/assign",
    icon: <FaTachometerAlt />,
  },
  Unit: {
    path: "/admin/unit",
    icon: <FaClipboardList />,
  },
  Companies: {
    path: "/admin/company",
    icon: <FaBuilding />,
  },
  Water: {
    path: "/admin/water",
    icon: <FaTint />,
  },
};

export const countMapping = {
  Categories: "CategoryMaster",
  "Sub Categories": "SubCategoryMaster",
  KPI: "kpiMaster",
  Fields: "FieldsMaster",
  Unit: "UnitMaster",
  Companies: "Companies",
  Water: "Water",
};

const TileComponent = () => {
  const tiles = [
    {
      heading: "Categories",
      count: "CategoryMaster",
      imageUrl: noPoverty,
      color: "#EA1B2B",
      icon: <FaListAlt />,
    },
    {
      heading: "Sub Categories",
      count: "SubCategoryMaster",
      imageUrl: noHunger,
      color: "#D09D27",
      icon: <FaClipboardList />,
    },
    {
      heading: "KPI",
      count: "kpiMaster",
      imageUrl: wellBeing,
      color: "#259A47",
      icon: <FaChartLine />,
    },
    {
      heading: "Fields",
      count: "FieldsMaster",
      imageUrl: noPoverty,
      color: "#C31F33",
      icon: <FaProjectDiagram />,
    },
    // {
    //   heading: "Assign",
    //   count: 20,
    //   imageUrl: noHunger,
    //   color: "#EF402B",
    //   icon: <FaTachometerAlt />
    // },
    {
      heading: "Unit",
      count: "UnitMaster",
      imageUrl: wellBeing,
      color: "#00AED9 ",
      icon: <FaClipboardList />,
    },
    {
      heading: "Companies",
      count: "Companies",
      imageUrl: wellBeing,
      color: "#FDB713",
      icon: <FaBuilding />,
    },
    {
      heading: "Water",
      count: "Water",
      imageUrl: wellBeing,
      color: "#FDB713",
      icon: <FaTint />,
    },
  ];

  const {
    dashboardStore: { fetchCount, count },
    companyStore: { getCompanyData, companiesList },

  } = useStore();

  const tablehead = [
    "Full Name",
    "Email",
    "Phone Number",
    "Employee Range",
    "Company Name",
    "Region",
    "Industry",
    "Allowed Employee",
    "Address",
    "Status",
    // "Action",
  ];

  useEffect(() => {
    fetchCount();
    getCompanyData();
  }, [fetchCount,getCompanyData]);
console.log('getCompanyData',companiesList);
  return (
    <div className="dashboard-content">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link href="/admin/dashboard">Admin</Link>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="card overflow-hidden">
            <div className="bg-primary-subtle">
              <div className="row">
                <div className="col-7">
                  <div className="text-primary p-3">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p>Admin</p>
                  </div>
                </div>
                <div className="col-5 align-self-end">
                  <img src={profile} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-sm-4">
                  <div className="avatar-md profile-user-wid mb-4">
                    <img
                      src={avatar}
                      alt=""
                      className="img-thumbnail rounded-circle"
                    />
                  </div>
                  <h5 className="font-size-15 text-truncate">Syed Websul</h5>
                  <p className="text-muted mb-0 text-truncate">Sub Admin</p>
                </div>

                <div className="col-sm-8">
                  <div className="pt-4">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="font-size-15">125</h5>
                        <p className="text-muted mb-0">Companies</p>
                      </div>
                      <div className="col-6">
                        <h5 className="font-size-15">₹ 12456</h5>
                        <p className="text-muted mb-0">Revenue</p>
                      </div>
                    </div>
                    {/* <div className="mt-4">
                                            <Link to="/admin/profile" className="btn btn-primary waves-effect waves-light btn-sm">View Profile <i className="mdi mdi-arrow-right ms-1"></i></Link>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Monthly Earning</h4>
              <div className="row">
                <div className="col-sm-6">
                  <p className="text-muted">This month</p>
                  <h3>₹ 34,252</h3>
                  <p className="text-muted">
                    <span className="text-success me-2">
                      {" "}
                      12% <i className="mdi mdi-arrow-up"></i>{" "}
                    </span>{" "}
                    From previous period
                  </p>
                  <div className="mt-4">
                    <Link
                      to="/"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      View More <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-4 mt-sm-0">
                    <RadialBarChart />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-wrap align-items-start">
                <h5 className="card-title mb-3 me-2">Total Users</h5>
              </div>

              <div className="d-flex flex-wrap">
                <div>
                  <h4 className="mb-1">10,512</h4>
                  <p className="text-success mb-0">
                    <span>
                      0.6 % <i className="mdi mdi-arrow-top-right ms-1"></i>
                    </span>
                  </p>
                </div>
                <div className="ms-auto align-self-end">
                  <i className="bx bx-group display-4 text-light"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="row card-info-box">
            {tiles.map((tile, index) => (
              <div className="col-md-4" key={index}>
                <Link
                  to={routeMapping[tile.heading]?.path || "/"}
                  className="tile-link"
                >
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex py-2">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{tile.heading}</p>
                          <h4 className="mb-0">{toJS(count)?.[tile.count]} </h4>
                        </div>

                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              {tile.icon}
                              {/* <i className="bx bx-copy-alt font-size-24"></i> */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <div className="card">
            <div className="card-body">
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">Revenue</h4>
                <div className="ms-auto"></div>
              </div>

              <ColumnChart />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h4 className="card-title ">Latest Companies</h4>
                <button
                  type="button"
                  className="btn btn-primary btn-sm  waves-effect waves-light"
                >
                  View All
                </button>
              </div>
              <div className="table-responsive ">
                <table className="table align-middle table-bordered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th className="align-middle">Company ID</th>
                      <th className="align-middle">Company Name</th>
                      <th className="align-middle">Registration Date</th>
                      <th className="align-middle">Amount</th>
                      <th className="align-middle">Payment Status</th>
                      <th className="align-middle">Payment Method</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <a href="/" className="text-body fw-bold">
                          #ESG2540
                        </a>{" "}
                      </td>
                      <td>Vedanta</td>
                      <td>07 Oct, 2019</td>
                      <td>₹ 5045</td>
                      <td>
                        <span className="badge badge-pill badge-soft-success font-size-11">
                          Paid
                        </span>
                      </td>
                      <td>
                        <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        >
                          View
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>2</td>
                      <td>
                        <a href="/" className="text-body fw-bold">
                          #ESG2541
                        </a>{" "}
                      </td>
                      <td>Sun Pharma</td>
                      <td>07 Oct, 2019</td>
                      <td>₹ 8280</td>
                      <td>
                        <span className="badge badge-pill badge-soft-danger font-size-11">
                          Cancelled
                        </span>
                      </td>
                      <td>
                        <i className="fab fa-cc-visa me-1"></i> Visa
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        >
                          View
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td>3</td>
                      <td>
                        <a href="/" className="text-body fw-bold">
                          #ESG2542
                        </a>{" "}
                      </td>
                      <td>Alembic Pharma</td>
                      <td>06 Oct, 2019</td>
                      <td>₹ 3284</td>
                      <td>
                        <span className="badge badge-pill badge-soft-success font-size-11">
                          Paid
                        </span>
                      </td>
                      <td>
                        <i className="fab fa-cc-paypal me-1"></i> Paypal
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        <a href="/" className="text-body fw-bold">
                          #ESG2543
                        </a>{" "}
                      </td>
                      <td>NSE</td>
                      <td>05 Oct, 2019</td>
                      <td>₹ 4312</td>
                      <td>
                        <span className="badge badge-pill badge-soft-success font-size-11">
                          Paid
                        </span>
                      </td>
                      <td>
                        <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        <a href="/" className="text-body fw-bold">
                          #ESG2544
                        </a>{" "}
                      </td>
                      <td>Cipla</td>
                      <td>04 Oct, 2019</td>
                      <td>₹ 7404</td>
                      <td>
                        <span className="badge badge-pill badge-soft-warning font-size-11">
                          Refund
                        </span>
                      </td>
                      <td>
                        <i className="fab fa-cc-visa me-1"></i> Visa
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        <a href="/" className="text-body fw-bold">
                          #ESG2545
                        </a>{" "}
                      </td>
                      <td>Abbot</td>
                      <td>04 Oct, 2019</td>
                      <td>₹ 3924</td>
                      <td>
                        <span className="badge badge-pill badge-soft-success font-size-11">
                          Paid
                        </span>
                      </td>
                      <td>
                        <i className="fab fa-cc-paypal me-1"></i> Paypal
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TileComponent);
