import React from "react";
import Chart from "react-apexcharts";

const DonutChart = ({
  data,
  labels,
  unit,
  height = 385,
  position = "bottom",
  download,
  heading,
  label,
  ...props
}) => {
  const options = {
    series: data,
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },
    colors: [
      "#FCD163",
      "#EF8083",
      "#88BE53",
      "#46A1E1",
      "#A889C4",
      "#FFA07A",
      "#90EE90",
      "#87CEEB",
      "#DDA0DD",
    ],

    labels: labels,
    legend: {
      position: position,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val + (unit || "");
        },
      },
    },
  };

  return (
    <div id="chart">
       <div>
          <h5 className="mb-3 text-muted">{heading ? heading +" ("+ unit +")" : ""}</h5>
       </div>
      <Chart
        options={options}
        series={options.series}
        type="donut"
        height={height}
        {...props}
      />
    </div>
  );
};

export default DonutChart;
