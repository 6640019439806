import { useMemo } from "react";
import useStore from "../../hooks/useStore";
import { STATIC_GROUPS } from "../../constants/constants";
import BarChart from "../charts/barChart";
import { observer } from "mobx-react";
function BiodiversityDashboardAccordion({ year }) {
  const {
    dashboardStore: { dashboardDetails },
  } = useStore();
  const barChartsGroupData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.biodiversity;
    const result = ["A", "B1"].map((group) => {
      const data = [year - 1, year].map((year) => {
        const temp = tempData?.[year]?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        return temp?.[group]?.prevValue || 0;
      });
      return {
        label: STATIC_GROUPS.biodiversity[group],
        data,
      };
    });
    return result;
  }, [dashboardDetails?.charts?.biodiversity, year]);
  return (
    <div className="row h-100  ">
      {barChartsGroupData.length !== 0 &&
        barChartsGroupData?.map?.(({ label, data }, index) => (
          <div key={index} className="col-xl-6  mb-3">
            <div className="card h-100  ">
              <div className="card-body">
                <div className="d-flex  justify-content-between ">
                  <h4 className="card-title mb-4 donut-heading">
                    {
                      [
                        "Total number of species affected(Water bodies & Habitats)",
                        "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk",
                      ][index]
                    }
                    {/* {index === 0 ? "(Kilo meter cube)" : "(Kilo meter cube)"} */}
                  </h4>

                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      {" "}
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">
                          {dashboardDetails?.charts?.aqi?.unit}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <BarChart
                  series={[{ data, name: label }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.aqi?.unit}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default observer(BiodiversityDashboardAccordion);
