import React from "react";
import ReactApexChart from "react-apexcharts";
import tools from "../../pages/report/components/tools";

const ColumnChartRotatedLabel = ({ series, categories,heading,download }) => {
  const maxSeriesValue = Math.max(...series[0]?.data);

  const options = {
    annotations: {
      points: [
        {
          x: "Bananas",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            // text: "Bananas are good",
          },
        },
      ],
    },
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          zoom: false,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: categories, // Categories for the X-axis (your months)
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "LTIFR",
      },
      labels: {
        formatter: function (value) {
          return Math.round(value); // Round Y-axis values
        },
      },
      min: 0, // Start Y-axis from 0
      max: Math.ceil(maxSeriesValue * 1.1), // 10% higher than max value
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  };

  return (
    <div>

      <div id="chart">
      {heading && <h5>{heading ? heading : ""}</h5>}
        <ReactApexChart
          options={options}
          series={series} // Updated: Pass the structured series data
          type="bar"
          height={450}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ColumnChartRotatedLabel;
