import Header from "../components/header";
import Sidebar from "../components/sidebar";
import { useLocation } from "react-router-dom";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { useAuth } from "../context/authProvider";

function Layout({ children }) {
  const location = useLocation();
  const {
    companyStore: { catId, companyData },
    userStore: { isAdminLoggedIn },
  } = useStore();

  const { dashboardTab, setDashboardTab } = useAuth();
  const [selectedClassname, setSelectedClassname] = useState("Environment");

  // Set the selected category
  const selectedCategory =
    toJS(companyData)?.assignCategory?.find((e) => e.catId === catId)
      ?.catIdAssignCategory?.name || "Environment";

  useEffect(() => {
    const className =
      selectedCategory === "Environment"
        ? "env-active"
        : selectedCategory === "Governance"
        ? "gov-active"
        : "social-active";

    setSelectedClassname(className);
  }, [selectedCategory, setDashboardTab]);

  const selectedTab =
    dashboardTab === "Environment"
      ? "env-active"
      : dashboardTab === "Governance"
      ? "gov-active"
      : "social-active";

  useEffect(() => {
    setSelectedClassname(selectedTab);
  }, [selectedTab]);

  const showSidebar = isAdminLoggedIn
    ? location.pathname.includes("/admin")
    : location.pathname !== "/company/dashboard" || catId;

  return (
    <div
      className={`layout-wrapper ${selectedClassname} ${
        !showSidebar ? "expanded" : ""
      }`}
    >
      <Header path={location.pathname} />
      {showSidebar && (
        <div className="sidebar-wrapper">
          <Sidebar />
        </div>
      )}
      <div className="main-content">
        <div className="page-content">{children}</div>
      </div>
    </div>
  );
}

export default observer(Layout);
