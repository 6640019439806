import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, Table } from "react-bootstrap";
import fetchData from "../../../../utils/fetchData";
import { useAuth } from "../../../../context/authProvider";
import {
  sumValues,
  calculateRevenue,
  getTableIds,
} from "../../../../utils/helpers";
const AddForm = ({ fields, data, setData, year }) => {
  console.log(data.length, "data");
  const [expanded, setExpanded] = useState(0);
  return (
    <div className="brsr-form-container">
      <Accordion
        className="water-table-accordion"
        defaultActiveKey={0}
        activeKey={expanded}
        onSelect={(eventKey) => setExpanded(eventKey)}
      >
        {fields.map(({ label, heading, tables }, index) => (
          <Accordion.Item eventKey={index}>
            <Accordion.Header>
              {index + 1}. {label}
            </Accordion.Header>
            <AccordionBody
              tableData={tables}
              data={data}
              setData={setData}
              year={year}
            />
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

function AccordionBody({ tableData, data, setData, year }) {
  const approvalStatus = data?.[0]?.approvalStatus;
  const [edit, setEdit] = useState(false);
  const { showAlert } = useAuth();
  const [ids, setIds] = useState([]);
  const isSaved = useMemo(
    () => data.find((da) => ids?.includes(da.id) && da.action === "edit"),
    [data, ids]
  );
  useEffect(() => {
    const tempIds = Array.from(getTableIds(tableData));
    setIds(tempIds);
  }, [tableData]);

  const initialSubmitHandler = () => {
    if (!year) {
      showAlert("Please select year!!");
      return;
    }

    const bodyData = data
      ?.filter(({ id }) =>
        data.find(({ action }) => action === "edit") ? ids.includes(id) : true
      )
      ?.map((e) => ({
        ...e,
        action: ids.includes(e.id) || e?.action === "edit" ? "edit" : "save",
      }));

    const formId = bodyData[0].formId;

    fetchData(`brsr/create${formId ? `?formId=${formId}` : ""}`, {
      method: "POST",
      body: JSON.stringify({
        data: bodyData,
        year,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          showAlert(res.message, res.status);
          setEdit(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log("[Error]", err);
      });
  };
  return (
    <Accordion.Body>
      {tableData.map(({ heading, label, th, tr }) => (
        <div
          className="accordion-item-content"
          style={{ marginBottom: "20px" }}
        >
          <h4 style={{ padding: 10, textAlign: "center", fontWeight: "bold" }}>
            {heading ? heading : " "}
          </h4>

          <h5>{label}</h5>
          <Table
            bordered
            className="align-middle table-bordered table-nowrap mb-0"
          >
            <thead>
              <tr>
                {th.map((thl) => (
                  <th key={thl}>{thl}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tr.map((trd) => (
                <tr>
                  {trd?.map((td) => (
                    <td {...(td.colSpan ? { colSpan: td.colSpan } : {})}>
                      <TD
                        config={td}
                        data={data}
                        setData={setData}
                        disabled={
                          !edit && isSaved && approvalStatus !== "approved"
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
      {approvalStatus !== "approved" && (
        <tr className="d-flex justify-content-end">
          <td>
            <button
              className="btn btn-primary"
              onClick={
                isSaved && !edit
                  ? () => {
                      setEdit(true);
                    }
                  : initialSubmitHandler
              }
            >
              {isSaved && !edit ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </Accordion.Body>
  );
}

const TD = ({
  config: { type, value, id, valKey, key, options, calculation },
  data,
  setData,
  disabled,
}) => {
  const handleInputChange = useCallback(
    (e) => {
      const inputValue =
        type === "checkbox" ? e.target.checked : e.target.value;
      setData((prev) =>
        prev.map((ele) =>
          ele.id === id
            ? { ...ele, value: { ...ele.value, [valKey]: inputValue } }
            : ele
        )
      );
    },
    [id, valKey, setData, type]
  );
  const cases = useMemo(
    () => ({
      // water Revenue Cases
      "7-0-3-1": () =>
        calculateRevenue(data, ["7-0-2-0", "7-0-2-1"], "7-0-3-0"),
      // Energy sum Cases
      "7-3-1-11": () =>
        sumValues(data, [
          "7-3-0-1",
          "7-3-0-2",
          "7-3-0-3",
          "7-3-0-4",
          "7-3-0-5",
          "7-3-0-6",
          "7-3-0-7",
          "7-3-0-8",
          "7-3-1-1",
          "7-3-1-2",
          "7-3-1-3",
          "7-3-1-4",
          "7-3-1-5",
          "7-3-1-6",
          "7-3-1-7",
          "7-3-1-8",
          "7-3-1-9",
        ]),
      // Energy revenue Cases
      "7-3-1-12": () =>
        calculateRevenue(
          data,
          [
            "7-3-0-1",
            "7-3-0-2",
            "7-3-0-3",
            "7-3-0-4",
            "7-3-0-5",
            "7-3-0-6",
            "7-3-0-7",
            "7-3-0-8",
            "7-3-1-1",
            "7-3-1-2",
            "7-3-1-3",
            "7-3-1-4",
            "7-3-1-5",
            "7-3-1-6",
            "7-3-1-7",
            "7-3-1-8",
            "7-3-1-9",
          ],
          "7-3-1-10"
        ),
      // Scope-1 Emissions Cases
      "7-4-0-5": () =>
        calculateRevenue(data, ["7-4-0-1", "7-4-0-2", "7-4-0-3"], "7-4-0-4"),
      // Scope-2 Emissions Cases
      "7-4-1-5": () =>
        calculateRevenue(
          data,
          ["7-4-1-1", "7-4-1-2", "7-4-1-3", "7-4-1-4"],
          "7-4-0-4"
        ),
      // Scope-3 Emissions Cases
      "7-4-2-17": () =>
        calculateRevenue(
          data,
          [
            "7-4-2-1",
            "7-4-2-2",
            "7-4-2-3",
            "7-4-2-4",
            "7-4-2-5",
            "7-4-2-6",
            "7-4-2-7",
            "7-4-2-8",
            "7-4-2-10",
            "7-4-2-11",
            "7-4-2-12",
            "7-4-2-13",
            "7-4-2-14",
            "7-4-2-15",
            "7-4-2-16",
          ],
          "7-4-0-4"
        ),
      //Total sum Scop-1, Scop-2, Scop-3 Cases
      "7-4-3-0": () => sumValues(data, ["7-4-0-1", "7-4-0-2", "7-4-0-3"]),
      "7-4-3-1": () =>
        sumValues(data, ["7-4-1-1", "7-4-1-2", "7-4-1-3", "7-4-1-4"]),
      "7-4-3-2": () =>
        sumValues(data, [
          "7-4-2-1",
          "7-4-2-2",
          "7-4-2-3",
          "7-4-2-4",
          "7-4-2-5",
          "7-4-2-6",
          "7-4-2-7",
          "7-4-2-8",
          "7-4-2-10",
          "7-4-2-11",
          "7-4-2-12",
          "7-4-2-13",
          "7-4-2-14",
          "7-4-2-15",
          "7-4-2-16",
        ]),
      //GHG Revenue Cases
      "7-4-3-4": () =>
        calculateRevenue(
          data,
          [
            "7-4-0-1",
            "7-4-0-2",
            "7-4-0-3",
            "7-4-1-1",
            "7-4-1-2",
            "7-4-1-3",
            "7-4-1-4",
            "7-4-2-1",
            "7-4-2-2",
            "7-4-2-3",
            "7-4-2-4",
            "7-4-2-5",
            "7-4-2-6",
            "7-4-2-7",
            "7-4-2-8",
            "7-4-2-10",
            "7-4-2-11",
            "7-4-2-12",
            "7-4-2-13",
            "7-4-2-14",
            "7-4-2-15",
            "7-4-2-16",
          ],
          "7-4-0-4"
        ),
    }),
    [data]
  );

  // Function to get filtered options based on revenue unit
  const getFilteredOptions = (revenueUnit, optionsList) => {
    const mappings = {
      "(in ₹ Mn)": [
        "(KL/revenue in ₹ Mn)",
        "(ML/revenue in ₹ Mn)",
        "(M3/revenue in ₹ Mn)",
      ],
      "(in $ Mn)": [
        "(KL/revenue in $ Mn)",
        "(ML/revenue in $ Mn)",
        "(M3/revenue in $ Mn)",
      ],
      "(in € Mn)": [
        "(KL/revenue in € Mn)",
        "(ML/revenue in € Mn)",
        "(M3/revenue in € Mn)",
      ],
    };
    return mappings[revenueUnit] || optionsList;
  };

  // Dynamically filter options
  const filteredOptions = useMemo(() => {
    let revenueUnit = "";
    if (id === "7-0-3-1") {
      revenueUnit = data?.find((ele) => ele.id === "7-0-3-0")?.value["Unit"];
      return getFilteredOptions(revenueUnit, [
        "(KL/revenue in ₹ Mn)",
        "(Kl/revenue in $ Mn)",
        "(Kl/revenue in € Mn)",
        "(ML/revenue in ₹ Mn)",
        "(Ml/revenue in $ Mn)",
        "(Ml/revenue in € Mn)",
        "(M3/revenue in ₹ Mn)",
        "(M3/revenue in $ Mn)",
        "(M3/revenue in € Mn)",
      ]);
    }
    if (id === "7-3-1-12") {
      revenueUnit = data?.find((ele) => ele.id === "7-3-1-10")?.value["Unit"];
      return getFilteredOptions(revenueUnit, [
        "(Kwh/revenue in ₹ Mn)",
        "(Kwh/revenue in $ Mn)",
        "(Kwh/revenue in € Mn)",
        "(G/revenue in ₹ Mn)",
        "(G/revenue in $ Mn)",
        "(G/revenue in € Mn)",
        "(Gj/revenue in ₹ Mn)",
        "(Gj/revenue in $ Mn)",
        "(Gj/revenue in € Mn)",
        "(Mj/revenue in ₹ Mn)",
        "(Mj/revenue in $ Mn)",
        "(Mj/revenue in € Mn)",
      ]);
    }
    if (["7-4-0-5", "7-4-1-5", "7-4-2-17", "7-4-3-4"].includes(id)) {
      revenueUnit = data?.find((ele) => ele.id === "7-4-0-4")?.value["Unit"];
      return getFilteredOptions(revenueUnit, [
        "(MT/revenue in ₹ Mn)",
        "(MT/revenue in $ Mn)",
        "(MT/revenue in € Mn)",
        "(t-Co2/revenue in ₹ Mn)",
        "(t-Co2/revenue in $ Mn)",
        "(t-Co2/revenue in € Mn)",
      ]);
    }
    return options;
  }, [id, options, data]);
  if (cases[id] && valKey === "Current Value") {
    return (
      <input
        disabled={true}
        type="number"
        className="form-control"
        value={cases[id](data)}
        readOnly
      />
    );
  }

  switch (type) {
    case "text":
    case "number":
    case "checkbox":
      const inputValue = data?.find((ele) => ele.id === id)?.value?.[valKey];
      const isChecked = type === "checkbox" && !!inputValue;

      return (
        <input
          disabled={disabled}
          type={type}
          className={type === "checkbox" ? " " : "form-control"}
          value={type === "checkbox" ? inputValue || false : inputValue || ""}
          checked={isChecked}
          onChange={handleInputChange}
        />
      );
    case "dropdown":
      return (
        <select
          disabled={disabled}
          className="form-control"
          value={data?.find((ele) => ele.id === id)?.value[valKey] || ""}
          onChange={handleInputChange}
        >
          {key === "Treatment" ? (
            options?.map((op) => (
              <option key={op} value={op}>
                {op}
              </option>
            ))
          ) : (
            <>
              <option value="">Select</option>
              {filteredOptions.map((op) => (
                <option key={op} value={op}>
                  {op}
                </option>
              ))}
            </>
          )}
        </select>
      );
    case "textarea":
      return (
        <>
          <textarea
            disabled={disabled}
            style={{
              padding: "5px 15px",
              fontSize: "14px",
              borderColor: "#e5e5e5",
              height: "5rem",
              width: "35rem",
              borderRadius: "8px",
            }}
            // className="form-control"
            value={data?.find((ele) => ele.id === id)?.value[valKey] || ""}
            onChange={handleInputChange}
          />
          {/* <p>{`${data?.find((ele) => ele.id === id)?.value[valKey].split(" ").length - 1} of 400 word`} </p> */}
        </>
      );
    case "static":
      return value;
    case "calculated":
      return (
        <input
          disabled={true}
          className="form-control"
          value={calculation({ data })}
        />
      );
    default:
      return null;
  }
};

export default observer(AddForm);
