import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AssignCategory from "./assignCategory";
import AssignSubCategory from "./assignSubCategory";
import AssignKPI from "./assignKpi";
import { observer } from "mobx-react";

const CompanyAssign = ({ tab, setTab, setStep }) => {
  switch (tab) {
    case "Category":
      return <AssignCategory setTab={setTab} />;
    case "Sub Category":
      return <AssignSubCategory setTab={setTab} />;
    case "KPI":
      return <AssignKPI setStep={setStep} />;
    case "KPI fields":
      return null;
    default:
      return null;
  }
};

const TabsComponent = ({ setStep }) => {
  const [activeTab, setActiveTab] = useState("Category");

  const tabs = ["Category", "Sub Category", "KPI"];

  return (
  
        <div className="card-body">
          <div className="step-container step-small">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`col step ${
              activeTab === tab ? "active-step" : "inactive-step"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className="tab-content mt-4">
        <CompanyAssign
          tab={activeTab}
          setTab={setActiveTab}
          setStep={setStep}
        />
      </div>
    </div>
  );
};

export default observer(TabsComponent);
