import { useCallback } from "react";

const AddedGroupData = ({ category, allRowsData, groupData, id }) => {
  const addedOutPut = useCallback(
    (key) => {
      if (category?.group === "C") {
        return (
          groupData?.["A"]?.reduce((acc, curr) => {
            return acc + (Number(curr?.[key]) || 0);
          }, 0) -
          groupData?.["B"]?.reduce((acc, curr) => {
            return acc + (Number(curr?.[key]) || 0);
          }, 0)
        );
      }
      return allRowsData?.reduce((acc, curr) => {
        return acc + (category.group === curr?.group ? Number(curr?.[key]) : 0);
      }, 0);
    },
    [allRowsData, category.group, groupData]
  );

  return (
    <tr className={`subTotalTr ${id}-footer`}>
      <td>
        <b>{""}</b>
      </td>
      <td colspan="2"
        style={{
          fontWeight: "600",
        }}
      >
        Total
      </td>
      <td></td>
      <td></td>
      <td className="totalTd">{addedOutPut("prevValue")}</td>
      <td className="totalTd">{addedOutPut("target")}</td>
      <td></td>
      <td>{addedOutPut("value") || ""}</td>
      <td></td>
      {/* <td></td> */}
    </tr>
  );
};

export default AddedGroupData;
