import React, { useState } from "react";

import { Link } from "react-router-dom";
import Layout from "../../../utils/layout";
import WaterRecycleFilters from "../water-recycle/WaterRecycleFilters";
import DynamicTable from "../../../datatable/DynamicTable";

const WaterConsumption = () => {
  const Month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const Year = ["2023", "2022"];
  const [filter, setFilter] = useState({
    month: "",
    year: "",
  });

  const [step, setStep] = useState(0);

  const steps = ["Consumption", "Intake"];

  const handleFilter = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };
  const data = React.useMemo(
    () => [
      {
        waterManagement: "Total water quantity recycled and reused",
        Unit: "m3",
        Frequency: "Monthly",
        PrevValue: 100,
        Value: 120,
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Water Management",
        accessor: "waterManagement",
      },
      {
        Header: "Unit",
        accessor: "Unit",
      },
      {
        Header: "Frequency",
        accessor: "Frequency",
      },
      {
        Header: "Prev. Value",
        accessor: "PrevValue",
      },
      {
        Header: "Target",
        accessor: "Target",
      },
      {
        Header: "Value",
        accessor: "Value",
      },
    ],
    []
  );

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };
  return (
    <Layout>
      <div className="water-list">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Water Consumption</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Water Consumption
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                      <div className="text-sm-end">
                        <Link to={"/admin/add-water-consumption"}>
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#newCustomerModal"
                            className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                          >
                            <i className="mdi mdi-plus me-1"></i> Add
                            WaterConsumption
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-sm-12">
                      <WaterRecycleFilters
                        label={"Month"}
                        onChange={(e) => handleFilter("month", e.target.value)}
                        selectedValue={filter.month}
                        options={Month}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <WaterRecycleFilters
                        label={"Year"}
                        onChange={(e) => handleFilter("year", e.target.value)}
                        selectedValue={filter.year}
                        options={Year}
                      />
                    </div>
                  </div>

                  <div className="card">
        <div className="card-body">
          <div className="step-container">
                      {steps.map((heading, index) => (
                        <div
                          key={index}
                          className={`col step ${
                            index === step ? "active-step" : "inactive-step"
                          }`}
                          onClick={() => setStep(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="number-wrapper">{index + 1}. </span>
                          {heading}
                        </div>
                      ))}
                    </div>

                    {step === 0 && (
                      <div className="table mt-4">
                        <DynamicTable
                          pageSize={data.length}
                          columns={columns}
                          data={data}
                        />
                      </div>
                    )}

                    {step === 1 && (
                      <div className="table mt-4">
                        <DynamicTable
                          pageSize={data.length}
                          columns={columns}
                          data={data}
                        />
                      </div>
                    )}
                    <div className="row mt-3">
                      <div className="col text-end">
                        {step > 0 && (
                          <button
                            className="btn btn-primary me-2"
                            onClick={handlePrev}
                          >
                            Previous
                          </button>
                        )}
                        {step < steps.length - 1 && (
                          <button
                            className="btn btn-primary"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WaterConsumption;
