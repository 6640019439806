import React, { useState } from "react";

import { Link } from "react-router-dom";
import Layout from "../../../utils/layout";
// import WaterRecycleFilters from "./water/WaterRecycleFilters";
import DynamicTable from "../../../datatable/DynamicTable";
import WaterRecycleFilters from "../water-recycle/WaterRecycleFilters";

const RainWater = () => {
  const Month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const Year = ["2023", "2022"];
  const [filter, setFilter] = useState({
    month: "",
    year: "",
  });

  const handleFilter = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };
  const data = React.useMemo(
    () => [
      {
        "Water Recycle Indicators": "Total water quantity recycled and reused",
        Unit: "m3",
        Frequency: "Monthly",
        PrevValue: 100,
        Target: 150,
        Value: 120,
        Comment: "Lorem ipsum dolor sit amet",
        Attachment: "attachment1.pdf",
      },
      {
        "Water Recycle Indicators": "Industrial water recycled/reused",
        Unit: "KWH",
        Frequency: "Weekly",
        PrevValue: 200,
        Target: 250,
        Value: 220,
        Comment: "Consectetur adipiscing elit",
        Attachment: "attachment2.pdf",
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Rain Water",
        accessor: "Water Recycle Indicators",
      },
      {
        Header: "Unit",
        accessor: "Unit",
      },
      {
        Header: "Frequency",
        accessor: "Frequency",
      },
      {
        Header: "Prev. Value",
        accessor: "PrevValue",
      },
      {
        Header: "Target",
        accessor: "Target",
      },
      {
        Header: "Value",
        accessor: "Value",
      },
      {
        Header: "Comment",
        accessor: "Comment",
      },
      {
        Header: "Attachment",
        accessor: "Attachment",
      },
    ],
    []
  );
  return (
    <Layout>
      <div className="water-list">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">List of Details</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Water</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                      <div className="text-sm-end">
                        <Link to={"/add-rain-water"}>
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#newCustomerModal"
                            className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                          >
                            <i className="mdi mdi-plus me-1"></i> Add Rain Water
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-sm-12">
                      <WaterRecycleFilters
                        label={"Month"}
                        onChange={(e) => handleFilter("month", e.target.value)}
                        selectedValue={filter.month}
                        options={Month}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <WaterRecycleFilters
                        label={"Year"}
                        onChange={(e) => handleFilter("year", e.target.value)}
                        selectedValue={filter.year}
                        options={Year}
                      />
                    </div>
                  </div>

                  <div className="table">
                    <DynamicTable
                      pageSize={data.length}
                      columns={columns}
                      data={data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RainWater;
