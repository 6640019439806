import React, { useState, useEffect } from "react";
import useStore from "../../hooks/useStore";
import { toJS } from "mobx";

const KpiModal = ({ open, onClose, mode, initialState }) => {
  const emptyState = {
    catId: "0",
    subCatId: "0",
    name: "",
    slug: "",
    position: "",
  };

  const {
    dashboardStore: { createKpi, updateKpi, categoryList, subCategories },
  } = useStore();

  const [formData, setFormData] = useState({ ...emptyState });

  // const subCategoryOptions = [
  //   "Climate change",
  //   "Carbon emission reduction",
  //   "Water pollution & water scarcity",
  //   "Energy efficiency",
  //   "Air Pollution",
  //   "Greenhouse gas emissions",
  //   "Biodiversity",
  // ];
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
      ...(name === "name" && {
        slug: `${value}`.toLowerCase().replaceAll(" ", "_"),
      }),
    });
  };

  useEffect(() => {
    if (mode) {
      setFormData(
        mode === "edit" && initialState
          ? {
              catId: initialState.catId,
              subCatId: initialState.subCatId,
              name: initialState.name,
              slug: initialState.slug,
              position: initialState.position,
            }
          : { ...emptyState }
      );
    }

    return () => {
      setFormData(emptyState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, initialState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === "edit" && initialState) {
      updateKpi(formData, initialState.id);
    } else {
      const payload = {
        ...formData,
        status: true,
      };
      createKpi(payload);
    }
    onClose();
  };

  if (!open) {
    return;
  }

  return (
    <div
      className={`modal fade ${open ? "show" : ""}`}
      id="newCustomerModal"
      tabIndex="-1"
      onClick={onClose}
      aria-labelledby="newCustomerModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content border-0">
          <div className="modal-header">
            <h5 className="mb-0" id="newCustomerModalLabel">
              {mode === "create" ? "Add Kpi" : "Edit Kpi"}
            </h5>
            <button
              type="button"
              className="btn-close "
              onClick={onClose}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form
              autoComplete="off"
              className="needs-validation createCustomer-form"
              id="createCustomer-form"
              noValidate
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label htmlFor="category-input" className="form-label">
                      Category
                    </label>
                    <select
                      value={formData?.catId}
                      onChange={handleChange}
                      name="catId"
                      id="category-input"
                      className="form-control"
                      required
                    >
                      <option value="0">Select category</option>
                      {categoryList?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select a category.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="sub-category-input" className="form-label">
                      Subcategory
                    </label>
                    <select
                      value={formData?.subCatId}
                      onChange={handleChange}
                      name="subCatId"
                      id="sub-category-input"
                      className="form-control"
                      required
                    >
                      <option value="0">Select subcategory</option>
                      {toJS(subCategories)
                        ?.filter((subCat) => subCat.catId === formData.catId)
                        ?.map((option, index) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select a subcategory.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="username-input" className="form-label">
                      Kpi Name
                    </label>
                    <input
                      type="text"
                      value={formData?.name}
                      onChange={handleChange}
                      name="name"
                      id="username-input"
                      className="form-control"
                      placeholder="Enter name"
                      required
                    />
                    <div className="invalid-feedback">Please enter a name.</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email-input" className="form-label">
                      Slug
                    </label>
                    <input
                      type="text"
                      value={formData?.slug}
                      onChange={handleChange}
                      name="slug"
                      id="slug-input"
                      className="form-control"
                      placeholder="Enter slug"
                      required
                    />
                    <div className="invalid-feedback">Enter slug</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone-input" className="form-label">
                      Position
                    </label>
                    <input
                      type="text"
                      value={formData?.position}
                      onChange={handleChange}
                      name="position"
                      id="position-input"
                      className="form-control"
                      placeholder="Enter position"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter position
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex gap-1 justify-content-end">
                    <button
                      type="button"
                      onClick={onClose}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      id="addCustomer-btn"
                      className="btn btn-success"
                      onClick={handleSubmit}
                    >
                      {mode === "create" ? "Add Kpi" : "Update"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiModal;
