import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useStore from "../hooks/useStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

const ModalComponent = () => {
  const navigate = useNavigate();
  const {
    companyStore: { isCompanyUnit, setUnit, companyData, companyUnit },
    userStore: { isLoggedIn, isAdminLoggedIn, logoutUser },
  } = useStore();
  const [show, setShow] = useState();
  useEffect(() => {
    if (isLoggedIn && isCompanyUnit) {
      setShow(true);
    }
    return () => {
      setShow(false);
    };
  }, [isCompanyUnit, isLoggedIn]);

  const logoutHandler = () => {
    if (isAdminLoggedIn) {
      navigate("/admin");
    }
    if (isLoggedIn) {
      navigate("/company");
    }
    logoutUser();
  };

  return (
    <Modal show={show} centered className="select-unit-modal" size="sm">
      <Modal.Header style={{ borderColor: "#eeeeee" }}>
        <Modal.Title>Select Unit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          {/* <label htmlFor="unit-select" className="unit-label">
            Choose a unit:
          </label> */}
          {toJS(companyData)?.assignUnit?.length === 0 ? (
            <div className="d-flex justify-content-between flex-column align-items-center text-center gap-3 ">
              <span className="font-size-14 text-muted">
                "No Unit Found. Contact to Admin"
              </span>

              <button className="btn btn-danger" onClick={logoutHandler}>
                {" "}
                <i className="bx bx-power-off font-size-16 align-middle me-1 "></i>{" "}
                Logout
              </button>
            </div>
          ) : (
            <select
              id="unit-select"
              className="form-control"
              value={companyUnit}
              onChange={(e) => {
                if (e.target.value) setUnit(e.target.value);
              }}
            >
              <option value="">Choose a unit</option>
              {toJS(companyData)?.assignUnit?.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default observer(ModalComponent);
