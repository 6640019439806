import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const MultiRadialChart = ({
  series,
  labels,
  unit,
  heading,
  position,
  download,
  height,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataSeries = series || [44, 55, 41, 17, 15];

  // Calculate the maximum value and add 20% to it
  const maxValue = Math.max(...dataSeries);
  const adjustedMax = maxValue + maxValue * 0.2; // Add 20% to the maximum value
  const scaledSeries = dataSeries.map((value) => (value / adjustedMax) * 100);
  const options = useMemo(
    () => ({
      chart: {
        type: "radialBar",
        toolbar: {
          show: true,
          offsetY: "100%",
          offsetX: 0,
          tools: {
            download:
              download === false
                ? undefined
                : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
          },
        },
      },
      labels: labels || [], // Set default empty array for labels
      plotOptions: {
        radialBar: {
          startAngle: 0, // Start position of the radial bars
          endAngle: 360, // Full circle
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
              // Show the actual value of each radial bar
              formatter: function (_, opts) {
                const value = dataSeries[opts.dataPointIndex] || 0;
                return value === 0 ? "" : `${value} ${unit || ""}`; // Use the actual value with unit, handle undefined case and hide when 0
              },
            },
            // total: {
            //   show: true,
            //   label: "Total",
            //   formatter: function (w) {
            //     // Update the total value based on the dynamic data
            //     const total = dataSeries.reduce((acc, val) => acc + val, 0);
            //     return total; // Show the sum of actual values
            //   },
            // },
          },
        },
      },
      legend: {
        show: true,
        position: position || "bottom",
        // Display the actual value in the legend
        formatter: function (seriesName, opts) {
          return `${seriesName}: ${dataSeries[opts.seriesIndex]}`; // Use the actual value
        },
      },
    }),
    [download, labels, position, dataSeries, unit]
  );

  return (
    <div>
      <div id="chart">
        {heading && <h5>{heading}</h5>}
        <ReactApexChart
          options={options}
          series={scaledSeries} // Pass the scaled values for the chart
          type="radialBar"
          height={height || 750}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default MultiRadialChart;
