import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { routeMapping } from "../tiles";
import useStore from "../../hooks/useStore";
import menuIcon from "../../assets/images/logo-icon.png";
import { toJS } from "mobx";

function Sidebar() {
  const navigate = useNavigate();
  const {
    companyStore: { subCategoryList, setSubCatId, setKpiId, subCatId, catId },
    userStore: { isLoggedIn, isAdminLoggedIn, userData },
  } = useStore();
  const [toggleSubCatId, setToggleSubCatId] = useState("");
  const subCatList = toJS(subCategoryList);
  const pathName = useLocation().pathname;

  useEffect(() => {
    setToggleSubCatId(subCatId);
  }, [subCatId]);

  if (isLoggedIn) {
    return (
      <>
        <div className="vertical-menu">
          <div data-simplebar className="h-100 scrolling-sidebar">
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title" key="t-menu">
                  Menu
                </li>

                {isLoggedIn && (
                  <>
                    {subCatList?.length > 0 &&
                      subCatList?.map((subCategory, subCatIndex) => (
                        <li key={subCategory.id}>
                          <button
                            onClick={() => {
                              setToggleSubCatId((prev) =>
                                prev ? null : subCategory.subCatId
                              );
                              setSubCatId(subCategory.subCatId);
                            }}
                          >
                            <img src={menuIcon} alt="" />{" "}
                            {subCategory.subCatIdAssignSubCategory.name}
                            <i
                              className={
                                toggleSubCatId === subCategory.subCatId &&
                                subCategory.kpi.length > 0
                                  ? "mdi mdi-chevron-up"
                                  : "mdi mdi-chevron-down"
                              }
                            ></i>
                          </button>

                          {toggleSubCatId === subCategory.subCatId &&
                            subCategory.kpi.length > 0 && (
                              <ul className={`kpi-menu  `}>
                                {subCategory.kpi.map((kpi) => (
                                  <li
                                    className={
                                      pathName ===
                                      `/company/${kpi.kpiIdAssignKpi.slug}`
                                        ? "sidebar-active"
                                        : ""
                                    }
                                    onClick={() => {
                                      navigate(
                                        `/company/${kpi.kpiIdAssignKpi.slug}`
                                      );
                                      setKpiId(kpi.kpiId);
                                    }}
                                    key={kpi.id}
                                  >
                                    {kpi.kpiIdAssignKpi.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                        </li>
                      ))}
                    {userData?.department !== "User" && catId === "2" && (
                      <li
                        to="/company/revenue"
                        onClick={() => {
                          navigate("/company/revenue");
                        }}
                      >
                        <button>
                          <img src={menuIcon} alt="" /> Revenue
                        </button>
                      </li>
                    )}
                    {userData?.department !== "User" && (
                      <>
                        <li
                          to="/company/employee"
                          onClick={() => {
                            navigate("/company/employee");
                          }}
                        >
                          <button>
                            <img src={menuIcon} alt="" /> Employee
                          </button>
                        </li>
                        <li
                          to="/company/"
                          onClick={() => {
                            navigate("/company/logList");
                          }}
                        >
                          <button>
                            <img src={menuIcon} alt="" />
                            Activity Log
                          </button>
                        </li>
                      </>
                    )}
                  </>
                )}
                {isAdminLoggedIn && (
                  <>
                    {Object.entries(routeMapping).map(([key, value]) =>
                      key === "Assign" ? null : (
                        <li
                          key={key}
                          onClick={() => {
                            navigate(value.path);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span>{value.icon}</span>
                          {key}
                        </li>
                      )
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>

            {/* <li className={`${openMenus["dashboards"] ? "menu-open" : ""}`}>
              <Link
                className="has-arrow waves-effect"
                onClick={() => toggleMenu("dashboards")}
              >
                <i className="bx bx-home-circle"></i>
                <span key="t-dashboards">Dashboards</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin">Default</Link>
                </li>
                <li>
                  <Link to="/admin/categories">Category</Link>
                </li>
                <li>
                  <Link to="/admin/company">Companies</Link>
                </li>
                <li>
                  <Link to="/admin/unit">Unit</Link>
                </li>
                <li>
                  <Link to="/admin/water">Water</Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/admin/dashboard" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/categories" className="waves-effect">
                <i className="bx bx-detail"></i>
                <span>Category</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/subcategories" className="waves-effect">
                <i className="bx bx-file"></i>
                <span>Sub Category</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/kpi" className="waves-effect">
                <i className="bx bx-receipt"></i>
                <span>KPI</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/admin/fields" className="waves-effect">
                <i className="bx bx-calendar"></i>
                <span>Fields</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/admin/unit" className="waves-effect">
                <i className="bx bx-task"></i>
                <span>Unit</span>
              </Link>
            </li> */}

            <li>
              <Link to="/admin/company" className="waves-effect">
                <i className="bx bx-store"></i>
                <span>Companies</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default observer(Sidebar);
