import React, { Fragment, useEffect, useMemo, useState } from "react";
import Layout from "../../../utils/layout";
import { Link, useNavigate } from "react-router-dom";
import WaterFormRow, { ADD_FIELD_FORM_VALUES } from "./waterFormRow";
import useStore from "../../../hooks/useStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import AddedGroupData from "./addedGroupData";
import { useAuth } from "../../../context/authProvider";
import { Form } from "react-bootstrap";
import { STATIC_GROUPS } from "../../../constants/constants";

const AddWaterForm = () => {
  const navigate = useNavigate();
  const { showAlert } = useAuth();
  const {
    userStore: { userData },
    companyStore: {
      createWater,
      kpiId,
      subCatId,
      catId,
      getCompanyAssignFields,
      assignedCategoryList,
      companyUnit,
      createWaterMessage,
    },
  } = useStore();
  const [dropdownData, setDropdownData] = useState({});
  const [payload, setPayload] = useState([]);
  const [allUnitData, setAllUnitData] = useState([]);
  const [validate, setValidate] = useState(false);
  const [tempPayload, setTempPayload] = useState([]);
  const handleDropdownData = (data) => {
    if (data) {
      setDropdownData(data);
    }
  };

  const groupData = useMemo(
    () =>
      payload?.reduce((acc, curr) => {
        if (acc[curr.group]) {
          return {
            ...acc,
            [curr.group]: [...acc[curr.group], curr],
          };
        }
        return {
          ...acc,
          [curr.group]: [curr],
        };
      }, {}),
    [payload]
  );

  useEffect(() => {
    setTempPayload(
      payload?.map((ele) => {
        const fieldName = ele?.fieldsIdAssignFields?.name;
        if (fieldName.includes("Percentage of water recycle/reuse")) {
          const numerator = payload?.find((pa) =>
            pa?.fieldsIdAssignFields?.name?.includes?.(
              "Total water quantity recycled and reused"
            )
          );
          const denominator = groupData?.A?.reduce?.(
            (acc, curr) => {
              return {
                prevValue: acc.prevValue + Number(curr?.prevValue),
                targetValue: acc.targetValue + Number(curr?.target),
              };
            },
            { prevValue: 0, targetValue: 0 }
          );
          const prevVal = Math.floor(
            (Number(numerator.prevValue) / Number(denominator.prevValue)) * 100
          );
          const target = Math.floor(
            (Number(numerator.target) / Number(denominator.targetValue)) * 100
          );
          return {
            ...ele,
            prevValue: prevVal || 0,
            target: target || 0,
          };
        } else {
          return ele;
        }
      })
    );
  }, [groupData?.A, payload]);

  const unitValidated = payload
    ?.filter((payload) => !payload.unit)
    ?.map((id) => id.id);

  useEffect(() => {
    if (createWaterMessage) {
      showAlert("Created SuccessFully.", true);
      navigate("/company/water");
    }
  }, [createWaterMessage, navigate, showAlert]);

  useEffect(() => {
    setPayload(
      assignedCategoryList.map((e) => ({
        ...e,
        ...ADD_FIELD_FORM_VALUES,
      }))
    );
  }, [assignedCategoryList]);

  useEffect(() => {
    setPayload((prev) =>
      prev.map((ele) => ({
        ...ele,
        ...dropdownData,
      }))
    );
  }, [dropdownData]);

  const getAllUnitData = () => {
    fetch(process.env.REACT_APP_BASE_URL + `unit-master/getAll`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("access-token"))?.token
        }`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setAllUnitData(result.data);
        }
      })
      .catch((error) => console.error("Eroooroorororoor", error));
  };
  useEffect(() => {
    getCompanyAssignFields(toJS(userData)?.id);
    getAllUnitData();
  }, [getCompanyAssignFields, userData, catId, subCatId, kpiId]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const form = event.target;
    if (form.checkValidity()) {
      createWater(
        payload.map((e) => ({
          prevValue: Number(e.prevValue) || 0,
          target: Number(e.target) || 0,
          unit: Number(e.unit),
          companyId: Number(toJS(userData)?.companyId),
          companyUnit: Number(companyUnit),
          frequencyType: e.frequencyType,
          frequencyDate: e.frequencyDate,
          frequencyMonth: e.frequencyMonth,
          frequencyYear: e.frequencyYear,
          fieldId: Number(e.fieldsIdAssignFields.id),
          fieldGroup: e.fieldsIdAssignFields.group,
          value: e.value,
          groupName: e.group,
          comment: e.comment,
          attachment: e.attachment,
        })),
        Object.entries(groupData)?.map(([key, value]) => {
          return {
            groupName: key,
            prevValue:
              key === "C"
                ? groupData?.["A"]?.reduce((acc, curr) => {
                    return acc + (Number(curr?.prevValue) || 0);
                  }, 0) -
                  groupData?.["B"]?.reduce((acc, curr) => {
                    return acc + (Number(curr?.prevValue) || 0);
                  }, 0)
                : value.reduce(
                    (acc, curr) => acc + (Number(curr?.prevValue) || 0),
                    0
                  ),
            targetValue:
              key === "C"
                ? groupData?.["A"]?.reduce((acc, curr) => {
                    return acc + (Number(curr?.target) || 0);
                  }, 0) -
                  groupData?.["B"]?.reduce((acc, curr) => {
                    return acc + (Number(curr?.target) || 0);
                  }, 0)
                : value.reduce(
                    (acc, curr) => acc + (Number(curr?.target) || 0),
                    0
                  ),
            frequencyType: dropdownData.frequencyType,
            frequencyDate: dropdownData.frequencyDate,
            frequencyMonth: dropdownData.frequencyMonth,
            frequencyYear: dropdownData.frequencyYear,
            kpiId,
            subCatId,
            catId,
            unitId: companyUnit,
          };
        })
      );
    } else {
      event.stopPropagation();
      showAlert("Fields Must Not Be Empty.");
    }
    setValidate(true);
  };

  const groupsName = useMemo(
    () => Array.from(new Set(assignedCategoryList.map((e) => e.group))),
    [assignedCategoryList]
  );

  return (
    <Layout>
      <div className="add-water-page">
        <Form
          noValidate
          validated={validate}
          onSubmit={onSubmitHandler}
          className="container-fluid"
        >
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Add Water</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/company/water">Water List</Link>
                    </li>
                    <li className="breadcrumb-item active">Add Water</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="card p-4">
            <WaterDropdownRow onDropdownData={handleDropdownData} />

            {/* Table  */}
            <div className="table-responsive ">
              <table className="table align-middle table-bordered table-nowrap mb-0">
                <thead>
                  <tr key={12} className="waterTabletr">
                    <th key={0}>Sr. No.</th>
                    <th key={1} colspan="2">
                      Title
                    </th>
                    <th key={2}>Unit</th>
                    <th key={3}>Frequency</th>
                    <th key={4}>Prev. Value</th>
                    <th key={5}>Target Value</th>
                    <th key={6}>Comment</th>
                    {/* <th key={7}> Value</th> */}
                    <th key={8}>Attachment</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(payload) &&
                    tempPayload
                      .sort((a, b) => Number(a.position) - Number(b.position))
                      ?.map((category, id, arr) => {
                        const prevGroup = tempPayload[id - 1]?.group;
                        const curr = tempPayload[id]?.group;

                        return (
                          <Fragment key={id}>
                            {prevGroup !== curr && (
                              <>
                                {id !== 0 && (
                                  <AddedGroupData
                                    key={id}
                                    category={{ ...category, group: prevGroup }}
                                    allRowsData={payload}
                                    groupData={groupData}
                                  />
                                )}
                                <tr
                                  className=""
                                  // style={{ position: "relative" }}
                                  style={{
                                    backgroundColor: " #ffe5a0",
                                  }}
                                  key={`${id}-heading`}
                                >
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                    }}
                                    colSpan={1}
                                  >
                                    {groupsName?.findIndex(
                                      (na) => na === category?.group
                                    ) + 1}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                    }}
                                    colSpan={7}
                                  >
                                    {STATIC_GROUPS.water[category.group]}
                                  </td>
                                </tr>
                              </>
                            )}
                            <WaterFormRow
                              unitValidated={unitValidated}
                              category={category}
                              key={id}
                              allUnitData={allUnitData}
                              setPt={setPayload}
                              id={groupData?.[category.group]?.findIndex(
                                (e) => category.id === e.id
                              )}
                            />
                            {id + 1 > tempPayload.length - 1 ? (
                              <AddedGroupData
                                key={`${id}-footer`}
                                category={{ ...category, group: prevGroup }}
                                allRowsData={payload}
                                groupData={groupData}
                              />
                            ) : null}
                          </Fragment>
                        );
                      })}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-12 text-end mt-3 ">
                <button className="btn btn-danger px-4 mx-2">Cancel</button>
                <button className="btn btn-success px-4 " type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
};

export default observer(AddWaterForm);

const Dropdown = ({ label, options, selectedValue, onChange }) => {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <select
        required
        className="form-select"
        value={selectedValue}
        onChange={onChange}
      >
        <option value="">Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const WaterDropdownRow = ({ onDropdownData }) => {
  const frequencyTypeList = ["Monthly", "Daily"];

  const MonthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const yearList = Array.from({ length: 5 }).map(
    (_, index) => new Date().getFullYear() - index
  );

  const [selectedFreq, setSelectedFreq] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [frequencyDate, setFrequencyDate] = useState("");

  useEffect(() => {
    if (selectedFreq === "Daily") {
      setSelectedMonth("");
      setSelectedYear("");
    } else {
      setFrequencyDate("");
    }
  }, [selectedFreq]);

  useEffect(() => {
    const data = {
      frequencyType: selectedFreq,
      frequencyMonth: selectedMonth,
      frequencyYear: selectedYear,
      frequencyDate,
    };
    onDropdownData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequencyDate, selectedFreq, selectedMonth, selectedYear]);
  return (
    <div className="row mb-2">
      <div className="col-md-4">
        <Dropdown
          label="Frequency Type"
          options={frequencyTypeList}
          selectedValue={selectedFreq}
          onChange={(e) => setSelectedFreq(e.target.value)}
        />
      </div>
      {selectedFreq === "Daily" && (
        <div className="frequency-date-input">
          <div className="mb-3">
            <label htmlFor="toDate" className="form-label">
              Frequency Date *
            </label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                id="toDate"
                required
                placeholder="Select date"
                value={frequencyDate}
                onChange={(e) => setFrequencyDate(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
        </div>
      )}
      {selectedFreq !== "Daily" && (
        <>
          <div className="col-md-4">
            <Dropdown
              label="Year"
              options={yearList}
              selectedValue={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <Dropdown
              label="Month"
              options={MonthList.filter((mon, index) =>
                selectedYear == new Date().getFullYear()
                  ? new Date().getMonth() >= index
                  : true
              )}
              selectedValue={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>
        </>
      )}
    </div>
  );
};
