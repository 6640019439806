import { useCallback } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const RowHandler = SortableHandle(({ children }) => (
  <div className="draggablehandle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-grip-vertical"
      viewBox="0 0 16 16"
    >
      <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    </svg>
    {children}
  </div>
));

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

const TableRow = ({ id: index, item, setData, className }) => {
  return (
    <tr key={index} className={className}>
      <td>
        <RowHandler> {index + 1}</RowHandler>
      </td>
      <td>{item.name}</td>
      <td>{item.group}</td>
      <td>
        <div className="form-check font-size-20 p-0">
          <input
            className="form-check-input m-0 float-none"
            type="checkbox"
            checked={item.isRequired}
            name="isRequired"
            onChange={(e) => {
              setData((prevFields) => {
                const updatedFields = [...prevFields];
                updatedFields[index].isRequired = e.target.checked;
                return updatedFields;
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="form-check font-size-20 p-0">
          <input
            className="form-check-input m-0 float-none"
            type="checkbox"
            checked={item.status}
            name="status"
            onChange={(e) => {
              setData((prevFields) => {
                const updatedFields = [...prevFields];
                updatedFields[index].status = e.target.checked;
                return updatedFields;
              });
            }}
          />
        </div>
      </td>
    </tr>
  );
};

const SortableItem = SortableElement((props) => {
  return <TableRow {...props} />;
});

export default function SortableTable({ data, setData, tableClass }) {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    },
    [setData]
  );

  return (
    <table className={tableClass}>
      <thead>
        <tr>
          <th>Sr. No.</th>
          <th>Name</th>
          <th>Group</th>
          <th>Required</th>
          <th>Assigned</th>
        </tr>
      </thead>
      <SortableCont
        onSortEnd={onSortEnd}
        axis="y"
        lockAxis="y"
        lockToContainerEdges={false}
        lockOffset={["30%", "50%"]}
        helperClass="helperContainerClass"
        useDragHandle={true}
      >
        {data.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            id={index}
            item={item}
            index={index}
            setData={setData}
            className="table-row"
          />
        ))}
      </SortableCont>
    </table>
  );
}
