import React from "react";
import ApexCharts from "react-apexcharts";

const GroupStackedChart = ({ series, categories,download,heading,subHeading }) => {
  const options = {
    series,
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },

    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      formatter: (val, opt) => {
        const series = opt?.config?.series[opt?.seriesIndex];
        if (series?.name === "Male") {
          return "M";
        }
        if (series?.name === "Female") {
          return "F";
        }
        if (series?.name === "Local Employee") {
          return "LE";
        }
        if (series?.name === "OutSourced Employee") {
          return "OS";
        }
        return val;
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 80,
      },
    },
    xaxis: {
      categories,
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "#FCD163",
      "#EF8083",
      "#88BE53",
      "#46A1E1",
      "#A889C4",
      "#FFA07A",
      "#90EE90",
      "#87CEEB",
      "#DDA0DD",
    ],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val;
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      formatter: (seriesName) => {
        if (seriesName === "Male") {
          return "Male (M)";
        }
        if (seriesName === "Female") {
          return "Female (F)";
        }
        if (seriesName === "Local Employee") {
          return "Local Employee (LE)";
        }
        if (seriesName === "OutSourced Employee") {
          return "OutSourced Employee (OS)";
        }
        return seriesName; // Return original name for others
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: (seriesName) => "",
        },
      },
    },
  };

  return (
    <div id="chart">
      <div>
        <h5 className="mb-3 text-muted">{heading ? heading  : ""}</h5>
        <p className="mb-3 text-muted">{subHeading ? subHeading : ""}</p>
      </div>
      <ApexCharts
        options={options}
        series={options.series}
        type="bar"
        height={460}
      />
    </div>
  );
};

export default GroupStackedChart;
