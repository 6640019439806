import { addChartToEditor } from "../../../utils/helpers";

const ChartImportButton = ({ editor, id, style }) => {
  return (
    <button
      className="btn btn-primary"
      onClick={async () => {
        await addChartToEditor({ editor, id, style });
      }}
    >
      Import
    </button>
  );
};

export default ChartImportButton;
