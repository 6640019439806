import React, { useState, useEffect } from "react";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";

const UnitModal = ({ open, onClose, mode, initialState }) => {
  const emptyState = {
    name: "",
    short: "",
    position: "",
  };

  const {
    dashboardStore: { createUnit, updateUnit },
  } = useStore();

  const [formData, setFormData] = useState({ ...emptyState });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (mode) {
      setFormData(
        mode === "edit" && initialState
          ? {
              name: initialState.name,
              short: initialState.shortName,
              position: initialState.position,
            }
          : { ...emptyState }
      );
      /*eslint-disable */
    }

    return () => {
      setFormData(emptyState);
    };
  }, [mode, initialState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === "edit" && initialState) {
      const payload = {
        name: formData.name,
        shortName: formData.short,
        position: formData.position,
        status: true,
      };
      updateUnit(payload, initialState.id);
    } else {
      createUnit({ ...formData, shortName: formData.short });
    }
    onClose();
  };

  if (!open) {
    return;
  }

  return (
    <div
      className={`modal fade ${open ? "show" : ""}`}
      id="newCustomerModal"
      tabIndex="-1"
      onClick={onClose}
      aria-labelledby="newCustomerModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content border-0">
          <div className="modal-header">
            <h5 className="mb-0" id="newCustomerModalLabel">
              {mode === "create" ? "Add Unit" : "Edit Unit"}
            </h5>
            <button
              type="button"
              className="btn-close "
              onClick={onClose}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body ">
            <form
              autoComplete="off"
              className="needs-validation createCustomer-form"
              id="createCustomer-form"
              noValidate
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label htmlFor="username-input" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      value={formData?.name}
                      onChange={handleChange}
                      name="name"
                      id="username-input"
                      className="form-control"
                      placeholder="Enter name"
                      required
                    />
                    <div className="invalid-feedback">Please enter a name.</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email-input" className="form-label">
                      Short Name
                    </label>
                    <input
                      type="text"
                      value={formData?.short}
                      onChange={handleChange}
                      name="short"
                      id="short-input"
                      className="form-control"
                      placeholder="Enter short Name"
                      required
                    />
                    <div className="invalid-feedback">Enter short</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone-input" className="form-label">
                      Position
                    </label>
                    <input
                      type="text"
                      value={formData?.position}
                      onChange={handleChange}
                      name="position"
                      id="position-input"
                      className="form-control"
                      placeholder="Enter position"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter position
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex gap-1 justify-content-end">
                    <button
                      type="button"
                      onClick={onClose}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      id="addCustomer-btn"
                      className="btn btn-success"
                      onClick={handleSubmit}
                    >
                      {mode === "create" ? "Add Unit" : "Update"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(UnitModal);
