import React from "react";

const WaterConsumptionTable = ({
  data,
  categoryList,
  frequency,
  handleInputChange,
  handleSelectChange,
}) => {
  return (
    <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          <td>
            <b>{row.waterRecycle}</b>
          </td>
          <td>
            <select
              className="form-select w-auto"
              value={row.selectedCategory}
              onChange={(e) => handleSelectChange(e, index, "selectedCategory")}
            >
              {categoryList.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </td>

          <td>
            <input
              type="text"
              className="form-control w-auto"
              name={`prevValue`}
              value={row.prevValue}
              onChange={(e) => handleInputChange(e, index, "prevValue")}
            />
          </td>

          <td>
            <input
              type="text"
              className="form-control  w-auto"
              name={`currValue`}
              value={row.target}
              onChange={(e) => handleInputChange(e, index, "target")}
            />
          </td>

          <td>
            <input
              type="text"
              className="form-control  w-auto"
              name={`value`}
              value={row.value}
              onChange={(e) => handleInputChange(e, index, "value")}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default WaterConsumptionTable;
