import React from "react";

const AssignRow = ({ category, index, onChange }) => {
  // const [isRequiredChecked, setIsRequiredChecked] = useState(
  //   category?.isRequired
  // );

  // const [group, setGroup] = useState(category?.group);
  // const [assigned, setAssigned] = useState(category?.assigned);

  // useEffect(() => {
  //   setIsRequiredChecked(category.isRequired);
  //   setGroup(category.group);
  //   setAssigned(category.assigned);
  // }, [category]);

  // const handleIsRequiredChange = (e) => {
  //   const updatedField = {
  //     ...category,
  //     isRequired: e.target.checked,
  //   };
  //   setIsRequiredChecked(e.target.checked);
  //   onFieldChange(updatedField);
  // };

  // const handleGroupChange = (e) => {
  //   const updatedField = {
  //     ...category,
  //     group: e.target.value,
  //   };
  //   setGroup(e.target.value);
  //   onFieldChange(updatedField);
  // };

  // const handleAssignedChange = (e) => {
  //   const updatedField = {
  //     ...category,
  //     assigned: e.target.checked,
  //   };
  //   setAssigned(e.target.checked);
  //   onFieldChange(updatedField);
  // };
  return (
    <tr className="odd">
      <td>{index}</td>
      <td>{category?.name}</td>

      <td>
        <input
          className="rounded form-control"
          value={category.group}
          onChange={onChange}
        />
      </td>
      <td>
        <div className="form-check font-size-20">
          <input
            className="form-check-input"
            type="checkbox"
            checked={category?.isRequired}
            onChange={onChange}
          />
        </div>
      </td>
      <td>
        <div className="form-check font-size-20">
          <input
            className="form-check-input"
            type="checkbox"
            checked={category?.assigned}
            onChange={onChange}
          />
        </div>
      </td>
    </tr>
  );
};

export default AssignRow;
