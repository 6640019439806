import { observer } from "mobx-react";
import useStore from "../../../hooks/useStore";
import { useEffect, useMemo, useState } from "react";
import { findKpi, generateYearArray } from "../../../utils/helpers";
import AddForm from "./addForm";
import ShowForm from "./showForm";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ApprovalEnums,
  GOVERNANCE_STATIC_GROUPS,
  USER_ROLE,
} from "../../../constants/constants";
import { useAuth } from "../../../context/authProvider";
import { Modal, Button } from "react-bootstrap";
import LogTableBody from "../../company/logForms/governanceLog";

const GovernanceKpisPage = ({
  dropDownValues,
  routeSubPath,
  groupConstant,
  label,
}) => {
  const navigate = useNavigate();
  const { year: tempYear } = useLocation().state || {};

  const {
    governance: { fetch, formData },
    formStore: { approveForm, deleteForm, logRaw, logData },
    companyStore: { kpiName },
    userStore: { employeeKpis, user },
  } = useStore();
  const { showAlert } = useAuth();
  //  state values
  const [year, setYear] = useState(tempYear || 0);
  // State for modals
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); // "approve" or "delete"
  //calculations
  const count = useMemo(
    () =>
      new Set(
        Object.keys(GOVERNANCE_STATIC_GROUPS[groupConstant]).map(
          (ele) => ele[0]
        )
      ).size,
    [groupConstant]
  );

  // side effects
  useEffect(() => {
    if (!tempYear && year) {
      fetch({ year });
    }
  }, [fetch, tempYear, year]);

  const showData = useMemo(
    () =>
      Array.from(new Set(formData?.map((ele) => ele?.fieldGroup[0]))).length ===
      count,
    [count, formData]
  );

  const approvalStatus = formData?.[0]?.approvalStatus;
  const formId = formData?.[0]?.formId;
  const yearData = formData?.[0]?.year;
  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );
  const handleModalShow = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalType("");
  };
  const handleModalLogShow = async (formId) => {
    // Fetch form log data
    await logData({ formId });
    setModalType("log");
    setShowModal(true);
  };

  const handleConfirmAction = () => {
    if (modalType === "approve") {
      approveForm({
        formId,
        type: "governance",
        showAlert,
        status: ApprovalEnums.Approved,
        navigate,
        // goback: true,
        year,
        kpiName,
      });
    } else if (modalType === "delete") {
      deleteForm({
        formId,
        type: "governance",
        showAlert,
        navigate,
        status: ApprovalEnums.Pending,
        year,
        kpiName,
      });
    }
    handleModalClose();
  };
  return (
    <div className="add-water-page">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">{label}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {/* <li className="breadcrumb-item">
                    <Link to="/company/aqi">Aqi List</Link>
                  </li> */}
                <li className="breadcrumb-item active"></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="card p-4">
        <div className="mb-3">
          <label className="form-label">Year</label>
          <select
            value={year}
            className="form-select"
            onChange={(event) => setYear(event.target.value)}
          >
            <option key="default" value={0}>
              Select Year
            </option>
            {generateYearArray().map((val) => (
              <option key={`${val}-options`} value={val}>{`FY${val}`}</option>
            ))}
          </select>

          {/* approval status Message  */}
          {formData?.[0]?.approvalStatus && Boolean(year) && (
            <div className="table-responsive mt-3 approval-table">
              <table className="table align-middle table-bordered table-nowrap mb-0  ">
                <thead>
                  <th>Updated By</th>
                  <th className=" p-3">Status</th>
                  <th className=" p-3">Approved By:</th>
                  <th>Log</th>
                  <th>Action</th>
                </thead>
                {/* {formData?.[0]?.approvalStatus &&( */}
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <div>
                          {formData?.[0]?.updater?.first_name ?? "-"}{" "}
                          {formData?.[0]?.updater?.last_name ?? "-"}
                        </div>
                        {formData?.[0]?.updatedBy && (
                          <div style={{ fontSize: "0.8em", color: "gray" }}>
                            (
                            {formData?.[0]?.updatedBy
                              ? new Date(
                                  formData?.[0]?.updatedAt
                                ).toLocaleDateString("en-GB")
                              : "-"}
                            )
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      {formData?.[0]?.approvalStatus === "approved"
                        ? "Approved"
                        : "Pending"}{" "}
                    </td>
                    <td>
                      <div>
                        <div>
                          {formData?.[0]?.approver?.first_name ?? "-"}{" "}
                          {formData?.[0]?.approver?.last_name ?? "-"}
                        </div>
                        {formData?.[0]?.approvedAt && (
                          <div style={{ fontSize: "0.8em", color: "gray" }}>
                            (
                            {formData?.[0]?.approvedAt
                              ? new Date(
                                  formData?.[0]?.approvedAt
                                ).toLocaleDateString("en-GB")
                              : "-"}
                            )
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <i
                        type="button"
                        onClick={() => handleModalLogShow(formId)}
                        className="mdi mdi-dots-vertical"
                      ></i>
                    </td>
                    {approvalStatus !== "approved" ? (
                      <td>
                        <div className="d-flex mt-2 gap-2 justify-content-center">
                          {kpi.isEdited && !kpi.isApproved && (
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => {
                                navigate("/company/approve/governance", {
                                  state: {
                                    year,
                                    groupConstant,
                                    type: "governance",
                                    kpiName,
                                  },
                                });
                              }}
                            >
                              Edit
                            </button>
                          )}
                          {(kpi.isApproved ||
                            user.role === USER_ROLE.Company) && (
                            <>
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => handleModalShow("approve")}
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  navigate("/company/approve/governance", {
                                    state: {
                                      year,
                                      groupConstant,
                                      type: "governance",
                                      // kpiName,
                                    },
                                  });
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => handleModalShow("delete")}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    ) : (
                      <td>-</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        {showData ? (
          <ShowForm groupConstant={groupConstant} />
        ) : (
          <AddForm
            year={year}
            dropDownValues={dropDownValues}
            routeSubPath={routeSubPath}
            groupConstant={groupConstant}
          />
        )}
      </div>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        size={modalType === "log" ? "xl" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "approve"
              ? "Approve Form"
              : modalType === "delete"
              ? "Delete Form"
              : "Log Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          {modalType === "approve" && (
            <p>Are you sure you want to approve this form?</p>
          )}
          {modalType === "delete" && (
            <p>
              Are you sure you want to delete this form? Once deleted, all data
              associated with this form will be permanently removed and cannot
              be recovered.
            </p>
          )}
          {modalType === "log" && (
            <div style={{ overflowX: "scroll" }}>
              <table className="table table-responsive scroll">
                <thead>
                  <tr key={12} className="waterTabletr">
                    <th key={0}>Username</th>
                    <th key={1}>Date</th>
                    <th key={2}>Action</th>
                    <th key={3}>Ip</th>
                    <th key={4}>Request</th>
                  </tr>
                </thead>
                <LogTableBody logRaw={logRaw} formId={formId} />
              </table>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          {modalType === "approve" && (
            <Button variant="primary" onClick={handleConfirmAction}>
              Approve
            </Button>
          )}
          {modalType === "delete" && (
            <Button variant="primary" onClick={handleConfirmAction}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default observer(GovernanceKpisPage);
