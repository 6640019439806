import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import React, { useMemo } from "react";
import { LOG_ACTION } from "../../constants/constants";
import Select from "react-select";

const LogActivityFilter = ({ filters, setFilters }) => {
  const {
    companyStore: { fetchLogActivity, companyDataJson },
  } = useStore();

  // filtered categories
  const categories = useMemo(
    () =>
      companyDataJson?.assignCategory?.map(
        ({ catIdAssignCategory: { name } }) => ({
          value: name,
          label: name,
        })
      ),
    [companyDataJson]
  );

  // filtered subCategories
  const subCategories = useMemo(
    () =>
      companyDataJson?.assignSubCategory
        ?.filter(({ catId }) =>
          filters?.category?.length === 0
            ? true
            : filters?.category
                ?.map((e) => e.value)
                ?.includes(
                  companyDataJson?.assignCategory?.find(
                    (f) => f.catId === catId
                  )?.catIdAssignCategory?.name
                )
        )
        ?.map(({ subCatIdAssignSubCategory: { name } }) => ({
          value: name,
          label: name,
        })),
    [
      companyDataJson?.assignCategory,
      companyDataJson?.assignSubCategory,
      filters?.category,
    ]
  );

  // filtered kpis
  const kpis = useMemo(
    () =>
      companyDataJson?.assignKpi
        ?.filter(({ subCatId }) =>
          filters?.subCategory?.length === 0
            ? true
            : filters?.subCategory
                ?.map((e) => e.value)
                ?.includes(
                  companyDataJson?.assignSubCategory?.find(
                    (f) => f.subCatId === subCatId
                  )?.subCatIdAssignSubCategory?.name
                )
        )
        ?.filter(({ catId }) =>
          filters?.category?.length === 0
            ? true
            : filters?.category
                ?.map((e) => e.value)
                ?.includes(
                  companyDataJson?.assignCategory?.find(
                    (f) => f.catId === catId
                  )?.catIdAssignCategory?.name
                )
        )
        ?.map(({ kpiIdAssignKpi: { name } }) => ({
          value: name,
          label: name,
        })),
    [
      companyDataJson?.assignCategory,
      companyDataJson?.assignKpi,
      companyDataJson?.assignSubCategory,
      filters?.category,
      filters?.subCategory,
    ]
  );

  // filtered employees
  const employees = useMemo(
    () =>
      companyDataJson?.companyEmployees
        ?.filter(({ firstName, lastName }) =>
          filters?.name
            ? `${firstName} ${lastName}`.includes(
                filters?.name
              )
            : true
        )
        .map(({ id, firstName, lastName }) => ({
          value: id,
          name: `${firstName} ${lastName}`,
          label: `${firstName} ${lastName}`,
        })),
    [companyDataJson?.companyEmployees, filters?.name]
  );

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchLogActivity(filters); // Fetch data with the current filters
  };

  const handleSelect = (value, key) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="startDate"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Category</label>
                <Select
                  isMulti
                  isClearable
                  name="category"
                  classNamePrefix="react-select-custom"
                  value={filters?.category}
                  options={categories}
                  onChange={(e) => handleSelect(e, "category")}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Sub Category</label>
                <Select
                  name="subCategory"
                  isMulti
                  isClearable
                  classNamePrefix="react-select-custom"
                  value={filters?.subCategory}
                  options={subCategories}
                  onChange={(e) => handleSelect(e, "subCategory")}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Kpi</label>
                <Select
                  isMulti
                  isClearable
                  name="kpi"
                  classNamePrefix="react-select-custom"
                  value={filters?.kpi}
                  options={kpis}
                  onChange={(e) => handleSelect(e, "kpi")}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Username</label>
                <Select
                  isMulti
                  isClearable
                  name="employee"
                  classNamePrefix="react-select-custom"
                  value={filters?.employee}
                  options={employees}
                  onChange={(e) => handleSelect(e, "employee")}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Action</label>
                <select
                  className="form-select"
                  name="action"
                  value={filters.action}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Action</option>
                  {Object.keys(LOG_ACTION).map((act) => (
                    <option key={act} value={act}>
                      {act}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3 mt-3 d-flex justify-content-start align-items-center">
              <button
                className="btn btn-primary"
                type="submit"
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default observer(LogActivityFilter);
