import React, { useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Modal, Button } from "react-bootstrap"; // Make sure you have react-bootstrap installed

const LogTableBody = ({ logRaw }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const logList = toJS(logRaw);

  // Function to open modal and set selected log data
  const handleViewClick = (log) => {
    setSelectedLog(log);
    setShowModal(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLog(null);
  };

  // Function to render key-value pairs with proper design
  const renderKeyValuePairs = (data, level = 0) => {
    if (typeof data !== "object" || data === null) {
      return null;
    }

    return Object.entries(data).map(([key, value], index) => (
      <div
        key={index}
        style={{
          marginBottom: "10px",
          paddingLeft: `${level * 20}px`, // Indentation for nested objects
          borderLeft: level > 0 ? "1px solid #ccc" : "none", // Add border for nested levels
        }}
      >
        {/* <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{' '} */}
        {!Number.isNaN(Number(key)) ? (
          <span style={{ display: "none" }}>
            {key.charAt(0).toUpperCase() + key.slice(1)}:
          </span>
        ) : (
          <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
        )}{" "}
        <div>
          {typeof value === "object" && value !== null ? (
            <div
              style={{
                backgroundColor: "#f8f9fa",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              {renderKeyValuePairs(value, level + 1)}{" "}
              {/* Recursively handle nested objects */}
            </div>
          ) : value !== null ? (
            value
          ) : (
            "null"
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      <tbody>
        {logList.length > 0 ? (
          logList.map((log, index) => (
            <tr key={index}>
              <td>{log.user.firstName}</td>
              <td>{new Date(log.createdAt).toLocaleDateString("en-GB")}</td>
              <td>{log.action}</td>
              <td>{log.ip}</td>
              <td>
                <button onClick={() => handleViewClick(log.request)}>
                  View
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}>
              <div className="text-center">No records found</div>
            </td>
          </tr>
        )}
      </tbody>

      {/* Modal for Viewing Log Details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Log Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {selectedLog ? (
            <>
              <p>
                <strong>Log Data:</strong>
              </p>
              <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                {renderKeyValuePairs(selectedLog.body || selectedLog.query)}
              </div>
            </>
          ) : (
            <p>No details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default observer(LogTableBody);
