import { useMemo } from "react";
import useStore from "../../hooks/useStore";
import BarChart from "../charts/barChart";
import { STATIC_GROUPS,REVENUE_LABELS } from "../../constants/constants";
import { observer } from "mobx-react";

function WaterDashboardAccordion({ year }) {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { revenuePageData },
  } = useStore();

  // Prepare water group data
  const barChartsGroupData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water || {};
    return ["D", "E", "F", "G", "H"].map((group) => {
      const data = [year - 1, year].map((y) => {
        const temp = tempData?.[y]?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        return temp?.[group]?.prevValue || 0;
      });
      return {
        label: STATIC_GROUPS.water[group],
        data,
      };
    });
  }, [dashboardDetails?.charts?.water, year]);


// Prepare revenue data
const revenueData = useMemo(() => {
  const filteredData =
    revenuePageData?.fieldData?.filter(
      (e) => e?.type === "water" && e?.title?.includes("Revenue")
    ) || [];

  const revenueUnit =
    revenuePageData?.revenue?.find((e) => e?.type === "all")?.unit
      ?.shortName || "";

  // Define the groups from REVENUE_LABELS
  const groups = Object.keys(REVENUE_LABELS.water);

  // Create a map of groups with zero values for missing years
  const years = [year - 1, year];
  const dataMap = groups.reduce((acc, group) => {
    acc[group] = years.reduce((yearAcc, y) => {
      yearAcc[y] = 0; // Default to zero for each year
      return yearAcc;
    }, {});
    return acc;
  }, {});

  // Populate dataMap with actual values
  filteredData.forEach((ele) => {
    
    const title = ele?.title;
    const value = ele?.value || 0;
    const matchingGroup = Object.keys(REVENUE_LABELS.water).find(
      (group) => REVENUE_LABELS.water[group] === title
    );

    if (matchingGroup) {
      years.forEach((y) => {
        if (ele?.year.toString() === y.toString()) {
          dataMap[matchingGroup][y] = value;
        }
      });
    }
  });  // Transform dataMap into the format required for charts
  return groups.map((group) => ({
    name: REVENUE_LABELS.water[group],
    unit: revenueUnit,
    data: years.map((y) => dataMap[group][y]),
  }));
}, [revenuePageData?.fieldData, revenuePageData?.revenue, year]);

  return (
    <div className="row h-100">
      {/* Render bar charts for water groups */}
      {barChartsGroupData.length !== 0 &&
        barChartsGroupData.map(({ label, data }, index) => (
          <div key={`water-group-${index}`} className="col-xl-6 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4 donut-heading">{label}</h4>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2">
                      <div>
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">
                          {dashboardDetails?.charts?.water?.unit || "KL"}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <BarChart
                  series={[{ data, name: label }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.water?.unit || "KL"}
                />
              </div>
            </div>
          </div>
        ))}

      {/* Render bar charts for revenue data */}
      {
        revenueData.map(({ name, data, unit }, index) => (
          <div key={`revenue-chart-${index}`} className="col-xl-6 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4 donut-heading">
                  {name}
                  </h4>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2">
                      <div>
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">{dashboardDetails?.charts?.water?.unit} / {unit} </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <BarChart
                  series={[{ data, name }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.water?.unit +"/"+ unit}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default observer(WaterDashboardAccordion);
