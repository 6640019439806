import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";

export default class Social {
  formDataRaw = [];
  chartDataRaw = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      formDataRaw: observable,
      chartDataRaw: observable,
      formData: computed,
      chartData: computed,
      create: action.bound,
      fetch: action.bound,
      fetchChartData: action.bound,
    });
  }

  get chartData() {
    return toJS(this.chartDataRaw);
  }

  get formData() {
    return toJS(
      this.formDataRaw?.map((ele) => ({
        ...ele,
        group: ele.fieldGroup,
        fieldName: toJS(ele.fieldName).name,
      }))
    );
  }
}

Social.prototype.create = function ({
  payload,
  year,
  update,
  formId,
  showAlert,
  setEditForm,
  navigate,
  pageUrl,
  data,
  month,
}) {
  const {
    userStore: { userData },
    companyStore: { companyUnit, kpiId, subCatId, catId },
  } = this.rootStore;
  const token = JSON.parse(
    localStorage.getItem("access-token") ||
      localStorage.getItem("admin-access-token")
  )?.token;
  const formDataPayload = payload?.map((ele) => ({
    ...ele,
    attachment: "",
    year,
    companyUnit,
    kpiId,
    subCatId,
    catId,
    companyId: Number(toJS(userData)?.companyId),
    fieldGroup: ele.group,
    ...(month ? { months: month } : {}),
  }));
  const formData = new FormData();
  formData.append("data", JSON.stringify(formDataPayload));
  if (month) {
    formData.append("months", month);
  }
  payload.forEach((pay, index) => {
    if (pay.attachment) {
      formData.append(`file[]`, pay.attachment);
      formData.append(`index[]`, index);
    }
  });
  fetch(
    process.env.REACT_APP_BASE_URL +
      `social/${update ? "update" : "create"}${
        formId ? `?formId=${formId}` : ""
      }`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      if (response.status) {
        showAlert(response.msg, true);
        if (update) {
          setEditForm("");
        }
        if (response?.data?.groupsFilled?.length === data.length) {
          navigate(pageUrl);
          window.location.reload();
          return;
        }
        navigate(pageUrl, {
          state: {
            year: year,
            formId: response.data.formId,
            groupsFilled: response.data.groupsFilled,
            formData: data,
            month: month,
          },
        });
        return;
      }
      showAlert(response.message);
    })
    .catch((err) => {
      showAlert(err.message);
      console.error(err);
    });
};

Social.prototype.fetch = function ({ year, month }) {
  const {
    companyStore: { companyUnit, kpiId, subCatId, catId },
  } = this.rootStore;

  const urlSearchParams = new URLSearchParams({
    companyUnit,
    kpiId,
    subCatId,
    catId,
    year,
    ...(month ? { months: month } : {}),
  });

  fetchData(`social/getData?${urlSearchParams.toString()}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.status) {
        this.formDataRaw = res.data;
      }
    });
};

Social.prototype.fetchChartData = function ({ year }) {
  const {
    companyStore: { companyUnit },
    dashboardStore: { showAll },
  } = this.rootStore;
  const params = new URLSearchParams();
  params.append("year", year);
  if (!showAll) {
    params.append("companyUnit", companyUnit);
  }

  fetchData(`social/charts?${params.toString()}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.status) {
        this.chartDataRaw = res.data;
      }
    });
};
