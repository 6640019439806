import { observer } from "mobx-react";
import useStore from "../../../../hooks/useStore";
import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const LogBody = () => {
  const {
    formStore: { log },
  } = useStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState(null);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

   // Function to render key-value pairs with proper design
   const renderKeyValuePairs = (data, level = 0) => {
    if (typeof data !== 'object' || data === null) {
      return null;
    }

    return Object.entries(data).map(([key, value], index) => (
      <div
        key={index}
        style={{
          marginBottom: '10px',
          paddingLeft: `${level * 20}px`, // Indentation for nested objects
          borderLeft: level > 0 ? '1px solid #ccc' : 'none', // Add border for nested levels
        }}
      >
         {!Number.isNaN(Number(key)) ? (
          <span style={{ display: "none" }}>{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
        ) : (
          <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
        )}
        {' '}
        <div>
          {typeof value === 'object' && value !== null ? (
            <div style={{ backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
              {renderKeyValuePairs(value, level + 1)} {/* Recursively handle nested objects */}
            </div>
          ) : (
            value !== null ? value : 'null'
          )}
        </div>
      </div>
    ));
  };


  return (
    <>
      <tbody>
        {log.length === 0 ? (
          <tr>
            <td colSpan={5}>
              <div className="text-center">No records found</div>
            </td>
          </tr>
        ) : (
          log?.map((logItem, index) => (
            <tr key={index}>
              <td>{logItem?.user?.firstName}</td>
              <td>{new Date(logItem.createdAt).toLocaleDateString("en-GB")}</td>
              <td>{logItem.action}</td>
              <td>{logItem.ip}</td>
              <td>
                <button
                  onClick={() => {
                    setModalOpen(true);
                    setView(logItem.request);
                  }}
                >
                  View
                </button>
              </td>
            </tr>
          ))
        )}
      </tbody>
      <Modal show={modalOpen} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Log Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {view ? (
            <>
              <p><strong>Log Data:</strong></p>
              <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                {renderKeyValuePairs(view.body.data || view.query || view.body)}
              </div>
            </>
          ) : (
            <p>No details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default observer(LogBody);
