/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../../hooks/useStore";
import Layout from "../../../utils/layout";
import Performance from "../../../components/performance";
import Financials from "../../../components/financials";
import {
  ApprovalEnums,
  FINANCIALS_LABELS,
  PERMISSBLE_LIMIT_GUIDANCE,
  STATIC_GROUPS,
  USER_ROLE,
} from "../../../constants/constants";
import DashboardHistogram from "../../../components/dashboard-chart/dashboard-histogram";
import DynamicTable from "../../../datatable/DynamicTable";
import { observer } from "mobx-react";
import MobileTable from "../../../components/mobileTable/mobileTable";
import PaginationControls from "../../../datatable/paginationControls";
import { findKpi } from "../../../utils/helpers";
import { Modal, Button } from "react-bootstrap";
import { useAuth } from "../../../context/authProvider";
import LogTableBody from "../logForms/environmentLog";

const WaterDashboardPage = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);

  const navigate = useNavigate();
  const {
    userStore: { isLoggedIn, path, employeeKpis, user },
    companyStore: { units, fetchUnits, kpiName },
    formStore: { fetchForms, forms, approveForm, deleteForm, logRaw, logData },
  } = useStore();

  const { showAlert } = useAuth();

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );

  const handleModalOpen = (type, formId) => {
    setModalType(type);
    setSelectedFormId(formId);
    setShowModal(true);
  };
  const handleModalLogShow = async (formId) => {
    await logData({ formId });
    setModalType("log");
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedFormId(null);
    setModalType(null);
  };

  const handleConfirmAction = async () => {
    if (selectedFormId) {
      if (modalType === "approve") {
        await approveForm({
          formId: selectedFormId,
          type: "water",
          showAlert,
          status: ApprovalEnums.Approved,
          kpiName,
          year,
        });
      } else if (modalType === "delete") {
        await deleteForm({
          formId: selectedFormId,
          type: "water",
          showAlert,
          status: ApprovalEnums.Pending,
          kpiName,
          year,
        });
      }
      handleModalClose();
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Sr No", Cell: ({ row: { _index } }) => _index + 1 },
      {
        Header: "Frequency Type",
        Cell: ({
          row: {
            _original: { frequencyType },
          },
        }) => frequencyType || "-",
      },
      {
        Header: "Frequency Date",
        Cell: ({
          row: {
            _original: { frequencyDate },
          },
        }) => frequencyDate || "-",
      },
      {
        Header: "Frequency Month",
        Cell: ({
          row: {
            _original: { frequencyMonth },
          },
        }) => frequencyMonth || "-",
      },
      {
        Header: "Frequency Year",
        Cell: ({
          row: {
            _original: { frequencyYear },
          },
        }) => frequencyYear || "-",
      },

      {
        Header: "Updated By",
        width: 150,
        Cell: ({
          row: {
            _original: { updater, creater, updatedAt },
          },
        }) => {
          const updaterName = updater?.first_name
            ? `${updater.first_name} ${updater.last_name}`
            : creater?.first_name
            ? `${creater.first_name} ${creater.last_name}`
            : "-";
          const formattedDate = updatedAt
            ? new Date(updatedAt).toLocaleDateString("en-GB")
            : "";

          return (
            <div>
              <div>{updaterName}</div>
              {formattedDate && (
                <div style={{ fontSize: "0.8em", color: "gray" }}>
                  ({formattedDate})
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        Cell: ({
          row: {
            _original: { approvalStatus },
          },
        }) => (approvalStatus === "approved" ? "Approved" : "Pending" || "-"),
      },
      {
        Header: "Approved By",
        width: 150,
        Cell: ({
          row: {
            _original: { approver, approvedAt },
          },
        }) => {
          const approverName = approver?.first_name
            ? `${approver.first_name} ${approver.last_name}`
            : "-";
          const formattedDate = approvedAt
            ? new Date(approvedAt).toLocaleDateString("en-GB")
            : "";

          return (
            <div>
              <div>{approverName}</div>
              {formattedDate && (
                <div style={{ fontSize: "0.8em", color: "gray" }}>
                  ({formattedDate})
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "View",
        Cell: ({
          row: {
            _original: { formId },
          },
        }) => (
          <span
            onClick={() => {
              navigate(isLoggedIn ? "/company/data" : "/admin/data", {
                state: { formId, page: "water", store: "waterStore" },
              });
            }}
            className="btn btn-primary btn-sm btn-action waves-effect waves-light"
          >
            View
          </span>
        ),
      },

      {
        Header: "Log",
        Cell: ({
          row: {
            _original: { formId },
          },
        }) => (
          <i
            type="button"
            onClick={() => handleModalLogShow(formId)}
            className="mdi mdi-dots-vertical"
          ></i>
        ),
      },

      {
        Header: "Actions",
        width: 270,
        Cell: ({
          row: {
            _original: { formId, approvalStatus },
          },
        }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            {approvalStatus === ApprovalEnums.Approved ? (
              <span>-</span>
            ) : (
              <>
                {user.role === USER_ROLE.Company || kpi.isApproved ? (
                  <>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleModalOpen("approve", formId)}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                      onClick={() =>
                        navigate("/company/approve/environment", {
                          state: {
                            formId,
                            groupConstant: "water",
                            type: "water",
                            kpiName,
                          },
                        })
                      }
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleModalOpen("delete", formId)}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <>
                    {kpi && (
                      <>
                        {kpi.isEdited && (
                          <button
                            className="btn btn-primary btn-sm btn-action waves-effect waves-light"
                            onClick={() =>
                              navigate("/company/approve/environment", {
                                state: {
                                  formId,
                                  groupConstant: "water",
                                  type: "water",
                                  kpiName,
                                  year,
                                },
                              })
                            }
                          >
                            Edit
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ),
      },
    ],
    [navigate, isLoggedIn, kpiName, kpi, user.role]
  );

  useEffect(() => {
    fetchForms({ type: "water", page, pageSize: 10, filter: { year } });
  }, [fetchForms, page, year]);

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  const handleView = (formId) => {
    navigate(isLoggedIn ? "/company/data" : "/admin/data", {
      state: { formId, page: "water", store: "waterStore" },
    });
  };

  const handleEdit = (formId) =>
    navigate("/company/approve/environment", {
      state: {
        formId,
        groupConstant: "water",
        type: "water",
        kpiName,
        year,
      },
    });

  return (
    <Layout>
      <div className="water-list">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Water</h4>
                <div className="page-title-right">
                  {isLoggedIn && (
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        width: "fit-content",
                      }}
                    >
                      <div className="text-sm-end d-flex gap-1 flex-wrap">
                        <Performance label="Water" />
                        <Financials
                          groups={["A", "B", "C"].map((ele) => ({
                            group: ele,
                            label: FINANCIALS_LABELS.water[ele],
                          }))}
                          guidance={PERMISSBLE_LIMIT_GUIDANCE}
                          key="financials"
                          units={units?.filter((e) =>
                            ["Mega liter", "Kilo Liter", "Meter cube"].includes(
                              e.name
                            )
                          )}
                        />
                        <Link to={`/${path}/add-water`}>
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#newCustomerModal"
                            className="btn btn-secondary waves-effect waves-light addCustomers-modal "
                          >
                            <i className="mdi mdi-plus me-1"></i> Add water
                          </button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {Object.entries(STATIC_GROUPS.water).map(
              ([group, title], index) => (
                <div
                  style={{
                    flex: "1 1 calc(50% - 20px)",
                    boxSizing: "border-box",
                  }}
                  key={group}
                >
                  <DashboardHistogram
                    index={index}
                    title={title}
                    group={group}
                    reportType="water"
                  />
                </div>
              )
            )}
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2 align-items-center">
                    <div className="col-xl-3">
                      <label className="form-label">
                        Filter data on Annual basis
                      </label>
                      <select
                        className="form-select"
                        value={year || new Date().getFullYear()}
                        onChange={(event) =>
                          setYear(Number(event.target.value))
                        }
                      >
                        {Array.from({ length: 5 })
                          .map((_, i) => new Date().getFullYear() - i)
                          .map((v) => (
                            <option key={v} value={v}>{`FY ${v}-${
                              v + 1
                            }`}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="table  dashboard-table">
                    <DynamicTable
                      pageSize={forms?.records?.length || 0}
                      columns={columns}
                      data={forms?.records || []}
                    />
                  </div>
                  <MobileTable
                    handleModalOpen={handleModalOpen}
                    handleView={handleView}
                    handleEdit={handleEdit}
                  />
                  <PaginationControls
                    totalPages={forms?.totalPages}
                    page={page}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Existing component code */}

        <Modal
          show={showModal}
          onHide={handleModalClose}
          size={modalType === "log" ? "xl" : ""}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modalType === "approve"
                ? "Approve Form"
                : modalType === "delete"
                ? "Delete Form"
                : "Log Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: "hidden" }}>
            {modalType === "approve" && (
              <p>Are you sure you want to approve this form?</p>
            )}
            {modalType === "delete" && (
              <p>
                Are you sure you want to delete this form? Once deleted, all
                data associated with this form will be permanently removed and
                cannot be recovered.
              </p>
            )}
            {modalType === "log" && (
              <div style={{ overflowX: "auto" }}>
                <table className="table table-responsive scroll">
                  <thead>
                    <tr key={12} className="waterTabletr">
                      <th key={0}>Username</th>
                      <th key={1}>Date</th>
                      <th key={2}>Action</th>
                      <th key={3}>Ip</th>
                      <th key={4}>Request</th>
                    </tr>
                  </thead>
                  <LogTableBody logRaw={logRaw} formId={selectedFormId} />
                </table>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            {modalType === "approve" && (
              <Button variant="primary" onClick={handleConfirmAction}>
                Approve
              </Button>
            )}
            {modalType === "delete" && (
              <Button variant="primary" onClick={handleConfirmAction}>
                Delete
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default observer(WaterDashboardPage);
