import React from "react";
import ReactApexChart from "react-apexcharts";
const GradientDonutChart = ({
  data,
  labels,
  unit,
  heading,
  position,
  download
}) => {
  const options = {
    series: data,
    chart: {
      width: 380,
      type: "donut",
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    labels: labels,
    legend: {
      position:position || "bottom",
      formatter: function (val, opts) {
        return val + " : " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + (unit || "");
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div>
      <div id="chart">
      {heading && <h5>{heading ? heading +" ("+ unit +")" : ""}</h5>}
        <ReactApexChart
          options={options}
          series={options.series} 
          type="donut"
          height={380}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
export default GradientDonutChart;
