import React from "react";
import ReactApexChart from "react-apexcharts";
const BasicBarChart = ({ series, categories,heading,unit,download }) => {

  const options = {
    chart: {
      type: "bar",
      height: 350,  // Increased chart height
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download: download === false ? undefined : '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },
    plotOptions: {
      bar: {
        // borderRadius: 4,
        horizontal: true,
        barHeight: "70%",  // Adjust bar height for better spacing
        distributed: true,
      },
    },
    dataLabels: {
        position: "top", // top, center, bottom
    },
   
    xaxis: {
      categories: categories?.map(cat => cat.replace(" Emission", "")) || ["Scope 1", "Scope 2 ", "Scope 3", "Total GHG"],  // Default categories if none passed and remove " Emission" from the end of each category
    },
    colors: [
      "#008ffb",
      "#008ffb",
      "#008ffb",
      "#008ffb",
      "#A889C4",
      "#FFA07A",
      "#90EE90",
      "#87CEEB",
      "#DDA0DD",
    ],
    tooltip: {
      // enabled: true,
      y: {
        formatter: function (value) {
          return `${value} MT`;  // Show the unit in the tooltip
        },
      },
    },
  };

  return (
    <div>
        <div id="chart">
        {heading && <h5>{heading ? heading +" ("+ unit +")" : ""}</h5>}      
        <ReactApexChart
          options={options}
          series={series}  // Proper fallback for empty data
          type="bar"
          height={350}  // Increased the height here as well
        />
      </div>
    </div>
  );
};
export default BasicBarChart;
