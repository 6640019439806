import React from "react";

function WaterRecycleFilters({ label, selectedValue, onChange, options }) {
  return (
    <div className="mb-4">
      <label className="form-label fw-bold">{label}</label>
      <select className="form-select" value={selectedValue} onChange={onChange}>
        <option value="">Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default WaterRecycleFilters;
