import { observer } from "mobx-react";
import CustomTooltip from "../utils/tooltip/tooltip";
import { useState } from "react";
import { PERMISSBLE_LIMIT_GUIDANCE } from "../constants/constants";
import info from "../pages/info.svg";
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
function PermissbleLimit({ value = 0, type, date, unit }) {
  const [file, setFile] = useState(null);
  const year = new Date(date).getFullYear();
  const daysInYear = isLeapYear(year) ? 366 : 365;
  const calculatedValue = Math.round(
    (type === "Daily"
      ? Number(value) / daysInYear
      : type === "Monthly"
      ? Number(value) / 12
      : value) * 100
  ) / 100;

  return (
    <tr>
      <td colSpan={4}>
        Permissible Limit as per Regulatory Norm
        <CustomTooltip position="top" content={PERMISSBLE_LIMIT_GUIDANCE}>
          <img
            style={{
              marginLeft: "2px",
            }}
            src={info}
            alt=""
          />
        </CustomTooltip>
      </td>
      <td>
      <input
          value={`${calculatedValue} ${unit || ""}`}
          className="rounded form-control w-100 numberCountInput"
          readOnly
        />
      </td>
      <td colSpan={2} />
      <td>
        {file ? (
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="fileUploader"
          >
            <span>{file?.name.slice(0, 10) + "..."}</span>
            <span
              onClick={() => setFile(null)}
              style={{
                fontWeight: "700",
                cursor: "pointer",
                fontSize: "16px",
                lineHeight: "20px",
              }}
              className="crossBtn"
            >
              X
            </span>
          </span>
        ) : (
          <span
            w-100
            style={{
              position: "relative",
            }}
          >
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose File
            </div>
            <input
              name="attachment"
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        )}
      </td>
    </tr>
  );
}

export default observer(PermissbleLimit);
