import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/authProvider";

const AssignSubCategory = ({ setTab }) => {
  const { token, showAlert } = useAuth();
  const location = useLocation();
  const data = location.state;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}assign-companies/subcategory/${data?.id}/${selectedCategory}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setSubCategoryList(result.data);
            const assignedSubcategories = result.data.filter(
              ({ status }) => status
            );
            setSubcategories(
              assignedSubcategories.length === 0
                ? result.data.map(({ id }) => id)
                : assignedSubcategories.map(({ id }) => id)
            );
          }
        })
        .catch((error) => console.error(error));
    }
  }, [selectedCategory, data?.id, token]);

  const handleSave = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const raw = JSON.stringify({
      companyId: data?.id,
      catId: selectedCategory,
      subcategories: subcategories,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}assign-companies/updateassignsubcategories`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showAlert(result.msg || result.message, result.status || false);
        // if (result.status) {
        //   setTab("KPI");
        // }
      })
      .catch((error) => console.error(error));
  };

  const handleAssignSubCategory = () => {
    handleSave();
    setTab("KPI");
  };

  return (
    <div className="mt-10">
      <AssignDropdownRow
        data={data}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        token={token}
      />
      <div className="table-responsive">
        <table className="table align-middle table-bordered table-nowrap mb-0">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Sub Category</th>
              <th>Assign</th>
            </tr>
          </thead>
          <tbody>
            {subCategoryList.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td>
                  <div className="form-check font-size-20 p-0">
                    <input
                      className="form-check-input m-0 float-none"
                      type="checkbox"
                      checked={subcategories?.includes(item?.id)}
                      id={index}
                      onChange={(e) => {
                        if (subcategories?.includes(item?.id)) {
                          setSubcategories(
                            subcategories?.filter((id) => id !== item?.id)
                          );
                        } else if (e.target.checked) {
                          setSubcategories([...subcategories, item?.id]);
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <button onClick={handleSave} className="btn btn-primary px-4">
            Save
          </button>
          <button
            className="btn btn-primary px-4 mx-4"
            onClick={handleAssignSubCategory}
          >
            Save & Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignSubCategory;

const AssignDropdownRow = ({
  data,
  selectedCategory,
  setSelectedCategory,
  token,
}) => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}assign-companies/category/${data?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setCategoryList(result.data);
          const isSelected = result.data.find((item) => item.status)?.catId;
          setSelectedCategory(isSelected);
        }
      })
      .catch((error) => console.error(error));
  }, [data?.id, token, setSelectedCategory]);

  return (
    <div className="row align-items-center">
      <div className="col-md-3">
        <div className="mb-3">
          <label className="form-label">Category</label>
          <select
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {categoryList?.map((item, index) => (
              <option key={index} value={item.catId}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
